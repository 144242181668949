import { t } from "pages/microservices/masterData/translation/Translation";
import { TITLE_ALREADY_EXISTS } from "pages/microservices/project/context/Constants";

import { isValidPhoneNumber } from "react-phone-number-input";

export interface ValidationErrors {
  [key: string]: string;
}

export interface Option {
  value?: number | string | null;
  label: string;
}

interface pcProps {
  PC: Option | null;
  employeeType: any[];
}

export function validateRequired(value: string): string | null | undefined {
  if (!value || (typeof value === "string" && value.trim() === "")) {
    return t("This field is required");
  }

  return null;
}

export function validateCkeditorData(value: string): string | null {
  if (value === "<p>&nbsp;</p>") {
    return t("This field is required");
  }

  return null;
}

export function validateNumber(value: string): string | null {
  const numberRegex = /^\d+(\.\d+)?$/;

  if (!value.trim()) {
    return null;
  }

  if (!numberRegex.test(value)) {
    return "This field is invalid. Please enter a valid number.";
  }

  return null;
}
export function validateNumberIfNotRequired(value: string): string | null {
  const numberRegex = /^\d+(\.\d+)?$/;
  if (!value) {
    return null;
  }
  if (!numberRegex.test(value)) {
    return "This field is invalid. Please enter a valid number.";
  }

  return null;
}

export function validateAmount(value: string): string | null {
  const numberRegex = /^(\d+|\d+\.\d*|\d*\.\d+)?([,.]\d{1,5})*$/;

  if (!numberRegex.test(value)) {
    return "This field is invalid. Please enter a valid format.";
  }

  return null;
}

export function validateVAT(value: string) {
  const cleanedValue = value.replace(/[\s-]/g, '');
  const vatPattern = /^BE\d{9,10}$/;

  if (!vatPattern.test(cleanedValue)) {
    return "This field is invalid";
  }
  return null;

}

export function validateWebsiteField(value: string): string | null {
  const urlRegex = /^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?$/;
  if (!urlRegex.test(value)) {
    return "This field is invalid.";
  }
  return null;
}

export function validateDate(value: string): string | null {
  if (value === null) {
    return t("This field is required");
  }

  return null;
}

export function validateAlphaNumeric(value: string): string | null {
  // Regular expression to check for alphanumeric characters, hyphen, and spaces
  if (!/^[a-zA-Z0-9\s\-']+$/u.test(value)) {
    return t("This field is invalid");
  }

  return null;
}

export function validateFileId(value: number): string | null {
  if (value === null) {
    return t("This field is required");
  }

  return null;
}

export function validatePhoneNumber(value: string): string | null {
  if (value === "" || typeof value !== "string") {
    return t("This field is required");
  }

  if (!isValidPhoneNumber(value)) {
    return t("This field is invalid.");
  }

  return null;
}

export function validatePhoneNumberIfNotRequired(value: string): string | null {
  if (value === "" || typeof value !== "string") {
    return null;
  }
  if (!isValidPhoneNumber(value)) {
    return t("This field is invalid.");
  }
  return null;
}

export const validateSelectField = (selectedObject: {
  value: number | string;
}) => {
  if (!selectedObject || selectedObject.value === "") {
    return t("This field is required");
  }
  return null;
};

export const validateMultiSelectField = (value: object[]) => {
  if (!Array.isArray(value) || value.every((item) => item === undefined)) {
    return t("This field is required");
  }
  return null;
};

export const validatePCEmployeeType = (value: pcProps[]) => {
  if (
    value.length > 0 &&
    value.some((item) => item.employeeType.length === 0)
  ) {
    return "Please select the employee types for PC";
  }
  return null;
};

export const validateFunctionField = (value: object[]) => {
  if (!Array.isArray(value) || value.every((item) => item === undefined)) {
    return t("Please select atleast one function");
  }
  return null;
};

export const scrollToTop = () => {
  return window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};
export function validateTextFiled(value: string): string | null {
  if (!/^[A-Za-z]+$/.test(value)) {
    return t("Only alphabets are allowed");
  }

  return null;
}

export function validateTextFiledSpace(value: string): string | null {
  if (!/^[A-Za-z\s]+$/.test(value)) {
    return t("Only alphabets and spaces are allowed");
  }

  return null;
}

export function validateTextFieldAlpha(value: string): string | null {
  // Regular expression to check for alphanumeric characters, accented characters, and spaces
  if (!/^[a-zA-Z0-9\u00C0-\u024F\u1E00-\u1EFF\s']+$/u.test(value)) {
    return t(
      "Only alphanumeric characters, accented characters, and spaces are allowed"
    );
  }

  return null;
}

export function validateUniqueKey(value: string): string | null {
  if (!/^[a-z_]+$/.test(value)) {
    return t("Unique Key can only contain lowercase letters and underscores");
  }

  return null;
}

export function validateCheckbox(value: boolean): string | null {
  if (value !== true) {
    return t("This field is required");
  }

  return null;
}

export function validateLocationPhone(value: any) {
  if (!/^\+?[0-9]+([- ]?[0-9]+)*$/.test(value)) {
    return t("This field is invalid");
  }
}

export const validateFileRequired = (file: File | null): string | null => {
  return !file ? t("This field is required") : null;
};

export function validateMinLength(
  value: string,
  minLength: number
): string | null {
  if (value.length < minLength) {
    return t(`Value must be at least ${minLength} characters long`);
  }

  return null;
}

export function validateMaxLength(
  value: string,
  maxLength: number
): string | null {
  if (value.length > maxLength) {
    return t(`Value must be at most ${maxLength} characters long`);
  }

  return null;
}

export function validateCheckboxes(
  internal: boolean,
  external: boolean
): string | null {
  if (!internal && !external) {
    return t("This field is required");
  }

  return null;
}

export function validatePassword(value: string): string | null {
  if (value.trim() === "") {
    return t("Password is required");
  }

  if (value.length < 8) {
    return t("Password must be at least 8 characters long");
  }

  if (value.length > 50) {
    return t("Password cannot exceed 50 characters");
  }

  if (!/[A-Z]/.test(value)) {
    return t("Password must contain at least one uppercase letter");
  }

  if (!/[a-z]/.test(value)) {
    return t("Password must contain at least one lowercase letter");
  }

  if (!/\d/.test(value)) {
    return t("Password must contain at least one number");
  }

  if (!/[!@#$%^&*()\-=_+[\]{}|\\;:'",.<>?]/.test(value)) {
    return t("Password must contain at least one special character");
  }

  return null;
}

export const validateMatchPassword = (
  password: string,
  confirmPassword: string
): string | null => {
  if (password !== confirmPassword) {
    return t("Passwords do not match");
  }
  return null;
};

// ValidationService.ts

export const validateTokens = (expectedTokens: string[]) => {
  return (value: string) => {
    if (expectedTokens?.length > 0) {
      const tokens = value.match(/\[[^\]]+\]/g) ?? [];
      const invalidTokens = tokens.filter(
        (token) => !expectedTokens.includes(token)
      );
      if (invalidTokens.length > 0) {
        return t(`Tokens must match the list of expected tokens`);
      }
    }
    return undefined;
  };
};

export function validateEmail(value: string): string | null {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  if (!emailRegex.test(value)) {
    return t("Invalid email address");
  }

  // if (/[A-Z]/.test(value)) {
  //   return t("Email adress should not contain uppercase letters");
  // }

  return null;
}

export function validateTimeFormat(value: string): string | null {
  // const timeRegex = /^([01]\d|2[0-3]):?([0-5]\d)(:?([0-5]\d))?$/;
  const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;
  if (!timeRegex.test(value)) {
    return "This field is invalid. Please use HH:mm";
  }

  return null;
}

export function validateMaxTimeFormat(value: string): string | null {
  // const timeRegex = /^(0?[0-9]|[1-9][0-9]*):?([0-5]\d)(:?([0-5]\d))?$/;
  const timeRegex = /^([0-4]?[0-9]):([0-5][0-9])$/;

  if (!timeRegex.test(value)) {
    return "This field is invalid. Please use HH:mm format less than 50 hrs.";
  }

  return null;
}
// Validate time format (HH:mm)
export const isValidTime = (time: string) => {
  const regex = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
  return regex.test(time);
};

export function validatePercentage(value: any) {
  if (!isNaN(value)) {
    const parsedValue = parseFloat(value);
    if (parsedValue >= 0 && parsedValue <= 100) {
      return null;
    }
    return "This should be between 0-100";
  }
  return "This field is invalid";
}

export function validateTimeRange(from: any, to: any, pause?: any): string | null {
  if ((!isValidTime(from) || !isValidTime(to))) {
    return 'Enter valid time';
  }
  // Helper function to convert time string to minutes
  const convertToMinutes = (time: any) => {
    if (time) {
      const [hours, minutes] = time?.split(":")?.map(Number);
      return hours * 60 + minutes;
    }
  };
  const fromMinutes = convertToMinutes(from);
  const toMinutes = convertToMinutes(to);
  const pauseMinutes = pause ? convertToMinutes(pause) : 0;
  let totalWorkingMinutes;
  if (toMinutes > fromMinutes) {
    totalWorkingMinutes = toMinutes - fromMinutes;
  } else {
    totalWorkingMinutes = (1440 - fromMinutes) + toMinutes; // span midnight
  }
  if (pause) {
    if (pauseMinutes >= totalWorkingMinutes) {
      return "Pause time must be less than the difference between From and To time";
    }
  }
  // else if (totalWorkingMinutes === 0) {
  //   return "From time should not be equal to to time";
  // }
  return null;
}

export function validateDateRange(start: any, end: any) {
  if (start > end) {
    return "Start date must be less than or equal to end date";
  }
  return null;
}

export function validateStartDate(value: string): string | null {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const inputDate = new Date(value);
  inputDate.setHours(0, 0, 0, 0);

  if (inputDate < today) {
    return "Date must be today or later.";
  }

  return null;
}

export function validateRadioField(value: number | null) {
  if (value === null) {
    return "This field is required";
  }
  return null;
}

export function validateLinkedinUrl(value: string | null) {
  let linkedinRegex = /^https:\/\/[a-z]{2,3}\.linkedin\.com\/.*$/;
  if (value) {
    return !linkedinRegex.test(value) ? t("Incorrect format") : null;
  }
  return null;
}

const getValidationRules = (
  validationRules: { [key: string]: Function[] },
  fieldName?: string
) => {
  if (fieldName && validationRules.hasOwnProperty(fieldName)) {
    return {
      [fieldName]: validationRules[fieldName],
    };
  }

  return validationRules;
};

/**
 * @param formData
 * in format:
 * { myFieldKey: myFieldValue,  myFieldKey2: myFieldValue2, }
 *
 * @param validationRules
 * Functions that are compatible as ValidationRule
 *
 * @param fieldName
 * The `name`attribute of the field
 * @param fieldValue
 * The current passed value, instead of the value that is available in state
 *
 * @returns object with single error per field passed to formData (if any)
 * in format:
 * { myFieldKey: myFieldError,  myFieldKey2: myFieldError2, }
 */
export function validateForm(
  formData: {
    [key: string]:
    | string
    | boolean
    | number
    | object
    | Date
    | File
    | null
    | object[]
    | Option
    | any
    | undefined;
  },
  validationRules: { [key: string]: Function[] },
  fieldName?: string,
  fieldValue?: any
): ValidationErrors {
  const errors: ValidationErrors = {};
  const vRules: any = getValidationRules(validationRules, fieldName);
  for (const key in formData) {
    if (formData.hasOwnProperty(key)) {
      // const value = formData[key];
      const value = fieldValue ?? formData[key];
      const rules = vRules[key];

      if (rules) {
        for (const rule of rules) {
          const error = rule(value);

          if (error) {
            errors[key] = error;
            break;
          }
        }
      }
    }
  }

  return errors;
}

export function validateSequenceForm(formData: {
  [key: string]:
  | string
  | boolean
  | number
  | object
  | Date
  | File
  | null
  | object[]
  | Option
  | any
  | undefined;
}) {
  const errors: ValidationErrors = {};

  if (
    (!formData.sequenceName ||
      (typeof formData.sequenceName === "string" &&
        formData.sequenceName.trim() === "")) &&
    formData.sequence.length === 0
  ) {
    errors.sequenceName = "This field is required";
  } else {
    // sequence title validation
    let index = formData.sequence
      .filter(
        (sequence: any) =>
          sequence.name.toLocaleLowerCase() !==
          formData.edit?.toLocaleLowerCase()
      )
      .findIndex(
        (item: any) =>
          item.name.toLocaleLowerCase() ===
          formData.sequenceName.toLocaleLowerCase()
      );
    if (index !== -1) {
      errors.sequenceName = TITLE_ALREADY_EXISTS;
    }
  }

  return errors;
}

/**
 * @param formData
 * in format:
 * { myFieldKey: myFieldValue,  myFieldKey2: myFieldValue2, }
 *
 * @param validationRules
 * Functions that are compatible as ValidationRule
 *
 * @param fieldName
 * The `name`attribute of the field
 * @param fieldValue
 * Optional - The current passed value, instead of the value that is available in state
 *
 * @returns string single error per field passed to formData (if any)
 */
export function validateFormField(
  formData: {
    [key: string]:
    | string
    | boolean
    | number
    | object
    | Date
    | File
    | null
    | object[]
    | Option
    | undefined;
  },
  validationRules: { [key: string]: Function[] },
  fieldName: string,
  fieldValue?: any
): string {
  let fieldError: ValidationErrors = validateForm(
    formData,
    validationRules,
    fieldName,
    fieldValue
  );
  return fieldError[fieldName] ? fieldError[fieldName] : "";
}

/**
 * IMPORTANT: not compatable with `src/services/validation/ValidationService/validateForm*` functions as ValidationRule!
 *
 * @param from
 * @param to
 * @param errorMessage
 * The translatable errorMessage to be displayed
 * @returns
 */
export function validateDateIsAfter(
  from: Date | undefined | null,
  to: Date | undefined | null,
  errorMessage: string
): string | undefined {
  if (!from || !to) {
    return "";
  }
  if (from > to) {
    return errorMessage;
  }
}

export function validateMultiChoiceAnswer(value: number[]) {
  if (value.length === 0) {
    return t("Check atleast one option as answer");
  }
  return undefined;
}
export function validateNoSpaces(value: string) {
  return value.indexOf(' ') === -1 ? null : 'Value should not contain spaces';
};
export function validateMultiChoice(value: string[]) {
  if (value.length < 4) {
    return t("Add 4 options for multiple choice question");
  }
  return undefined;
}

export function validateDichtmsOptionsAnswer(value: number[]) {
  if (value.length === 0) {
    return t("Check any one option as answer");
  }
  return undefined;
}

export function validateDichtmsOptions(value: string[]) {
  if (value.length < 2) {
    return t("Add 2 options for dichotomous question");
  }
  return undefined;
}

export function validateSSN(ssn: string): string | null  {
  if(ssn === null){
    return "Please enter a valid ssn";
  }
  if (ssn === "" ) {
    return null;
  }
  const ssnNumber = ssn.replace(/[-. ]/g, ""); // remove -, ., and space from ssn
  if (ssnNumber.length !== 11) {
    return t("This field is invalid");
  }
  const ssnLastTwoDigits = parseInt(ssnNumber.substring(9, 11)); //get the last two digits
  const yyMMdd = ssnNumber.substring(0, 6); // get first 6 digits
  const XXX = ssnNumber.substring(6, 9); // get 3 digits after 6th index

  // Combine the portions into a single numeric value
  const combinedValue = parseInt(yyMMdd + XXX);
  const secondNo = 2000000000 + combinedValue;

  if (checkSum(combinedValue) === ssnLastTwoDigits) {
    return null;
  }
  if (checkSum(secondNo) === ssnLastTwoDigits) {
    return null;
  } else {
    return t(`${ssnNumber} is not a valid ssnumber (incorrect checksum)`);
  }
}

function checkSum(number: any, modulusValue = 97) {
  const remainder = number % modulusValue;
  return modulusValue - remainder;
}
