import React from "react";
import DaysOfWeekTable from "pages/microservices/project/components/shiftTable";
import { t } from "pages/microservices/masterData/translation/Translation";
import { useFormContext } from "pages/microservices/project/context/Context";
import { calculateWeeklyTotal } from "pages/microservices/project/Utils";
import LabelField from "components/atoms/LabelField";
interface ShiftTableProps {
  selectedOption: any;
}

const ShiftTable: React.FC<ShiftTableProps> = ({ selectedOption }) => {
  const { state, dispatch } = useFormContext();
  const currentShift: any = state?.shifts?.data?.shiftTable;

  return (
    <div key={selectedOption.value}>
      <div className="row">
        <div className="col-6">
          <LabelField title={selectedOption.label} className="tab-subtitle" />
        </div>
        <div className="col-6 text-end tab-subtitle">
          {currentShift?.[selectedOption.value] && (
            <>
              {t("Total weekly time")}:
              {calculateWeeklyTotal(currentShift?.[selectedOption.value])}
            </>
          )}
        </div>
      </div>
      <DaysOfWeekTable
        selectedShift={selectedOption}
        tableIndex={selectedOption.value}
      />
    </div>
  );
};

export default ShiftTable;
