import React, { useState, useEffect, useRef } from "react";

import { t } from "../translation/Translation";
import WindowHeightComponent from "components/atoms/WindowHeightComponent";
import { useNavigate, useParams } from "react-router-dom";
import Button from "components/atoms/Button";
import {
  PATH_ACCESS_DENIED,
  PATH_CONTRACT_OVERVIEW,
  PATH_EMPLOYEE_MANAGE,
  PATH_HOME,
} from "constants/Paths";
import LoadingIcon from "utils/LoadingIcon";
import { ApiCall } from "services/ApiServices";
import { M_MASTER_DATA } from "constants/Constants";
import {
  APPROVE_CONTRACT,
  SEND_CONTRACT_MAIL,
  SIGNATURE,
} from "routes/ApiEndpoints";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import CheckBoxField from "components/atoms/CheckBoxField";
import EmployerAuthenticModal from "pages/microservices/project/Proposals/EmployerAuthenticModal";
import SignatureModal from "pages/microservices/project/Proposals/SignatureModel";
import SignatureCanvas from "react-signature-canvas";
import CustomNotify from "components/atoms/CustomNotify";
const ContractOverview: React.FC = () => {
  const url = new URL(window.location.href);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showSignatureModal, setSignatureModal] = useState(false);
  const [AuthenticatedMail, setAuthenticatedMail] = useState("");
  const [signatureErrorMessage, setSignatureErrorMessage] = useState("");
  const signatureRef = useRef<SignatureCanvas | null>(null);
  const [agreement, setAgreement] = useState(false);
  const [approve, setApprove] = useState(false);
  const [approveNow, setApproveNow] = useState(false);
  const userAuth = useSelector(selectAuth);
  const handleAuthenticationSuccess = (
    success: any,
    email: any,
    signatureImage: any
  ) => {
    setIsAuthenticated(success);
    setAuthenticatedMail(email);
    handleModalClose();
  };
  const handleCheckbox = (checked: boolean) => {
    setChecked((prevState) => ({
      ...prevState,
      check: checked ? 1 : 0,
    }));
    setIsAuthenticated(false);
  };
  const [checked, setChecked] = useState({
    check: 0,
  });
  const htmlPath = url.searchParams.get("htmlPath");

  let id = url.searchParams.get("id");
  if (isNaN(Number(id))) {
    const decryptedProjectId = id ? atob(id) : "";
    const splitArray = decryptedProjectId.split("id-");
    id = splitArray[1];
  }

  useEffect(() => {
    if (htmlPath) {
      const agreeParam = url.searchParams.get("agree");
      const signParam = url.searchParams.get("sign");
      if (url.searchParams.get("approve") == "true") {
        setApproveNow(true);
      }

      if (agreeParam === "contract") {
        setAgreement(true);
        const requestIdAsNumber = id ? parseInt(id.toString(), 10) : null;
        if (
          requestIdAsNumber !== null &&
          typeof requestIdAsNumber === "number" &&
          typeof userAuth.userId === "number"
        ) {
          if (requestIdAsNumber !== userAuth.employeeId) {
            navigate(PATH_ACCESS_DENIED);
          }
        }
      }

      if (signParam == "true") {
        setApprove(true);
      }
    }
  }, [htmlPath, url.searchParams, id, userAuth.userId]);

  const handleModalClose = () => {
    setShowModal(false);
    setSignatureModal(false);
  };
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const requestData = {
    userId: id,
  };
  const handleSend = async () => {
    setLoading(true);
    try {
      const url = `${SEND_CONTRACT_MAIL}`;
      const response = await ApiCall.service(
        url,
        "POST",
        requestData,
        false,
        M_MASTER_DATA
      );
      if (response?.status === 200) {
        CustomNotify({ type: "success", message: ` ${response?.message}` });
        navigate(PATH_EMPLOYEE_MANAGE);
      } else {
        //CustomNotify({ type: "error", message: `Error: ${response.message}` });
      }
    } catch (error) {
      //console.error(error);
    }
    setLoading(false);
  };
  const handleApprove = async () => {
    try {
      const url = `${APPROVE_CONTRACT}`;
      const response = await ApiCall.service(
        url,
        "POST",
        requestData,
        false,
        M_MASTER_DATA
      );
      if (response?.status === 200) {
        CustomNotify({ type: "success", message: ` ${response?.message}` });
        navigate(PATH_HOME);
      } else {
        //CustomNotify({ type: "error", message: `Error: ${response.message}` });
      }
    } catch (error) {
      //console.error(error);
    }
  };
  const handleBack = () => {
    navigate(PATH_EMPLOYEE_MANAGE);
  };
  const handleClearSignature = () => {
    signatureRef.current?.clear();
  };

  const handleSaveSignature = async () => {
    if (!signatureRef.current?.isEmpty()) {
      const signatureImage = signatureRef.current?.toDataURL();
      handleModalClose();
      setSignatureErrorMessage("");
      const url = `${SIGNATURE}`;
      const data = {
        signature: signatureImage,
        userId: id,
      };
      const response = await ApiCall.service(
        url,
        "POST",
        data,
        true,
        M_MASTER_DATA
      );
      if (response?.status === 200) {
        const htmlFilePath = response?.data.html_file_path;
        const userId = response.userId;
        navigate(
          `${PATH_CONTRACT_OVERVIEW}?htmlPath=${htmlFilePath}&id=${userId}&agree=${"contract"}&sign=${true}`
        );
      }
    } else {
      setSignatureErrorMessage(t("Please add signature before saving."));
    }
  };
  const handleAddSignature = () => {
    setSignatureModal(true);
  };

  return (
    <>
      <WindowHeightComponent>
        <div className="d-flex h-100 overflow-auto flex-column">
          <div className="flex-1 overflow-auto">
            <iframe
              src={`${process.env.REACT_APP_CONTRACT_URL}/${htmlPath}`}
              height="99%"
              width="100%"
            />
          </div>
        </div>
      </WindowHeightComponent>
      <div className="">
        {!agreement ? (
          <div className="d-flex justify-content-between align-items-center">
            <Button
              type="button"
              title={t("Back")}
              handleClick={handleBack}
              className="btn form-button px-3 shadow-none text-uppercase"
            />
            {!loading ? (
              <Button
                type="button"
                title={t("send")}
                handleClick={handleSend}
                className="btn form-button px-3 ms-3 shadow-none text-uppercase"
              />
            ) : (
              <LoadingIcon
                className="ms-3 h-100"
                iconType="bars"
                color="#00a5ce"
              />
            )}
          </div>
        ) : (
          !isAuthenticated &&
          !approve && (
            <CheckBoxField
              name={"checkbox"}
              onChangeHandler={(e) => handleCheckbox(e.target.checked)}
              isChecked={checked.check === 1}
              label={t(
                "I hereby declare that I have reviewed the document and confirming that I agree with all details"
              )}
            />
          )
        )}
      </div>
      <div className="d-flex justify-content-between align-items-center">
        {approve && (
          <Button
            type="button"
            title={t("Approve")}
            handleClick={handleApprove}
            className="btn form-button px-3 shadow-none text-uppercase"
          />
        )}
      </div>

      {/* {checked.check === 1 && !isAuthenticated && !showModal && (
        <EmployerAuthenticModal
          handleClose={handleModalClose}
          onAuthenticationSuccess={handleAuthenticationSuccess}
        />
      )} */}
      <div>
        {showSignatureModal && checked.check === 1 && (
          <SignatureModal
            handleClose={handleModalClose}
            handleClearSignature={handleClearSignature}
            handleSaveSignature={handleSaveSignature}
            signatureRef={signatureRef}
            signatureErrorMessage={signatureErrorMessage}
          />
        )}
      </div>
      <div className="col-md-6 mb-3 my-3">
        {/* {isAuthenticated */}
        {checked.check === 1 &&
          !approve && ( // Conditionally render buttons based on authentication status
            <Button
              type="button"
              title={t("Add signature")}
              handleClick={handleAddSignature}
              className="form-button"
            />
          )}
      </div>
    </>
  );
};

export default ContractOverview;
