import React, { useEffect, useState } from "react";
import { useFormContext } from "pages/microservices/companyCreation/context/Context";
import Basic from "./Basic";
import Address from "./Address";
import Contacts from "./Contacts";
import { useParams } from "react-router-dom";
import { COMPANY_DETAILS } from "routes/ApiEndpoints";
import { ApiCall } from "services/ApiServices";
import { mapToSelect } from "utils/MapToSelect";
import { M_COMPANY_CREATION } from "constants/Constants";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import CompanyLocation from "./CompanyLocation";
import { t } from "pages/microservices/masterData/translation/Translation";

type DynamicActionType =
  | "UPDATE_BASIC_FIELD"
  | "UPDATE_ADDRESS_FIELD"
  | "UPDATE_SELECT_FIELD";

export const CompanyForm = () => {
  const { state, dispatch } = useFormContext();

  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 60) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const { companyId } = useParams<{ companyId: string }>();

  useEffect(() => {
    const fetchDataFromUrl = async () => {
      try {
        const url = !companyId
          ? COMPANY_DETAILS
          : `${COMPANY_DETAILS}/${companyId}`;
        const response = await ApiCall.getService(
          url,
          "GET",
          M_COMPANY_CREATION,
          false
        );

        if (response?.status === 200) {
          const {
            basicDetails,
            addressDetails,
            contactDetails,
            companyLocationDetails,
            countries,
            paritairCommittees,
            locations,
            parentCompany,
            naceCodes,
            bussinessUnit,
            roles,
            genders,
            language,
          } = response.data;
          const pcOptions = paritairCommittees.map((item: any) => {
            return {
              value: item.id,
              label: `(${item.pc_number}) ${item.pc_name}`,
            };
          });

          const parentCompanyOptions = parentCompany.map((item: any) => ({
            value: item.id,
            label: item.company_name,
          }));
          dispatch({
            type: "SET_DROPDOWN",
            dropdownValues: mapToSelect(roles),
            field: "rolesList",
          });
          dispatch({
            type: "SET_DROPDOWN",
            dropdownValues: mapToSelect(countries),
            field: "countryList",
          });
          dispatch({
            type: "SET_DROPDOWN",
            dropdownValues: mapToSelect(genders),
            field: "genders",
          });
          dispatch({
            type: "SET_DROPDOWN",
            dropdownValues: pcOptions,
            field: "pcList",
          });
          dispatch({
            type: "SET_DROPDOWN",
            dropdownValues: language,
            field: "languageList",
          });
          dispatch({
            type: "SET_DROPDOWN",
            dropdownValues: locations,
            field: "locationList",
          });
          dispatch({
            type: "SET_DROPDOWN",
            dropdownValues: parentCompanyOptions,
            field: "companiesList",
          });
          dispatch({
            type: "SET_DROPDOWN",
            dropdownValues: mapToSelect(naceCodes),
            field: "naceCodesList",
          });
          if (userData.role.includes("SALES") || userData.role.includes("HR")) {
            dispatch({
              type: "SET_DROPDOWN",
              dropdownValues: bussinessUnit,
              field: "bussinessUnitList",
            });
            dispatch({
              type: "UPDATE_BASIC_FIELD",
              field: "isBussinessUnit",
              value: true,
            });
            dispatch({
              type: "UPDATE_BASIC_FIELD",
              field: "bussinessUnit",
              value: userData.businessUnit,
            });
            dispatch({
              type: "UPDATE_BASIC_FIELD",
              field: "companyType",
              value: { value: 2, label: "External" },
            });
          } else {
            dispatch({
              type: "SET_DROPDOWN",
              dropdownValues: bussinessUnit,
              field: "bussinessUnitList",
            });
            dispatch({
              type: "UPDATE_BASIC_FIELD",
              field: "isBussinessUnit",
              value: false,
            });
            dispatch({
              type: "UPDATE_BASIC_FIELD",
              field: "bussinessUnit",
              value: userData.businessUnit,
            });
          }

          // Dispatch actions to update basic and address fields
          const fieldMappings = [
            {
              fieldPrefix: "basic",
              data: basicDetails,
            },
            {
              fieldPrefix: "address",
              data: addressDetails,
            },
          ];
          fieldMappings.forEach(({ fieldPrefix, data }) => {
            if (data) {
              Object.entries(data).forEach(([field, value]) => {
                const dynamicActionType: DynamicActionType =
                  `UPDATE_${fieldPrefix.toUpperCase()}_FIELD` as DynamicActionType;
                dispatch({
                  type: dynamicActionType,
                  field,
                  value: value as string | null | number,
                });
              });
            }
          });
          if (contactDetails && contactDetails.length > 0) {
            dispatch({
              type: "ADD_UPDATE_DYNAMIC",
              field: "companyContactPersonTable",
              value: contactDetails,
            });
          }
          if (companyLocationDetails && companyLocationDetails.length > 0) {
            dispatch({
              type: "ADD_UPDATE_DYNAMIC_COMPANY_LOCATION",
              field: "companyLocationTable",
              value: companyLocationDetails,
            });
          }
        }
      } catch (error) {}
    };
    fetchDataFromUrl();
  }, [companyId, dispatch]);

  const handleTabClick = (tabId: string) => {
    const currentTab = state.tabDetails.findIndex((tab) => tabId === tab.id);
    state.tabDetails[currentTab].error = false;
    const updatedTabDetails = state.tabDetails.map((tab) => ({
      ...tab,
      showStatus: tab.id === tabId,
    }));

    dispatch({ type: "UPDATE_TAB_DETAILS", tabDetails: updatedTabDetails });
  };

  const ConstructTabs = (id: string, showStatus: boolean) => {
    switch (id) {
      case "basic":
        return <Basic />;
      case "address":
        return <Address />;
      case "companyLocation":
        return <CompanyLocation />;
      case "contacts":
        return <Contacts />;

      default:
        break;
    }
  };

  const permissionType = companyId ? "update" : "create";
  const permissionObject: any = {
    permission: "Company",
  };
  permissionObject[permissionType] = true;
  const userData = useSelector(selectAuth);

  return (
    <>
      <div className="position-relative">
        <AccessControl
          requiredPermissions={[permissionObject]}
          renderNoAccess={true}
          override={userData.isSuperAdmin}
        >
          <div
            className="row header-sticky position-sticky"
            style={{ marginBottom: "2.3vw", height: "auto" }}
          >
            <div className="col-12">
              <h1
                className="page-title mb-0 d-flex align-items-center"
                style={{ paddingBottom: "0.7vw" }}
              >
                {t("Create company")}
              </h1>
            </div>
          </div>
          <nav
            className={`projectTabs company-step-tabs createFormTabs ${
              isSticky
                ? "createTabsSticky position-sticky"
                : "company-step-tabs-absolute position-absolute w-100"
            }`}
          >
            <div
              className="d-flex border-0 w-100 m-auto gap-3"
              id="nav-tab"
              role="tablist"
            >
              <div
                className={`d-flex border-0 w-75 m-auto gap-3 align-items-center justify-content-center`}
              >
                {state.tabDetails.map((item: any, index) => (
                  <button
                    key={item.id}
                    className={`createStepBtn  shadow-none  text-break ${
                      item.error ? "error" : ""
                    } ${item.showStatus ? "active" : "inactive"}`}
                    id={`${item.id}-tab`}
                    data-bs-toggle="tab"
                    data-bs-target={`#${item.id}`}
                    type="button"
                    role="tab"
                    aria-controls={`nav-${item.id}`}
                    aria-selected={item.showStatus}
                    onClick={() => handleTabClick(item.id)}
                  >
                    {/* <div className="steps rounded-circle">{`${index + 1}`}</div> */}
                    <div className="step-title">{item.title} </div>
                  </button>
                ))}
              </div>
            </div>
          </nav>
          <div className="tab-content" id="nav-tabContent">
            {state.tabDetails.map((item: any) => (
              <div
                key={item.id}
                className={`tab-pane fade ${item.showStatus ? "show" : ""} ${
                  item.showStatus ? "active" : ""
                }`}
                id={item.id}
                role="tabpanel"
                aria-labelledby={`${item.id}-tab`}
              >
                {ConstructTabs(item.id, item.showStatus)}
              </div>
            ))}
          </div>
        </AccessControl>
      </div>
    </>
  );
};
