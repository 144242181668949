import {
  PATH_CREATE_CONTRACT,
  PATH_EMPLOYEE_CREATE,
  PATH_EMPLOYEE_EDIT,
  PATH_EMPLOYEE_FLEET_OVERVIEW,
  PATH_MATCHING,
  PATH_MATCHING_EMPLOYEE,
  PATH_PROJECTS_MANAGE,
  PATH_WAGE_PROPOSAL,
  PATH_HR_PLANNING,
} from "constants/Paths";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { t } from "../masterData/translation/Translation";
import { useEffect, useState } from "react";
import TableActions from "components/organism/Actions/TableAction";
import Button from "components/atoms/Button";
import {
  DOWNLOAD_CONTRACT_PDF,
  DOWNLOAD_WAGE_PROPOSAL_PDF,
  EMPLOYEE_ARCHIVE,
  GET_ALL_EMPLOYEE,
  GET_FILTERED_EMPLOYEE,
  SEND_CONTRACT_MAIL,
  SEND_WAGE_PROPOSAL_MAIL,
} from "routes/ApiEndpoints";
import { ApiCall } from "services/ApiServices";
import {
  getEmployeeStatusColor,
  getEmployeeTooltipMessageUser,
} from "services/util/UtilService";
import ModalPopup from "components/atoms/ModalPopup";
import { M_MASTER_DATA } from "constants/Constants";
import CustomNotify from "components/atoms/CustomNotify";
import Pagination from "components/atoms/Pagination";
import Search from "components/atoms/Search";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import EncryptDecryptService from "services/EncryptDecryptService";
import RightSideBar from "../masterData/follow-up-todo/RightSideBar";
import { IconData } from "services/IconData";
import Title from "components/atoms/Title";
import LabelField from "components/atoms/LabelField";
import ResetBtn from "components/atoms/ResetBtn";
import SearchBtn from "components/atoms/SearchBtn";
import LinkTo from "components/atoms/LinkTo";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import "./css/employee.css";
export interface employee {
  id?: number;
  name: string;
  status: any;
}
interface DataProps {
  page: number;
  search?: any;
  companyId: number;
  projectId: number;
}
export const Employees = () => {
  const url = useLocation();
  const urlParts = url.pathname.split("/");
  const project = urlParts[urlParts.length - 2];
  const company = urlParts[urlParts.length - 1];
  let projectId = 0;
  let companyId = 0;
  if (!isNaN(Number(project)) && !isNaN(Number(company))) {
    projectId = parseInt(project);
    companyId = parseInt(company);
  }
  const queryParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [submit, setSubmit] = useState(true);
  const [search, setSearch] = useState({
    businessUnit: queryParams.get("businessUnit") ?? "",
    name: queryParams.get("first_name") ?? "",
    email: queryParams.get("email") ?? "",
    SSN: queryParams.get("SSN") ?? "",
    mobileNumber: queryParams.get("mobileNumber") ?? "",
    function: queryParams.get("function") ?? "",
    status: queryParams.get("status") ?? "",
  });

  const [employees, setEmployees] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [id, setId] = useState<number | undefined>(undefined);
  const EmployeeStatus = [
    "Active employee",
    "Wage proposal not approved",
    "Contract proposal approved",
    "Wage proposal approved",
    "Contract generated",
    "Contract proposal flow done",
    "Inactive employee",
  ];
  const [filteredData, setfilteredData] = useState(false);
  const [followupSideBar, setFollowupSideBar] = useState<boolean>(false);
  const [sideBarWidth, setSidebarWidth] = useState("");
  const [entity, setEntity] = useState<{
    entityId: string | number | null;
    businessUnit: string | number | null;
  }>({ entityId: null, businessUnit: null });
  const [dropdownData, setDropdownData] = useState();
  const [unarchive, setUnarchieve] = useState(false);
  useEffect(() => {
    const data: DataProps = {
      page: currentPage,
      search: search,
      companyId: companyId,
      projectId: projectId,
    };
    if (projectId !== 0 && companyId !== 0) {
      fetchFilteredEmployees(data);
    } else {
      fetchEmployees(data);
    }
  }, [currentPage, submit]);

  const fetchFilteredEmployees = async (data: DataProps) => {
    setfilteredData(true);
    try {
      ApiCall.service(
        GET_FILTERED_EMPLOYEE,
        "POST",
        data,
        false,
        "m-masterdata"
      ).then((response) => {
        if (response?.data?.data) {
          setEmployees(response.data.data);
        }
      });
    } catch (error) {
      console.error("Failed to fetch employees:", error);
    }
  };
  const fetchEmployees = async (data: DataProps) => {
    try {
      const response = await ApiCall.service(
        GET_ALL_EMPLOYEE,
        "POST",
        data,
        false,
        "m-masterdata"
      );
      setDropdownData(response.data.funcDropdownOptions);
      setEmployees(response.data.data);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Failed to fetch employees:", error);
    }
  };

  const handleEdit = (id: number) => {
    navigate(`${PATH_EMPLOYEE_EDIT}/${id}`);
  };

  const handleArchive = (id: number, status: boolean) => {
    if (status === false) {
      setUnarchieve(true);
    }
    setShowModal(true);
    setId(id);
  };

  const handleModalClose = () => setShowModal(!showModal);
  const handleSaveChanges = async () => {
    setShowModal(false);
    if (id) {
      const postData = {
        id: id,
        archiveStatus: !unarchive,
      };
      const response = await ApiCall.service(
        `${EMPLOYEE_ARCHIVE}`,
        "POST",
        postData,
        false,
        M_MASTER_DATA
      );

      if (response?.status === 200) {
        setSubmit((prevSubmit) => !prevSubmit);
        CustomNotify({ type: "success", message: response.message });
      } else {
        CustomNotify({ type: "error", message: response.message });
      }
    }
  };

  // Function to create query parameters from the search object
  const createQueryParams = (data: employee) => {
    const queryParams = Object.entries(data)
      .filter(([key, value]) => value !== undefined && value !== "")
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");

    return queryParams;
  };

  const handleSearch = (name: string, value: string | number) => {
    setSearch((prevSearch) => ({
      ...prevSearch,
      [name]: value,
    }));
  };

  const handleSearchChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    const debounceTime = 10;
    clearTimeout((handleSearchChange as any).timeoutId);
    (handleSearchChange as any).timeoutId = setTimeout(() => {
      handleSearch(name, value);
    }, debounceTime);
  };

  const handleSubmit = (e: any, clear: boolean = false) => {
    e.preventDefault();
    handleSubmitAction(clear);
  };
  const handleMatching = (id: number) => {
    navigate(`${PATH_MATCHING_EMPLOYEE}/${id}`);
  };

  const handleSubmitAction = (clear = false) => {
    const newPage = 1;
    setCurrentPage(newPage);

    if (clear) {
      setSearch({
        businessUnit: "",
        name: "",
        email: "",
        SSN: "",
        mobileNumber: "",
        function: "",
        status: "",
      });
      navigate({
        pathname: window.location.pathname,
        search: `?page=${newPage}`,
      });
    } else {
      const queryParams = createQueryParams(search);
      if (queryParams) {
        navigate({
          pathname: window.location.pathname,
          search: `?${queryParams}&page=${newPage}`,
        });
      } else {
        CustomNotify({
          type: "warning",
          message: t("Search input not provided."),
        });
      }
    }

    setSubmit((prevSubmit) => !prevSubmit);
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleLink = (employeeId: number) => {
    navigate(`${PATH_PROJECTS_MANAGE}/${employeeId}`);
  };
  const userData = useSelector(selectAuth);
  const handlePlanning = (employeeid: any) => {
    // navigate(`${PATH_HR_PLANNING}?employee=${employeeid}`);
    window.location.href = `${PATH_HR_PLANNING}?employee=${employeeid}`;
  };

  const handleFleet = (employeeID: number) => {
    navigate(`${PATH_EMPLOYEE_FLEET_OVERVIEW}/${employeeID}/2`);
  };

  const handleAddContract = (
    id: number,
    wage_status: any,
    contract_status: any,
    contract_path: any
  ) => {
    const data = {
      id: id,
      status: wage_status === 3 || wage_status === 4 ? "true" : "false",
      contract_status: contract_status,
      contract_path: contract_path,
    };

    const append = EncryptDecryptService.encryptData(JSON?.stringify(data));
    const encodedData = encodeURIComponent(append);
    navigate(`${PATH_CREATE_CONTRACT}?data=${encodedData}`);
  };
  const handleDownloadWageProposal = async (id: number) => {
    const response = await ApiCall.getService(
      `${DOWNLOAD_WAGE_PROPOSAL_PDF}/${id}`,
      "GET",
      M_MASTER_DATA
    );
    if (response && response.download_url) {
      const link = document.createElement("a");
      link.href = response.download_url;
      link.target = "_blank";
      link.setAttribute("download", "");
      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
        document.body.removeChild(link);
      }, 1000);
    } else {
      //   console.error("Invalid response or missing download URL");
    }
  };
  const handleDownloadContract = async (id: number) => {
    const response = await ApiCall.getService(
      `${DOWNLOAD_CONTRACT_PDF}/${id}`,
      "GET",
      M_MASTER_DATA
    );
    if (response && response.download_url) {
      const link = document.createElement("a");
      link.href = response.download_url;
      link.target = "_blank";
      link.setAttribute("download", "");
      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
        document.body.removeChild(link);
      }, 1000);
    } else {
      //   console.error("Invalid response or missing download URL");
    }
  };
  const handleSendWageProposalMail = async (
    id: number,
    wage_mail_send: any
  ) => {
    navigate(`${PATH_WAGE_PROPOSAL}/${id}?wage_mail_send=${wage_mail_send}`);
  };
  const handleFollowup = (
    event: any,
    id: string | number | null,
    businessUnit: string | number | null
  ) => {
    if (entity.entityId === id) {
      setEntity({
        entityId: null,
        businessUnit: null,
      });
      setFollowupSideBar(false);
    } else {
      setEntity({
        entityId: id ?? null,
        businessUnit: businessUnit ?? null,
      });
      setFollowupSideBar(true);
    }
  };

  const handleCloseSidebar = () => {
    setFollowupSideBar(false);
  };

  const getWidth = (width: any) => {
    setSidebarWidth(width);
  };

  function handleClick(userData: any): void {
    navigate(`/employee/details/${userData.user_id}`, {
      state: { element: "employee" },
    });
  }

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Employees",
          read: true,
        },
      ]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <>
        <div
          className={`main-container ${followupSideBar ? "sidebar-open" : ""}`}
          style={{ marginRight: followupSideBar ? sideBarWidth : undefined }}
        >
          <div className="search-bar">
            <Title title={t("Manage employees")} />
            <div className="row">
              <div className="col-3" style={{ marginBottom: "0.7vw" }}>
                <Search
                  className="form-control"
                  handleChange={handleSearchChange}
                  placeholder={t("Business unit ")}
                  name="businessUnit"
                  value={search.businessUnit}
                />
              </div>
              <div className="col-3">
                <Search
                  className="form-control"
                  handleChange={handleSearchChange}
                  placeholder={t("Employee name")}
                  name="name"
                  value={search.name}
                />
              </div>
              <div className="col-3">
                <Search
                  className="form-control"
                  handleChange={handleSearchChange}
                  placeholder={t("Email")}
                  name="email"
                  value={search.email}
                />
              </div>
              <div className="col-3">
                <Search
                  className="form-control"
                  handleChange={handleSearchChange}
                  placeholder={t("SSN")}
                  name="SSN"
                  value={search.SSN}
                />
              </div>
              <div className="col-3">
                <Search
                  className="form-control"
                  handleChange={handleSearchChange}
                  placeholder={t("Mobile number")}
                  name="mobileNumber"
                  value={search.mobileNumber}
                />
              </div>
              <div className="col-3">
                <select
                  className="form-select field-shadow form-control"
                  name="function"
                  id="function"
                  onChange={handleSearchChange}
                  value={search.function}
                >
                  <option value="">{t("Function")}</option>

                  {dropdownData &&
                    Object.entries(dropdownData).map(([key, value]) => (
                      <option key={key} value={key}>
                        {value as string}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-3">
                <select
                  className="form-select field-shadow form-control"
                  name="status"
                  id="status"
                  onChange={handleSearchChange}
                  value={search.status}
                >
                  <option value="">{t("Status")}</option>
                  {EmployeeStatus?.map((optn, key) => (
                    <option key={key} value={key}>
                      {optn}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-3 ms-auto">
                <div className="d-flex align-items-center justify-content-end">
                  <ResetBtn handleResetClick={(e) => handleSubmit(e, true)} />
                  <SearchBtn handleSearchClick={handleSubmit} />
                </div>
              </div>
            </div>
          </div>

          <div className="position-relative tableMainWrapper">
            <div className="">
              <AccessControl
                requiredPermissions={[
                  {
                    permission: "Employees",
                    create: true,
                  },
                ]}
                override={userData.isSuperAdmin}
              >
                <div className="row">
                  <div className="ManageCreateBtn">
                    <LinkTo
                      pagelink={PATH_EMPLOYEE_CREATE}
                      title={t("Create employee")}
                      icon={faPlus}
                    />
                  </div>
                </div>
              </AccessControl>
            </div>
            <div className="tableSection table-responsive manage-employees">
              <div className="tableWithSearchBar">
                <table className="table table-hover">
                  <thead>
                    <tr className="TableHeader">
                      <th style={{ width: "10%" }}>{t("Business unit")}</th>
                      <th style={{ width: "10%" }}>{t("Employee name")}</th>
                      <th style={{ width: "18%" }}>{t("Email")}</th>
                      <th style={{ width: "10%" }}>{t("SSN")}</th>
                      <th style={{ width: "10%" }}>{t("Mobile number")}</th>
                      <th style={{ width: "10%" }}>{t("Function")}</th>
                      <th style={{ width: "8%" }}>{t("Status")}</th>
                      <th
                        className="table-action-icons"
                        style={{ width: "10%" }}
                      >
                        {t("Action")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {employees.length > 0 ? (
                      employees.map((employee: any) => (
                        <tr key={employee.id}>
                          <td>{employee?.businessUnitNumber}</td>
                          <td
                            onClick={() => {
                              handleClick(employee);
                            }}
                            className="cursor-pointer nameHoverClass"
                          >{`${employee?.first_name} ${employee?.last_name}`}</td>
                          <td>
                            <a
                              href={`mailto:${employee?.email}`}
                              target="_blank"
                              style={{ color: "black" }}
                            >
                              {employee?.email}
                            </a>
                          </td>
                          <td>{employee?.ssn}</td>
                          <td>{employee?.mobile_number}</td>
                          <td>
                            {employee?.empFunctions &&
                              employee?.empFunctions.join(", ")}
                          </td>
                          <td className="align-middle">
                            <div
                              className={getEmployeeStatusColor(
                                employee?.wage_contract_status,
                                employee?.employeeStatus
                              )}
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title={getEmployeeTooltipMessageUser(
                                employee?.wage_contract_status,
                                employee?.contractProposalFlow,
                                employee?.employeeStatus
                              )}
                            ></div>
                          </td>
                          <td className="align-middle table-action-icons">
                            <TableActions
                              isAction={true}
                              handleEdit={() => handleEdit(+employee.id)}
                              // handleArchive={() =>
                              //   handleArchive(+employee?.id, employee?.status)
                              // }
                              {...(employee?.employeeStatus
                                ? {
                                    handleArchive: () =>
                                      handleArchive(
                                        +employee?.id,
                                        employee?.employeeStatus
                                      ),
                                  }
                                : {
                                    handleUnArchive: () =>
                                      handleArchive(
                                        +employee?.id,
                                        employee?.employeeStatus
                                      ),
                                  })}
                              handleMatching={() =>
                                handleMatching(+employee.id)
                              }
                              linkIcon={employee && employee.status.length > 0}
                              handleLink={() => handleLink(+employee.id)}
                              followupIcon={
                                employee.status &&
                                employee.employeeStatus === true
                              }
                              handleFollowup={(e) => {
                                handleFollowup(
                                  e,
                                  employee.id,
                                  employee?.businessUnitId
                                );
                              }}
                              handleAddPlanning={() =>
                                handlePlanning(+employee.id)
                              }
                              contract={employee.contract_status}
                              value={+employee.id}
                              permission={"Employees"}
                              handleFleet={() => handleFleet(employee.id)}
                              {...(employee.documents.wage_status && {
                                handleDownloadWageProposal: () =>
                                  handleDownloadWageProposal(+employee.id),
                              })}
                              {...(employee.documents.contract_status && {
                                handleDownloadContract: () =>
                                  handleDownloadContract(+employee.id),
                              })}
                              {...((employee?.employeeStatus &&
                                employee.wage_contract_status === 1 && {
                                  handleSendWageProposalMail: () =>
                                    handleSendWageProposalMail(
                                      +employee.id,
                                      employee?.wage_mail_send
                                    ),
                                }) ||
                                (((employee?.employeeStatus &&
                                  employee.wage_contract_status === 2) ||
                                  employee.wage_contract_status === 3 ||
                                  employee.wage_contract_status === 4) && {
                                  handleAddContract: () =>
                                    handleAddContract(
                                      +employee.id,
                                      employee.wage_contract_status,
                                      employee.documents.contract_status,
                                      employee.documents.contract_path
                                    ),
                                }))}
                            />
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={8} className="text-center">
                          {t("No results found")}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div
                className="pagination justify-content-center align-items-center"
                style={{ paddingTop: "0.25vw" }}
              >
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              </div>
            </div>
          </div>
          <div
            className="col-md-6 align-self-center"
            style={{ padding: "1vw 0" }}
          >
            <Link
              to={"/"}
              className="text-uppercase back-btn text-decoration-underline"
            >
              {t("Back")}
            </Link>
          </div>
          <ModalPopup
            show={showModal}
            onHide={handleModalClose}
            title={
              unarchive
                ? t("Unarchive Confirmation")
                : t("Archive confirmation")
            }
            body={
              unarchive
                ? t("Are you sure want to unarchive?")
                : t("Are you sure want to archive?")
            }
            onCloseButtonClick={handleModalClose}
            onConfirmButtonClick={handleSaveChanges}
            closeTitle="No"
            confirmTitle="Yes"
          />
          {followupSideBar && (
            <RightSideBar
              title={"Follow-ups"}
              entityType={"employee"}
              businessUnit={entity.businessUnit}
              entityId={entity.entityId}
              onClose={handleCloseSidebar}
              getWidth={getWidth}
              recordsLimit={10}
              className="right-sidebar p-3"
              titleClassName="mt-4"
              destination="/employees"
            />
          )}
        </div>
      </>
    </AccessControl>
  );
};
