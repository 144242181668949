import React, { useState } from "react";
import TitleFieldMolecule from "components/molecules/TitleField";
import { ShiftHeader } from "TableHeader";
import { Link, useNavigate } from "react-router-dom";
import { t } from "pages/microservices/masterData/translation/Translation";
import { ARCHIVE_SHIFT, ARCHIVE_TIMETABLE } from "routes/ApiEndpoints";
import { ApiCall } from "services/ApiServices";
import CustomNotify from "components/atoms/CustomNotify";
import Pagination from "components/atoms/Pagination";
import ModalPopup from "components/atoms/ModalPopup";
import DynamicTableStructure from "components/atoms/DynamicTableStructure";
import { M_MASTER_DATA } from "constants/Constants";
import {
  PATH_CREATE_SHIFT,
  PATH_EDIT_SHIFT,
  PATH_MASTER_DATA,
  PATH_TIMETABLE_CREATE,
  PATH_TIMETABLE_EDIT,
} from "constants/Paths";
import { Shift } from "./Manage";
import Search from "components/atoms/Search";
import Button from "components/atoms/Button";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import { IconData } from "services/IconData";
import Title from "components/atoms/Title";
import LabelField from "components/atoms/LabelField";
import ResetBtn from "components/atoms/ResetBtn";
import SearchBtn from "components/atoms/SearchBtn";
import LinkTo from "components/atoms/LinkTo";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

interface ManageShiftsPageProps {
  shiftObject: Shift[];
  searchValue: (name: string, value: string | number) => void;
  deleteFilter: (deleteId: number) => void;
  searchData: any;
  isTimeTable?: any;
  handleSubmitAction: (clear: boolean) => void;
  currentPage: any;
  setCurrentPage: any;
  totalPages: any;
}

const ManageShiftsPage: React.FC<ManageShiftsPageProps> = ({
  shiftObject,
  searchValue,
  deleteFilter,
  searchData,
  handleSubmitAction,
  isTimeTable,
  currentPage,
  setCurrentPage,
  totalPages,
}) => {
  const [deleteID, setDeleteId] = useState<number | undefined>();
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const handleSearchChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    const debounceTime = 10;
    clearTimeout((handleSearchChange as any).timeoutId);
    (handleSearchChange as any).timeoutId = setTimeout(() => {
      searchValue(name, value);
    }, debounceTime);
  };

  const handleModalClose = () => setShowModal(!showModal);
  const handleSaveChanges = async () => {
    setShowModal(false);
    if (deleteID) {
      let apiToDelete = isTimeTable ? ARCHIVE_TIMETABLE : ARCHIVE_SHIFT;
      const url = `${apiToDelete}/${deleteID}`;
      const response = await ApiCall.getService(url, "GET", M_MASTER_DATA);

      if (response?.status === 200) {
        deleteFilter(deleteID);
        CustomNotify({ type: "success", message: response?.message });
      }
    }
  };

  const handleSubmit = (e: any, clear: boolean = false) => {
    e.preventDefault();
    handleSubmitAction(clear);
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleDelete = (Shift: Shift | any) => {
    setShowModal(true);
    setDeleteId(Shift.id);
  };

  const handleEdit = (Shift: Shift | any) => {
    if (Shift.id) {
      let navigatePath = isTimeTable ? PATH_TIMETABLE_EDIT : PATH_EDIT_SHIFT;
      navigate(`${navigatePath + "/" + Shift.id}`);
    }
  };
  const permissionType = isTimeTable ? "Timetable" : "Shifts";
  const userData = useSelector(selectAuth);

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: permissionType,
          read: true,
        },
      ]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <div className="search-bar">
        <Title
          title={isTimeTable ? t("Manage timetable") : t("Manage shifts")}
        />
        <div className="row">
          <div className="col-8">
            <Search
              className="form-control"
              handleChange={handleSearchChange}
              placeholder={t("Shift")}
              name="shift_name"
              value={searchData.shift_name}
            />
          </div>
          <div className="col-3">
            <div className="d-flex align-items-center">
              <ResetBtn handleResetClick={(e) => handleSubmit(e, true)} />
              <SearchBtn handleSearchClick={handleSubmit} />
            </div>
          </div>
        </div>
      </div>
      <div className="position-relative tableMainWrapper">
        <AccessControl
          requiredPermissions={[
            {
              permission: permissionType,
              create: true,
            },
          ]}
          override={userData.isSuperAdmin}
        >
          <div className="row">
            <div className="ManageCreateBtn">
              <LinkTo
                pagelink={
                  isTimeTable ? PATH_TIMETABLE_CREATE : PATH_CREATE_SHIFT
                }
                title={isTimeTable ? t("Create timetable") : t("Create shift")}
                icon={faPlus}
              />
            </div>
          </div>
        </AccessControl>
        <div className="table-responsive tableSection">
          <div className="TableHeight">
            <DynamicTableStructure
              isAction
              headers={ShiftHeader}
              data={shiftObject}
              handleEdit={handleEdit}
              handleArchive={handleDelete}
              name={"manage shifts"}
              permission={permissionType}
            />
          </div>
          <div
            className="pagination justify-content-center align-items-center"
            style={{ paddingTop: "0.25vw" }}
          >
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </div>
      <div style={{ padding: "1vw 0" }}>
        <Link
          to={PATH_MASTER_DATA}
          className="text-uppercase back-btn text-decoration-underline"
        >
          {t("Back")}
        </Link>
      </div>
      <ModalPopup
        show={showModal}
        onHide={handleModalClose}
        title={t("Archive confirmation")}
        body={t("Are you sure want to archive?")}
        onCloseButtonClick={handleModalClose}
        onConfirmButtonClick={handleSaveChanges}
        closeTitle={t("No")}
        confirmTitle={t("Yes")}
      />
    </AccessControl>
  );
};
export default ManageShiftsPage;
