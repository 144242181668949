import AccessControl from "services/AccessControl";
import { t } from "pages/microservices/masterData/translation/Translation";
import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import { getTiles } from "./DashboardConstants";
import RecruitmentCard from "components/atoms/RecruitmentCard";
import CardTitle from "components/atoms/CardTitle";
import CardCircle from "components/atoms/CardCircle";

const HomePage: React.FC = () => {
  const userData = useSelector(selectAuth);
  const tiles = getTiles(userData);
  return (
    <>
      <div className="row recruiter-action-tabs mt-1">
        <div
          className="col-md-12 d-grid"
          style={{
            marginBottom: "1.5vw",
            paddingLeft: "0.75vw",
            paddingRight: "0.75vw",
            gridTemplateColumns: "repeat(4, 1fr)",
            gap: "1.5vw",
          }}
        >
          {tiles &&
            tiles.map((tab, index) => (
              <AccessControl
                key={index}
                requiredPermissions={tab?.requiredPermissions}
                override={userData.isSuperAdmin}
              >
                <Link
                  to={tab.path}
                  className="card border-0 card-shadow justify-content-center h-100 rounded-3"
                  style={{ padding: "1vw" }}
                >
                  <RecruitmentCard
                    className={`d-flex justify-content-between align-items-center w-100 h-100`}
                  >
                    <CardTitle
                      title={tab.name}
                      className={`dashboard-card-title`}
                    />
                    <CardCircle
                      width="3.5vw"
                      height="3.5vw"
                      boxShadow="rgb(0, 165, 206) 0vw 0.104vw 0.417vw 0vw"
                      className="float-end justify-content-center hoverShadow"
                    >
                      {tab?.icon}
                    </CardCircle>
                  </RecruitmentCard>
                  {/* {/ <div className="tab">{t(tab.name)}</div> /} */}
                </Link>
              </AccessControl>
            ))}
        </div>
      </div>
    </>
  );
};

export default HomePage;
