import React from "react";

const AddContractIcon = () => {
  return (
    <span title="Contract">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="68.778"
        height="69.946"
        viewBox="0 0 68.778 69.946"
      >
        <path
          id="Signed"
          d="M52.009,69.946H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0H52.009a2,2,0,0,1,2,2V34.556C49.736,44.4,44.645,56.161,44.66,56.315l.8,6.118c-3.51-2.238-7.054-4.309-9.208-4.309a1.168,1.168,0,0,0-1.012.533c-.423.663.089,1.8.5,2.712.281.623.524,1.161.375,1.355a.1.1,0,0,1-.089.036,6.884,6.884,0,0,1-1.859-1.108c-.565-.391-1.339-.926-2.18-1.454-2.748-1.724-4.986-2.561-6.843-2.561A4.809,4.809,0,0,0,21.686,59a7.753,7.753,0,0,0-2.58-1.385A.86.86,0,0,0,19,57.6a.847.847,0,0,0-.8.7c-.115.513.758.982,1.529,1.4a7.737,7.737,0,0,1,.787.458,3.145,3.145,0,0,0-.562,1.244,2.9,2.9,0,0,0,.794,2.4,4.507,4.507,0,0,0,3.53,1.7,3.494,3.494,0,0,0,.647-.054,1.364,1.364,0,0,0,1.2-1.412c-.035-.913-1.183-2.4-3.072-3.991a4.2,4.2,0,0,1,2.326-.686c2.063,0,4.3,1.366,6.231,2.647l.656.437c1.772,1.186,3.3,2.209,4.472,2.209a1.558,1.558,0,0,0,.69-.15c.354-.174,1.184-.58.094-3.124-.005-.01-.541-1.106-.7-1.552,2.421.326,6.5,3,8.459,4.282l.005,0a9.039,9.039,0,0,0,1.193.734H46.5a.817.817,0,0,0,.482-.155l3.595-2.531,2.779-2.036a13.14,13.14,0,0,0,.657-1.313v9.126A2,2,0,0,1,52.009,69.946ZM8.385,51.655v1.614H42.506V51.655Zm0-4.3v1.615H42.506V47.35Zm0-7.791v1.614H42.506V39.559Zm0-4.3v1.615H42.506V35.254Zm0-7.791v1.615H42.506V27.463Zm0-4.3v1.614H42.506V23.159Zm0-7.792v1.615H42.506V15.367Zm0-4.3v1.614H42.506V11.063ZM46.7,63.939h-.057l-1.255-6.507c-.019-.189,15.994-36.815,16.156-37.185.051-.116.429-.232,1.052-.232a8.636,8.636,0,0,1,3.191.717,5.151,5.151,0,0,1,2.919,2.323.545.545,0,0,1,.055.352c-.164.368-16.369,36.818-16.612,37l-2.162,1.583C47.217,63.939,46.831,63.939,46.7,63.939Zm-22.47-.059a3.26,3.26,0,0,1-2.518-1.356,1.243,1.243,0,0,1,.1-1.352,11.971,11.971,0,0,1,2.579,2.7A1.51,1.51,0,0,1,24.234,63.879Z"
          fill="currentColor"
        />
      </svg>
    </span>
  );
};

export default AddContractIcon;
