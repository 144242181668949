import React, { useState } from "react";
import { CompanyData } from "./ManageCompanies";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo, faPlus } from "@fortawesome/free-solid-svg-icons";
import ArchiveIcon from "../../../../../static/images/ArchiveIcon";
import EditIcon from "../../../../../static/images/EditIcon";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Button from "components/atoms/Button";
import Search from "components/atoms/Search";
import ModalPopup from "components/atoms/ModalPopup";
import { ApiCall } from "services/ApiServices";
import CustomNotify from "components/atoms/CustomNotify";
import {
  ADD_COMPANY_TO_HOTLIST,
  ARCHIVE_COMPANY,
  REMOVE_COMPANY_FROM_HOTLIST,
} from "routes/ApiEndpoints";
import Pagination from "components/atoms/Pagination";
import Cooperation from "static/images/Cooperation";
import { M_COMPANY_CREATION } from "constants/Constants";
import { t } from "pages/microservices/masterData/translation/Translation";
import TableActions from "components/organism/Actions/TableAction";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import RightSideBar from "pages/microservices/masterData/follow-up-todo/RightSideBar";
import FollowUpIcon from "static/images/FollowUpIcon";
import ViewIcon from "static/images/ViewIcon";
import { IconData } from "services/IconData";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import SendMailIcon from "static/images/sendMailIcon";
import PhoneCallIcon from "static/images/phoneIcon";
import ProjectLinkedIcon from "static/images/projectLikedIcon";
import WhiteHeartIcon from "static/images/whiteHeartIcon";
import BlackHeartIcon from "static/images/BlackHeartICon";
import CheckBoxField from "components/atoms/CheckBoxField";
import { PATH_PROJECTS_MANAGE } from "constants/Paths";
import ResetBtn from "components/atoms/ResetBtn";
import SearchBtn from "components/atoms/SearchBtn";
import TitleAtom from "components/atoms/Title";

interface ManageCompanyPageProps {
  compObj: CompanyData[];
  currentPage: number;
  totalPages: number;
  onPageChange: (pageNumber: number) => void;
  itemsPerPage: number;
  searchValue: (name: string, value: any) => void;
  deleteFilter: (deleteId: number) => void;
  handleSubmitAction: (clear: boolean) => void;
  searchData: any;
  bussinessUnit: any;
  // fetchCompaniesDetails: (data: any) => Promise<void>;
  addOrRemoveHotlist: (companyId: number, type: boolean) => void;
}

export const ManageCompaniesPage: React.FC<ManageCompanyPageProps> = ({
  compObj,
  currentPage,
  totalPages,
  onPageChange,
  itemsPerPage,
  searchValue,
  deleteFilter,
  handleSubmitAction,
  searchData,
  bussinessUnit,
  addOrRemoveHotlist,
}) => {
  const [deleteID, setDeleteId] = useState<number | undefined>();
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  let pageIndex = (currentPage - 1) * itemsPerPage + 1;
  const AgreementStatus = ["Not completed", "Completed"];
  const CompanyStatus = [
    "Drafted company ",
    "Registered company",
    "Inactive company",
  ];
  const Type = ["Prospect", "Customer"];
  const [followupSideBar, setFollowupSideBar] = useState<boolean>(false);
  const [entity, setEntity] = useState<{
    entityId: string | number | null;
    businessUnit: string | number | null;
  }>({ entityId: null, businessUnit: null });
  const [sideBarWidth, setSidebarWidth] = useState("");

  const sortedType = Type.map((type, index) => ({
    type,
    originalIndex: index,
  }));
  sortedType.sort((a, b) => a.type.localeCompare(b.type));
  const sortedAgreementStatus = AgreementStatus.map((agrestatus, index) => ({
    agrestatus,
    originalIndex: index,
  }));
  sortedAgreementStatus.sort((a, b) =>
    a.agrestatus.localeCompare(b.agrestatus)
  );

  const handleSearchChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    // Explicitly toggle hotlist value
    let finalValue: any;
    if (name === "hotlist") {
      finalValue = searchData.hotlist === true ? false : true; // Set to false if true, otherwise true
    } else {
      finalValue = value; // For other fields
    }

    if (name === "city") {
      const alphabetRegex = /^[A-Za-z\s]*$/;
      if (!alphabetRegex.test(value)) {
        return; // Ignore non-alphabet input
      }
    }
    // Validation for phoneNumber: allows only numbers
    if (name === "phoneNumber") {
      const numericRegex = /^[0-9]*$/;
      if (!numericRegex.test(value)) {
        return; // Ignore non-numeric input
      }
    }
    const debounceTime = 10;
    clearTimeout((handleSearchChange as any).timeoutId);
    (handleSearchChange as any).timeoutId = setTimeout(() => {
      searchValue(name, finalValue);
    }, debounceTime);
  };

  const handleSubmit = (e: any, clear: boolean = false) => {
    e.preventDefault();
    handleSubmitAction(clear);
  };

  const deleteCompany = (id: number | undefined) => {
    setShowModal(true);
    setDeleteId(id);
  };

  const handleModalClose = () => setShowModal(!showModal);

  const handleSaveChanges = async () => {
    setShowModal(false);

    if (deleteID) {
      const response = await ApiCall.getService(
        `${ARCHIVE_COMPANY}/${deleteID}`,
        "GET",
        M_COMPANY_CREATION
      );
      if (response?.status === 200) {
        deleteFilter(deleteID);
        CustomNotify({ type: "success", message: response.msg });
      }
    }
  };
  const addToHotlist = async (companyId: number | undefined, type: boolean) => {
    if (companyId) {
      const response = await ApiCall.service(
        ADD_COMPANY_TO_HOTLIST,
        "POST",
        { companyId: companyId, type: type },
        false,
        M_COMPANY_CREATION
      );
      if (response?.status === 200) {
        CustomNotify({ type: "success", message: response.msg });
        addOrRemoveHotlist(companyId, type);
      }
    }
  };

  const handleFollowup = (
    event: any,
    id: string | number | null,
    businessUnit: string | number | null
  ) => {
    if (entity.entityId === id) {
      setEntity({
        entityId: null,
        businessUnit: null,
      });
      setFollowupSideBar(false);
    } else {
      setEntity({
        entityId: id ?? null,
        businessUnit: businessUnit ?? null,
      });
      setFollowupSideBar(true);
    }
  };

  const handleCloseSidebar = () => {
    setFollowupSideBar(false);
  };

  const getWidth = (width: any) => {
    setSidebarWidth(width);
  };

  const userData = useSelector(selectAuth);
  function handleClick(company: any): void {
    const companyId = company.id;
    navigate(`/company/${companyId}?mode=view`);
  }
  const handleBusinessUnitChange = (selectedOptions: any, name: string) => {
    searchValue(name, selectedOptions);
  };
  const onChangeWrapper = (selectedOptions: any) => {
    handleBusinessUnitChange(selectedOptions, "bussinessUnit");
  };
  const handleStatusFilter = (
    comType: string,
    e: React.MouseEvent<HTMLButtonElement>,
    name: string
  ) => {
    e.preventDefault();
    searchValue(name, comType);
    // Call the fetch function here
  };
  const handeSendMail = (companyId: any) => {
    //need to implement
  };
  const handePhoneCall = (companyId: any) => {
    // need to implemtn
  };

  const statusParam = searchData.companyType;
  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Company",
          read: true,
        },
      ]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <>
        <div className="search-bar">
          <TitleAtom title={t("Manage companies")} />
          <div className="row">
            <div className="col-md-12 createNavTabs">
              <button
                className={`link ${
                  statusParam === null || statusParam === "0"
                    ? "active nav-subtab"
                    : "nav-subtab"
                } me-3 border-0 bg-transparent ps-0 btn`}
                onClick={(e) => handleStatusFilter("0", e, "companyType")}
                style={{ cursor: "pointer" }}
              >
                {t("All")}
              </button>
              <button
                className={`link ${
                  statusParam === "1" ? "active nav-subtab" : "nav-subtab"
                } btn me-3 border-0 bg-transparent ps-0`}
                onClick={(e) => handleStatusFilter("1", e, "companyType")}
                style={{ cursor: "pointer" }}
              >
                {t("Client")}
              </button>
              <button
                className={`link ${
                  statusParam === "2" ? "active nav-subtab" : "nav-subtab"
                } btn me-3 border-0 bg-transparent ps-0`}
                onClick={(e) => handleStatusFilter("2", e, "companyType")}
                style={{ cursor: "pointer" }}
              >
                {t("ExCilent")}
              </button>
              <button
                className={`link ${
                  statusParam === "3" ? "active nav-subtab" : "nav-subtab"
                } btn me-3 border-0 bg-transparent ps-0`}
                onClick={(e) => handleStatusFilter("3", e, "companyType")}
                style={{ cursor: "pointer" }}
              >
                {t("Prospect")}
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-3 hideLabel" style={{ marginBottom: "0.8vw" }}>
              <SelectWithSearch
                onChange={onChangeWrapper}
                title={t("Business unit")}
                placeHolder={t("Select bussiness unit")}
                search={true}
                options={bussinessUnit}
                value={searchData.bussinessUnit}
                isDisabled={false}
                isMulti={true}
                isMandatory={false}
                error={""}
                className=""
                name={"bussinessUnit"}
              />
            </div>

            <div className="col-3">
              <Search
                className="form-control"
                handleChange={handleSearchChange}
                placeholder="Company name"
                name="name"
                value={searchData?.name}
              />
            </div>
            <div className="col-3">
              <Search
                handleChange={handleSearchChange}
                placeholder={t("VAT number")}
                name="vatNumber"
                value={searchData?.vatNumber}
              />
            </div>
            <div className="col-3">
              <Search
                handleChange={handleSearchChange}
                placeholder={t("City")}
                name="city"
                value={searchData?.city}
              />
            </div>
            <div className="col-3">
              <Search
                handleChange={handleSearchChange}
                placeholder={t("Mobile number")}
                name="phoneNumber"
                value={searchData?.phoneNumber}
              />
            </div>
            <div className="col-3">
              <select
                className="form-select form-control field-shadow"
                name="cs"
                id="cs"
                onChange={handleSearchChange}
                value={searchData.cs}
              >
                <option value="">{t("Status")}</option>
                {CompanyStatus?.map((optn, key) => (
                  <option key={key} value={key}>
                    {optn}
                  </option>
                ))}
              </select>
            </div>
            {/* <div className="col-3" style={{ paddingTop: "0.6vw" }}>
              <CheckBoxField
                label={t("Hotlist")}
                name="hotlist"
                onChangeHandler={handleSearchChange}
                isChecked={searchData.hotlist}
                id="hotlist"
                lineHeight="1.6vw"
              />
            </div> */}
            <div className="col-3 ms-auto">
              <div className="d-flex align-items-center justify-content-end">
                <ResetBtn handleResetClick={(e) => handleSubmit(e, true)} />
                <SearchBtn handleSearchClick={handleSubmit} />
              </div>
            </div>
          </div>
        </div>
        <div className="position-relative tableMainWrapper">
          <div className="row">
            <div className="ManageCreateBtn">
              <AccessControl
                requiredPermissions={[
                  {
                    permission: "Company",
                    create: true,
                  },
                ]}
                override={userData.isSuperAdmin}
              >
                <Link to="/company" className="form-button me-3">
                  + {t("Create company")}
                </Link>
              </AccessControl>
              <AccessControl
                requiredPermissions={[
                  {
                    permission: "Location",
                    read: true,
                  },
                ]}
                override={userData.isSuperAdmin}
              >
                <Link to="/manage-locations" className="form-button me-3">
                  {t("Manage Location")}
                </Link>
              </AccessControl>
              <AccessControl
                requiredPermissions={[
                  {
                    permission: "Contact person",
                    read: true,
                  },
                ]}
                override={userData.isSuperAdmin}
              >
                <Link to="/manage-contact/person" className="form-button">
                  {t("Manage Contact Person")}
                </Link>
              </AccessControl>
            </div>
          </div>
          <div className="table-responsive manage-companies tableSection">
            <div className="tableWithSearchBar">
              <table className="table table-hover">
                <thead>
                  <tr className="TableHeader">
                    <th>{t("Company name")}</th>
                    <th>{t("VAT number")}</th>
                    <th>{t("Zip code")}</th>
                    <th>{t("City")}</th>
                    <th>{t("Country")}</th>
                    <th>{t("Email")}</th>
                    <th>{t("Mobile number")}</th>
                    <th>{t("Businees unit")}</th>
                    <th>{t("Status")}</th>
                    <th className="actions table_actions">
                      <AccessControl
                        requiredPermissions={[
                          {
                            permission: "Company",
                            update: true,
                            delete: true,
                          },
                        ]}
                        actions={true}
                        strict={false}
                        override={userData.isSuperAdmin}
                      >
                        {t("Action")}
                      </AccessControl>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {compObj && compObj.length > 0 ? (
                    compObj.map((company, index) => {
                      let color_cs =
                        company.cs === 0
                          ? "bg-warning"
                          : company.cs === 1
                          ? "bg-success"
                          : "bg-danger";

                      return (
                        <tr
                          key={company.id}
                          className="border mb-3 box-shadow align-middle"
                        >
                          <td
                            onClick={() => {
                              handleClick(company);
                            }}
                            className="cursor-pointer nameHoverClass"
                          >
                            {`${company.name}`}
                          </td>
                          <td className="text-break" data-label="Type">
                            {company.vatNumber}
                          </td>
                          <td className="text-break" data-label="Type">
                            {company.zipCode}
                          </td>
                          <td className="text-break" data-label="Type">
                            {company.city}
                          </td>
                          <td className="text-break" data-label="Type">
                            {company.country}
                          </td>
                          <td className="text-break" data-label="Type">
                            {company.email}
                          </td>
                          <td className="text-break" data-label="Type">
                            {company.phoneNumber}
                          </td>
                          <td className="text-break" data-label="Type">
                            {company.bussinessUnit}
                          </td>
                          <td className="text-break ps-3">
                            <span
                              className={`d-inline-block rounded-circle status-icon ${color_cs}`}
                              data-toggle="tooltip"
                              title={CompanyStatus[company.cs ?? 0]}
                            ></span>
                          </td>
                          <td className="table-action-icons px-2">
                            <div className="d-none d-md-none d-lg-block">
                              {company.active && company.cs === 0 && (
                                <>
                                  {/* Case 1: cs = 0, active = true */}
                                  <Button
                                    title={<EditIcon />}
                                    handleClick={() =>
                                      navigate(`/company/${company.id}`)
                                    }
                                    className={"table-action-btn border-0 me-2"}
                                    tooltip={t("Edit")}
                                  />
                                  <Button
                                    title={<ViewIcon />}
                                    handleClick={() =>
                                      navigate(
                                        `/company/${company.id}?mode=view`
                                      )
                                    }
                                    className={"table-action-btn border-0 me-2"}
                                    tooltip={t("View")}
                                  />
                                  <Button
                                    title={<ArchiveIcon />}
                                    handleClick={() =>
                                      deleteCompany(company.id)
                                    }
                                    className={"table-action-btn border-0 me-2"}
                                    tooltip={t("Archive")}
                                  />
                                </>
                              )}
                              {company.active && company.cs === 1 && (
                                <>
                                  {/* Case 2: cs = 1, active = true (Show all actions) */}
                                  <Button
                                    title={<SendMailIcon />}
                                    handleClick={() =>
                                      handeSendMail(company.id)
                                    }
                                    className={
                                      "table-action-btn border-0 me-2 mb-2"
                                    }
                                    tooltip={t("Send mail")}
                                  />
                                  <Button
                                    title={<PhoneCallIcon />}
                                    handleClick={() =>
                                      handePhoneCall(company.id)
                                    }
                                    className={"table-action-btn border-0 me-2"}
                                    tooltip={t("Phone call")}
                                  />
                                  <Button
                                    title={<EditIcon />}
                                    handleClick={() =>
                                      navigate(`/company/${company.id}`)
                                    }
                                    className={"table-action-btn border-0 me-2"}
                                    tooltip={t("Edit")}
                                  />
                                  <Button
                                    title={<ViewIcon />}
                                    handleClick={() =>
                                      navigate(
                                        `/company/${company.id}?mode=view`
                                      )
                                    }
                                    className={"table-action-btn border-0 me-2"}
                                    tooltip={t("View")}
                                  />
                                  <Button
                                    title={<ProjectLinkedIcon />}
                                    handleClick={() =>
                                      navigate(
                                        `${PATH_PROJECTS_MANAGE}/?company=${company.id}`
                                      )
                                    }
                                    className={"table-action-btn border-0 me-2"}
                                    tooltip={t("Projects")}
                                  />
                                  {/* {company.isHotlist ? (
                                    <Button
                                      title={<BlackHeartIcon />}
                                      handleClick={() =>
                                        addToHotlist(company.id, false)
                                      }
                                      className={
                                        "table-action-btn border-0 me-2"
                                      }
                                      tooltip={t("Remove from hotlist")}
                                    />
                                  ) : (
                                    <Button
                                      title={<WhiteHeartIcon />}
                                      handleClick={() =>
                                        addToHotlist(company.id, true)
                                      }
                                      className={
                                        "table-action-btn border-0 me-2"
                                      }
                                      tooltip={t("Add to hotlist")}
                                    />
                                  )} */}
                                  <Button
                                    title={<ArchiveIcon />}
                                    handleClick={() =>
                                      deleteCompany(company.id)
                                    }
                                    className={"table-action-btn border-0 me-2"}
                                    tooltip={t("Archive")}
                                  />
                                  <Button
                                    title={<FollowUpIcon />}
                                    handleClick={(e) =>
                                      handleFollowup(
                                        e,
                                        company.id ? company.id : 0,
                                        company?.get_bussiness_unit?.id
                                      )
                                    }
                                    className={"table-action-btn border-0 me-2"}
                                    tooltip={t("Followup")}
                                  />
                                </>
                              )}
                              {!company.active && company.cs === 0 && (
                                <>
                                  {/* Case 3: cs = 0, active = false */}
                                  <Button
                                    title={<EditIcon />}
                                    handleClick={() =>
                                      navigate(`/company/${company.id}`)
                                    }
                                    className={"table-action-btn border-0 me-2"}
                                    tooltip={t("Edit")}
                                  />
                                  <Button
                                    title={<ViewIcon />}
                                    handleClick={() =>
                                      navigate(
                                        `/company/${company.id}?mode=view`
                                      )
                                    }
                                    className={"table-action-btn border-0 me-2"}
                                    tooltip={t("View")}
                                  />
                                  <Button
                                    title={<ArchiveIcon />}
                                    handleClick={() =>
                                      deleteCompany(company.id)
                                    }
                                    className={"table-action-btn border-0 me-2"}
                                    tooltip={t("Archive")}
                                  />
                                </>
                              )}
                              {!company.active && company.cs === 2 && (
                                <>
                                  {/* Case 4: cs = 2, active = false */}
                                  <Button
                                    title={<EditIcon />}
                                    handleClick={() =>
                                      navigate(`/company/${company.id}`)
                                    }
                                    className={"table-action-btn border-0 me-2"}
                                    tooltip={t("Edit")}
                                  />
                                  <Button
                                    title={<ViewIcon />}
                                    handleClick={() =>
                                      navigate(
                                        `/company/${company.id}?mode=view`
                                      )
                                    }
                                    className={"table-action-btn border-0 me-2"}
                                    tooltip={t("View")}
                                  />
                                </>
                              )}
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr className="border">
                      <td
                        colSpan={10}
                        className="border-0 text-center py-3 px-2"
                      >
                        <span className="text-danger fw-bold">No records</span>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <div
              className="pagination justify-content-center align-items-center"
              style={{ paddingTop: "0.25vw" }}
            >
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={onPageChange}
              />
            </div>
          </div>
        </div>

        <div className="align-self-center" style={{ padding: "1vw 0" }}>
          <Link
            to="/"
            className="text-uppercase back-btn text-decoration-underline"
          >
            {t("Back")}
          </Link>
        </div>
        <ModalPopup
          show={showModal}
          onHide={handleModalClose}
          title="Archive confirmation"
          body="Are you sure want to archive?"
          onCloseButtonClick={handleModalClose}
          onConfirmButtonClick={handleSaveChanges}
          closeTitle="No"
          confirmTitle="Yes"
        />
        {followupSideBar && (
          <RightSideBar
            title={"Follow-ups"}
            entityType={"company"}
            businessUnit={entity.businessUnit}
            entityId={entity.entityId}
            onClose={handleCloseSidebar}
            getWidth={getWidth}
            recordsLimit={10}
            className="right-sidebar p-3"
            titleClassName="mt-4"
            destination="/manage-companies"
          />
        )}
      </>
    </AccessControl>
  );
};
export default ManageCompaniesPage;
