import React, { useEffect, useState, Suspense } from "react";
import { ApiCall } from "services/ApiServices";
import { MANAGE_LOCATION } from "routes/ApiEndpoints";
import ManageLocationPage from "./ManageLocationPage";
import { M_COMPANY_CREATION } from "../../../../constants/Constants";
import { useNavigate } from "react-router-dom";
import CustomNotify from "components/atoms/CustomNotify";
import { t } from "pages/microservices/masterData/translation/Translation";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";

export interface LocationData {
  id?: number;
  company: string;
  location: string;
}

interface DataProps {
  page: number;
  search?: any;
}

const ManageLocation: React.FC = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const [locations, setLocations] = useState<LocationData[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [submit, setSubmit] = useState(true);
  const [search, setSearch] = useState({
    company: queryParams.get("company") ?? "",
    location: queryParams.get("location") ?? "",
  });
  const navigate = useNavigate();


  useEffect(() => {
    const data: DataProps = {
      page: currentPage,
      search: search,
    };
    fetchLocationDetails(data);
  }, [currentPage, submit]);

  const fetchLocationDetails = async (data: DataProps) => {
    const response = await ApiCall.service(
      MANAGE_LOCATION,
      "POST",
      data,
      false,
      M_COMPANY_CREATION
    );
    if (response?.status === 200) {
      //console.log(response.data);
      setLocations(response?.data);
      setTotalPages(response?.totalPages);
    }
  };

  const handleSearch = (name: string, value: string | number) => {
    setSearch((prevSearch) => ({
      ...prevSearch,
      [name]: value,
    }));
  };

  // Function to create query parameters from the search object
  const createQueryParams = (
    data: Record<string, string | number | boolean>
  ) => {
    const queryParams = Object.entries(data)
      .filter(([key, value]) => {
        return value !== undefined && value !== "";
      })
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");

    return queryParams;
  };
  const handleSubmit = (clear: boolean) => {
    if (clear) {
      navigate({ search: "" });
      setSearch({
        company: "",
        location: "",
      });
    }

    if (createQueryParams(search) !== "" && !clear) {
      navigate(`?${createQueryParams(search)}`);
    } else if (!clear) {
      CustomNotify({
        type: "warning",
        message: t("Search input not provided."),
      });
    }
    setSubmit(!submit);
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleDeleteData = (deleteId: number) => {
    setLocations((prevdata) =>
      prevdata.filter((location) => location.id !== deleteId)
    );
  };
 
  const userData=useSelector(selectAuth);

  return (
    <AccessControl
      requiredPermissions={[{
        permission: "Location",
        read: true
      }]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <>
        <Suspense
          fallback={<div className="text-center text-danger">Loading...</div>}
        >
          <ManageLocationPage
            locationObject={locations}
            searchValue={handleSearch}
            deleteFilter={handleDeleteData}
            searchData={search}
            handleSubmitAction={handleSubmit}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            totalPages={totalPages}

          />
        </Suspense>
      </>
    </AccessControl>
  );
};
export default ManageLocation;
