import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Button from "components/atoms/Button";
import ModalPopup from "components/atoms/ModalPopup";
import { ApiCall } from "services/ApiServices";
import CustomNotify from "components/atoms/CustomNotify";
import { t } from "pages/microservices/masterData/translation/Translation";
import { PATH_CREATE_CLOTHES, PATH_EDIT_CLOTHES } from "constants/Paths";
import TableActions from "components/organism/Actions/TableAction";
import { useNavigate } from "react-router-dom";
import {
  DOWNLOAD_PROJECT_PDF,
  COMPANY_LIST,
  CLOTHES_DELETE,
  FLEET_EMP_DELETE,
  FLEET_EMP_CREATE,
} from "routes/ApiEndpoints";
import { ERROR, M_MASTER_DATA } from "constants/Constants";
import Pagination from "components/atoms/Pagination";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import FilterElementsRender from "../FilterElement";
import { fetchOptions } from "../utils/FetchOptions";
import { getStatusColor, getTooltipMessage } from "services/util/UtilService";
import FleetEmployeeModal from "../FleetEmployeeModal";
import {
  validateForm,
  validateRequired,
} from "services/validation/ValidationService";
import { IconData } from "services/IconData";
import TitleAtom from "components/atoms/Title";
import ResetBtn from "components/atoms/ResetBtn";
import SearchBtn from "components/atoms/SearchBtn";

interface ManageClothesProps {
  data?: any;
  fleetProps?: any;
  handleData: any;
  currentPage: number;
  totalPages: number;
  onPageChange: (pageNumber: number) => void;
  itemsPerPage: number;
  searchValue: (name: string, value: string | number) => void;
  handleSubmitAction: (clear: boolean) => void;
  searchData: any;
  handleGetVacany: any;
}

interface CompanyItem {
  value: number;
  label: string;
}

const ManageClothes: React.FC<ManageClothesProps> = ({
  data,
  fleetProps,
  handleData,
  currentPage,
  totalPages,
  onPageChange,
  itemsPerPage,
  searchValue,
  handleSubmitAction,
  searchData,
  handleGetVacany,
}) => {
  const [id, setId] = useState<number | undefined>(undefined);
  const [showModal, setShowModal] = useState(false);
  const { id: routeId, userType: userType } = useParams();
  const [showFleetEmployeeModal, setShowFleetEmployeeModal] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    businessUnit: [],
    status: [],
  });

  const [fleetData, setFleetData] = useState({
    fromDate: "",
    toDate: "",
    type: "clothes",
    userId: routeId,
    userType,
    fleetId: "",
    status: false,
    count: 1,
    remainingUnits: "",
    purchaseDate: "",
  });

  const navigate = useNavigate();

  const handleSearchChange = (event: any, name: string, type: string) => {
    switch (type) {
      case "text":
      case "date":
        return searchValue(name, event.target.value);

      case "select":
        return searchValue(name, event.value);
    }
  };

  /** Adding fleet material to employee */
  const handleAddFleetEmp = async () => {
    if (validationFunction()) {
      try {
        const response = await ApiCall.service(
          FLEET_EMP_CREATE,
          "POST",
          fleetData,
          false,
          M_MASTER_DATA
        );
        if (response.status == 200) {
          CustomNotify({ type: "success", message: response.message });
          setShowFleetEmployeeModal(false);
          handleGetVacany({
            page: 1,
            nOR: 10,
            type: "clothes",
            userId: routeId ?? null,
            userType: userType ?? null,
            search: {
              company: "",
              businessUnit: "",
              brand: "",
              model: "",
              status: "",
            },
          });
        } else {
          CustomNotify({ type: "warning", message: response.message });
        }
      } catch (error) {
        console.log("Error");
      }
    }
  };

  const [error, setErrors] = useState({
    fromDate: "",
    toDate: "",
    count: "",
  });

  const validationFunction = (
    name: any = null,
    value: any = null,
    isSingleFieldValidation = false
  ) => {
    const validationRules: any = {
      fromDate: [validateRequired],
      toDate: [validateRequired],
      count: [validateRequired],
    };
    const validationErrors: any = validateForm(
      { ...fleetData, [name]: value },
      isSingleFieldValidation ? validationRules[name] : validationRules
    );
    if (isSingleFieldValidation) {
      setErrors((prevErrors: any) => {
        if (validationErrors.hasOwnProperty(name)) {
          return { ...prevErrors, [name]: validationErrors[name] };
        }
        const { [name]: ty, ...remains } = prevErrors;
        return { ...remains };
      });
    } else {
      setErrors(validationErrors);
      if (Object.keys(validationErrors).length > 0) {
        return false;
      } else {
        const fromDate = new Date(fleetData.fromDate);
        const lastDate = new Date(fleetData.toDate);
        const purchaseDate = new Date(fleetData.purchaseDate);
        if (fromDate > lastDate) {
          setErrors((prevState: any) => ({
            ...prevState,
            fromDate: "From date should be on or before to date",
          }));
          return false;
        }
        if (fromDate < purchaseDate) {
          setErrors((prevState: any) => ({
            ...prevState,
            fromDate: "From date should be on or after purchase date",
          }));
          return false;
        }
        return true;
      }
    }
  };

  const handleFleetEmpModalClose = () => {
    setFleetData({
      fromDate: "",
      toDate: "",
      type: "clothes",
      userId: routeId,
      fleetId: "",
      status: false,
      count: 0,
      remainingUnits: "",
      purchaseDate: "",
      userType,
    });
    setShowFleetEmployeeModal(false);
  };

  const handleRemoveData = async () => {
    try {
      const response = await ApiCall.service(
        FLEET_EMP_DELETE,
        "POST",
        { fleetId: fleetData.fleetId, userId: routeId, type: "clothes" },
        false,
        M_MASTER_DATA
      );
      if (response.status == 200) {
        CustomNotify({ type: "success", message: response?.message });
        handleGetVacany({
          page: 1,
          nOR: 10,
          type: "clothes",
          userId: routeId ?? null,
          userType,
          search: {
            company: "",
            businessUnit: "",
            brand: "",
            model: "",
            status: "",
          },
        });
        setShowFleetEmployeeModal(false);
      } else {
        CustomNotify({ type: "error", message: response?.message });
      }
    } catch (error) {
      console.log("Error");
    }
  };

  const handleLink = (id: any) => {
    const filteredData = data.filter((eachItem: any) => eachItem.id == id);
    if (filteredData.length > 0) {
      const { fromDate, toDate, count, remainingUnits, purchaseDate } =
        filteredData[0];
      setFleetData({
        fromDate: fromDate,
        toDate: toDate,
        type: "clothes",
        userId: routeId,
        fleetId: id,
        status: true,
        count: count,
        remainingUnits,
        purchaseDate,
        userType,
      });
    }
    setShowFleetEmployeeModal(true);
  };

  const handleRemove = (id: any) => {
    const filteredData = data.filter((eachItem: any) => eachItem.id == id);
    if (filteredData.length > 0) {
      const { fromDate, toDate, count, remainingUnits, purchaseDate } =
        filteredData[0];
      setFleetData({
        fromDate: fromDate,
        toDate: toDate,
        type: "clothes",
        userId: routeId,
        fleetId: id,
        status: true,
        count: count,
        remainingUnits,
        purchaseDate,
        userType,
      });
    } else {
      setFleetData((prevState: any) => ({ ...prevState, fleetId: id }));
    }
    setShowFleetEmployeeModal(true);
  };

  const handleSaveChanges = async () => {
    setShowModal(false);
    let postData = {
      id: id,
    };

    if (id) {
      const response = await ApiCall.service(
        CLOTHES_DELETE,
        "POST",
        postData,
        false,
        M_MASTER_DATA
      );

      if (response?.status === 200) {
        CustomNotify({ type: "success", message: response.message });
        handleGetVacany({
          page: 1,
          nOR: 10,
          type: "clothes",
          userId: routeId ?? null,
          userType: userType ?? null,
          search: {
            company: "",
            businessUnit: "",
            brand: "",
            model: "",
            status: "",
          },
        });
      } else {
        CustomNotify({ type: "error", message: response.message });
      }
    }
  };

  useEffect(() => {
    getFilterData();
  }, []);

  const getFilterData = async () => {
    try {
      const filterData = await fetchOptions();
      if (filterData?.status == 200) {
        setFilterOptions((prevState: any) => ({
          ...prevState,
          businessUnit: filterData.data.businessUnit,
          status: filterData.data.status,
        }));
      } else {
        CustomNotify({ type: "error", message: filterData.message });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleModalClose = () => setShowModal(!showModal);

  const handleSubmit = (e: any, clear: boolean = false) => {
    e.preventDefault();
    handleSubmitAction(clear);
  };

  const handleEdit = (id: number) => {
    navigate(`${PATH_EDIT_CLOTHES}/${id}`);
  };

  const handleDelete = (id: number) => {
    setShowModal(true);
    setId(id);
  };
  const userData = useSelector(selectAuth);

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Clothes",
          read: true,
        },
      ]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <>
        <div className="search-bar">
          {fleetProps !== "fleet" ? (
            <>
              <TitleAtom title={t("Manage clothes")} />
              <div className="row">
                <FilterElementsRender
                  data={searchData?.company}
                  type="text"
                  handleChange={(event) =>
                    handleSearchChange(event, "company", "text")
                  }
                  value={searchData?.company}
                  placeHolder={"Company"}
                  name="company"
                />

                <FilterElementsRender
                  data={filterOptions.businessUnit}
                  type="multi-select"
                  handleChange={(event) =>
                    handleSearchChange(event, "businessUnit", "select")
                  }
                  value={searchData?.businessUnit}
                  placeHolder={"Business unit"}
                  name="businessUnit"
                />

                <FilterElementsRender
                  data={searchData?.brand}
                  type="text"
                  handleChange={(event) =>
                    handleSearchChange(event, "brand", "text")
                  }
                  value={searchData?.brand}
                  placeHolder={"Brand"}
                  name="brand"
                />
                <FilterElementsRender
                  data={searchData?.model}
                  type="text"
                  handleChange={(event) =>
                    handleSearchChange(event, "model", "text")
                  }
                  value={searchData?.model}
                  placeHolder={"Model"}
                  name="model"
                />

                <FilterElementsRender
                  data={filterOptions.status}
                  type="multi-select"
                  handleChange={(event) =>
                    handleSearchChange(event, "status", "select")
                  }
                  value={searchData?.status}
                  placeHolder={"Status"}
                  name="status"
                />
                <div className="col ms-auto">
                  <div className="d-flex align-items-center justify-content-end">
                    <ResetBtn handleResetClick={(e) => handleSubmit(e, true)} />
                    <SearchBtn handleSearchClick={handleSubmit} />
                  </div>
                </div>
              </div>
            </>
          ) : (
            " "
          )}
        </div>

        <div className="position-relative tableMainWrapper">
          {fleetProps !== "fleet" && (
            <AccessControl
              requiredPermissions={[
                {
                  permission: "Clothes",
                  create: true,
                },
              ]}
              override={userData.isSuperAdmin}
            >
              <div className="row">
                <div className="ManageCreateBtn">
                  <Link to={PATH_CREATE_CLOTHES} className="form-button">
                    + {"Create clothes"}
                  </Link>
                </div>
              </div>
            </AccessControl>
          )}
          <div className="tableSection table-responsive">
            <div className="manageClothesHeight">
              <table className="table table-hover">
                <thead>
                  <tr className="TableHeader">
                    <th style={{ width: "15%" }}>{t("Company")}</th>
                    <th style={{ width: "15%" }}>{t("Business unit")}</th>
                    <th style={{ width: "15%" }}>{t("Brand")}</th>
                    <th style={{ width: "15%" }}>{t("Model ")}</th>
                    <th style={{ width: "8%" }}>{t("Status")}</th>
                    <th style={{ width: "15%" }}>
                      <AccessControl
                        requiredPermissions={[
                          {
                            permission: "Clothes",
                            update: true,
                            delete: true,
                          },
                        ]}
                        actions={true}
                        strict={false}
                        override={userData.isSuperAdmin}
                      >
                        {t("Action")}
                      </AccessControl>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data && data.length > 0 ? (
                    data.map((record: any, key: any) => {
                      return (
                        <tr key={key}>
                          <td className="align-middle">
                            {record.ownerCompany}
                          </td>
                          {record.businessUnit &&
                          record.businessUnit.length > 0 ? (
                            <td className="align-middle">
                              {record.businessUnit
                                .map((fun: any) => fun.label)
                                .join(", ")}
                            </td>
                          ) : (
                            <td></td>
                          )}
                          <td className="align-middle">{record.brand}</td>
                          <td className="align-middle">{record.model}</td>
                          <td className="align-middle">
                            <div
                              className={getStatusColor(record.status)}
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title={getTooltipMessage(record.status)}
                            ></div>
                          </td>
                          <td className="align-middle">
                            <TableActions
                              isAction={true}
                              {...(fleetProps !== "fleet"
                                ? {
                                    handleEdit: handleEdit,
                                    handleArchive: handleDelete,
                                  } //handleSendMaterialMail: () => { }
                                : record.isLinked
                                ? { handleRemove: handleRemove }
                                : { handleAddFleet: handleLink })}
                              value={record.id}
                              permission={"Clothes"}
                              signature={record.project_pdf_path}
                            />
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={10} className="text-center">
                        {t("No records found")}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div
              className="pagination justify-content-center align-items-center"
              style={{ paddingTop: "0.25vw" }}
            >
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={onPageChange}
              />
            </div>
          </div>
        </div>
        <ModalPopup
          show={showModal}
          onHide={handleModalClose}
          title={t("Archive confirmation")}
          body={t("Are you sure want to archive?")}
          onCloseButtonClick={handleModalClose}
          onConfirmButtonClick={handleSaveChanges}
          closeTitle="No"
          confirmTitle="Yes"
        />

        {showFleetEmployeeModal && (
          <FleetEmployeeModal
            handleClose={handleFleetEmpModalClose}
            data={fleetData}
            errors={error}
            setFleetData={setFleetData}
            handleSave={handleAddFleetEmp}
            handleRemove={handleRemoveData}
            validationFn={validationFunction}
          />
        )}
      </>
    </AccessControl>
  );
};
export default ManageClothes;
