import React, { useState } from "react";
import Search from "components/atoms/Search";
import Pagination from "components/atoms/Pagination";
import { Link, useNavigate } from "react-router-dom";
import EditIcon from "static/images/EditIcon";
import { M_IDENTITY_MANAGER, UserStatus } from "constants/Constants";
import { UserData } from "./ManageUsers";
import ModalPopup from "components/atoms/ModalPopup";
import CustomNotify from "components/atoms/CustomNotify";
import { ApiCall } from "services/ApiServices";
import "static/css/users.css";
import {
  DELETE_USER,
  GET_ALL_USERS,
  SEND_ACTIVATION_MAIL,
  UNARCHIVE_USER,
} from "routes/ApiEndpoints";
import { t } from "pages/microservices/masterData/translation/Translation";
import TableStructure from "components/atoms/TableStructure";
import { UserTableHeader } from "TableHeader";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { IconData } from "services/IconData";
import { User } from "aws-sdk/clients/budgets";
import { AutoComplete } from "primereact/autocomplete";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
// import "primeicons/primeicons.css";
import Title from "components/atoms/Title";
import LabelField from "components/atoms/LabelField";
import ResetBtn from "components/atoms/ResetBtn";
import SearchBtn from "components/atoms/SearchBtn";
import LinkTo from "components/atoms/LinkTo";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

interface ManageUsersPageProps {
  usersObj: UserData[];
  currentPage: number;
  totalPages: number;
  onPageChange: (pageNumber: number) => void;
  itemsPerPage: number;
  searchValue: (name: string, value: string | number) => void;
  deleteFilter: (deleteId: number) => void;
  handleSubmitAction: (clear: boolean) => void;
  searchData: any;
  options?: any;
}

const ManageUsersPage: React.FC<ManageUsersPageProps> = ({
  usersObj,
  currentPage,
  totalPages,
  onPageChange,
  itemsPerPage,
  searchValue,
  deleteFilter,
  handleSubmitAction,
  searchData,
  options,
}) => {
  const [deleteID, setDeleteId] = useState<number | undefined>();
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const [items, setItems] = useState<any>([]);
  const [value, setValue] = useState("");

  const search = async (event: any) => {
    if (!event.query) return; // Check if event.query exists
    try {
      const response = await ApiCall.service(
        GET_ALL_USERS,
        "POST",
        { user: event.query },
        false,
        M_IDENTITY_MANAGER
      );
      if (response.status == 200) {
        setItems(response.data);
      }
    } catch (error) {
      console.log("Error");
    }
  };

  const handleInputChange = (event: any) => {
    const { name, value } = event?.target;
    searchValue(name, value);
  };

  const handleSelectChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement> | any,
    category: string
  ) => {
    searchValue(category, event);
  };

  // start of index
  let pageIndex = (currentPage - 1) * itemsPerPage + 1;
  const columns = [
    "businessUnits",
    "first_name",
    "last_name",
    "email",
    "phoneNumber",
    "role",
    "status",
  ];
  const handleModalClose = () => setShowModal(!showModal);
  const handleSaveChanges = async () => {
    setShowModal(false);
    if (deleteID) {
      const url = `${DELETE_USER}/${deleteID}`;
      const response = await ApiCall.getService(url, "GET");
      if (response?.status === 200) {
        const url = `archiveUser/${deleteID}`; //archive user in candidate-creation
        await ApiCall.getService(url, "GET", "candidate-creation");
        deleteFilter(deleteID);
        CustomNotify({ type: "success", message: response.msg });
      }
    }
  };

  const handleUnArchiveChanges = async (userId: number) => {
    if (userId) {
      const url = `${UNARCHIVE_USER}/${userId}`;
      const response = await ApiCall.getService(url, "GET");
      if (response?.status === 200) {
        deleteFilter(userId);
        CustomNotify({ type: "success", message: response.msg });
      }
    }
  };

  const handleSubmit = (e: any, clear: boolean = false) => {
    e.preventDefault();
    handleSubmitAction(clear);
  };

  const deleteUser = (userData: UserData | any) => {
    setShowModal(true);
    setDeleteId(userData.user_id);
  };

  const handleEdit = (userData: UserData | any) => {
    if (userData.user_id) {
      navigate(`/user/edit/${userData.user_id}`);
    }
  };

  const handleView = (userData: UserData | any) => {
    if (userData.user_id) {
      navigate(`/user/view/${userData.user_id}`);
    }
  };

  const handleUnArchive = (userData: UserData | any) => {
    if (userData.user_id) {
      handleUnArchiveChanges(userData.user_id);
    }
  };

  const sendActivationMail = async (userData: any) => {
    try {
      const response = await ApiCall.service(
        SEND_ACTIVATION_MAIL,
        "POST",
        { ...userData },
        false,
        M_IDENTITY_MANAGER
      );
      if (response.status == 200) {
        CustomNotify({ type: "success", message: response.message });
      } else {
        CustomNotify({ type: "warning", message: response.message });
      }
    } catch (error) {
      console.log("Error");
    }
  };

  const handleSendMail = (userData: UserData | any) => {
    if (userData.user_id) {
      sendActivationMail(userData);
    }
  };

  const handleCha = (ev: any, element: string) => {
    searchValue(element, ev.value);
    setValue(ev.value);
  };

  function handleClick(userData: any): void {
    navigate(`/candidate/details/${userData.user_id}`);
  }

  const userData = useSelector(selectAuth);

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Users",
          read: true,
        },
      ]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <>
        <div className="search-bar">
          <Title title={t("Manage users")} />
          <div
            className="d-grid"
            style={{ gridTemplateColumns: "repeat(6, 1fr)", gap: "1vw" }}
          >
            <div>
              <SelectWithSearch
                containerClassName="mb-0"
                search
                onChange={(value) => {
                  handleSelectChange(value, "businessUnits");
                }}
                options={options?.businessUnits}
                placeHolder={"Business units"}
                name={"businessUnits"}
                value={searchData?.businessUnits}
                isClearable
                id={"businessUnits"}
                isMulti={true}
              />
            </div>
            <div>
              <AutoComplete
                value={searchData.userName}
                suggestions={items}
                completeMethod={search}
                onChange={(ev) => {
                  handleCha(ev, "userName");
                }}
                field="label"
                placeholder={t("Type to search...")}
                inputStyle={{ color: "#212529" }}
                multiple={true}
                className="w-100"
                inputClassName="form-control field-shadow"
              />
            </div>
            <div>
              <Search
                className="form-control "
                handleChange={handleInputChange}
                placeholder={t("Email")}
                name="email"
                value={searchData.email}
              />
            </div>
            <div>
              <SelectWithSearch
                containerClassName="mb-0"
                search
                onChange={(value) => handleSelectChange(value, "role")}
                options={options?.roles}
                placeHolder={"Role"}
                name={"role"}
                value={searchData?.role}
                isClearable
                id={"role"}
              />
            </div>
            <div>
              <select
                className="form-select form-control field-shadow"
                name="status"
                id="status"
                onChange={(value) => {
                  handleInputChange(value);
                }}
                value={searchData.status}
              >
                <option value="">{t("Status")}</option>
                {UserStatus?.map((optn, key) => (
                  <option key={key} value={key}>
                    {optn}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <div className="d-flex align-items-center justify-content-end">
                <ResetBtn handleResetClick={(e) => handleSubmit(e, true)} />
                <SearchBtn handleSearchClick={handleSubmit} />
              </div>
            </div>
          </div>
        </div>
        <div className="position-relative tableMainWrapper">
          <AccessControl
            requiredPermissions={[
              {
                permission: "Role",
                create: true,
              },
            ]}
            override={userData.isSuperAdmin}
          >
            <div className="row">
              <div className="ManageCreateBtn">
                <LinkTo
                  pagelink="/user/create"
                  title={t("Create user")}
                  icon={faPlus}
                />
              </div>
            </div>
          </AccessControl>
          <div className="table-responsive manage-users tableSection">
            <div className="TableHeight">
              <TableStructure
                isAction
                headers={UserTableHeader}
                data={usersObj}
                values={columns}
                handleEdit={handleEdit}
                handleArchive={deleteUser}
                handleUnArchive={handleUnArchive}
                searchStatus={searchData.status}
                permissionType={"Users"}
                handleView={handleView}
                handleSendMail={handleSendMail}
              />
            </div>
            <div
              className="pagination justify-content-center align-items-center"
              style={{ paddingTop: "0.25vw" }}
            >
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={onPageChange}
              />
            </div>
          </div>
        </div>

        <div>
          <div
            className="col-md-6 align-self-center"
            style={{ padding: "1vw 0" }}
          >
            <Link
              to="/"
              className="text-uppercase back-btn text-decoration-underline"
            >
              {t("Back")}
            </Link>
          </div>
        </div>
        <ModalPopup
          show={showModal}
          onHide={handleModalClose}
          title={t("Archive confirmation")}
          body={t("Are you sure want to archive?")}
          onCloseButtonClick={handleModalClose}
          onConfirmButtonClick={handleSaveChanges}
          closeTitle={t("No")}
          confirmTitle={t("Yes")}
        />
      </>
    </AccessControl>
  );
};

export default ManageUsersPage;
