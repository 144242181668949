import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

interface CommonModalProps {
  show: boolean;
  onHide: () => void;
  title: string;
  body: string | React.ReactNode;
  onCloseButtonClick?: () => void;
  onConfirmButtonClick?: () => void;
  closeTitle?: string;
  confirmTitle?: string;
  className?:string;
  modalClassName?:string;
}

const ModalPopup: React.FC<CommonModalProps> = ({
  show,
  onHide,
  title,
  body,
  onCloseButtonClick,
  onConfirmButtonClick,
  closeTitle,
  confirmTitle,
  className,
  modalClassName
}) => {
  return (
    <>
      <Modal show={show} onHide={onHide} centered className={`${className} modalPopupClass`} dialogClassName= {`${modalClassName}`}>
        <Modal.Header closeButton title="Close" className="color-dark-skyblue font-CalbriBold pb-2 px-0" style={{fontSize:"1vw"}}>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-0">
          {typeof body === "string" ? (
            <div dangerouslySetInnerHTML={{ __html: body }} />
          ) : (body)
          }
        </Modal.Body>
        <Modal.Footer className="border-0 px-0">
          {onCloseButtonClick && (
            <Button
              onClick={onCloseButtonClick}
              className="me-3 close-button"
            >
              {closeTitle}
            </Button>
          )}
          {onConfirmButtonClick && (
            <Button
              onClick={onConfirmButtonClick}
              className="mx-0 form-button"
            >
              {confirmTitle}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalPopup;
