export const getObjectEntryByKey = (object: any, targetKey: any): any => {
  return Object.keys(object).filter((key) => key === targetKey);
};

/**
 * @param object
 *
 * @param ignoreKeys
 * The keys, that are to be ignored in format {myKey: "", myKey2: ""}
 *
 * @returns true
 * If object is empty ({})
 *
 * @retutns false
 * Otherwise
 */
export const isObjectEmpty = (
  object: Object,
  ignoreKeys: Object = {}
): boolean => {
  if (Object.keys(ignoreKeys).length > 0) {
    // let objectCopy = structuredClone(object);
    let objectKeys = Object.keys(object).filter((objKey) => {
      return !ignoreKeys.hasOwnProperty(objKey as string);
    });
    return objectKeys.length === 0;
  }
  return Object.keys(object).length === 0;
};

// export const getKeyByValue = (object: any, value: any): any => {
//     return Object.keys(object).find(key => object[key] === value);
// }

export const getStatusColor = (data: any) => {
  switch (data) {
    case "0":
    case 0:
    case "false":
    case false:
      return "danger";
    case "1":
    case 1:
    case "true":
    case true:
      return "success";
    case 2:
    case "2":
      return "orange";
    default:
      return "success";
  }
};
export const getTempAgencyColor = (data: any) => {
  switch (data) {
    case "0":
    case 0:
    case "false":
    case false:
      return "danger";
    case "1":
    case 1:
      return "danger";
    case 2:
    case "2":
      return "orange";

    case 3:
    case "3":
      return "success";

    default:
      return "success";
  }
}
export const getAgreementStatusColor = (data: any) => {
  switch (data) {
    case "0":
    case 0:
    case "false":
    case false:
      return "danger";
    case "1":
    case 1:
    case "true":
    case true:
      return "success";
    default:
      return "success";
  }
};

export const getTooltipMessage = (isActive: any) => {
  switch (isActive) {
    case "0":
      return "Drafted project";
    case "1":
    case "false":
    case false:
      return "Inactive project";
    case "2":
      return "Contract not signed";
    case "3":
    case "true":
    case true:
      return "Active project";
    default:
      return "Unknown status";
  }
};
export const getTempAgencyTooltipMessage = (isActive: any) => {
  switch (isActive) {
    case "0":
      return "Drafted temp agency";
    case "1":
    case 1:
    case "false":
    case false:
      return "Inactive temp agency";
    case "2":
    case 2:
      return "Registerd temp agency";
    case "3":
    case 3:
    case "true":
    case true:
      return "Active Temp agency";
    default:
      return "Unknown status";
  }
}
export const getAgreementTooltipMessage = (isSign: any) => {
  switch (isSign) {
    case "0":
    case 0:
      return "Drafted project proposal";
    case "1":
    case 1:
      return "Project proposal is signed";
    case "true":
    case true:
      return "Project proposal is signed";
    default:
      return "Unknown status";
  }
};
export const getProjectTooltipMessage = (isSign: any) => {
  switch (isSign) {
    case "0":
    case 0:
      return "Drafted project";
    case "1":
    case 1:
      return "Inactive project";
    case 2:
    case "2":
    case true:
    case "true":
      return "Active project";
    case 3:
    case "3":
    case false:
    case "false":
      return "Archived project";
    default:
      return "Unknown status";
  }
};

/**
 * user status
 *
 * 0 => pending
 * 1 => active
 * 2 => Inactive
 * 3 =>
 *
 */
export const getProjectStatusColor = (data: any) => {
  switch (data) {
    case "0":
    case 0:
      return "orange";
    case "1":
    case 1:
    case "false":
      return "danger";
    case 2:
    case "2":
    case true:
    case "true":
      return "success";
    case 3:
    case "3":
    case false:
    case "false":
      return "danger";
    default:
      return "success";
  }
};
export const getuserStatusColor = (data: any) => {
  switch (data) {
    case "0":
    case 0:
      return "orange";
    case "1":
    case 1:
    case "true":
      return "success";
    case 2:
    case "2":
    case false:
      return "danger";
    default:
      return "success";
  }
};

export const getEmployeeStatusColor = (data: any, employeeStatus: boolean) => {
  if (employeeStatus) {
    switch (data) {
      case 0:
        return "secondary";
      case 1:
      case "true":
        return "orange";
      case 2:
        return "primary";
      case 3:
        return "cyan";
      case 4:
        return "success";
      default:
        return "success";
    }
  } else {
    return "danger";
  }
};

export const getEmployeeTooltipMessageUser = (
  isActive: any,
  contractProposalFlow: boolean,
  employeeStatus: boolean
) => {
  if (employeeStatus) {
    switch (isActive) {
      case "0":
        return "Draft employee";
      case 1:
        return "Wage proposal not approved";
      case 2:
        if (contractProposalFlow) {
          return "Contract proposal approved";
        } else {
          return "Wage proposal approved";
        }
      case 3:
        return "Contract generated";
      case 4:
        return "Contract proposal flow done";
      default:
        return "Unknown status";
    }
  } else {
    return "Inactive employee";
  }
};

export const getTooltipMessageUser = (isActive: any) => {
  switch (isActive) {
    case "0":
      return "Employee not activated";
    case "1":
    case 1:
    case "true":
    case true:
      return "Active employee";
    case "2":
    case 2:
    case "false":
    case false:
      return "Inactive employee";

    default:
      return "Unknown status";
  }
};
