import WindowHeightComponent from "components/atoms/WindowHeightComponent";
import {
  MANAGE_HOLIDAY_CODES,
  MANAGE_HOLIDAY_PER_PC,
  MANAGE_PUBLIC_HOLIDAYS,
} from "constants/Paths";
import { selectAuth } from "features/auth/AuthSlice";
import { t } from "pages/microservices/masterData/translation/Translation";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import AccessControl from "services/AccessControl";
import RecruitmentCard from "components/atoms/RecruitmentCard";
import CardTitle from "components/atoms/CardTitle";
import Title from "components/atoms/Title";

const HolidayConfig: React.FC = () => {
  const userData = useSelector(selectAuth);
  return (
    <>
      <div className="row recruiter-action-tabs mt-1">
        <div
          className="col-md-12 d-grid"
          style={{
            marginBottom: "1.5vw",
            paddingLeft: "0.75vw",
            paddingRight: "0.75vw",
            gridTemplateColumns: "repeat(4, 1fr)",
            gap: "1.5vw",
          }}
        >
          <AccessControl
            requiredPermissions={[
              {
                permission: "Holiday codes",
              },
            ]}
            override={userData.isSuperAdmin}
          >
            <Link
              to={MANAGE_HOLIDAY_CODES}
              className="card border-0 card-shadow justify-content-center h-100 rounded-3"
              style={{ padding: "2vw" }}
            >
              <RecruitmentCard
                className={`d-flex justify-content-between align-items-center w-100 h-100`}
              >
                <CardTitle
                  title={t("Holiday codes")}
                  className={`dashboard-card-title`}
                />
              </RecruitmentCard>
            </Link>
          </AccessControl>
          <AccessControl
            requiredPermissions={[
              {
                permission: "Public holidays",
              },
            ]}
            override={userData.isSuperAdmin}
          >
            <Link
              to={MANAGE_PUBLIC_HOLIDAYS}
              className="card border-0 card-shadow justify-content-center h-100 rounded-3"
              style={{ padding: "2vw" }}
            >
              <RecruitmentCard
                className={`d-flex justify-content-between align-items-center w-100 h-100`}
              >
                <CardTitle
                  title={t("Public holidays")}
                  className={`dashboard-card-title`}
                />
              </RecruitmentCard>
            </Link>
          </AccessControl>
          <AccessControl
            requiredPermissions={[
              {
                permission: "Holiday per PC",
              },
            ]}
            override={userData.isSuperAdmin}
          >
            <Link
              to={MANAGE_HOLIDAY_PER_PC}
              className="card border-0 card-shadow justify-content-center h-100 rounded-3"
              style={{ padding: "2vw" }}
            >
              <RecruitmentCard
                className={`d-flex justify-content-between align-items-center w-100 h-100`}
              >
                <CardTitle
                  title={t("Holiday per PC")}
                  className={`dashboard-card-title`}
                />
              </RecruitmentCard>
            </Link>
          </AccessControl>
        </div>
      </div>
      <div className="my-2 px-1">
        <Link
          to="/"
          className="text-uppercase back-btn text-decoration-underline"
        >
          {t("Back")}
        </Link>
      </div>
    </>
  );
};
export default HolidayConfig;
