import {
  CAR_CREATE,
  CAR_EDIT,
} from "routes/ApiEndpoints";
import { M_MASTER_DATA } from "constants/Constants";
import { PATH_MANAGE_CAR, PATH_MANAGE_MATERIAL, PATH_VACANCY_MANAGE } from "constants/Paths";
import { FormProviderWrapper } from "utils/multiTabForm/formWrapper/Index";
import { useLocation, useParams } from "react-router-dom";
import { rentalFunction, usersSetFunction } from "./customFunctions";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";

export type CustomFnType = (payload: any, dataObj: any) => any;

const AddCar = () => {
  const location = useLocation();
  const path = location.pathname;
  const { id: routeId } = useParams<{ id: string }>();

  const customActions: CustomFnType = (payload: any, dataObj: any) => {
    switch (payload.type) {
      case "rental":
        return rentalFunction(payload, [
          "interCompanyPrice",
          "interEmployeePrice",
          "externalPrice",
        ]);
      case "users":
        return usersSetFunction(payload, dataObj, 'car');
      default: return "Called with wrong key";
    }
  };

  const permissionType = routeId ? "update" : "create";
  const permissionObject: any = {
    permission: "Car",
  };
  permissionObject[permissionType] = true;
  const userData = useSelector(selectAuth);

  return (
    <>
      <AccessControl
        requiredPermissions={[permissionObject]}
        renderNoAccess={true}
        override={userData.isSuperAdmin}
      >
        <FormProviderWrapper
          backToManage={"/fleetOverview/car"}
          formName={"Car"}
          dataSubmissionEndPoint={CAR_CREATE}
          microservice={M_MASTER_DATA}
          dataFetchEndPoint={CAR_EDIT}
          entryId={routeId}
          customFn={customActions}
          formTitle={routeId ? "Edit car" : "Create car"}
        />
      </AccessControl>
    </>
  );
};

export default AddCar;
