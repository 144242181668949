import React, { useState } from "react";
import SearchModel from "components/atoms/SearchModel";
import { Link, useNavigate } from "react-router-dom";
import EditIcon from "static/images/EditIcon";
import DeleteIcon from "static/images/DeleteIcon";
import ButtonWithChildren from "components/atoms/ButtonWithChildren";
import Pagination from "components/atoms/Pagination";
import ModalPopup from "components/atoms/ModalPopup";
import { ApiCall } from "services/ApiServices";
import CustomNotify from "components/atoms/CustomNotify";
import { DELETE_ROLE } from "routes/ApiEndpoints";
import "static/css/roles.css";
import Button from "components/atoms/Button";
import { t } from "pages/microservices/masterData/translation/Translation";
import TableStructure from "components/atoms/TableStructure";
import { RoleTableHeader } from "TableHeader";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import Title from "components/atoms/Title";
import LinkTo from "components/atoms/LinkTo";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

interface RolesType {
  id?: number;
  name?: string;
}

interface Props {
  rolesObj: RolesType[];
}

const ManageRolesPage: React.FC<Props> = ({ rolesObj }) => {
  const [roles, setRoles] = useState(rolesObj);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [deleteID, setDeleteId] = useState<number | undefined>();
  const [showModal, setShowModal] = useState(false);
  const [modalBody, setModelBody] = useState(t("Are you sure want to delete?"));
  const itemsPerPage = 10;
  const navigate = useNavigate();

  // Filter the data based on the search query
  const filteredData = roles.filter((item: RolesType) =>
    item.name?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Get the current items to display on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  // Handle search input change
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset to the first page when the search query changes
  };

  const deleteRole = (RolesType: RolesType | any) => {
    setShowModal(true);
    setDeleteId(RolesType.id);
  };

  const EditRole = (RolesType: RolesType | any) => {
    if (RolesType.id) {
      navigate(`/role/edit/${RolesType.id}`);
    }
  };

  const cloneRole = (RolesType: RolesType | any) => {
    if (RolesType.id) {
      navigate(`/role/clone/${RolesType.id}`)
    }
  }

  // Handle page change
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  // Modal popup for delete confirmation
  const handleModalClose = () => {
    setShowModal(!showModal);
    setModelBody(t("Are you sure want to delete?"));
  };
  const handleSaveChanges = async () => {
    setShowModal(false);
    let body = {
      roleId: deleteID,
      force: false,
    };
    if (modalBody !== t("Are you sure want to delete?")) {
      body.force = true;
    }
    const response = await ApiCall.service(DELETE_ROLE, "POST", body);
    if (response.status === 403) {
      setModelBody(response.msg);
      setShowModal(true);
    } else if (response.status === 200) {
      const newRolesObj = roles.filter(
        (item: RolesType) => item.id !== deleteID
      );
      setRoles(newRolesObj);
      CustomNotify({ type: "success", message: response.msg });
    } else {
      CustomNotify({ type: "error", message: response.msg });
    }
  };

  const columns = ["name"];
  const userData = useSelector(selectAuth);

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Role",
          read: true,
        },
      ]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <>
        <div className="search-bar" style={{ paddingBottom: "0.25vw" }}>
          <Title title={t("Manage Roles")} />
          <div className="row">
            <div className="col-8">
              <SearchModel
                className="form-control"
                placeHolder={t("Search by name") + "..."}
                onChange={handleSearchChange}
              />
            </div>
          </div>
        </div>
        <div className="position-relative tableMainWrapper">
          <AccessControl
            requiredPermissions={[
              {
                permission: "Role",
                create: true,
              },
            ]}
            override={userData.isSuperAdmin}
          >
            <div className="d-none d-lg-block">
              <div className="row">
                <div className="ManageCreateBtn">
                  <LinkTo
                    pagelink="/role/create"
                    title={t("Create role")}
                    icon={faPlus}
                  />
                </div>
              </div>
            </div>
            <div className="d-block d-lg-none">
              <div className="row">
                <div className="col-md-6 ms-auto pb-3">
                  <LinkTo
                    pagelink="/role/create"
                    title={t("Create role")}
                    icon={faPlus}
                    className="align-items-center justify-content-center w-100"
                  />
                </div>
              </div>
            </div>
          </AccessControl>
          <div className="table-responsive manage-roles tableSection">
            <div className="TableHeight">
              <TableStructure
                 isAction
                 headers={RoleTableHeader}
                 data={currentItems}
                 values={columns}
                 handleEdit={EditRole}
                 handleDelete={deleteRole}
                 handleClone={cloneRole}
                 permissionType="Role"
              />
            </div>

            <div
              className="pagination justify-content-center align-items-center"
              style={{ paddingTop: "0.25vw" }}
            >
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
        <div
          className="col-md-6 align-self-center"
          style={{ padding: "1vw 0" }}
        >
          <Link
            to="/"
            className="text-uppercase back-btn text-decoration-underline"
          >
            {t("Back")}
          </Link>
        </div>
        <ModalPopup
          show={showModal}
          onHide={handleModalClose}
          title={t("Delete confirmation")}
          body={modalBody}
          onCloseButtonClick={handleModalClose}
          onConfirmButtonClick={handleSaveChanges}
          closeTitle={t("No")}
          confirmTitle={"Yes"}
        />
      </>
    </AccessControl>
  );
};

export default ManageRolesPage;
