import React, { useEffect, useState } from "react";
import SearchModel from "components/atoms/SearchModel";
import Pagination from "components/atoms/Pagination";
import { ApiCall } from "services/ApiServices";
import { Link, useNavigate } from "react-router-dom";
import CustomNotify from "components/atoms/CustomNotify";
import ModalPopup from "components/atoms/ModalPopup";
import EditIcon from "static/images/EditIcon";
import DeleteIcon from "static/images/DeleteIcon";
import "static/css/permission.css";
import Button from "components/atoms/Button";
import { GET_PERMISSIONS, DELETE_PERMISSION } from "routes/ApiEndpoints";
import { t } from "pages/microservices/masterData/translation/Translation";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import Title from "components/atoms/Title";
import LinkTo from "components/atoms/LinkTo";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
const ManagePermissions = () => {
  interface Permission {
    permission_id?: number;
    name?: string;
    classification_name?: string;
  }
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [permissions, setPermissions] = useState<Permission[]>([]);
  const [deleteID, setDeleteId] = useState<number | undefined>();
  const [showModal, setShowModal] = useState(false);
  const itemsPerPage = 10;

  useEffect(() => {
    fetchAllPermissions();
  }, []);

  const fetchAllPermissions = async () => {
    const response = await ApiCall.getService(GET_PERMISSIONS, "GET");
    if (response.status === 200) {
      setPermissions(response.data);
    }
  };

  const deletePermission = (id: number | undefined) => {
    setShowModal(true);
    setDeleteId(id);
  };

  // Filter the data based on the search query
  const filteredData = permissions.filter(
    (item: Permission) =>
      item.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.classification_name
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase())
  );

  // Get the current items to display on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  // Handle search input change
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset to the first page when the search query changes
  };

  // Handle page change
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  // Modal popup for delete confirmation
  const handleModalClose = () => setShowModal(!showModal);
  const handleSaveChanges = async () => {
    setShowModal(false);
    if (deleteID) {
      const url = `${DELETE_PERMISSION}/${deleteID}`;
      const response = await ApiCall.getService(url, "GET");
      if (response.status === 200) {
        setPermissions((prevdata) =>
          prevdata.filter(
            (permissions) => permissions.permission_id !== deleteID
          )
        );
        CustomNotify({ type: "success", message: response.msg });
      }
    }
  };

  const userData = useSelector(selectAuth);

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Permission",
          read: true,
        },
      ]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <>
        <div className="search-bar">
          <Title title={t("Manage permissions")} />
          <div className="row">
            <div className="col-8">
              <SearchModel
                className="form-control"
                placeHolder={t("Search by name or classification...")}
                onChange={handleSearchChange}
              />
            </div>
          </div>
        </div>
        <div className="position-relative tableMainWrapper">
          <AccessControl
            requiredPermissions={[
              {
                permission: "Permission",
                create: true,
              },
            ]}
            override={userData.isSuperAdmin}
          >
            <div className="row">
              <div className="ManageCreateBtn">
                <LinkTo
                  pagelink="/permission/create"
                  title={t("Create permission")}
                  icon={faPlus}
                />
              </div>
            </div>
          </AccessControl>
          <div className="tableSection">
            <div className="table-responsive manage-permission">
              <div className="TableHeight">
                <table className="table table-hover">
                  <thead>
                    <tr className="TableHeader">
                      <th className="ps-lg-4">{t("Name")}</th>
                      <th>{t("Classification")}</th>
                      <th>
                        <AccessControl
                          requiredPermissions={[
                            {
                              permission: "Permission",
                              update: true,
                              delete: true,
                            },
                          ]}
                          override={userData.isSuperAdmin}
                          actions={true}
                          strict={false}
                        >
                          {t("Actions")}
                        </AccessControl>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems && currentItems.length > 0 ? (
                      currentItems?.map((item, index) => (
                        <tr
                          key={item.permission_id}
                          className="border-bottom box-shadow mb-3"
                        >
                          <td className="text-break ps-lg-4" data-label="Name">
                            {item.name}
                          </td>
                          <td
                            className="text-break"
                            data-label="Classification"
                          >
                            {item.classification_name}
                          </td>
                          <td className="px-2 table-action-icons">
                            <AccessControl
                              requiredPermissions={[
                                {
                                  permission: "Permission",
                                  update: true,
                                },
                              ]}
                              override={userData.isSuperAdmin}
                            >
                              <button
                                className="table-action-btn border-0 p-0 me-2"
                                title={t("Delete")}
                                onClick={() =>
                                  navigate(
                                    `/permission/edit/${item.permission_id}`
                                  )
                                }
                              >
                                <EditIcon />
                              </button>
                            </AccessControl>
                            <AccessControl
                              requiredPermissions={[
                                {
                                  permission: "Permission",
                                  delete: true,
                                },
                              ]}
                              override={userData.isSuperAdmin}
                            >
                              <button
                                className="table-action-btn border-0 p-0 me-2"
                                title={t("Delete")}
                                onClick={() =>
                                  deletePermission(item.permission_id)
                                }
                              >
                                <DeleteIcon />
                              </button>
                            </AccessControl>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className="border">
                        <td colSpan={4} className="border-0 text-center py-3">
                          <span className="text-danger fw-bold">
                            {t("No records")}
                          </span>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div
                className="pagination justify-content-center"
                style={{ paddingTop: "0.25vw" }}
              >
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-md-6 align-self-center"
          style={{ padding: "1vw 0" }}
        >
          <Link
            to="/"
            className="text-uppercase back-btn text-decoration-underline"
          >
            {t("Back")}
          </Link>
        </div>
        <ModalPopup
          show={showModal}
          onHide={handleModalClose}
          title={t("Delete confirmation")}
          body={t("Are you sure want to delete?")}
          onCloseButtonClick={handleModalClose}
          onConfirmButtonClick={handleSaveChanges}
          closeTitle={t("No")}
          confirmTitle={t("Yes")}
        />
      </>
    </AccessControl>
  );
};

export default ManagePermissions;
