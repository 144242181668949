import React, { useState, useEffect } from "react";
import { LocationData } from "./ManageLocation";
import { Link, useNavigate, useParams } from "react-router-dom";
import Search from "components/atoms/Search";
import ModalPopup from "components/atoms/ModalPopup";
import { ApiCall } from "services/ApiServices";
import CustomNotify from "components/atoms/CustomNotify";
import { ARCHIVE_LOCATION } from "routes/ApiEndpoints";
import Pagination from "components/atoms/Pagination";
import "./location.css";
import {
  LOCATION,
  MANAGE_COMPANY,
  M_COMPANY_CREATION,
} from "../../../../constants/Constants";
import TableStructure from "components/atoms/TableStructure";
import {
  ExcludeLocationCompanyData,
  LocationColumn,
  LocationTableHeader,
} from "TableHeader";
import { t } from "pages/microservices/masterData/translation/Translation";
import Popup from "components/molecules/Popup";
import FormatData from "components/molecules/FormatData";
import Button from "components/atoms/Button";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import Title from "components/atoms/Title";
import LinkTo from "components/atoms/LinkTo";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import ResetBtn from "components/atoms/ResetBtn";
import SearchBtn from "components/atoms/SearchBtn";

interface ManageLocationPageProps {
  locationObject: LocationData[];
  currentPage: number;
  searchValue: (name: string, value: string | number) => void;
  deleteFilter: (deleteId: number) => void;
  onPageChange: (pageNumber: number) => void;
  handleSubmitAction: (clear: boolean) => void;
  searchData: any;
  totalPages: number;
}

const ManageLocationPage: React.FC<ManageLocationPageProps> = ({
  locationObject,
  searchValue,
  deleteFilter,
  searchData,
  handleSubmitAction,
  currentPage,
  totalPages,
  onPageChange,
}) => {
  //Modal popup for company details
  const [popup, setPopup] = useState({
    isModelOpen: false,
  });
  //set company details in modal
  const [modalData, setModalData] = useState<any>(null);
  const [deleteID, setDeleteId] = useState<number | undefined>();
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // let currentItems = locationObject?.slice(indexOfFirstItem, indexOfLastItem);
  let currentItems = locationObject;
  //Show comapny details on click
  currentItems = currentItems.map((item: any) => {
    item.company = (
      <span onClick={(e: any) => viewCompanyDetails(e, item)}>
        {item.company}
      </span>
    );
    return item;
  });

  const handleSearchChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    const debounceTime = 10;
    clearTimeout((handleSearchChange as any).timeoutId);
    (handleSearchChange as any).timeoutId = setTimeout(() => {
      searchValue(name, value);
    }, debounceTime);
  };

  const deleteLocation = (locationData: LocationData | any) => {
    setShowModal(true);
    setDeleteId(locationData.id);
  };

  const handleEdit = (locationData: LocationData | any) => {
    if (locationData.id) {
      navigate(`${LOCATION}/${locationData.id}`);
    }
  };
  const viewCompanyDetails = (
    event: React.MouseEvent<HTMLAnchorElement>,
    value: string
  ) => {
    event.preventDefault();
    setModalData(value);
    setPopup((prev) => ({ ...prev, isModelOpen: true }));
  };

  const closeModal = () => {
    setPopup((prev) => ({ ...prev, isModelOpen: false }));
  };

  const handleSubmit = (e: any, clear: boolean = false) => {
    e.preventDefault();
    handleSubmitAction(clear);
  };

  const handleModalClose = () => setShowModal(!showModal);

  const handleSaveChanges = async () => {
    setShowModal(false);

    if (deleteID) {
      const response = await ApiCall.getService(
        `${ARCHIVE_LOCATION}/${deleteID}`,
        "GET",
        M_COMPANY_CREATION
      );
      if (response?.status === 200) {
        deleteFilter(deleteID);
        CustomNotify({ type: "success", message: response.msg });
      }
    }
  };

  const userData = useSelector(selectAuth);

  return (
    <>
      <div className="search-bar">
        <Title title={t("Manage location")} />
        <div className="row">
          <div className="col-4">
            <Search
              handleChange={handleSearchChange}
              placeholder={t("Location")}
              name="location"
              value={searchData.location}
            />
          </div>
          <div className="col-4">
            <Search
              handleChange={handleSearchChange}
              placeholder={t("Company")}
              name="company"
              value={searchData.company}
            />
          </div>
          <div className="col-4">
            <div className="d-flex">
              <ResetBtn handleResetClick={(e) => handleSubmit(e, true)} />
              <SearchBtn handleSearchClick={handleSubmit} />
            </div>
          </div>
        </div>
      </div>
      <div className="position-relative tableMainWrapper">
        <AccessControl
          requiredPermissions={[
            {
              permission: "Location",
              create: true,
            },
          ]}
          override={userData.isSuperAdmin}
        >
          <div className="row">
            <div className="ManageCreateBtn">
              <LinkTo
                pagelink="/location"
                title={t("Create location")}
                icon={faPlus}
              />
            </div>
          </div>
        </AccessControl>
        <div className="table-responsive manage-location tableSection">
          <div className="TableHeight">
            <TableStructure
              isAction
              headers={LocationTableHeader}
              data={currentItems}
              values={LocationColumn}
              handleEdit={handleEdit}
              handleArchive={deleteLocation}
              searchStatus={searchData}
              permissionType={"Location"}
            />
          </div>
          <div className="pagination justify-content-center align-items-center">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={onPageChange}
            />
          </div>
        </div>
      </div>

      <div className="col-md-4 align-self-center mt-3">
        <Link
          to={MANAGE_COMPANY}
          className="text-uppercase back-btn text-decoration-underline"
        >
          {t("Back")}
        </Link>
      </div>
      <ModalPopup
        show={showModal}
        onHide={handleModalClose}
        title="Archive confirmation"
        body="Are you sure want to archive?"
        onCloseButtonClick={handleModalClose}
        onConfirmButtonClick={handleSaveChanges}
        closeTitle="No"
        confirmTitle="Yes"
      />
      {popup.isModelOpen && (
        <Popup
          title={t("Company Details")}
          body={
            <FormatData
              data={modalData}
              excludeData={ExcludeLocationCompanyData}
            />
          }
          cancel={closeModal}
          modalSize="lg"
          notext="Close"
          cancelButtonClass="shadow-none float-end d-flex justify-content-center"
        />
      )}
    </>
  );
};
export default ManageLocationPage;
