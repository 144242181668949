import { VACANCY_EDIT, VACANCY_CREATE, MATERIAL_CREATE, MATERIAL_EDIT } from "routes/ApiEndpoints";
import { M_MASTER_DATA } from "constants/Constants";
import { PATH_MANAGE_MATERIAL } from "constants/Paths";
import { FormProviderWrapper } from "utils/multiTabForm/formWrapper/Index";
import { useLocation, useParams } from "react-router-dom";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import { usersSetFunction } from "../car/customFunctions";

export type CustomFnType = (payload: any, dataObj: any) => any;

const AddMaterial = () => {
  const location = useLocation();
  const path = location.pathname;
  const { id: routeId } = useParams<{ id: string }>();

  const customActions: CustomFnType = (payload: any, dataObj: any) => {
    switch (payload.type) {
      case "users":
        return usersSetFunction(payload, dataObj, 'material');
      default: return "Called with wrong key";
    }
  };

  const permissionType = routeId ? "update" : "create";
  const permissionObject: any = {
    permission: "Material"
  }
  permissionObject[permissionType] = true;
  const userData = useSelector(selectAuth);

  return (
    <>
      <AccessControl
        requiredPermissions={[
          permissionObject
        ]}
        renderNoAccess={true}
        override={userData.isSuperAdmin}
      >
        <FormProviderWrapper
          backToManage={"/fleetOverview/material"}
          formName={"Material"}
          dataSubmissionEndPoint={MATERIAL_CREATE}
          microservice={M_MASTER_DATA}
          dataFetchEndPoint={MATERIAL_EDIT}
          entryId={routeId}
          customFn={customActions}
          formTitle={routeId ? "Edit material" : "Create material"}
        />
      </AccessControl>
    </>
  );
};

export default AddMaterial;
