import React from "react";
import Button from "components/atoms/Button";
import EditIcon from "static/images/EditIcon";
import DeleteIcon from "static/images/DeleteIcon";
import ArchieveIcon from "static/images/ArchiveIcon";
import Unarchive from "static/images/UnarchiveIcon";
import { t } from "pages/microservices/masterData/translation/Translation";
import Download from "static/images/downloadIcon";
import Matching from "static/images/matchingIcon";
import AddPlanningIcon from "static/images/AddPlanningIcon";
import AddProjectIcon from "static/images/AddProjectIcon";
import ViewIcon from "static/images/ViewIcon";
import HotlistIcon from "static/images/HotlistIcon";
import HotlistSelectedIcon from "static/images/HotlistSelectedIcon";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import LinkIcon from "static/images/LinkIcon";
import AddContractIcon from "static/images/addContractIcon";
import SendMailIcon from "static/images/sendMailIcon";
import CloneIcon from "static/images/CloneIcon";
import Close from "static/images/Close";
import TickIcon from "static/images/TickIcon";
import SelectedEmployeeIcon from "static/images/SelectedEmployeeIcon";
import AddEmployeeIcon from "static/images/AddEmployeeIcon";
import AddedProjectIcon from "static/images/AddedProjectIcon";
import SendContractIcon from "static/images/sendContractIcon";
import DownloadWageIcon from "static/images/wageProposalDownloadOcon";
import DownloadContractIcon from "static/images/ContractDownloadIcon";
import FleetIcon from "static/images/FleetIcon";
import AddFleetIcon from "static/images/AddFleetIcon";
import RemoveFleetIcon from "static/images/RemoveFleet";
import FollowUpIcon from "static/images/FollowUpIcon";
import { IconData } from "services/IconData";
import BlackHeartIcon from "static/images/BlackHeartICon";
import WhiteHeartIcon from "static/images/whiteHeartIcon";
interface TableActionIconsProps {
  isAction: boolean;
  signature?: String;
  contract?: string;
  sendWageMail?: string;
  handleEdit?: (value: any) => void;
  handleDelete?: (value: any) => void;
  handleArchive?: (value: any) => void;
  handleUnArchive?: (value: any) => void;
  handleDownload?: (value: any) => void;
  handleSendProjectProposal?: (value: any) => void;
  handleView?: (value: any) => void;
  handleSearchSubmit?: any;
  handleMatching?: (value: any) => void;
  handleAddPlanning?: (value: any) => void;
  handleAddProject?: (value: any) => void;
  handleAddHotlist?: (value: any) => void;
  handleAdd?: (value: any) => void;
  handlePlan?: (value: any) => void;
  handleLink?: (value: any) => void;
  handleAddContract?: (value: any) => void;
  handleSendWageProposalMail?: (value: any) => void;
  handleSendContractMail?: (value: any) => void;
  handleClone?: (value?: any) => void;
  handleApprove?: (value: any) => void;
  handleReject?: (value: any) => void;
  handleSendMaterialMail?: (value: any) => void;
  handleAddEmployee?: (value: any) => void;
  handleDownloadWageProposal?: (value: any) => void;
  handleDownloadContract?: (value: any) => void;
  handleFleet?: (value: any) => void;
  handleAddFleet?: (value: any) => void;
  handleRemove?: (value: any) => void;
  isDisable?: boolean;
  handleFollowup?: (value: any) => void;
  editClass?: string;
  deleteClass?: string;
  archiveClass?: string;
  unArchiveClass?: string;
  downloadClass?: string;
  sendProjectProposalClass?: string;
  matchingClass?: string;
  AddPlanningClass?: string;
  AddContractClass?: string;
  sendWageProposalClass?: string;
  AddProjectClass?: string;
  AddHotlistClass?: string;
  AddLinkClass?: string;
  viewClass?: string;
  cloneClass?: string;
  value?: any;
  permission: any;
  linkIcon?: any;
  employeeAddedToProject?: any;
  projectAddedToEmployee?: any;
  followupIcon?: any;
}

const TableActions: React.FC<TableActionIconsProps> = (props) => {
  const userData = useSelector(selectAuth);
  const hidAndShowFileViewIcon = (value: any) => {
    if (typeof value === 'object' && "file_path" in value && value?.file_path == "") {
      return false;
    }
    return true;
  }

  return (
    <div className="d-flex align-items-center">
      {props.isAction && props.handleEdit && (
        <AccessControl
          requiredPermissions={[
            {
              permission: props.permission,
              update: true,
            },
          ]}
          override={userData.isSuperAdmin}
        >
          <Button
            title={<EditIcon />}
            className={props.editClass ?? "table-action-btn border-0  p-0 me-2"}
            handleClick={() => props.handleEdit?.(props.value)}
            tooltip={t("Edit")}
          />
        </AccessControl>
      )}
      {props.isAction && props.handleDelete && (
        <Button
          title={<DeleteIcon />}
          className={props.deleteClass ?? "table-action-btn border-0  p-0 me-2"}
          handleClick={() => props.handleDelete?.(props.value)}
          tooltip={t("Delete")}
        />
      )}
      {props.isAction && props.handleArchive && (
        <AccessControl
          requiredPermissions={[
            {
              permission: props.permission,
              delete: true,
            },
          ]}
          override={userData.isSuperAdmin}
        >
          <Button
            title={props.value.status ? <ArchieveIcon /> : <ArchieveIcon />}
            className={props.archiveClass ?? "table-action-btn border-0  p-0 me-2"}
            handleClick={() => props.handleArchive?.(props.value)}
            tooltip={props.value.status ? t("Unarchive") : t("Archive")}
          />
        </AccessControl>
      )}
      {props.isAction && props.handleUnArchive && (
        <Button
          title={props.value.status ? <Unarchive /> : <Unarchive />}
          className={props.unArchiveClass ?? "table-action-btn border-0  p-0 me-2"}
          handleClick={() => props.handleUnArchive?.(props.value)}
        />
      )}
      {props.isAction && props.handleDownload && hidAndShowFileViewIcon(props?.value) && (
        <Button
          title={<Download />}
          className={props.downloadClass ?? "table-action-btn border-0  p-0 me-2"} // Fixed typo here
          handleClick={() => props.handleDownload?.(props.value)}
          tooltip={t("Download")}
        />
      )}
      {props.isAction && props.handleDownloadWageProposal && (
        <Button
          title={<DownloadWageIcon />}
          className={props.downloadClass ?? "table-action-btn border-0  p-0 me-2"} // Fixed typo here
          handleClick={() => props.handleDownloadWageProposal?.(props.value)}
          tooltip={t("Download proposal")}
        />
      )}
      {props.isAction && props.handleDownloadContract && (
        <Button
          title={<DownloadContractIcon />}
          className={props.downloadClass ?? "table-action-btn border-0  p-0 me-2"} // Fixed typo here
          handleClick={() => props.handleDownloadContract?.(props.value)}
          tooltip={t("Download contract")}
        />
      )}
      {props.isAction && props.handleSendProjectProposal && (
        <Button
          title={props.value.status ? <SendMailIcon /> : <SendMailIcon />}
          className={props.sendWageProposalClass ?? "table-action-btn border-0  p-0 me-2"}
          handleClick={() => props.handleSendProjectProposal?.(props.value)}
          tooltip={t("Send proposal")}
        />
      )}
      {/* {props.isAction && props.handleView && ( */}
      {props.isAction && props.handleView && hidAndShowFileViewIcon(props?.value) && (
        <AccessControl
          requiredPermissions={[
            {
              permission: props.permission,
              read: true,
            },
          ]}
          override={userData.isSuperAdmin}
        >
          <Button
            title={props.value.status ? <ViewIcon /> : <ViewIcon />}
            className={props.viewClass ?? "table-action-btn border-0  p-0 me-2"}
            handleClick={() => props.handleView?.(props.value)}
            tooltip={t("View")}
          />
        </AccessControl>
      )}
      {props.isAction && props.handleSearchSubmit && (
        <>
          <Button
            title={IconData.ResetIcon}
            type="submit"
            handleClick={(e) => props.handleSearchSubmit(e, true)}
            className="delete-btn  shadow-none text-uppercase me-3 "
            tooltip={t("Reset")}
          />
          <Button
            title={t("Search")}
            type="submit"
            handleClick={props.handleSearchSubmit}
            className="form-button shadow-none text-uppercase px-3 search-btns"
            tooltip={t("Search")}
          />
        </>
      )}
      {props.isAction && props.handleMatching && (
        <Button
          title={props.value.status ? <Matching /> : <Matching />}
          className={props.matchingClass ?? "table-action-btn border-0  p-0 me-2"}
          handleClick={() => props.handleMatching?.(props.value)}
        />
      )}
      {props.isAction && props.handleAddProject && (
        <Button
          title={props.projectAddedToEmployee ? <AddedProjectIcon /> : <AddProjectIcon />}
          className={props.AddProjectClass ?? "table-action-btn border-0  p-0 me-2"}
          handleClick={() => props.handleAddProject?.(props.value)}
        />
      )}
      {props.isAction && props.handleAddPlanning && (
        <Button
          title={props.value.status ? <AddPlanningIcon /> : <AddPlanningIcon />}
          className={props.AddPlanningClass ?? "table-action-btn border-0  p-0 me-2"}
          handleClick={() => props.handleAddPlanning?.(props.value)}
        />
      )}
      {props.isAction && props.handleAddHotlist && (
        <Button
          title={(props.value === true || props?.value?.ishotlisted) ? <BlackHeartIcon /> : <WhiteHeartIcon />}
          className={props.AddHotlistClass ?? "table-action-btn border-0  p-0 me-2"}
          handleClick={() => props.handleAddHotlist?.(props.value)}
        />
      )}
      {props.linkIcon && props.handleLink && (
        <Button
          title={props.value === true ? <LinkIcon /> : <LinkIcon />}
          className={props.AddLinkClass ?? "table-action-btn border-0  p-0 me-2"}
          handleClick={() => props.handleLink?.(props.value)}
        />
      )}
      {props.isAction && props.handleAddContract && (
        <Button
          title={props.value.status ? <AddContractIcon /> : <AddContractIcon />}
          className={props.AddContractClass ?? "table-action-btn border-0  p-0 me-2"}
          handleClick={() => props.handleAddContract?.(props.value)}
        />
      )}
      {props.isAction && props.handleSendWageProposalMail && (
        <Button
          title={props.value.status ? <SendMailIcon /> : <SendMailIcon />}
          className={props.sendWageProposalClass ?? "table-action-btn border-0  p-0 me-2"}
          handleClick={() => props.handleSendWageProposalMail?.(props.value)}
          tooltip={t("Send wage proposal")}
        />
      )}
      {props.isAction && props.handleSendContractMail && (
        <Button
          title={props.value.status ? <SendContractIcon /> : <SendContractIcon />}
          className={props.sendWageProposalClass ?? "table-action-btn border-0  p-0 me-2"}
          handleClick={() => props.handleSendContractMail?.(props.value)}
        />
      )}

      {props.isAction && props.handleClone && (
        <Button
          title={<CloneIcon />}
          className={props.cloneClass ?? "table-action-btn border-0  p-0 me-2"}
          handleClick={() => props.handleClone?.(props.value)}
          tooltip={t("Clone")}
        />
      )}
      {props.isAction && props.handleApprove && (
        <Button
          title={<TickIcon />}
          handleClick={() => props.handleApprove?.(props.value)}
          className={props.AddLinkClass ?? "table-action-btn border-0  p-0 me-2"}
          tooltip={t("Approve")}
        />
      )}
      {props.isAction && props.handleReject && (
        <Button
          title={<Close />}
          handleClick={() => props.handleReject?.(props.value)}
          className={props.AddLinkClass ?? "table-action-btn border-0  p-0 me-2"}
          tooltip={t("Reject")}
        />
      )}

      {props.isAction && props.handleSendMaterialMail && (
        <Button
          title={props.value.status ? <SendMailIcon /> : <SendMailIcon />}
          className={props.sendWageProposalClass ?? "table-action-btn border-0  p-0 me-2"}
        />)}


      {props.isAction && props.handleAddEmployee && (
        <Button
          title={
            props.employeeAddedToProject === true ? <SelectedEmployeeIcon /> : <AddEmployeeIcon />
          }
          className={props.AddProjectClass ?? "table-action-btn border-0  p-0 me-2"}
          handleClick={() => props.handleAddEmployee?.(props.value)}
        />
      )}

      {props.isAction && props.handleFleet && (
        <Button
          title={<FleetIcon />}
          handleClick={() => props.handleFleet?.(props.value)}
          className={props.sendWageProposalClass ?? "table-action-btn border-0  p-0 me-2"}
          tooltip={t("Fleet")}
          disabled={props?.isDisable}
        />
      )}

      {props.isAction && props.handleAddFleet && (
        <Button
          title={<AddFleetIcon />}
          handleClick={() => props.handleAddFleet?.(props.value)}
          className={"table-action-btn border-0  p-0 me-2"}
          tooltip={t("Add")}
          disabled={props?.isDisable}
        />
      )}

      {props.isAction && props.handleRemove && (
        <Button
          title={<RemoveFleetIcon />}
          handleClick={() => props.handleRemove?.(props.value)}
          className={"table-action-btn border-0  p-0 me-2"}
          tooltip={t("Remove")}
          disabled={props?.isDisable}
        />
      )}

      {props.followupIcon && props.isAction && props.handleFollowup && (
        <Button
          title={<FollowUpIcon/>}
          className={"table-action-btn border-0  p-0 me-2"}
          handleClick={() => props.handleFollowup?.(props.value)}
        />
      )}
    </div>
  );
};

export default TableActions;
