import { useFormContext } from "pages/microservices/companyCreation/context/Context";
import CheckBoxField from "components/atoms/CheckBoxField";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import { Navigation } from "../formNavigation/Navigation";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { ValidationRules } from "utils/TypeAnnotations";
import {
  Option,
  scrollToTop,
  validateForm,
  validateRequired,
  validateSelectField,
} from "services/validation/ValidationService";
import { useState } from "react";
import { AddressProps } from "pages/microservices/companyCreation/annotations/CompanyAnnotations";
import { ApiCall } from "services/ApiServices";
import { REGISTER_COMPANY } from "routes/ApiEndpoints";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomNotify from "components/atoms/CustomNotify";
import { mapToSelect } from "utils/MapToSelect";
import { M_COMPANY_CREATION } from "../../../../../constants/Constants";
import LabelField from "components/atoms/LabelField";
import { t } from "pages/microservices/masterData/translation/Translation";

const Address = () => {
  const { state, dispatch } = useFormContext();
  const navigate = useNavigate();
  const [countryId, setCountryId] = useState<number | null>(null);
  const [validationStatus, setValidationStatus] = useState({
    isValid: false,
    type: "",
  });
  const { companyId } = useParams<{ companyId: string }>();
  const location = useLocation();
  // Check if 'mode=view' is present in the query string
  const isViewMode = location.search.includes("mode=view");

  const validation = (
    name: string,
    value: string | boolean | Date | object[] | null | Option | number,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      // location: [validateRequired],
      street: [validateRequired],
      number: [validateRequired],
      zipCode: [validateRequired],
      city: [validateRequired],
      country: [validateSelectField],
      biStreet: [validateRequired],
      biZipCode: [validateRequired],
      biNumber: [validateRequired],
      biCity: [validateRequired],
      biCountry: [validateSelectField],
    };
    const locationValidationRules: ValidationRules = {
      locationStreet: [validateRequired],
      locationNumber: [validateRequired],
      locationZipCode: [validateRequired],
      locationCity: [validateRequired],
      locationCountry: [validateSelectField],
    };

    const validationErrors = validateForm(
      { ...state.address, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    dispatch({
      type: "UPDATE_FIELD_ERROR",
      fieldName: name,
      error: validationErrors[name],
    });
    const isFieldValid = Object.keys(validationErrors).length === 0;

    if (isFieldValid) {
      state.fieldError[name] = "";
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const copyBillingFieldsFromOfficial = (isChecked: boolean) => {
    if (isChecked) {
      const billingFields = [
        "street",
        "number",
        "box",
        "zipCode",
        "city",
        "country",
      ];
      billingFields.forEach((fieldName) => {
        const billingFieldName =
          "bi" + fieldName.charAt(0).toUpperCase() + fieldName.slice(1);
        const fieldValue = state.address[fieldName as keyof AddressProps];
        dispatch({
          type: "UPDATE_ADDRESS_FIELD",
          field: billingFieldName,
          value: fieldValue,
        });
        validation(billingFieldName, fieldValue, true);
      });
    } else {
      const billingFields = [
        "biStreet",
        "biNumber",
        "biBox",
        "biZipCode",
        "biCity",
        "biCountry",
      ];
      billingFields.forEach((fieldName) => {
        dispatch({
          type: "UPDATE_ADDRESS_FIELD",
          field: fieldName,
          value: "",
        });
        validation(fieldName, "", true);
      });
    }
  };
  const handleLocationAddressUpdate = (isChecked: boolean) => {
    if (isChecked) {
      const locationAddressFields = [
        { field: "locationStreet", value: state.address.street },
        { field: "locationNumber", value: state.address.number },
        { field: "locationBox", value: state.address.box },
        { field: "locationZipCode", value: state.address.zipCode },
        { field: "locationCity", value: state.address.city },
        { field: "locationCountry", value: state.address.country },
      ];
      dispatch({
        type: "UPDATE_COMPANYLOCATION_FIELD",
        field: "locationName",
        value: `${state.basic.companyName}-HQ`,
        // index: 0,
      });
      locationAddressFields.forEach((field) => {
        dispatch({
          type: "UPDATE_COMPANYLOCATION_FIELD",
          field: field.field,
          value: field.value,
          // index: 0,
        });
        const locationAddressFields = [
          { field: "locationPhoneNumber", value: "" },
          { field: "locationName", value: "" },
          { field: "locationStreet", value: "" },
          { field: "locationNumber", value: "" },
          { field: "locationBox", value: "" },
          { field: "locationZipCode", value: "" },
          { field: "locationCity", value: "" },
          { field: "locationCountry", value: "" },
        ];
        locationAddressFields.forEach((field) => {
          dispatch({
            type: "UPDATE_FIELD_ERROR",
            fieldName: field.field,
            error: field.value,
          });
        });
        // validation(field.field, field.field, true);
      });
    } else {
      const locationAddressFields = [
        { field: "locationStreet", value: "" },
        { field: "locationNumber", value: "" },
        { field: "locationBox", value: "" },
        { field: "locationZipCode", value: "" },
        { field: "locationCity", value: "" },
        { field: "locationCountry", value: "" },
      ];
      locationAddressFields.forEach((field) => {
        dispatch({
          type: "UPDATE_COMPANYLOCATION_FIELD",
          field: field.field,
          value: field.value,
        });
      });
    }
  };

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;

    if (type === "checkbox") {
      const newValue = checked ? 1 : 0;
      dispatch({ type: "UPDATE_ADDRESS_FIELD", field: name, value: newValue });
      validation(name, checked, true);
      if (name === "sameAddress") {
        copyBillingFieldsFromOfficial(checked);
      }
      if (name === "hqAddress") {
        handleLocationAddressUpdate(checked);
      }
    } else {
      dispatch({ type: "UPDATE_ADDRESS_FIELD", field: name, value });
      validation(name, value, true);
      if (state.address.sameAddress) {
        const billingFieldName =
          "bi" + name.charAt(0).toUpperCase() + name.slice(1);
        dispatch({
          type: "UPDATE_ADDRESS_FIELD",
          field: billingFieldName,
          value,
        });
        validation(billingFieldName, value, true);
      }
    }
  };

  const handleSelectChange = (selectedOption: any, name: string) => {
    dispatch({
      type: "UPDATE_ADDRESS_FIELD",
      field: name,
      value: selectedOption,
    });

    if (state.address.sameAddress) {
      const billingFieldName =
        "bi" + name.charAt(0).toUpperCase() + name.slice(1);

      dispatch({
        type: "UPDATE_ADDRESS_FIELD",
        field: billingFieldName,
        value: selectedOption,
      });
      validation(billingFieldName, selectedOption, true);
    }
    validation(name, selectedOption, true);
  };

  const validStatus = (validation: any) => {
    setValidationStatus({
      isValid: true,
      // isValid: validation.isValid,
      type: validation.type,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const formData = {
      basic: state.basic,
      address: state.address,
    };
    formData.basic.companyId = companyId;
    if (state.basic.vat !== "" && state.basic.companyName !== "") {
      const url = !companyId
        ? REGISTER_COMPANY
        : `${REGISTER_COMPANY}/${companyId}`;
      const response = await ApiCall.service(
        url,
        "POST",
        formData,
        false,
        M_COMPANY_CREATION
      );
      if (response?.status === 200) {
        if (validationStatus.type === "draft") {
          CustomNotify({
            type: "success",
            message: "Draft saved successfully!",
          });
          navigate("/manage-companies");
        } else {
          navigate(`/company/${response.companyId}`);
        }
      } else if (response?.status === 400) {
        CustomNotify({ type: "error", message: response.msg.vat_number });
        dispatch({ type: "UPDATE_TAB_ERROR", tabIndex: 0, error: true });
        scrollToTop();
        state.fieldError.vat = response.msg.vat_number;
      }
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <div
        className="form-border"
        style={{ paddingTop: "3vw", paddingBottom: "1vw" }}
      >
        <div className="row">
          <div className="col-md-12">
            <LabelField
              title={t("Official address")}
              className="tab-subtitle"
            />
          </div>
        </div>
        {/* <div className="row">
          <div className="col-sm-12 col-md-6">
            <LabelWithInputField
              isMandatory={true}
              name="location"
              handleChange={handleFieldChange}
              value={state.address.location}
              id="location"
              label="Location"
              type="text"
              error={state.fieldError.location}
            />
          </div>
        </div> */}
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <LabelWithInputField
              isMandatory={true}
              name="street"
              handleChange={handleFieldChange}
              value={state.address.street}
              id="street"
              label="Street"
              type="text"
              error={state.fieldError.street}
              isDisabled={isViewMode}
            />
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="row">
              <div className="col-sm-12 col-md-8">
                <LabelWithInputField
                  isMandatory={true}
                  name="number"
                  handleChange={handleFieldChange}
                  value={state.address.number}
                  id="number"
                  label="Number"
                  type="text"
                  error={state.fieldError.number}
                  isDisabled={isViewMode}
                />
              </div>
              <div className="col-sm-12 col-md-4">
                <LabelWithInputField
                  isMandatory={false}
                  name="box"
                  handleChange={handleFieldChange}
                  value={state.address.box}
                  id="box"
                  label="Box"
                  type="text"
                  isDisabled={isViewMode}
                />
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-4">
            <LabelWithInputField
              isMandatory={true}
              name="zipCode"
              handleChange={handleFieldChange}
              value={state.address.zipCode}
              id="zipCode"
              label="Zip code"
              type="text"
              error={state.fieldError.zipCode}
              isDisabled={isViewMode}
            />
          </div>
          <div className="col-sm-12 col-md-4">
            <LabelWithInputField
              isMandatory={true}
              name="city"
              handleChange={handleFieldChange}
              value={state.address.city}
              id="city"
              label="City"
              type="text"
              error={state.fieldError.city}
              isDisabled={isViewMode}
            />
          </div>
          <div className="col-sm-12 col-md-4">
            <SelectWithSearch
              title="Country"
              name="country"
              isMandatory={true}
              search={true}
              options={state.countryList}
              placeHolder="Select"
              value={state.address.country}
              onChange={(e) => handleSelectChange(e, "country")}
              isMulti={false}
              className="select-field"
              error={state.fieldError.country}
              isDisabled={isViewMode}
            />
          </div>

          <div className="col-md-12 mb-3 mt-2">
            <CheckBoxField
              label="This is the Headquarters address"
              name="hqAddress"
              onChangeHandler={handleFieldChange}
              isChecked={state.address.hqAddress === 1}
              disable={isViewMode}
              id="hqAdress"
              lineHeight="1.6vw"
            />
          </div>
          <div className="col-md-12">
            <CheckBoxField
              label="Same as official address"
              name="sameAddress"
              onChangeHandler={handleFieldChange}
              isChecked={
                state.address.sameAddress === 1 ||
                state.address.sameAddress === true
              }
              disable={isViewMode}
              id="sameAddress"
              lineHeight="1.6vw"
            />
          </div>
          <div className="row mt-3">
            <div className="col-md-12">
              <LabelField
                title={t("Billing address")}
                className="tab-subtitle"
              />
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <LabelWithInputField
              isMandatory={true}
              name="biStreet"
              handleChange={handleFieldChange}
              value={state.address.biStreet}
              isDisabled={
                state.address.sameAddress === 1 ||
                state.address.sameAddress === true ||
                isViewMode
              }
              id="street"
              label="Street"
              type="text"
              error={state.fieldError.biStreet}
            />
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="row">
              <div className="col-sm-12 col-md-8">
                <LabelWithInputField
                  isMandatory={true}
                  name="biNumber"
                  handleChange={handleFieldChange}
                  value={state.address.biNumber}
                  isDisabled={
                    state.address.sameAddress === 1 ||
                    state.address.sameAddress === true ||
                    isViewMode
                  }
                  id="number"
                  label="Number"
                  type="text"
                  error={state.fieldError.biNumber}
                />
              </div>
              <div className="col-sm-12 col-md-4">
                <LabelWithInputField
                  isMandatory={false}
                  name="biBox"
                  handleChange={handleFieldChange}
                  value={state.address.biBox}
                  isDisabled={
                    state.address.sameAddress === 1 ||
                    state.address.sameAddress === true ||
                    isViewMode
                  }
                  id="box"
                  label="Box"
                  type="text"
                />
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-4">
            <LabelWithInputField
              isMandatory={true}
              name="biZipCode"
              handleChange={handleFieldChange}
              value={state.address.biZipCode}
              isDisabled={
                state.address.sameAddress === 1 ||
                state.address.sameAddress === true ||
                isViewMode
              }
              id="zipCode"
              label="Zip code"
              type="text"
              error={state.fieldError.biZipCode}
            />
          </div>
          <div className="col-sm-12 col-md-4">
            <LabelWithInputField
              isMandatory={true}
              name="biCity"
              handleChange={handleFieldChange}
              value={state.address.biCity}
              isDisabled={
                state.address.sameAddress === 1 ||
                state.address.sameAddress === true ||
                isViewMode
              }
              id="biCity"
              label="City"
              type="text"
              error={state.fieldError.biCity}
            />
          </div>
          <div className="col-sm-12 col-md-4">
            <SelectWithSearch
              title="Country"
              name="biCountry"
              isMandatory={true}
              search={true}
              placeHolder="Select"
              options={state.countryList}
              isDisabled={
                state.address.sameAddress === 1 ||
                state.address.sameAddress === true ||
                isViewMode
              }
              value={state.address.biCountry}
              onChange={(e) => handleSelectChange(e, "biCountry")}
              isMulti={false}
              error={state.fieldError.biCountry}
            />
          </div>
        </div>
      </div>
      <div className="">
        <Navigation validStatus={validStatus} />
      </div>
    </form>
  );
};

export default Address;
