import {
  ContactPersonTableHeader,
  ExcludeContactCompanyData,
  RoleTableHeader,
} from "TableHeader";
import SearchModel from "components/atoms/SearchModel";
import TableStructure from "components/atoms/TableStructure";
import { t } from "pages/microservices/masterData/translation/Translation";
import React, { useEffect, useState, Suspense } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ApiCall } from "services/ApiServices";
import {
  CONTACT_PERSON_DATA,
  EDIT_CONTACT_PERSON,
  DELETE_CONTACT_PERSON,
  GET_COMPANY,
} from "routes/ApiEndpoints";
import { ERROR, M_COMPANY_CREATION } from "constants/Constants";
import ModalPopup from "components/atoms/ModalPopup";
import CustomNotify from "components/atoms/CustomNotify";
import Pagination from "components/atoms/Pagination";
import Popup from "components/molecules/Popup";
import FormatData from "components/molecules/FormatData";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import Button from "components/atoms/Button";
import Search from "components/atoms/Search";
import { IconData } from "services/IconData";
import Title from "components/atoms/Title";
import LabelField from "components/atoms/LabelField";
import ResetBtn from "components/atoms/ResetBtn";
import SearchBtn from "components/atoms/SearchBtn";
import LinkTo from "components/atoms/LinkTo";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import SelectWithSearch from "components/atoms/SelectWithSearch";

interface ContactPerson {
  id?: number;
  name?: string;
  company?: string;
  location?: string;
  companyEmail?: string;
  companyPhoneNumber?: string;
  user_id: number;
  businessUnit: string;
}

interface ContactData {
  contactPerson: any;
  company: any;
}
const ManageContactPerson: React.FC = () => {
  // State to manage the message
  const [contactPerson, setContactPerson] = useState<ContactPerson[]>([]);
  const [deleteID, setDeleteId] = useState<number | undefined>();
  const [showModal, setShowModal] = useState(false);
  const [modalBody, setModelBody] = useState(t("Are you sure want to delete?"));
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [submit, setSubmit] = useState(false);
  const userData = useSelector(selectAuth);

  const queryParams = new URLSearchParams(window.location.search);
  const [searchData, setSearchData] = useState({
    contactPerson: "",
    company: "",
    email: "",
    phoneNumber: "",
    businessUnit: userData.businessUnit,
  });
  const [filterOptions, setFilterptions] = useState({
    companies: [],
    businessUnit: [],
  });

  //Modal popup for company details
  const [popup, setPopup] = useState({
    isModelOpen: false,
  });
  //set company details in modal
  const [modalData, setModalData] = useState<any>(null);

  const itemsPerPage = 10;
  const navigate = useNavigate();

  // useEffect(() => {
  //   const fetchOptions = async () => {
  //     try {
  //       const response = await ApiCall.getService(
  //         GET_COMPANY,
  //         "GET",
  //         M_COMPANY_CREATION,
  //         false
  //       );
  //       setFilterptions((prevState) => ({
  //         ...prevState,
  //         companies: response.company,
  //       }));
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };
  //   fetchOptions();
  // }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const postData = {
          page: currentPage,
          search: searchData,
        };
        const response = await ApiCall.service(
          CONTACT_PERSON_DATA,
          "POST",
          postData,
          false,
          M_COMPANY_CREATION
        );
        setTotalPages(response?.totalPages);

        const modifiedContactPersons: ContactPerson[] = response.data.map(
          (person: ContactPerson) => ({
            ...person,
          })
        );
        setContactPerson(modifiedContactPersons);
        setFilterptions((prevState) => ({
          ...prevState,
          companies: response.company,
          businessUnit: response.businessUnitOption,
        }));
      } catch (error) {
        console.error(ERROR, error);
      }
    };
    fetchData();
  }, [currentPage, submit]);

  const closeModal = () => {
    setPopup((prev) => ({ ...prev, isModelOpen: false }));
  };

  // Handle delete change
  const deleteContactPerson = (person: ContactPerson | any) => {
    setShowModal(true);
    setDeleteId(person.user_id);
  };

  // Handle edit change
  const editContactPerson = (person: ContactPerson | any) => {
    if (person.user_id) {
      navigate(`/create/contact-person/?edit=${person.user_id}`);
    }
  };

  const handleSearchChange = (event: any) => {
    const { name, value } = event.target;
    setSearchData((prevState) => ({ ...prevState, [name]: value }));
  };

  //Handle page Cahnges
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  //handle close model
  const handleModalClose = () => {
    setShowModal(!showModal);
    setModelBody(t("Are you sure want to delete?"));
  };

  //handle archive changes
  const handleSaveChanges = async () => {
    setShowModal(false);

    if (deleteID) {
      const url = `${DELETE_CONTACT_PERSON}/${deleteID}`;
      const response = await ApiCall.getService(url, "GET", M_COMPANY_CREATION);
      if (response.status === 403) {
        setModelBody(response.msg);
        setShowModal(true);
      } else if (response.status === 200) {
        const newContactPersonObj = contactPerson.filter(
          (item: ContactPerson) => item.user_id !== deleteID
        );
        setContactPerson(newContactPersonObj);
        CustomNotify({ type: "success", message: response.msg });
      } else {
        CustomNotify({ type: "error", message: response.msg });
      }
    }
  };
  const createQueryParams = (data: ContactData) => {
    const buildParams = (key: string, value: any) => {
      if (Array.isArray(value)) {
        return value
          .map((item, index) =>
            Object.entries(item)
              .map(([subKey, subValue]) =>
                subValue !== undefined && subValue !== null
                  ? `${encodeURIComponent(
                      `${key}[${index}][${subKey}]`
                    )}=${encodeURIComponent(String(subValue))}`
                  : ""
              )
              .filter(Boolean) // Filter out empty strings
              .join("&")
          )
          .join("&");
      } else if (typeof value === "object" && value !== null) {
        return Object.entries(value)
          .map(([subKey, subValue]) =>
            subValue !== undefined && subValue !== null
              ? `${encodeURIComponent(
                  `${key}[${subKey}]`
                )}=${encodeURIComponent(String(subValue))}`
              : ""
          )
          .filter(Boolean) // Filter out empty strings
          .join("&");
      } else {
        return `${encodeURIComponent(key)}=${encodeURIComponent(
          String(value)
        )}`;
      }
    };

    const queryParams = Object.entries(data)
      .filter(([key, value]) => value !== undefined && value !== "")
      .map(([key, value]) => buildParams(key, value))
      .join("&");

    return queryParams;
  };

  const handleSubmitAction = (clear = false) => {
    const newPage = 1;
    setCurrentPage(newPage);
    if (clear) {
      setSearchData({
        contactPerson: "",
        company: "",
        email: "",
        phoneNumber: "",
        businessUnit: "",
      });
      navigate({
        pathname: window.location.pathname,
        search: `?page=${newPage}`,
      });
    } else {
      const queryParams = createQueryParams(searchData);
      if (queryParams) {
        navigate({
          pathname: window.location.pathname,
          search: `?${queryParams}&page=${newPage}`,
        });
      } else {
        CustomNotify({
          type: "warning",
          message: t("Search input not provided."),
        });
      }
    }

    setSubmit((prevSubmit) => !prevSubmit);
  };

  const handleSubmit = (e: any, clear: boolean = false) => {
    e.preventDefault();
    handleSubmitAction(clear);
  };

  const columns = [
    "businessUnit",
    "name",
    "companyName",
    "location",
    "companyEmail",
    "companyPhoneNumber",
  ];

  function handleClick(userData: any): void {
    navigate(`/create/contact-person/?edit=${userData.user_id}`);
  }
  const onChangeWrapper = (selectedOptions: any) => {
    setSearchData((prevState) => ({
      ...prevState,
      ["businessUnit"]: selectedOptions,
    }));
  };
  const handleSendMail = (value: any) => {
    //need to implement as job
  };
  const handlePhoneCall = (value: any) => {
    //need to implement as job
  };
  const handleFallowUp = (value: any) => {
    //need to implement as job
  };

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Contact person",
          read: true,
        },
      ]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <>
        <div className="search-bar">
          <Title title={t("Manage contact person")} />
          <div className="row">
            <div className="col-2 hideLabel">
              <SelectWithSearch
                onChange={onChangeWrapper}
                title={t("Business unit")}
                placeHolder={t("Select bussiness unit")}
                search={true}
                options={filterOptions.businessUnit}
                value={searchData.businessUnit}
                isDisabled={false}
                isMulti={true}
                isMandatory={false}
                error={""}
                className=""
                name={"bussinessUnit"}
              />
            </div>
            <div className="col-2">
              <Search
                handleChange={handleSearchChange}
                placeholder={t("Search by Contact person name")}
                name="contactPerson"
                value={searchData.contactPerson}
              />
            </div>
            <div className="col-2">
              <select
                className="form-select form-control field-shadow"
                name="company"
                id="company"
                onChange={handleSearchChange}
                value={searchData.company}
              >
                <option value="">{t("Select company")}</option>
                {filterOptions.companies?.map((optn: any, key: any) => (
                  <option key={key} value={optn.value}>
                    {optn.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-2">
              <Search
                handleChange={handleSearchChange}
                placeholder={t("Email")}
                name="email"
                value={searchData.email}
              />
            </div>
            <div className="col-2">
              <Search
                handleChange={handleSearchChange}
                placeholder={t("Phone number")}
                name="phoneNumber"
                value={searchData.phoneNumber}
              />
            </div>
            <div className="col-2">
              <div className="d-flex align-items-center justify-content-end">
                <ResetBtn handleResetClick={(e) => handleSubmit(e, true)} />
                <SearchBtn handleSearchClick={handleSubmit} />
              </div>
            </div>
          </div>
        </div>
        <div className="position-relative tableMainWrapper">
          <AccessControl
            requiredPermissions={[
              {
                permission: "Contact person",
                create: true,
              },
            ]}
            override={userData.isSuperAdmin}
          >
            <div className="row">
              <div className="ManageCreateBtn">
                <LinkTo
                  pagelink="/create/contact-person"
                  title={t("Create Contact Person")}
                  icon={faPlus}
                />
              </div>
            </div>
          </AccessControl>
          <div className="table-responsive manage-contact-person tableSection">
            <div className="TableHeight">
              <TableStructure
                isAction
                headers={ContactPersonTableHeader}
                data={contactPerson}
                values={columns}
                handleEdit={editContactPerson}
                handleDelete={deleteContactPerson}
                handleClick={handleClick}
                handleSendMail={handleSendMail}
                handlePhoneCall={handlePhoneCall}
                handleFallowUp={handleFallowUp}
                permissionType={"Contact person"}
                clickStatus={true}
              />
            </div>
            <div
              className="pagination justify-content-center align-items-center"
              style={{ paddingTop: "0.25vw" }}
            >
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
        <div
          className="col-md-6"
          style={{ marginTop: "1vw", marginBottom: "1vw" }}
        >
          <Link
            to="/manage-companies"
            className="text-uppercase back-btn text-decoration-underline"
          >
            {t("Back")}
          </Link>
        </div>
        <ModalPopup
          show={showModal}
          onHide={handleModalClose}
          title={t("Delete confirmation")}
          body={modalBody}
          onCloseButtonClick={handleModalClose}
          onConfirmButtonClick={handleSaveChanges}
          closeTitle={t("No")}
          confirmTitle={"Yes"}
        />
        {popup.isModelOpen && (
          <Popup
            title={t("Company Details")}
            body={
              <FormatData
                data={modalData}
                excludeData={ExcludeContactCompanyData}
              />
            }
            cancel={closeModal}
            modalSize="lg"
            notext="Close"
            cancelButtonClass="shadow-none float-end d-flex justify-content-center"
          />
        )}
      </>
    </AccessControl>
  );
};

export default ManageContactPerson;
