import React, { useEffect, useState } from "react";
import { ApiCall } from "services/ApiServices";
import ManageUsersPage from "./ManageUsersPage";
import { useNavigate } from "react-router-dom";
import CustomNotify from "components/atoms/CustomNotify";
import { GET_USERS } from "routes/ApiEndpoints";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";

export interface UserData {
  user_id?: number;
  email: string;
  status: any;
  userName: any;
  businessUnits: any;
  role: string;
}

interface DataProps {
  page: number;
  nOR: number;
  search?: UserData;
  initialCall?: boolean;
}

const ManageUsers: React.FC = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const statusValue = queryParams.get("status");
  const status: number | string = statusValue !== null ? parseInt(statusValue) : '';
  const [users, setUsers] = useState<UserData[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [submit, setSubmit] = useState(true);


  // Function to clear search parameters on refresh
  function clearSearchParametersOnRefresh() {
    window.addEventListener('beforeunload', () => {
      sessionStorage.removeItem('searchParams');
    });
  }

  // Call the restore function on page load
  window.addEventListener('load', () => {
    clearSearchParametersOnRefresh();
  });

  const storedParams = sessionStorage.getItem('searchParams');
  const previousData = storedParams ? JSON.parse(storedParams) : null;

  const userBusinessUnit = useSelector(selectAuth)?.businessUnit;


  const [search, setSearch] = useState<UserData>({
    userName: previousData?.userName ?? "",
    email: previousData?.email ?? "",
    role: previousData?.role ?? "",
    businessUnits: previousData?.businessUnits ?? userBusinessUnit,
    status: previousData?.status ?? "",
  });

  const [options, setOptions] = useState({
    roles: [],
    businessUnits: [],
    initialCall: true
  });
  const itemsPerPage = 10;

  useEffect(() => {
    const data: DataProps = {
      page: currentPage,
      nOR: itemsPerPage,
      search: search,
      initialCall: options?.initialCall
    };

    fetchUsers(data);
  }, [currentPage, submit]);

  const fetchUsers = async (data: DataProps) => {
    const response = await ApiCall.service(GET_USERS, "POST", data);
    if (response?.status === 200) {
      setUsers(response.data);
      setTotalPages(response.totalPages);
      if (options?.initialCall && response?.options) {
        setOptions((prev) => ({
          ...prev, roles: response?.options?.roles,
          businessUnits: response?.options?.businessUnits,
          initialCall: false
        }))
      }
    }
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleSearch = (name: string, value: string | number) => {
    setSearch((prevSearch) => ({
      ...prevSearch,
      [name]: value,
    }));
  };

  const handleDeleteData = (deleteId: number) => {
    setUsers((prevdata) => prevdata.filter((users) => users.user_id !== deleteId));
    setCurrentPage(1);
  }

  const handleSubmit = (clear: boolean) => {
    if (clear) {
      sessionStorage.setItem('searchParams', JSON.stringify({
        email: '',
        role: '',
        status: '',
        userName: [],           //Setting search data in session storage
        businessUnits: '',
      }));
      setSearch({
        email: '',
        role: '',
        status: '',
        userName: '',
        businessUnits: '',
      });
    }
    else {
      sessionStorage.setItem('searchParams', JSON.stringify(search));
    }

    setSubmit(!submit);
    setCurrentPage(1);
  }

  return (
    <>
      <ManageUsersPage
        usersObj={users}
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
        itemsPerPage={itemsPerPage}
        searchValue={handleSearch}
        deleteFilter={handleDeleteData}
        handleSubmitAction={handleSubmit}
        searchData={search}
        options={options}
      />
    </>
  );
};

export default ManageUsers;
