import React, { useEffect, useState } from "react";
import TitleFieldMolecule from "components/molecules/TitleField";
import { BusinessUnitTableHeader } from "TableHeader";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { t } from "pages/microservices/masterData/translation/Translation";
import { ARCHIVE_BUSINESS_UNIT, COMPANY_LIST } from "routes/ApiEndpoints";
import { ApiCall } from "services/ApiServices";
import CustomNotify from "components/atoms/CustomNotify";
import Button from "components/atoms/Button";
import Search from "components/atoms/Search";
import Pagination from "components/atoms/Pagination";
import ModalPopup from "components/atoms/ModalPopup";
import { BusinessUnit } from "./ManageBusinessUnit";
import DynamicTableStructure from "components/atoms/DynamicTableStructure";
import { ERROR, M_MASTER_DATA } from "constants/Constants";

import {
  PATH_CREATE_BUSINESS_UNIT,
  PATH_EDIT_BUSINESS_UNIT,
  PATH_MASTER_DATA,
} from "constants/Paths";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import { IconData } from "services/IconData";
import Title from "components/atoms/Title";
import LabelField from "components/atoms/LabelField";
import ResetBtn from "components/atoms/ResetBtn";
import SearchBtn from "components/atoms/SearchBtn";
import LinkTo from "components/atoms/LinkTo";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

interface ManageBusinessUnitPageProps {
  businessUnitObj: BusinessUnit[];
  currentPage: number;
  totalPages: number;
  onPageChange: (pageNumber: number) => void;
  itemsPerPage: number;
  searchValue: (name: string, value: string | number) => void;
  deleteFilter: (deleteId: number) => void;
  handleSubmitAction: (clear: boolean) => void;
  searchData: any;
}
interface CompanyItem {
  value: number;
  label: string;
}

const ManageBusinessUnitPage: React.FC<ManageBusinessUnitPageProps> = ({
  businessUnitObj,
  currentPage,
  totalPages,
  onPageChange,
  itemsPerPage,
  searchValue,
  deleteFilter,
  handleSubmitAction,
  searchData,
}) => {
  const [deleteID, setDeleteId] = useState<number | undefined>();
  const [showModal, setShowModal] = useState(false);
  const [companyList, setCompanyList] = useState<CompanyItem[] | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ApiCall.getService(
          COMPANY_LIST,
          "get",
          M_MASTER_DATA
        );
        if (response?.status === 200) {
          setCompanyList(response?.data);
        } else {
          console.error(ERROR);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const navigate = useNavigate();
  const handleSearchChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    const debounceTime = 10;
    clearTimeout((handleSearchChange as any).timeoutId);
    const selectedValue =
      e.target instanceof HTMLSelectElement ? e.target.value : value;
    (handleSearchChange as any).timeoutId = setTimeout(() => {
      searchValue(name, selectedValue);
    }, debounceTime);
  };
  const handleModalClose = () => setShowModal(!showModal);
  const handleSaveChanges = async () => {
    setShowModal(false);
    if (deleteID) {
      const url = `${ARCHIVE_BUSINESS_UNIT}/${deleteID}`;

      const response = await ApiCall.getService(url, "GET", M_MASTER_DATA);
      if (response?.status === 200) {
        deleteFilter(deleteID);
        CustomNotify({ type: "success", message: response.message });
      }
    }
  };

  const handleSubmit = (e: any, clear: boolean = false) => {
    e.preventDefault();
    handleSubmitAction(clear);
  };

  const deleteBusinessUnit = (BusinessUnit: BusinessUnit | any) => {
    setShowModal(true);
    setDeleteId(BusinessUnit.id);
  };

  const handleEdit = (BusinessUnit: BusinessUnit | any) => {
    if (BusinessUnit.id) {
      navigate(`${PATH_EDIT_BUSINESS_UNIT + "/" + BusinessUnit.id}`);
    }
  };

  const userData = useSelector(selectAuth);

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Business unit",
          read: true,
        },
      ]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <>
        <div>
          <div className="search-bar">
            <Title title={t("Manage business unit")} />
            <div className="row">
              <div className="col-3">
                <Search
                  className="form-control"
                  handleChange={handleSearchChange}
                  placeholder={t("Business unit name")}
                  name="business_unit_name"
                  value={searchData.business_unit_name}
                />
              </div>
              <div className="col-3">
                <select
                  className="form-select field-shadow form-control"
                  name="company"
                  id="company"
                  onChange={handleSearchChange}
                  value={searchData?.company}
                >
                  {companyList && companyList.length > 0 ? (
                    <>
                      <option value="">{t("Company")}</option>
                      {companyList.map((option) => (
                        <option key={option.label} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </>
                  ) : (
                    <option value="">{t("No companies available")}</option>
                  )}
                </select>
              </div>
              <div className="col-3">
                <div className="d-flex align-items-center">
                  <ResetBtn handleResetClick={(e) => handleSubmit(e, true)} />
                  <SearchBtn handleSearchClick={handleSubmit} />
                </div>
              </div>
            </div>
          </div>
          <div className="position-relative tableMainWrapper">
            <AccessControl
              requiredPermissions={[
                {
                  permission: "Business unit",
                  create: true,
                },
              ]}
              override={userData.isSuperAdmin}
            >
              <div className="row">
                <div className="ManageCreateBtn">
                  <LinkTo
                    pagelink={PATH_CREATE_BUSINESS_UNIT}
                    title={t("Create business unit")}
                    icon={faPlus}
                  />
                </div>
              </div>
            </AccessControl>
            <div className="table-responsive tableSection manage-businesss-unit">
              <div className="TableHeight">
                <DynamicTableStructure
                  isAction
                  headers={BusinessUnitTableHeader}
                  data={businessUnitObj}
                  handleEdit={handleEdit}
                  handleArchive={deleteBusinessUnit}
                  name={"manage_business_unit"}
                  permission={"Business unit"}
                />
              </div>
              <div
                className="pagination justify-content-center align-items-center"
                style={{ paddingTop: "0.25vw" }}
              >
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={onPageChange}
                />
              </div>
            </div>
          </div>

          <div
            className="col-md-6 align-self-center"
            style={{ padding: "1vw 0" }}
          >
            <Link
              to={PATH_MASTER_DATA}
              className="text-uppercase back-btn text-decoration-underline"
            >
              {t("Back")}
            </Link>
          </div>
        </div>
        <ModalPopup
          show={showModal}
          onHide={handleModalClose}
          title={t("Archive confirmation")}
          body={t("Are you sure want to archive?")}
          onCloseButtonClick={handleModalClose}
          onConfirmButtonClick={handleSaveChanges}
          closeTitle={t("No")}
          confirmTitle={t("Yes")}
        />
      </>
    </AccessControl>
  );
};
export default ManageBusinessUnitPage;
