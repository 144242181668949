import React, { useState, useEffect } from "react";
import TitleFieldMolecule from "components/molecules/TitleField";
import { t } from "pages/microservices/masterData/translation/Translation";
import Button from "components/atoms/Button";
import LoadingIcon from "utils/LoadingIcon";
import { ApiCall } from "services/ApiServices";
import { Link, useNavigate } from "react-router-dom";
import { M_MASTER_DATA } from "../../constants/Constants";
import { FormElementRender } from "utils/multiTabForm/components/formElementRender/Index";
import { getValue } from "utils/multiTabForm/components/common/FormService";
import { fieldValidator } from "utils/multiTabForm/components/validator/Index";
import CloseFile from "static/images/CloseFile";
import CustomNotify from "components/atoms/CustomNotify";
import TitleAtom from "components/atoms/Title";

interface ValidationRules {
  [key: string]: Function[];
}
interface FormBuilderProps {
  entryId?: number | string | null;
  actionType?: string;
  getDataAPI?: any;
  SaveDataAPI?: any;
  formName?: string | null;
  title?: string;
  formType?: string;
  redirect: string;
  validationRules: ValidationRules;
  microserviceName?: string;
  getFormUrl?: string;
}

const ConfigFormBuilder: React.FC<FormBuilderProps> = ({
  entryId,
  actionType,
  getDataAPI,
  SaveDataAPI,
  formName,
  title,
  formType,
  redirect,
  validationRules,
  microserviceName = "m-identitymanager",
  getFormUrl,
}) => {
  const navigate = useNavigate();
  const [state, setState] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData(formName);
  }, [formName]);

  const fetchData = async (formName: any) => {
    try {
      const postData = {
        method: "POST",
        form: formName,
      };
      const response = await ApiCall.service(
        getFormUrl,
        "POST",
        postData,
        true,
        M_MASTER_DATA
      );
      setState(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (entryId) {
      const postData = { id: entryId, elementCategory: formName };
      const fetchDataForEditing = async (postData: any) => {
        try {
          const response = await ApiCall.service(
            getDataAPI,
            "POST",
            postData,
            false,
            microserviceName
          );
          if (response?.status === 200) {
            const data = response.data;
            let formElements = state;
            Object.values(formElements).forEach((value: any, key: any) => {});
            setState((prevState: any) => {
              const updatedState = [...prevState];
              updatedState[0] = {
                ...prevState[0],
                value: data.name,
              };
              return updatedState;
            });
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchDataForEditing(postData);
    }
  }, [entryId]);

  const changeHandler = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    field: any,
    key: any,
    editor: any
  ) => {
    const value = getValue(field.type, event, editor);
    const error = fieldValidator(field, { value })?.[field.name];

    setState((prevState: any) => {
      const updatedState = [...prevState];
      updatedState[key] = {
        ...prevState[key],
        value: value,
        error: error,
      };
      return updatedState;
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    setLoading(true);
    e.preventDefault();
    const filteredFields = state.filter(
      (field: any) =>
        field?.validationRules?.length > 0 && field?.display !== false
    );
    if (validateField(filteredFields)) {
      const data = { id: entryId, elementCategory: formName, ...state };
      let response = await ApiCall.service(
        SaveDataAPI,
        "POST",
        data,
        true,
        microserviceName
      );
      if (response.status === 200) {
        navigate(`${redirect}`, { state: { element: formName } });
        CustomNotify({ type: "success", message: response.message });
      } else if (response.status === 400) {
        CustomNotify({ type: "error", message: response.message });
      }
    }
    setLoading(false);
  };

  const validateField = (filteredFields: any) => {
    let errorResult = true; // to evaluate errors of the fields
    filteredFields.forEach((field: any, key: any) => {
      let validation = fieldValidator(field);
      setState((prevState: any) => {
        const updatedState = [...prevState];
        updatedState[key] = {
          ...prevState[key],
          error: validation[field.name],
        };
        return updatedState;
      });
      if (Object.keys(validation).length > 0) {
        errorResult = errorResult && false;
        return false;
      }
    });
    return errorResult;
  };

  const handleAddAnother = () => {
    const updatedForm = JSON.parse(JSON.stringify([...state]));
    let newForm = updatedForm[0];
    newForm.value = "";
    newForm.error = "";
    state.push(newForm);
    setState([...state]);
  };

  const removeDynamicForm = (key: any) => {
    const updatedForm = [...state];
    updatedForm.splice(key, 1);
    setState(updatedForm);
  };

  return (
    <>
      <div className="search-bar">
        <TitleAtom title={`${title} ${formName}`} />
      </div>
      <div className="form-height">
        <div className="form-border" style={{ paddingBottom: "1vw" }}>
          <div className="row">
            {Object.entries(state).map(([key, value]) => {
              return (
                <React.Fragment key={key}>
                  {key !== "0" && (
                    <div className="table-action-icons">
                      <span
                        title="Delete"
                        onClick={() => removeDynamicForm(key)}
                        className="table-action-btn cursor-pointer float-end"
                      >
                        {actionType != "edit" && <CloseFile />}
                      </span>
                    </div>
                  )}
                  {FormElementRender(
                    value,
                    (
                      e: React.ChangeEvent<
                        HTMLInputElement | HTMLSelectElement
                      >,
                      editor: any
                    ) => changeHandler(e, value, key, editor)
                  )}
                </React.Fragment>
              );
            })}
          </div>
          {!entryId && (
            <div className="row" style={{ paddingTop: "1vw 0" }}>
              <div className="col-md-12">
                <Button
                  title={"+ " + t("Add another")}
                  handleClick={handleAddAnother}
                  className="form-button float-end px-2"
                />
              </div>
            </div>
          )}
        </div>
        <div className="row" style={{ padding: "1vw 0" }}>
          <div className="col-md-4 align-self-center">
            <Link
              to={{ pathname: `${redirect}` }}
              state={{ element: formName }}
              className="text-uppercase back-btn text-decoration-underline"
            >
              {t("Back")}
            </Link>
          </div>
          <div className="col-md-8 text-end">
            {!loading ? (
              <Button
                title={t("Save")}
                type="submit"
                className="form-button float-end px-2"
                handleClick={handleSubmit}
              />
            ) : (
              <LoadingIcon
                iconType="bars"
                color="#00a5ce"
                className="float-end"
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfigFormBuilder;
