import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { ManagePublicHolidays } from "TableHeader";
import Button from "components/atoms/Button";
import CustomNotify from "components/atoms/CustomNotify";
import DynamicTableStructure from "components/atoms/DynamicTableStructure";
import ModalPopup from "components/atoms/ModalPopup";
import Pagination from "components/atoms/Pagination";
import Search from "components/atoms/Search";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import SearchWithLabel from "components/molecules/SearchWithLabel";
import TitleFieldMolecule from "components/molecules/TitleField";
import TableActions from "components/organism/Actions/TableAction";
import { M_MASTER_DATA } from "constants/Constants";
import {
  CREATE_PUBLIC_HOLIDAY,
  EDIT_PUBLIC_HOLIDAY,
  HOLIDAY_CONFIGS,
} from "constants/Paths";
import { t } from "pages/microservices/masterData/translation/Translation";
import { useEffect, useState } from "react";
import DatePicker, { DateObject } from "react-multi-date-picker";
import { Link, useNavigate } from "react-router-dom";
import {
  CLONE_PUBLIC_HOLIDAY,
  DELETE_PUBLIC_HOLIDAY,
  GET_PUBLIC_HOLIDAYS,
} from "routes/ApiEndpoints";
import { ApiCall } from "services/ApiServices";
import { IconData } from "services/IconData";
import TitleAtom from "components/atoms/Title";
import LabelField from "components/atoms/LabelField";
import ResetBtn from "components/atoms/ResetBtn";
import SearchBtn from "components/atoms/SearchBtn";

interface HolidaysData {
  year: any;
  country: any;
  region: any;
  id?: any;
}
interface ManagePublicHolidayData {
  id: any;
  holiday_name: any;
  date: any;
  country: any;
  region: any;
}

interface DataProps {
  page: number;
  search?: any;
}
interface Option {
  label: string;
  value: string | number;
}

const ManageHolidays = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [submit, setSubmit] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [search, setSearch] = useState({
    year: queryParams.get("year") ?? "",
    country: queryParams.get("country") ?? "",
    region: queryParams.get("region") ?? "",
  });
  const [holidaysData, setHolidaysData] = useState<ManagePublicHolidayData[]>(
    []
  );
  const [fromYear, setFromYear] = useState<number | null>(null);
  const [toYear, setToYear] = useState<number | null>(null); //
  const [fromYearError, setFromYearError] = useState<string>("");
  const [toYearError, setToYearError] = useState<string>("");
  const [option, setOptions] = useState<{
    country: Option[];
  }>({
    country: [],
  });

  const [id, setId] = useState<number | undefined>(undefined);
  useEffect(() => {
    const data: DataProps = {
      page: currentPage,
      search: search,
    };
    fetchPublicHolidays(data);
    setSearch((prevState) => ({ ...prevState, ["year"]: "2024" }));
  }, [currentPage, submit]);

  const fetchPublicHolidays = async (data: DataProps) => {
    try {
      const response = await ApiCall.service(
        GET_PUBLIC_HOLIDAYS,
        "POST",
        data,
        false,
        M_MASTER_DATA
      );
      if (response?.status === 200) {
        setHolidaysData(response?.data?.holidayData);
        setOptions({
          country: response?.data?.countryOption || [],
        });
        const totalPages = response?.totalPages;
        setTotalPages(totalPages);
      } else {
        CustomNotify({ type: "warning", message: response.message });
      }
    } catch (error) {
      console.log("Error occured ", error);
    }
  };
  //onChange event handler for select element
  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setSearch((prevState) => ({ ...prevState, [name]: value }));
  };

  //onSubmit event handler
  const handleSubmit = (e: any, clear: boolean = false) => {
    e.preventDefault();
    if (clear) {
      setSearch((prevState) => ({
        ...prevState,
        year: "",
      }));
    }
    handleSubmitAction(clear);
  };

  const handleSubmitAction = (clear = false) => {
    const newPage = 1;
    setCurrentPage(newPage);
    if (clear) {
      setSearch({ year: "", country: "", region: "" });
      navigate({
        pathname: window.location.pathname,
        search: `?page=${newPage}`,
      });
    } else {
      const queryParams = createQueryParams(search);
      if (queryParams) {
        navigate({
          pathname: window.location.pathname,
          search: `?${queryParams}&page=${newPage}`,
        });
      } else {
        CustomNotify({
          type: "warning",
          message: t("Search input not provided."),
        });
      }
    }

    setSubmit((prevSubmit) => !prevSubmit);
  };

  // Function to create query parameters from the search object
  const createQueryParams = (data: HolidaysData) => {
    const queryParams = Object.entries(data)
      .filter(([key, value]) => value !== undefined && value !== "")
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");

    return queryParams;
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  //onClick edit handler
  const handleEdit = (id: any) => {
    navigate(`${EDIT_PUBLIC_HOLIDAY}/${id}`);
  };

  const handleModalClose = () => setShowModal(!showModal);

  const handleDelete = (id: any) => {
    setId(id);
    setShowModal(true);
  };
  const handleSaveChanges = async () => {
    setShowModal(false);
    if (id) {
      const response = await ApiCall.service(
        DELETE_PUBLIC_HOLIDAY,
        "POST",
        { id: id },
        false,
        M_MASTER_DATA
      );

      if (response?.status === 200) {
        CustomNotify({ type: "success", message: response?.message });
        setHolidaysData((prevdata) =>
          (prevdata as ManagePublicHolidayData[]).filter(
            (holidayData) => holidayData.id !== id
          )
        );

        setCurrentPage(1);
      } else {
        CustomNotify({ type: "error", message: response?.message });
      }
    }
  };
  const handleSearchChange = (event: any) => {
    const { name, value } = event.target;
    setSearch((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleDateChange = (date: any, name: any) => {
    const value = date.year;
    setSearch((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleFromYearChange = (e: DateObject | null) => {
    if (e !== null) {
      const year = e.year;
      setFromYear(year);
      setToYear(year + 1);
    } else {
      setFromYear(null);
      setToYear(null);
    }
  };

  const handleToYearChange = (e: DateObject | null) => {
    if (e !== null) {
      const year = e.year;
      setToYear(year);
    } else {
      setToYear(null);
    }
  };

  const handleReset = () => {
    setFromYear(null);
    setToYear(null);
    setFromYearError("");
    setToYearError("");
  };
  const handleClone = async () => {
    let isValid = true;
    if (fromYear === null) {
      setFromYearError("This field is required.");
      isValid = false;
    }
    if (toYear === null) {
      setToYearError("This field is required.");
      isValid = false;
    } else if (fromYear !== null && fromYear === toYear) {
      setToYearError("From and To years cannot be the same.");
      isValid = false;
    }
    if (isValid) {
      setFromYearError("");
      setToYearError("");

      // Create the request data object
      const requestData = {
        from: fromYear,
        to: toYear,
      };
      const response = await ApiCall.service(
        CLONE_PUBLIC_HOLIDAY,
        "POST",
        requestData,
        false,
        M_MASTER_DATA
      );

      if (response?.status === 200) {
        CustomNotify({ type: "success", message: response?.message });
        const data: DataProps = {
          page: currentPage,
          search: search,
        };
        fetchPublicHolidays(data);
      } else {
        CustomNotify({ type: "error", message: response?.message });
      }
    }
  };

  return (
    <>
      <div className="search-bar">
        <TitleAtom title={t("Manage public holidays")} />
        <div className="row" style={{ marginBottom: "1vw" }}>
          <div className="col-3 datePicker">
            <DatePicker
              inputClass="form-control field-shadow"
              name="date"
              placeholder={fromYear !== null ? fromYear.toString() : ""}
              value={fromYear !== null ? new Date(fromYear, 0, 1) : null}
              onChange={(e: any) => handleFromYearChange(e)}
              format="YYYY"
              onlyYearPicker={true}
              minDate={new Date(2014, 0, 1)}
              zIndex={1000}
            />
            {fromYearError && (
              <div className="text-danger">{fromYearError}</div>
            )}
          </div>
          <div className="col-3 datePicker">
            <DatePicker
              inputClass="form-control field-shadow"
              name="date"
              placeholder={toYear !== null ? toYear.toString() : ""}
              value={toYear !== null ? new Date(toYear, 0, 1) : null}
              onChange={(e: any) => handleToYearChange(e)}
              format="YYYY"
              onlyYearPicker={true}
              minDate={
                fromYear !== null ? new Date(fromYear + 1, 0, 1) : new Date()
              }
              zIndex={1000}
            />
            {toYearError && <div className="text-danger">{toYearError}</div>}
          </div>
          <div className="col-3">
            <div className="d-flex align-items-center">
              <ResetBtn handleResetClick={handleReset} />
              <Button
                title={t("Clone holiday")}
                handleClick={handleClone}
                className={"form-button d-flex align-items-center"}
                type={"button"}
                buttonTitle="me-3"
                icon={faCopy}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-3 datePicker">
            <DatePicker
              //  value={search.year ? new Date(search.year, 0, 1) : null}
              inputClass="form-control field-shadow"
              name="date"
              placeholder={new Date().getFullYear().toString()}
              onChange={(e) => handleDateChange(e, "year")}
              format="YYYY"
              onlyYearPicker={true}
              minDate={new Date(2014, 0, 1)}
              zIndex={1000}
              // filterDate={(date: Date) => date.getFullYear() >= minimumYear} // Filter out dates before the minimum year
            />
          </div>
          <div className="col-3">
            <select
              className="form-select field-shadow form-control"
              name="country"
              id="country"
              onChange={handleSelectChange}
              value={search.country}
            >
              {option.country && option.country.length > 0 ? (
                <>
                  <option value="">{t("Select country")}</option>
                  {option.country.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </>
              ) : (
                <option value="">{t("No country available")}</option>
              )}
            </select>
          </div>
          <div className="col-3">
            <Search
              className="form-control"
              handleChange={handleSearchChange}
              placeholder={t("Region")}
              name="region"
              value={search.region}
            />
          </div>
          <div className="col-3 ms-auto">
            <div className="d-flex align-items-center">
              <ResetBtn handleResetClick={(e) => handleSubmit(e, true)} />
              <SearchBtn handleSearchClick={handleSubmit} />
            </div>
          </div>
        </div>
      </div>
      <div className="position-relative tableMainWrapper">
        <div className="row">
          <div className="ManageCreateBtn">
            <Link to={CREATE_PUBLIC_HOLIDAY} className="form-button">
              + {t("Add holiday")}
            </Link>
          </div>
        </div>
        <div className="table-responsive manage-publicholiday-codes tableSection">
          <div className="tableWithSearchBar">
            <table className="table table-hover">
              <thead>
                <tr className="TableHeader">
                  <th style={{ width: "20%" }}>{t("Holiday name")}</th>
                  <th style={{ width: "20%" }}>{t("Date")}</th>
                  <th style={{ width: "20%" }}>{t("Country")}</th>
                  <th style={{ width: "20%" }}>{t("Region")}</th>
                  <th style={{ width: "20%" }}>{t("Actions")}</th>
                </tr>
              </thead>
              <tbody>
                {(holidaysData && holidaysData.length) > 0 ? (
                  holidaysData.map((eachItem, index) => (
                    <tr key={eachItem.id}>
                      <td className="align-middle">{eachItem.holiday_name}</td>
                      <td className="align-middle">{eachItem.date}</td>
                      <td className="align-middle">
                        {eachItem.country && eachItem.country.length > 0
                          ? eachItem.country
                              .map((eachCountry: any) => eachCountry.name)
                              .join(", ")
                          : null}
                        {eachItem.country.name}
                      </td>
                      <td className="align-middle">{eachItem.region}</td>
                      <td className="align-middle">
                        <TableActions
                          isAction={true}
                          handleEdit={handleEdit}
                          handleDelete={handleDelete}
                          value={eachItem.id}
                          permission={"Public holidays"}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={5} className="text-center">
                      {t("No data found")}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div
            className="pagination justify-content-center align-items-center"
            style={{ paddingTop: "0.25vw" }}
          >
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </div>
      <div className="col-md-6 align-self-center" style={{ padding: "1vw 0" }}>
        <Link
          to={HOLIDAY_CONFIGS}
          className="text-uppercase back-btn text-decoration-underline"
        >
          {t("Back")}
        </Link>
      </div>
      <ModalPopup
        show={showModal}
        onHide={handleModalClose}
        title="Delete confirmation"
        body="Are you sure want to delete?"
        onCloseButtonClick={handleModalClose}
        onConfirmButtonClick={handleSaveChanges}
        closeTitle="No"
        confirmTitle="Yes"
      />
    </>
  );
};
export default ManageHolidays;
