import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Button from "components/atoms/Button";
import ModalPopup from "components/atoms/ModalPopup";
import { ApiCall } from "services/ApiServices";
import CustomNotify from "components/atoms/CustomNotify";
import { t } from "pages/microservices/masterData/translation/Translation";
import { PATH_CREATE_FLEET_LOCATION, PATH_CREATE_SUPPLIER, PATH_EDIT_FLEET_LOCATION, PATH_EDIT_SUPPLIER } from "constants/Paths";
import TableActions from "components/organism/Actions/TableAction";
import { useNavigate } from "react-router-dom";
import {

    FLEET_LOCATION_DELETE,
    PROJECTS_OPTIONS,
    SUPPLIER_DELETE,
} from "routes/ApiEndpoints";
import { ERROR, M_MASTER_DATA } from "constants/Constants";
import Pagination from "components/atoms/Pagination";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import FilterElementsRender from "../FilterElement";
import { fetchOptions } from "../utils/FetchOptions";
import { IconData } from "services/IconData";
import Title from "components/atoms/Title";
import LabelField from "components/atoms/LabelField";
import ResetBtn from "components/atoms/ResetBtn";
import SearchBtn from "components/atoms/SearchBtn";
import LinkTo from "components/atoms/LinkTo";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

interface ManageSupplierProps {
    data?: any[];
    handleData: any;
    currentPage: number;
    totalPages: number;
    onPageChange: (pageNumber: number) => void;
    itemsPerPage: number;
    searchValue: (name: string, value: string | number) => void;
    handleSubmitAction: (clear: boolean) => void;
    searchData: any;
    handleGetFleetLocation: any;
}

const ManageFleetLocationPage: React.FC<ManageSupplierProps> = ({
    data,
    handleData,
    currentPage,
    totalPages,
    onPageChange,
    itemsPerPage,
    searchValue,
    handleSubmitAction,
    searchData,
    handleGetFleetLocation,
}) => {
    const [id, setId] = useState<number | undefined>(undefined);
    const [showModal, setShowModal] = useState(false);
    const [filterOptions, setFilterOptions] = useState({
        countries: [],
    });

    const navigate = useNavigate();

    const handleSearchChange = (event: any, name: string, type: string) => {
        switch (type) {
            case "text":
            case "date":
                return searchValue(name, event.target.value);

            case "select":
                return searchValue(name, event.value);
        }
    };

    const handleSaveChanges = async () => {
        setShowModal(false);
        let postData = {
            id: id,
        };

        if (id) {
            const response = await ApiCall.service(
                FLEET_LOCATION_DELETE,
                "POST",
                postData,
                false,
                M_MASTER_DATA
            );

            if (response?.status === 200) {
                CustomNotify({ type: "success", message: response.message });
                handleGetFleetLocation({
                    page: 1,
                    nOR: 10,
                    search: {
                        location: "",
                        city: "",
                        country: "",
                    },
                });
            } else {
                CustomNotify({ type: "error", message: response.message });
            }
        }
    };

    useEffect(() => {
        getFilterData();
    }, []);

    const getFilterData = async () => {
        try {
            const optionsResponse = await ApiCall.service(
                PROJECTS_OPTIONS,
                'POST',
                { options: ["Country"] },
                false,
                M_MASTER_DATA,
            );
            if (optionsResponse?.status == 200) {
                setFilterOptions((prevOptions) => ({ ...prevOptions, countries: optionsResponse?.data?.Country }))
            }
            else {
                CustomNotify({ type: "warning", message: optionsResponse.message })
            }
        }
        catch (error) {
            console.log("Error");
        }
    };

    const handleModalClose = () => setShowModal(!showModal);

    const handleSubmit = (e: any, clear: boolean = false) => {
        e.preventDefault();
        handleSubmitAction(clear);
    };

    const handleEdit = (id: number) => {
        navigate(`${PATH_EDIT_FLEET_LOCATION}/${id}`);
    };

    const handleArchive = (id: number) => {
        setShowModal(true);
        setId(id);
    };
    const userData = useSelector(selectAuth);

    return (
        <AccessControl
            requiredPermissions={[
                {
                    permission: "FleetLocation",
                    read: true,
                },
            ]}
            renderNoAccess={true}
            override={userData.isSuperAdmin}
        >
            <>
                <div className="search-bar">
                    <Title title={t("Manage fleet location")} />
                    <div className="row">
                        <div className="col-10">
                            <div className="row">
                                <FilterElementsRender
                                    data={searchData?.location}
                                    type="text"
                                    handleChange={(event) =>
                                        handleSearchChange(event, "location", "text")
                                    }
                                    value={searchData?.location}
                                    placeHolder={"Location"}
                                    name="location"
                                    searchclass="col-lg-3"
                                />

                                <FilterElementsRender
                                    data={searchData?.city}
                                    type="text"
                                    handleChange={(event) =>
                                        handleSearchChange(event, "city", "text")
                                    }
                                    value={searchData?.city}
                                    placeHolder={"City"}
                                    name="city"
                                    searchclass="col-lg-3"
                                />
                                <FilterElementsRender
                                    data={filterOptions?.countries}
                                    type="multi-select"
                                    handleChange={(event) =>
                                        handleSearchChange(event, "country", "select")
                                    }
                                    value={searchData?.country}
                                    placeHolder={"Country"}
                                    name="country"
                                    searchclass="col-lg-3"
                                />
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-2">
                            <div className="d-flex align-items-center justify-content-end">
                                <ResetBtn handleResetClick={(e) => handleSubmit(e, true)} />
                                <SearchBtn handleSearchClick={handleSubmit} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="position-relative tableMainWrapper">
                    <div>
                        <AccessControl
                            requiredPermissions={[
                                {
                                    permission: "FleetLocation",
                                    create: true,
                                },
                            ]}
                            override={userData.isSuperAdmin}
                        >
                            <div className="row">
                                <div className="ManageCreateBtn">
                                    <LinkTo
                                        pagelink={PATH_CREATE_FLEET_LOCATION}
                                        title={t("Create fleet location")}
                                        icon={faPlus}
                                    />
                                </div>
                            </div>
                        </AccessControl>
                    </div>
                    <div className="table-responsive manage-projects tableSection">
                        <div className="manageSupplierHeight">
                            <table className="table table-hover">
                                <thead>
                                    <tr className="TableHeader">
                                        <th style={{ width: "10%" }}>{t("Location")}</th>
                                        <th style={{ width: "20%" }}>{t("City")}</th>
                                        <th style={{ width: "20%" }}>{t("Country")}</th>
                                        <th style={{ width: "20%" }}>
                                            <AccessControl
                                                requiredPermissions={[
                                                    {
                                                        permission: "FleetLocations",
                                                        update: true,
                                                        delete: true,
                                                    },
                                                ]}
                                                actions={true}
                                                strict={false}
                                                override={userData.isSuperAdmin}
                                            >
                                                {t("Action")}
                                            </AccessControl>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data && data.length > 0 ? (
                                        data.map((record, key) => {
                                            return (
                                                <tr key={key}>
                                                    <td className="align-middle">{record.locationName}</td>
                                                    <td className="align-middle">{record.city}</td>
                                                    <td className="align-middle">{record?.country?.label}</td>
                                                    <td className="align-middle">
                                                        <TableActions
                                                            isAction={true}
                                                            handleEdit={handleEdit}
                                                            handleDelete={handleArchive}
                                                            value={record.id}
                                                            permission={"FleetLocations"}
                                                        />
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan={5} className="text-center">
                                                {t("No records found")}
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <div
                            className="pagination justify-content-center align-items-center"
                            style={{ paddingTop: "0.25vw" }}
                        >
                            <Pagination
                                currentPage={currentPage}
                                totalPages={totalPages}
                                onPageChange={onPageChange}
                            />
                        </div>
                    </div>
                </div>
                <ModalPopup
                    show={showModal}
                    onHide={handleModalClose}
                    title={t("Delete confirmation")}
                    body={t("Are you sure want to delete?")}
                    onCloseButtonClick={handleModalClose}
                    onConfirmButtonClick={handleSaveChanges}
                    closeTitle="No"
                    confirmTitle="Yes"
                />
            </>
        </AccessControl>
    );
};
export default ManageFleetLocationPage

