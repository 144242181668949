export const SERVICE_URL = process.env.REACT_APP_SERVICE_REGISTRY;
export const getRefreshToken = SERVICE_URL + "get-refresh-token";
export const forgotPassword = "forgot-password";
export const FILE_UPLOAD = "api/file/upload";
export const FILE_DELETE = "api/delete/file";
export const FILE_DOWNLOAD = "api/download/file";

// Identity Manager
export const GET_ROLES = "getRoles";
export const REGISTER = "registration";
export const GET_USER = "getUser";
export const GET_USERS = "getUsers";
export const UPDATE_USER = "updateUser";
export const FORGOT_PASSWORD = "forgot-password";
export const LOGIN = "login";
export const RESET_PASSWORD = "reset-password";
export const CANDIDATE_STATUS = "updateCandidateStatus";
export const GET_PERMISSION = "getPermission";
export const UPDATE_PERMISSION = "updatePermission";
export const STORE_PERMISSION = "storePermission";
export const GET_PERMISSIONS = "getPermissions";
export const DELETE_PERMISSION = "deletePermission";

export const EDIT_ROLE = "editRole";
export const CLASS_BY_PERMISSIONS = "classificationsByPermissions";
export const CREATE_ROLE = "createRole";
export const DELETE_ROLE = "deleteRole";
export const DELETE_USER = "deleteUser";

//My Profile
export const GET_PROFILE = "getProfile";
export const SAVE_PROFILE = "saveProfile";

// Central Data Management
export const MODULES = "getModules";
export const STORE_TEMPLATE = "storeTemplate";
export const GET_TEMPLATE = "getTemplate";
export const UPDATE_TEMPLATE = "updateTemplate";
export const GET_TEMPLATES = "getTemplates";
export const GET_APP_LANG = "getAppLang";

export const FETCH_TRANSLATIONS = "fetchTranslations";
export const SAVE_TRANSLATION = "saveTranslation";

//Business unit
export const CREATE_BUSINESS_UNIT = "createBusinessUnit";
export const UPDATE_BUSINESS_UNIT = "createBusinessUnit";
export const GET_BUSINESS_UNIT = "getBusinessUnit";
export const ARCHIVE_BUSINESS_UNIT = "archiveBusinessUnit";
export const COMPANY_LIST = "companyOptions";

export const CREATE_EMPLOYEE_TYPE = "createEmployeeType";
export const GET_EMPLOYEE_TYPE = "getEmployeeType";
export const ARCHIVE_EMPLOYEE_TYPE = "archiveEmployeeType";
export const GET_PARTICULAR_EMPLOYEE_TYPE = "getParticularEmployeeType";

export const CREATE_SHIFT = "createShift";
export const UPDATE_SHIFT = "createShift";
export const GET_SHIFT = "getShift";
export const ARCHIVE_SHIFT = "archiveShift";

export const GET_TEMPLATE_DATA = "getTemplateData";
export const GET_PARTICULAR_TEMPLATE_DETAILS = "getParticularTemplate";
export const TEMPLATE_CREATE = "createTemplate";

//Project parameter getProjectParameters
export const PROJECT_PARAMETERS = "getProjectParameters";

//Tag-creation
export const STORE_TAGS = "storeTags";
export const GET_TAGS = "getTags";
export const ARCHIVE_TAG = "archiveTag";
export const EDIT_TAG = "updateTag";

export const PROPOSAL_AGREEMENT = "proposalAgreement";
export const SAVE_SIGNATURE = "saveSignature";
export const GET_SIGNATURE = "getSignature";
export const GET_ADMIN_SIGNATURE = "getAdminSignature";

export const EMPLOYER_AUTHONTICATION = "employerAuthenticate";
export const APPROVE_BY_MANAGER = "approveByManager";

// Candidate-creation
export const GENERAL_DETAILS = "getGeneralDetails";
export const OTHER_DETAILS = "getOtherTabDetails";
export const JOBPROFILE_DETAILS = "getJobProfileDetails";
export const CANDIDATE_COMPETENCE = "getCandidateCompetence";
export const DOCUMENT_DETAILS = "getDocumentDetails";
export const REFERENCE_DETAILS = "getReferenceDetails";
export const STUDY_DETAILS = "getStudyDetails";
export const WORK_DETAILS = "getWorkDetails";
export const RESGISTER_CANDIDATE = "registerCandidate";
export const CANDIDATES = "getCandidates";
export const ARCHIVE_CANDIDATE = "archiveCandidate";
export const DELETE_USER_CC = "deleteUser";
export const REGISTER_CC = "registration";
export const UPDATE_USER_CC = "updateUser";
export const UNARCHIVE_USER = "unArchiveUser";

//Company-creation
export const COMPANY_VAT_VALIDATION = "get-company";
export const COMPANY_VAT_EXISTENCE = "companyVatExistence";
export const REGISTER_COMPANY = "registerCompany";
export const COMPANY_DETAILS = "getCompanyDetails";
export const USERS_TO_IDM = "userToIdm";
export const MANAGE_COMPANY = "manageCompany";
export const ARCHIVE_COMPANY = "archiveCompany";
export const LOCATION_DETAILS = "getLocationDetails";
export const ADD_LOCATION = "addLocation";
export const MANAGE_LOCATION = "manageLocation";
export const ARCHIVE_LOCATION = "archiveLocation";

export const ADD_CHECKLIST = "addChecklist";
export const GET_CHECKLIST = "getChecklistDetails";
export const MANAGE_CHECKLIST = "manageChecklist";
export const CREATE_CONTACT_PERSON = "CreateContactPerson";
export const CONTACT_PERSON_DATA = "getContactPersonData";
export const EDIT_CONTACT_PERSON = "getContactPerson";
export const GET_COMPANY = "getCompany";
export const DELETE_CONTACT_PERSON = "deleteContactPerson";
export const FETCH_LOCATION_BY_COMP_ID = "getCompanyLocation";
export const ADD_COMPANY_TO_HOTLIST="addCompanyToHotlist";
export const REMOVE_COMPANY_FROM_HOTLIST='removeCompanyFromHotlist'

// configuration and settings
export const FETCH_FUNCTION_DETAILS = "fetchFunctionDetails";
export const STORE_FUNCTIONS_TO_COUNTRY = "storeFunctions";
export const MANAGE_FUNCTIONS = "manageFunctions";
export const GET_WORK_TYPE_COEFFICIENT = "getWorkTypeCoefficient";
export const STORE_WORK_TYPE_COEFFICIENT = "storeWorkTypeCoefficient";
export const GET_NESTED_LOW_COFFICIENTS = "getNestedLowCoefficients";
export const MANAGE_LOW_COEFFICIENTS = "manageLowCoefficients";
export const UPDATE_LOW_COEFFICIENTS = "updateLowCoefficients";
export const STORE_CITY = "storeCity";
export const UPDATE_CITY = "updateCity";
export const GET_CITY_DETAILS = "getCityDetails";
export const GET_CITIES_DETAILS = "getCitiesDetails";
export const GET_REGION_FOR_COUNTRIES = "getRegionForCountryDetails";
export const GET_COUNTRIES_FOR_REGION = "getCountryForRegionDetails";
export const GET_NESTED_INFLATIONS = "getNestedInflations";

// vacancies
export const VACANCY_CREATE = "vacancyCreate";
export const GET_VACANCY = "vacancy";
export const VACANCIES = "vacancies";
export const VACANCY_EDIT = "editVacancy";
export const VACANCY_DELETE = "deleteVacancy";
export const GET_VDAB_COMPETENCES = "getVdabCompetences";
//

export const STORE_EMPLOYMENT = "storeEmployment";
export const STORE_COMPANY = "storeCompany";
export const EMPLOYMENT_DETAILS = "getEmploymentDetails";
export const REQUIREMENT_DETAILS = "getRequirementDetails";

export const SAVE_INFLATIONS = "saveInflations";
export const GET_INFLATIONS = "getInflations";
export const GET_SSN = "getSSN";

// Projects
export const PROJECTS_OPTIONS = "projectOptions";
export const PROJECT_CREATE = "createProject";
export const PROJECTS = "projects";
export const PROJECT = "project";
export const PROJECT_ARCHIVE = "projectArchive";
export const PROJECT_UNARCHIVE = "projectUnarchive";
export const COMPANY_DATA = "getCompanyDetailsById";
export const DOWNLOAD_PROJECT_PDF = "downloadProjectProposalPdf";
export const SIGNATURE = "signature";
export const GENERATE_PROJECT_PROPOSAL = "genrateProjectProposal";
export const APPROVE_PROJECT_PROPOSAL = "approveProjectProposal";
export const SEND_PROJECT_PROPOSAL = "sendProjectProtect";
export const SEND_PROJECT_PROPOSAL_TO_MANAGER = "sendProjectProprosalToManager";
export const CREATE_CONTACT_PERSONS_FROM_PROJECT = "createContactPersonFromProject";
export const CREATE_LOCATION_FROM_PROJECT="createLocationFromProject";

//Form data
export const GET_FORM_DATA = "getForm";
export const GET_MULTI_TAB_FORM = "multiTabForm";

export const EMPLOYEES_CREATE = "employeeCreate";
export const EMPLOYEES_EDIT = "employeeEdit";
export const GET_EMPLOYEE = "employee";
export const GET_ALL_EMPLOYEE = "getAllemployees";
export const EMPLOYEE_ARCHIVE = "employeeArchive";
export const EMPLOYEE_UNARCHIVE = "employeeUnarchive";
export const FETCH_FOLLOWUP_INFO = "employeeFollowup";

//PC data
export const PC_CREATE = "pcCreate";
export const PC_GET = "partairComitee";
export const PC_EDIT = "pcEdit";
export const PC_ARCHIVE = "archivePc";
export const GET_PC_CATEGORIES = "getPcCategories";

// master data config elements list
export const GET_MASTER_DATA_CONFIG_ELEMENT_LIST = "configElements";
export const MASTER_DATA_CONFIG_FORM_ELEMENT = "configFormElements";
export const MASTER_DATA_CONFIG_ELEMENT_DATA = "configElementsData";
export const MASTER_DATA_CONFIG_ELEMENT_CREATE = "configElementsCreate";
export const MASTER_DATA_CONFIG_ELEMENT_BY_ID = "configElementsById";
export const MASTER_DATA_CONFIG_ELEMENT_ARCHIVE = "configElementsArchive";
export const MASTER_DATA_CONFIG_ELEMENT_UNARCHIVE = "configElementsUnArchive";

//masterdata competence
export const COMPETENCE_CREATE = "createCompetence";
export const COMPETENCE_ARCHIVE = "archiveCompetence";
export const COMPETNCE_GET = "getCompetences";
export const COMPETNCE_EDIT = "editCompetence";
export const GET_COMPETENCE_OPTIONS = "getCompetenceOptions";
export const GET_COMPETENCE_TYPE = "getCompetenceType";
export const GET_CATOGRY_BY_ID = "getPcCatogoryById";
export const GET_FUNCTION_BY_CATEGORY_ID = 'getFunctionByCategoryId';
//Time table
export const STORE_TIMETABLE = "timeTableCreate";
export const GET_TIMETABLES = "getTimetables";
export const GET_SHIFT_OPTIONS = "getShiftOptions";
export const GET_SHIFT_DETAILS = "getShiftsDetails";
export const ARCHIVE_TIMETABLE = "archiveTimetable";

//Application configuration
export const GET_ENTITY = "getEntities";
export const GET_CONFIG_DATA = "getConfigData";
export const SAVE_CONFIG_DATA = "saveConfigData";

//Matching
export const GET_LOCATION_FUNCTION_OPTION = "getLocationAndFunctionsOption";
export const GET_COMPANY_PROJECT_OPTION = "getCompanyAndProjectsOption";
export const GET_MATECHED_EMPLOYEES = "getMatchedEmployees";
export const GET_EMPLOYEES = "getEmployees";
export const REMOVE_FROM_PROJECT = "removeFromProject";
export const FETCH_ALL_EMPLOYEES = "fetchAllEmployees";
export const FETCH_ACTIVE_PROJECT = "fetchActiveProject";
export const GET_EMPLOYEE_DETAILS_BY_ID = "getEmployeeDetailsById";

//planning
export const CREATE_PLANNING = "createPlanning";
export const FETCH_PLANNING = "fetchAllPlanning";
export const FETCH_EACH_PLANNING = "fetchEachPlan";
export const GET_FILTERED_PROJECT = "getFilteredProjects";
export const GET_EMPLOYEE_PROJECT_OPTION = "getEmployeeAndProjectsOption";
export const GET_PLANNING_FILTER_DATA = "getPlanningFilterData";
export const GET_PROJECT_SHIFT_DETAILS = "getProjectShiftDetails";
export const FETCH_SALES = "fetchSales";

//Hotlist
export const GET_HOTLIST_DATA = "getHotlistData";
export const SAVE_HOTLIST_DATA = "saveHotlistData";
export const DELETE_HOTLIST_DATA = "deleteHotlistData";

//documents
export const GET_DOCUMENTS = "getDocuments";

//Manage questions
export const GET_QUESTION_DETAILS = "getQuestionDetails";
export const STORE_QUESTIONS = "storeQuestions";
export const UPDATE_QUESTIONS = "updateQuestions";
export const MANAGE_QUESTIONS = "manageQuestions";
export const DELETE_QUESTIONS = "deleteQuestions";
export const GET_FUNCTION_FOR_PC = "getFunctionForPc";
export const GET_FUNCTION_FOR_CATEGORY = "getFunctionForCategory";

//Screening
export const GET_SCREENING_DETAILS = "getScreeningDetails";
export const GET_QUESTIONS_BY_FUNCTIONS = "getQuestionsByFunctions";
export const STORE_SCREENING_INFO = "storeScreeningInfo";
export const GET_SCREENING_ASSESSMENT = "getScreeningAssessment";
export const MANAGE_SCREENING = "manageScreening";
export const CANDIDATE_RESULT = "candidateResultUpdate";

//Get permissions of user
export const GET_USER_PERMISSIONS = "getUserPermissions";

export const ADD_TO_PROJECT = "addToProject";
export const GET_FILTERED_EMPLOYEE = "getEmployeeData";
export const GET_FILTERED_PROJECTS = "getProjectData";

//employee wage proposal
export const SEND_WAGE_PROPOSAL_MAIL = "sendWageProposalMail"; //post
export const APPROVE_WAGE_PROPOSAL = "ApproveWageProposal"; //post
export const CONTRACT_OPTIONS = "contractOptions";
export const CREATE_CONTRACT = "createContract";
export const DELETE_CONTRACT = "deleteContract";
export const GET_EMPLOYEE_WAGE = "getEmployeeWage";
export const CONTRACT_DATA = "contractOverview";
export const GET_CONTRACT_DATA = "getContractData";
export const SEND_CONTRACT_MAIL = "sendContractMail";
export const GENERATE_WAGE_PROPOSAL = "generateWageProposal";
export const DOWNLOAD_WAGE_PROPOSAL_PDF = "downloadWageProposal";
export const APPROVE_CONTRACT = "approveContract"
export const DOWNLOAD_CONTRACT_PDF = "downloadContract"

//Masterdata salary benefits
export const CREATE_SALARY_BENEFITS = "salaryBenefitsStore";
export const GET_SALARY_BENEFITS = "salaryBenefits";
export const ARCHIVE_SALARY_BENEFITS = "salaryBenefitsArchive";
//Holiday codes
export const SAVE_HOLIDAY_CODES = "saveHolidayCodes";
export const EDIT_HOLIDAY_CODES = "editHolidayCodes";
export const GET_OPTIONS_FOR_HOLIDAY_CODES = "getHolidayCodeOptions";
export const GET_HOLIDAY_CODES_DATA = "getHolidayCodes";
export const DELETE_HOLIDAY_CODE = "deleteHolidayCode";

//Create holiday
export const SAVE_HOLIDAY = "savePublicHoliday";
export const EDIT_HOLIDAY = "editHoliday";
export const GET_OPTIONS_FOR_HOLIDAY = "getHolidayOptions";

//Manage public holidays
export const GET_PUBLIC_HOLIDAYS = "getAllPublicHolidays";
export const DELETE_PUBLIC_HOLIDAY = "deletePublicHoliday";
export const CLONE_PUBLIC_HOLIDAY = "clonePublicHoliday";
//Manage holiday per pc
export const GET_PUBLIC_HOLIDAYS_AND_PC = "getHolidayAndPc";
export const GET_HOLIDAYS_OF_PARTICULAR_PC = "getHolidayPerPC";
export const SAVE_HOLIDAYS_OF_PARTICULAR_PC = "saveHolidayPerPC";
export const SAVE_HOLIDAY_PER_PC = "saveHolidayPerPc";
export const GET_HOLIDAYS_AND_PC = "getPcHoliday";
export const EDIT_HOLIDAY_PER_PC = "editHolidayPerPc";
export const DELETE_HOLIDAY_PER_PC = "deleteHolidayPerPC";

//Todo
export const GET_OPTIONS = "getOptions";
export const GET_ENTITY_DETAILS = "getEntityRelatedTodos";
export const CREATE_TODO = "todoCreate";
export const GET_TODO = "todo";
export const DELETE_TODO = "todoDelete";
export const USER_LIST = "getUsers";
export const EDIT_TODO = "editTodo";
export const MANAGE_TODO_DATA = "manageTodoData";

//Contracts
export const GET_DROPDOWN_DATA = "getContractOptions";
export const ADD_CONTRACT_TEMPLATE = "addContractTemplate";
export const GET_CONTRACT_TEMPLATES_DATA = "getContractTemplateData";
export const DELETE_CONTRACT_TEMPLATE = "deleteContractTemplate";
export const GET_TEMPLATE_BY_ID = "getTemplateById";
//Leave count of employee
export const GET_EMPLOYEE_LEAVE_COUNT = "getEmployeeLeaveCount";
export const GET_SPECIFIC_LEAVE_DETAILS = "getSpecificLeaveDetails";

//leave
export const GET_HOLIDAY_CODE_AND_HR = "getHolidayCodeAndHr";
export const SAVE_APPLY_FOR_LEAVE = "saveApplyForLeave";
export const GET_APPLIED_LEAVE_DATA = "getAppliedLeaveData";
export const EDIT_APPLIED_LEAVE = "editAppliedLeave";
export const DELETE_APPLIED_LEAVE = "deleteAppliedLeave";
export const GET_OPTIONS_FOR_EMPLOYER_LEAVE_COUNT = "getEmployerLeaveOptions";
export const GET_OPTIONS_FOR_EMPLOYER_MANAGE = "getEmployerManageOptions";
export const GET_ALL_EMPLOYEES_LEAVE_DATA = "getEmployeesLeaveData";
export const GET_ALL_EMPLOYEES_LEAVE_COUNT = "getAllEmployeesLeaveCount";
export const LEAVE_ACTION = "leaveAction";
export const GET_BUSINESS_UNIT_AND_EMPLOYEE = "getBussinessUnitAndEmployee";
export const GET_EMPLOYEE_LEAVE_BALANCE = "getEmployeeLeaveBalance";
export const APPROVE_OR_REJECT_LEAVE = "approveOrRejectLeave";

//Candidate
export const GET_COUNTS_FOR_CANDIDATES = "getCountsForCandidate";

// fleet and material
export const MATERIAL_CREATE = "createMaterial";
export const MATERIAL_EDIT = "editMaterial";
export const MATERIAL_DELETE = "deleteMaterial";
export const MANAGE_MATERIALS = "manageMaterials";
export const FLEET_AND_MATERIAL_FILTER_OPTIONS = "fleetAndMaterialFilterOptions";
export const SAVE_EMP_FLEET = "saveEmpFleet";

// car
export const CAR_CREATE = "createCar";
export const CAR_EDIT = "editCar";
export const CAR_DELETE = "deleteCar";
export const CARS = "manageCars";
export const USERS = "getUsersForCar";

// clothes 

export const CLOTHES_CREATE = "createClothes"
export const CLOTHES_EDIT = "editClothes";
export const CLOTHES_DELETE = "deleteClothes";
export const CLOTHES = "cars";


// supplier

export const SUPPLIER_CREATE = "createSupplier";
export const SUPPLIER_EDIT = "editSupplier";
export const SUPPLIER_DELETE = "deleteSupplier";
export const SUPPLIERS = "manageSuppliers";

// client

export const CLIENT_CREATE = "createClient";
export const CLIENT_EDIT = "editClient";
export const CLIENT_DELETE = "deleteClient";
export const CLIENTS = "manageClients";

// purchase order 

export const PURCHASE_ORDER_CREATE = "createPurchaseOrder"
export const PURCHASE_ORDER_EDIT = "editPurchaseOrder";
export const PURCHASE_ORDER_DELETE = "deletePurchaseOrder";
export const PURCHASE_ORDERS = "purchaseOrders";

// fleet locations

export const FLEET_LOCATION_CREATE = "createFleetLocation";
export const FLEET_LOCATION_EDIT = "editFleetLocation";
export const FLEET_LOCATION_DELETE = "deleteFleetLocation";

export const FLEET_EMP_CREATE = "createFleetEmployee";
export const FLEET_EMP_DELETE = "deleteFleetEmployee";

// Timesheests
export const CREATE_TIMESHEET = "createTimesheet"
export const GET_TIMEHSHEETS = "getTimesheets"

//Dashboard followups
export const GET_FOLLOWUP_DETAILS = "getFollowupDetails";
export const CLOSE_FOLLOWUP = "followUpClose";
//Pay roll
export const GET_PAYROLL = 'getPayRoll';

//temp agency
export const CREATE_TEMP_AGENCY = "createTempAgency";
export const GET_TEMP_AGENCY = "getTempAgency";
export const GET_TEMP_AGENCY_BY_ID = "getTempAgencyById";
export const DELETE_TEMP_AGENCY = "deleteTempAgency";
export const GENERATE_TEMP_AGENCY_PROPOSAL = "generateTempAgencyProposal";
export const SEND_TEMP_AGENCY_PROPOSAL = "sendTempAgencyProposalMail";
export const APPROVE_TEMP_AGENCY_PROPOSAL = "approveTempAgencyProposal";
export const DOWNLOAD_TEMP_AGENCY_PDF = "downloadTempAgencyPdf";
export const CREATE_CONTACT_PERSONS_FROM_TEMP_AGENCY = "createContactPesonTempAgency";

export const COEFFICIENT_EDIT = "coefficientEdit";
export const CREATE_UPDATE_COEFFICIENT = "createOrUpdateCoefficent";
export const GET_COEFFICIENT_DEVIATION = "getCoefficientDeviation";
export const GET_WAGE_ELEMENTS = "getWageElement";
export const SAVE_WAGE_ELEMENT = "saveWageElement";

export const FETCH_WAGE_ELEMENT = "fetchWageElement";
export const FETCH_COMPANY_BU = "fetchCompanyBusinessUnit"

export const GET_PROJECT_OPTIONS_FOR_MARGIN = "fetchProjectsData";
export const CALCULATE_MARGIN = "calculateMargin";

export const GET_ALL_USERS = "getAllUsers";
export const SEND_ACTIVATION_MAIL = "sendActivationMail";