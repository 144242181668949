import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  CREATE_TODO,
  EDIT_TODO,
  GET_ENTITY_DETAILS,
  GET_OPTIONS,
  GET_TODO,
} from "routes/ApiEndpoints";
import { M_MASTER_DATA } from "constants/Constants";
import { PATH_TODO_MANAGE } from "constants/Paths";
import { t } from "../translation/Translation";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import { OptionProps, ValidationRules } from "utils/TypeAnnotations";
import {
  validateForm,
  validateMultiSelectField,
  validateRequired,
  validateSelectField,
} from "services/validation/ValidationService";
import { ApiCall } from "services/ApiServices";
import TitleFieldMolecule from "components/molecules/TitleField";
import Button from "components/atoms/Button";
import CustomNotify from "components/atoms/CustomNotify";
import CheckBoxField from "components/atoms/CheckBoxField";
import Followup from "./Followup";
import Todo from "./Todo";
import GetQueryParams from "services/util/GetQueryParams";
import { mapToSelect } from "utils/MapToSelect";

interface TodoForm {
  id?: number | string;
}
export interface TodoData {
  entityType: OptionProps;
  assignedTo: OptionProps;
  bussinessUnit: OptionProps;
  todoStatus: OptionProps;
  entityRelatedTodoType: OptionProps;
  entityRelatedEntityName: OptionProps;
}

export interface commonData {
  entity_type: string;
  entity_id: number | null;
  entity_name: string;
  assigned_to: number | null;
  bussiness_unit: object[];
  todo_status: string;
  todo_type_id: string;
  info: string;
  date: Date | null;
  id: string;
}

export interface FollowupData {
  // entityType: null,
  // entityId: null,
  // todoTypeId: null,
  // assignedTo: null,
  followedBy: number | null;
  followupDate: Date | null;
  feedback: string;
  status: boolean;
}

const CreateTodo: React.FC<TodoForm> = () => {
  const location = useLocation();
  const path = location.pathname;
  const { id: routeId } = useParams<{ id: string }>();
  const permissionType = routeId ? "update" : "create";
  const permissionObject: any = {
    permission: "Todo",
  };
  permissionObject[permissionType] = true;
  const userData = useSelector(selectAuth);
  const initialCommonErrors: { [key: string]: string } = {};
  const navigate = useNavigate();
  const [commonErrors, setCommonErrors] = useState<{ [key: string]: string }>(
    initialCommonErrors
  );
  const [hasChild, setHasChild] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const [dropdownOptions, setDropdownOptions] = useState({
    entityType: [] as OptionProps,
    assignedTo: [] as OptionProps,
    bussinessUnit: [] as OptionProps,
    todoStatus: [] as OptionProps,
    entityRelatedTodoType: [] as OptionProps,
    entityRelatedEntityName: [] as OptionProps,
  });
  const [commonData, setCommonData] = useState<commonData>({
    entity_type: "",
    entity_id: null,
    entity_name: "",
    assigned_to: userData.userId,
    bussiness_unit: [],
    todo_status: "",
    todo_type_id: "",
    info: "",
    date: new Date(),
    id: "",
  });
  const [followupData, setFollowupData] = useState<FollowupData>({
    // entityType: null,
    // entityId: null,
    // todoTypeId: null,
    followedBy: userData.userId,
    followupDate: new Date(),
    feedback: "",
    status: false,
  });
  const queryParams = GetQueryParams();
  const destinationUrl = queryParams.get("destination");
  const urlEntityType = queryParams.get("type");
  const entityId = queryParams.get("entityId");

  const urlBusinessUnit = queryParams.getAll("businessUnit").map(Number);
  useEffect(() => {
    const postData = {
      selectedBusinessUnits: urlBusinessUnit ? urlBusinessUnit : null,
    };
    const url = routeId ? `${GET_TODO}/${routeId}` : GET_TODO;
    routeId && setIsEdit(true);
    ApiCall.service(url, "POST", postData, true, M_MASTER_DATA).then(
      (response) => {
        if (response?.status === 200) {
          const dropDownOptions = response.data.dropdownOptions;
          setDropdownOptions((prevData: any) => ({
            ...prevData,
            entityType: dropDownOptions?.entityType,
            // assignedTo: dropDownOptions?.assignedTo,
            bussinessUnit: dropDownOptions?.bussinessUnit,
            todoStatus: dropDownOptions?.todoStatus,
          }));
          let filteredAssignedToData: any = [];
          if (destinationUrl?.includes("employees")) {
            //assign to only HR
            const filterHRDetails = dropDownOptions?.assignedTo.filter(
              (record: any) =>
                record.roles.some((roles: any) => {
                  return roles === "HR" || roles === "ADMIN";
                })
            );
            filteredAssignedToData = filterHRDetails;
          } else if (destinationUrl?.includes("projects")) {
            //assign to only sales and HR
            const filterSalesHRDetails = dropDownOptions?.assignedTo.filter(
              (record: any) =>
                record.roles.some((roles: any) => {
                  return (
                    roles === "SALES" || roles === "HR" || roles === "ADMIN"
                  );
                })
            );
            filteredAssignedToData = filterSalesHRDetails;
          } else if (destinationUrl?.includes("manage-companies")) {
            //assign to only sales
            const filterSalesDetails = dropDownOptions?.assignedTo.filter(
              (record: any) =>
                record.roles.some((roles: any) => {
                  return roles === "SALES" || roles === "ADMIN";
                })
            );
            filteredAssignedToData = filterSalesDetails;
          } else {
            filteredAssignedToData = dropDownOptions?.assignedTo;
          }
          setDropdownOptions((prevData) => ({
            ...prevData,
            assignedTo: filteredAssignedToData,
          }));

          if (response.data.selFormattedBusUnits !== undefined) {
            const entityTypeId =
              dropDownOptions.entityType &&
              dropDownOptions.entityType.find(
                (record: any) =>
                  record.label.toLowerCase() === urlEntityType?.toLowerCase()
              )?.value;
            setCommonData((prevData) => ({
              ...prevData,
              bussiness_unit: response.data.selFormattedBusUnits,
              entity_type: entityTypeId,
            }));
          }
          if (
            response.data.todoData !== undefined &&
            Object.keys(response.data.todoData).length > 0
          ) {
            const todoData = response.data.todoData;
            const businessUnits = mapToSelect(
              todoData?.todo_business_units,
              "businessUnitNumber"
            );
            setCommonData((prevData) => ({
              ...prevData,
              entity_type: todoData?.entity_type,
              entity_id: todoData?.entity_id,
              entity_name: todoData.entity_id.label && todoData.entity_id.label,
              assigned_to: todoData?.assigned_to,
              bussiness_unit: businessUnits,
              todo_status: todoData?.status,
              todo_type_id: todoData?.todo_type_id,
              info: todoData?.info,
              date: todoData?.date,
            }));
          }

          if (
            response.data.followupData !== undefined &&
            Object.keys(response.data.followupData).length > 0
          ) {
            const data = response.data.followupData;
            setHasChild(!hasChild);
            setFollowupData((prevData) => ({
              ...prevData,
              entityType: data?.entity_type,
              entityId: data?.entity_id,
              todoTypeId: data?.todo_type_id,
              followedBy: data?.assigned_to,
              followupDate: data?.follow_up_date,
              feedback: data?.feedback,
              status: data?.status,
            }));
          }
        }
      }
    );
    if (urlEntityType !== null) {
      fetchEntityDetails(urlEntityType);
    }

    if (urlEntityType && entityId) {
      setCommonData((prevData: any) => ({
        ...prevData,
        entity_id: entityId,
      }));
    }
  }, []);

  const commonValidation = (
    name: any = null,
    value: any = null,
    isSingleFieldValidation = false
  ) => {
    const validationRules: ValidationRules = {
      entity_type: [validateSelectField],
      entity_id: [validateSelectField],
      // entity_name: [validateRequired],
      assigned_to: [validateSelectField],
      bussiness_unit: [validateMultiSelectField],
      todo_status: [validateSelectField],
      todo_type_id: [validateSelectField],
      info: [validateRequired],
      date: [validateRequired],
      followupDate: [validateRequired],
    };

    const validationErrors = validateForm(
      {
        ...commonData,
        ...(hasChild && { followupData: followupData.followupDate }),
        [name]: value,
      },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );
    if (isSingleFieldValidation) {
      setCommonErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setCommonErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const handleSelectChange = (
    selectedOption: any,
    fieldName: string,
    type: string
  ) => {
    if (type === "todo") {
      setCommonData((prevData) => ({
        ...prevData,
        [fieldName]:
          fieldName === "bussiness_unit"
            ? selectedOption
            : selectedOption.value,
      }));
    } else if (type === "followup") {
      setFollowupData((prevData) => ({
        ...prevData,
        [fieldName]: selectedOption.value,
      }));
    }

    if (fieldName == "entityType" || fieldName === "entity_type") {
      fetchEntityDetails(selectedOption.label);
    }
    commonValidation(fieldName, selectedOption, true);
  };

  const fetchEntityDetails = (entityType: any) => {
    const data: any = { entityType: entityType };
    ApiCall.service(GET_ENTITY_DETAILS, "POST", data, true, M_MASTER_DATA).then(
      (response) => {
        const entityName =
          response?.data?.entityNames &&
          response.data?.entityNames.find(
            (record: any) => record.value === Number(entityId)
          )?.label;
        setCommonData((prevData) => ({
          ...prevData,
          entity_name: entityName,
        }));

        setDropdownOptions((prevData: any) => ({
          ...prevData,
          entityRelatedTodoType: response?.data?.todoTypes,
          entityRelatedEntityName: response?.data?.entityNames,
        }));
      }
    );
  };

  const handleChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
    type: string
  ) => {
    const { name, value } = event.target as HTMLInputElement;

    if (type === "todo") {
      setCommonData((prevData) => ({ ...prevData, [name]: value }));
    } else if (type === "followup") {
      setFollowupData((prevData) => ({ ...prevData, [name]: value }));
    }
    commonValidation(name, value, true);
  };

  const handleDateChange = (date: any, fieldName: string, type: string) => {
    if (date != null) {
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      const formattedDate = `${year}-${month}-${day}`;
      if (type === "todo") {
        setCommonData((prevData: any) => ({
          ...prevData,
          [fieldName]: formattedDate,
        }));
      } else if (type === "followup") {
        setFollowupData((prevData) => ({
          ...prevData,
          [fieldName]: formattedDate,
        }));
      }
      commonValidation(fieldName, formattedDate, true);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const { name, value } = e.target as HTMLInputElement;
    let postData = {
      todoData: { ...commonData },
      assigned_by: userData.userId,
      followupData: hasChild ? followupData : null,
    };
    if (routeId) {
      Object.assign(postData, { id: routeId });
    }
    const validationResult = commonValidation(name, value);
    const apiEndPoint = routeId ? EDIT_TODO : CREATE_TODO;

    if (validationResult) {
      ApiCall.service(apiEndPoint, "POST", postData, true, M_MASTER_DATA)
        .then((response) => {
          if (response.status === 200) {
            navigate(destinationUrl ? destinationUrl : `${PATH_TODO_MANAGE}`);
            CustomNotify({ type: "success", message: response.message });
          } else {
            CustomNotify({ type: "warning", message: response.message });
          }
        })
        .catch((error) => {
          CustomNotify({ type: "error", message: error });
        });
    }
  };
  //checkbox field change
  const handleFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, checked } = event.target as HTMLInputElement;
    const value = checked ? 1 : 0;
    setFollowupData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (name === "hasChild") {
      setHasChild(!hasChild);
    }
  };

  return (
    <AccessControl
      requiredPermissions={[permissionObject]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <div>
        <TitleFieldMolecule
          title={routeId ? t("Edit todo followup") : t("Create todo followup")}
        />
        <Todo
          dropdownOptions={dropdownOptions}
          handleSelectChange={handleSelectChange}
          commonData={commonData}
          commonErrors={commonErrors}
          handleDateChange={handleDateChange}
          handleChange={handleChange}
          type="todo"
          isEdit={isEdit}
          url={destinationUrl}
          assignedTo={userData.userId}
        />
        <div style={{ padding: "1vw 0" }}>
          <CheckBoxField
            label={t("Add follow up")}
            name="hasChild"
            onChangeHandler={handleFieldChange}
            isChecked={hasChild}
            className="document-checkbox"
            // disable={id !== undefined && hasChild}
            id="addFollowUp"
            lineHeight="1.5vw"
          />
        </div>
        {hasChild && (
          <div className="form-border" style={{ paddingBottom: "1vw" }}>
            <Followup
              options={dropdownOptions}
              followupData={followupData}
              commonErrors={commonErrors}
              handleSelectChange={handleSelectChange}
              handleDateChange={handleDateChange}
              handleChange={handleChange}
              type="followup"
              isEdit={isEdit}
              commonData={commonData}
              handleCheckBoxChange={handleFieldChange}
              followupFor={userData.userId}
            />
          </div>
        )}
        <div className="row" style={{ padding: "1vw 0" }}>
          <div className="col-6">
            <Link
              to={destinationUrl ? destinationUrl : `${PATH_TODO_MANAGE}`}
              className="text-uppercase back-btn text-decoration-underline"
            >
              {t("Back")}
            </Link>
          </div>
          <div className="col-6">
            <Button
              title="Save"
              handleClick={(e) => handleSubmit(e)}
              className="form-button px-2 float-end"
            />
          </div>
        </div>
      </div>
    </AccessControl>
  );
};

export default CreateTodo;
