import React from "react";
import { Link } from "react-router-dom";
import ModalPopup from "components/atoms/ModalPopup";
import { ApiCall } from "services/ApiServices";
import CustomNotify from "components/atoms/CustomNotify";
import { t } from "pages/microservices/masterData/translation/Translation";
import { PATH_VACANCY_CREATE } from "constants/Paths";
import TableActions from "components/organism/Actions/TableAction";
import { useNavigate } from "react-router-dom";
import { VACANCIES, VACANCY_CREATE, VACANCY_DELETE } from "routes/ApiEndpoints";
import { M_MASTER_DATA } from "constants/Constants";
import Pagination from "components/atoms/Pagination";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import StatusComponent from "components/atoms/ManageStatus";
import Title from "components/atoms/Title";
import ResetBtn from "components/atoms/ResetBtn";
import SearchBtn from "components/atoms/SearchBtn";
import LinkTo from "components/atoms/LinkTo";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import CheckBoxField from "components/atoms/CheckBoxField";
import { fetchProjectOptions, getPCAndItsParameters } from "./utils/Utils";

interface ManageVacanciesProps {
  state: any;
  setState: any;
  onPageChange: (pageNumber: number) => void;
  handleSubmitAction: (clear: boolean) => void;
  handleGetVacany: (postdata: any, onChange?: boolean) => Promise<void>;
}

export const ManageVacancies: React.FC<ManageVacanciesProps> = ({
  state,
  onPageChange,
  handleSubmitAction,
  handleGetVacany,
  setState,
}) => {
  const userData = useSelector(selectAuth);
  const navigate = useNavigate();
  const handleChange = async (e: any, name: any) => {
    let value: any = Array?.isArray(e) ? e?.map((item: any) => item?.value) : e?.value ?? "";    
    let selected: any = { ...state.selected };
    let options: any = { ...state.options };
    let opt: any = {};
    if (name === "hotlist") {
      value = e?.target?.checked;
    } else {
      if (name === 'company') {
        opt = await fetchProjectOptions(
          {onChangeOptions: true, companyId: value, options: ["Project", "BusinessUnit", "ParitairComitee"]},
          VACANCIES
        );        
        options = {
          ...options,
          Project: opt?.Project ?? [],
          PcFunction: getPCAndItsParameters(opt)?.PcFunctions ?? [],
          BusinessUnit: opt?.BusinessUnit ?? []
        };
        selected = {
          ...selected,
          [name]: value,
          project: (options?.Project ?? []).some((option: any) => option?.value === state?.selected?.project) ? state?.selected?.project : null,
          function:  (state?.selected?.function ?? []).filter((selected: any) => (options?.PcFunction ?? []).some((option: any) => option?.value === selected)),
          business_unit: (state?.selected?.business_unit ?? []).filter((selected: any) => (options?.BusinessUnit ?? []).some((option: any) => option?.value === selected))
        };
      } else if (name === 'project') {
        opt = await fetchProjectOptions(
          {onChangeOptions: true, project_id: value, options: ["BusinessUnit"]},
          VACANCIES
        );
        options = { ...options, BusinessUnit: opt?.BusinessUnit ?? []};
        selected = {
          ...selected,
          business_unit: (state?.selected?.business_unit ?? []).filter((selected: any) => (options?.BusinessUnit ?? []).some((option: any) => option?.value === selected))
        };
      }      
    }
    selected[name] = value;
    setState((prev: any) => ({ ...prev, selected, options}));
  };

  const handleSaveChanges = async () => {
    let postData = {
      id: state?.popup?.delete?.data?.id,
      changeStatus: state?.popup?.delete?.data?.status,
      user_id: userData.userId
    };
    if (state?.popup?.delete?.data?.id) {      
      const response = await ApiCall.service(VACANCY_DELETE, "POST", postData, true, M_MASTER_DATA);
      if (response?.status === 200) {
        CustomNotify({ type: "success", message: response.message });
        handleModal();
        setState((prev: any) => ({
          ...prev,
          data: state?.data?.map((item: any) => {            
            if (item?.id == state?.popup?.delete?.data?.id) {
              return { ...item, status: state?.popup?.delete?.data?.status === 'active' ? 'inactive' : 'active' };
            }
            return item;
          })
        }))
      } else {
        CustomNotify({ type: "error", message: response.message });
      }
    }
  };

  const handleModal = (value?: any) => {    
    setState((prev: any) => ({
      ...prev,
      popup: {
        ...prev?.popup,
        delete: {
          ...prev?.popup?.delete,
          data: value ?? {},
          show: value ? true : false,
        },
      },
    }));
  };

  const handleSubmit = (e: any, clear: boolean = false) => {
    e.preventDefault();
    handleSubmitAction(clear);
  };

  const handleHotList = async (e: any, ) => {
    let postData = {
      hotlist: true,
      id: e?.id,
      ishotlisted: !e?.ishotlisted,
      user_id: userData.userId
    };
    if (e?.id) {      
      const response = await ApiCall.service(VACANCY_CREATE, "POST", postData, true, M_MASTER_DATA);
      if (response?.status === 200) {
        CustomNotify({ type: "success", message: response.message });
        setState((prev: any) => ({
          ...prev,
          data: prev?.data?.map((item: any) => {
            if (item?.id === e?.id) {
              return { ...item, ishotlisted: !e?.ishotlisted };
            }
            return item;
          })
        }))
      } else {
        CustomNotify({ type: "error", message: response.message });
      }
    }
  };

  const handleEdit = (value: any) => {
    navigate(`${PATH_VACANCY_CREATE}/${value?.id}`);
  };
  
  return (
    <AccessControl
      requiredPermissions={[{ permission: "Vacancy", read: true }]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <div className="search-bar" style={{ paddingBottom: "0.25vw" }}>
        <Title title={t("Manage vacancy")} />
        <div className="row">
          <div className="hideLabel col-2">
            <SelectWithSearch
              title={t("Business unit")}
              name="business_unit"
              placeHolder={t("Select business unit")}
              search
              isMulti
              options={state?.options?.BusinessUnit ?? []}
              value={state?.selected?.business_unit}
              onChange={(e: any) => handleChange(e, "business_unit")}
              className="select-field"
              id="business_unit"
            />
          </div>
          <div className="hideLabel col-2">
            <SelectWithSearch
              title={t("Company")}
              name="company"
              placeHolder={t("Select company")}
              search
              options={state?.options?.Company ?? []}
              value={state?.selected?.company}
              onChange={(e: any) => handleChange(e, "company")}
              className="select-field"
              id="company"
            />
          </div>
          <div className="hideLabel col-2">
            <SelectWithSearch
              title={t("Project")}
              name="project"
              placeHolder={t("Select project")}
              search
              options={state?.options?.Project ?? []}
              value={state?.selected?.project}
              onChange={(e: any) => handleChange(e, "project")}
              className="select-field"
              id="project"
            />
          </div>
          <div className="hideLabel col-2">
            <SelectWithSearch
              title={t("Function")}
              name="function"
              placeHolder={t("Select function")}
              search
              isMulti
              options={state?.options?.PcFunction ?? []}
              value={state?.selected?.function}
              onChange={(e: any) => handleChange(e, "function")}
              className="select-field"
              id="function"
            />
          </div>
          <div className="hideLabel col-2">
            <SelectWithSearch
              title={t("Vacancy status")}
              name="vacancy_status"
              placeHolder={t("Select vacancy status")}
              search
              options={state?.options?.VacancyStatus ?? []}
              value={state?.selected?.vacancy_status}
              onChange={(e: any) => handleChange(e, "vacancy_status")}
              className="select-field"
              id="vacancy_status"
            />
          </div>
          <div className="col-2" style={{ paddingTop: "0.5vw" }}>
            <CheckBoxField
              label={t("Hotlist")}
              name="hotlist"
              onChangeHandler={(e: any) => handleChange(e, "hotlist")}
              isChecked={state?.selected?.hotlist}
              lineHeight="1.5vw"
            />
          </div>
          <div className="ms-auto col-3">
            <div className="d-flex align-items-center justify-content-end">
              <ResetBtn handleResetClick={(e) => handleSubmit(e, true)} />
              <SearchBtn handleSearchClick={handleSubmit} />
            </div>
          </div>
        </div>
      </div>
      <div className="position-relative tableMainWrapper">
        <div>
          <AccessControl
            requiredPermissions={[
              {
                permission: "Vacancy",
                create: true,
              },
            ]}
            override={userData.isSuperAdmin}
          >
            <div className="row">
              <div className="ManageCreateBtn">
                <LinkTo
                  pagelink={PATH_VACANCY_CREATE}
                  title={t("Create Vacancy")}
                  icon={faPlus}
                />
              </div>
            </div>
          </AccessControl>
        </div>
        <div className="tableSection">
          <div className="table-responsive manage-projects">
            <table className="table table-hover">
              <thead>
                <tr className="TableHeader">
                  <th>{t("Business unit")}</th>
                  <th>{t("Company")}</th>
                  <th>{t("Project")}</th>
                  <th>{t("Function")}</th>
                  <th>
                    <div>{t("Updated by")}</div>
                    <div>{t("Updated at")}</div>
                  </th>
                  <th>{t("Posting")}</th>
                  <th>{t("Vacancy status")}</th>
                  {/* <th>{t("Status")}</th> */}
                  <th className="table_actions">
                    <AccessControl
                      requiredPermissions={[{ permission: "Vacancy", update: true, delete: true }]}
                      actions={true}
                      strict={false}
                      override={userData.isSuperAdmin}
                    >
                      {t("Action")}
                    </AccessControl>
                  </th>
                </tr>
              </thead>
              <tbody>
                {state?.data && state?.data?.length > 0 ? (
                  state?.data?.map((record: any, key: any) => {                    
                    return (
                      <tr key={key}>
                        <td className="align-middle">{record?.business_unit}</td>
                        <td className="align-middle">{record?.company_name}</td>
                        <td className="align-middle">{record?.project_name}</td>
                          <td className="align-middle">
                            {record?.functions && record?.functions?.length > 0 ? (
                              record?.functions?.map((fun: any) => fun.label)?.join(", ")
                            ) : ""}
                          </td>
                        <td className="align-middle">
                          <div>{record?.updated_by}</div>
                          <div>{record?.updated_at}</div>
                        </td>
                          <td className="align-middle">
                            {record?.vacancy_mediums && record?.vacancy_mediums?.length > 0 ? (
                              record?.vacancy_mediums?.map((med: any) => med?.label)?.join(", ")
                            ) : ""}
                          </td>
                        <td className="align-middle">
                          <StatusComponent
                            initialStatus={record?.vacancy_status?.toLowerCase() === "open" ? 1 : 2}
                            tooltip={record?.vacancy_status?.toLowerCase() === "open" ? t("Open") : t("Closed")}
                          />
                        </td>
                        {/* <td className="align-middle">
                          <StatusComponent
                            initialStatus={record?.status?.toLowerCase() === "active" ? 1 : record?.status?.toLowerCase() === "draft" ? 0 : 2}
                            tooltip={record?.status?.toLowerCase() === "active" ? t("Active") : record?.status?.toLowerCase() === "draft" ? t("Draft") : t("Inactive")}
                          />
                        </td> */}
                        <td className="align-middle">
                          <TableActions
                            isAction={true}
                            handleEdit={handleEdit}
                            handleArchive={record?.status === 'draft' ? undefined : handleModal}
                            value={record}
                            handleAddHotlist={record?.status === 'draft' ? undefined : handleHotList}
                            permission={"Vacancy"}
                            signature={record.project_pdf_path}
                          />
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={4} className="text-center">
                      {t("No records found")}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="pagination justify-content-center align-items-center py-3 my-lg-0">
            <Pagination
              currentPage={state?.currentPage}
              totalPages={state?.totalPages}
              onPageChange={onPageChange}
            />
          </div>
        </div>
      </div>
      <div className="col-md-6 align-self-center my-2">
        <Link
          to={"/dashboard"}
          className="text-uppercase back-btn text-decoration-underline"
        >
          {t("Back")}
        </Link>
      </div>
      <ModalPopup
        show={state?.popup?.delete?.show}
        onHide={() => handleModal()}
        title={t("Delete confirmation")}
        body={t(`Are you sure want to ${state?.popup?.delete?.data?.status === 'active' ? 'archive' : 'unarchive'}`) + "?"}
        onCloseButtonClick={() => handleModal()}
        onConfirmButtonClick={handleSaveChanges}
        closeTitle={t("No")}
        confirmTitle={t("Yes")}
      />
    </AccessControl>
  );
};
export default ManageVacancies;
