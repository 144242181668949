import { HOLIDAY_CONFIGS, MANAGE_EMPLOYEE_LEAVES, MANAGE_EMPLOYER_LEAVES, PATH_EMPLOYEE_MANAGE, PATH_CANDIDATE_SCREENING, PATH_MANAGE_CANDIDATES, PATH_HR_PLANNING, PATH_SALES_PLANNING, PATH_WORK_PAY_BILL_ROLL, PATH_MANAGE_TRANSLATIONS } from "constants/Paths";
import { IconData } from "services/IconData";

interface Tab {
  requiredPermissions: any;
  path: string;
  name: string;
  icon: any;
  circleColor: string;
}

export const getTiles = (userData: any): Tab[] => [
 
  { name: "Roles", circleColor: "", icon: IconData.RoleRoundIcon, path: "/manage-roles", requiredPermissions: [{ permission: "Role", read: true }]},
  { name: "Users", circleColor: "", icon: IconData.UserRoundIcon, path: "/manage-users", requiredPermissions: [{ permission: "Users", read: true }]},
  { name: "Permissions", circleColor: "", icon: IconData.PermissionsRoundIcon, path: "/manage-permissions", requiredPermissions: [{ permission: "Permission", read: true }]},
  { name: "Companies", circleColor: "", icon: IconData.CompaniesRoundIcon, path: "/manage-companies", requiredPermissions: [{ permission: "Company", read: true }]},
  { name: "Projects", circleColor: "", icon: IconData.ProjectsRoundIcon, path: "/projects", requiredPermissions: [{ permission: "Project", read: true }]},
  { name: "Master data", circleColor: "", icon: IconData.MasterDataRoundIcon, path: "/config/settings", requiredPermissions: [{ permission: "Masterdata", read: true }]},
  { name: "Employees", circleColor: "", icon: IconData.EmployeesRoundIcon, path: PATH_EMPLOYEE_MANAGE, requiredPermissions: [{ permission: "Employees", read: true }]},
  { name: "Vacancies", circleColor: "", icon: IconData.VacanciesRoundIcon, path: "/vacancies", requiredPermissions: [{ permission: "Vacancy", read: true }]},
  { name: "Planning", circleColor: "", icon: (userData.role?.includes("HR")) ? IconData.HRPlanningRoundIcon : IconData.SalesPlanningRoundIcon, path: (userData.role?.includes("HR")) ? PATH_HR_PLANNING : PATH_SALES_PLANNING, requiredPermissions: [{ permission: (userData.role?.includes("HR")) ? "Planning" : "Sales planning", read: true }]},
  { name: "Holiday", circleColor: "", icon: IconData.HolidayRoundIcon, path: HOLIDAY_CONFIGS, requiredPermissions: [{ permission: "Holiday Config", read: true }]},
  { name: "Candidates", circleColor: "", icon: IconData.CandidatesRoundIcon, path: PATH_MANAGE_CANDIDATES, requiredPermissions: [{ permission: "Candidates", read: true }]},
  { name: "Leaves", circleColor: "", icon: IconData.LeavesRoundIcon, path: (userData.role?.includes("HR")) ? MANAGE_EMPLOYER_LEAVES : MANAGE_EMPLOYEE_LEAVES, requiredPermissions: [{ permission: "Leaves", read: true }]},
  { name: "Fleet and material", circleColor: "", icon: IconData.FleetAndMaterialRoundIcon, path: "/fleetOverview/car", requiredPermissions: [{ permission: "FleetMaterials", read: true }]},
  { name: "Timesheets", circleColor: "", icon: IconData.TimesheetRoundIcon, path: "/timesheets", requiredPermissions: [{ permission: "Timesheets", read: true }]},
  // {name: "Screening", circleColor: "", icon: IconData.CompaniesRoundIcon, path: PATH_CANDIDATE_SCREENING, requiredPermissions: [{permission: "Screening", read: true}]},
  {name: "Pay roll", circleColor: "", icon: IconData.PayrollRoundIcon, path: PATH_WORK_PAY_BILL_ROLL, requiredPermissions: [{permission: "PayRoll", read: true}]},
  {name: "Translations", circleColor: "", icon: IconData.TranslationsRoundIcon, path: PATH_MANAGE_TRANSLATIONS, requiredPermissions: [{ permission: "Translations", read: true }]},
  { name: "Margin calculation",circleColor: "", icon: IconData.PayrollRoundIcon, path:'/margin', requiredPermissions: [{ permission: "MarginCalculation", read: true }] },
  { name: "Partena",circleColor: "", icon: IconData.PartenaIcon, path:'/partena', requiredPermissions: [{ permission: "", read: true }] },
];

export const sideBarItems = (userData: any): any =>  [
  { name: "Dashboard", path: "/dashboard", icon: IconData.DashBoardIcon, id: "dashboard" },
  { name: "Employees", path: "/employees", icon: IconData.EmployeeIcons, id: "employees", requiredPermissions: [{ permission: "Employees", read: true }]},
  { name: "Companies", path: "/manage-companies", icon: IconData.CompaniesIcon, id: "companies", requiredPermissions: [{ permission: "Company", read: true }]},
  { name: "Company contacts", path: "/manage-contact/person", icon: IconData.CompanyContactsIcon, id: "company-contacts", requiredPermissions: [{ permission: "Contact person", read: true }]},
  { name: "Vacancies", path: "/vacancies", icon: IconData.VacanciesIcon, id: "vacancies", requiredPermissions: [{ permission: "Vacancy", read: true }]},
  { name: "Candidates", path: "/manage-candidates", icon: IconData.CandidatesIcon, id: "candidates", requiredPermissions: [{ permission: "Candidates", read: true }]},
  { name: "Users", path: "/manage-users", icon: IconData.UsersIcon, id: "users", requiredPermissions: [{ permission: "Users", read: true }]},
  { name: "Translations", path: PATH_MANAGE_TRANSLATIONS, icon: IconData.TranslationsIcon, id: "translation", requiredPermissions: [{ permission: "Translations", read: true }]},
  { name: "Budget", path: "/dashboard", icon: IconData.BudgetIcon, id: "budget", requiredPermissions: [{ permission: "Budget", read: true }]},
  { name: "Planning", path: (userData.role?.includes("HR")) ? PATH_HR_PLANNING : PATH_SALES_PLANNING, icon: (userData.role?.includes("HR")) ? IconData.HrPlanningIcon : IconData.SalesPlanningIcon, id: "planning", requiredPermissions: [{ permission: (userData.role?.includes("HR")) ? "Planning" : "Sales planning", read: true }]},
  { name: "Leaves", path: "/dashboard", icon: IconData.LeavesIcon, id: "leaves", requiredPermissions: [{ permission: "Leaves", read: true }]},
  { name: "Matching", path: (userData.role?.includes("HR")) ? PATH_HR_PLANNING : PATH_SALES_PLANNING, icon: IconData.MatchingIcon, id: "matching", requiredPermissions: [{ permission: "Matching", read: true }]},
  { name: "Cars", path: "/fleetOverview/car", icon: IconData.CarsIcons, id: "cars", requiredPermissions: [{ permission: "Car", read: true }]},
  { name: "Material", path: "/fleetOverview/material", icon: IconData.MaterialIcon, id: "material", requiredPermissions: [{ permission: "Material", read: true }]},
  { name: "Clothes", path: "/fleetOverview/clothes", icon: IconData.ClothsIcon, id: "clothes", requiredPermissions: [{ permission: "Clothes", read: true }]},
  { name: "Supplier", path: "/fleetOverview/supplier", icon: IconData.SupplierIcon, id: "supplier", requiredPermissions: [{ permission: "Supplier", read: true }]},
  { name: "Purchase order", path: "/fleetOverview/purchaseOrder", icon: IconData.PurchaseOrderIcon, id: "purchase-order", requiredPermissions: [{ permission: "PurchaseOrder", read: true }]},
  { name: "Client", path: "/fleetOverview/client", icon: IconData.ClientsIcon, id: "client", requiredPermissions: [{ permission: "Client", read: true }]},
  { name: "Backoffice", path: "/dashboard", icon: IconData.BackOfficeIcon, id: "backoffice", requiredPermissions: [{ permission: "Backoffice", read: true }]},
  { name: "Accidents", path: "/dashboard", icon: IconData.AccidentsIcon, id: "accidents", requiredPermissions: [{ permission: "Accidents", read: true }]},
  { name: "Medical examination", path: "/dashboard", icon: IconData.MedicalEmaminationIcon, id: "medical-examination", requiredPermissions: [{ permission: "Medical examination", read: true }]},
  { name: "Work post visits", path: "/dashboard", icon: IconData.WorkPostVisitsIcon, id: "work-post-visits", requiredPermissions: [{ permission: "Work post visits", read: true }]},
];

export const rolesTilesData = [
  { id: 1, title: "Number of customer visits", value: 6, totalValue: 10, path: "/manage-companies", component: IconData.CustomerVisitIcon, requiredPermissions: [{ permission: "Number of customer visits", read: true }] },
  { id: 2, title: "Number of prospect visits", value: 3, totalValue: 22, path: "/vacancies", component: IconData.ProspectVisitIcon, requiredPermissions: [{ permission: "Number of prospect visits", read: true }] },
  { id: 3, title: "Number of projects", value: 10, totalValue: 13, path: "/projects", component: IconData.NumberOfProjectsIcon, requiredPermissions: [{ permission: "Number of projects", read: true }] },
  { id: 5, title: "Current projects", value: 2, totalValue: 8, path: "/dashboard", component: IconData.CurrentProjectsIcon, requiredPermissions: [{ permission: "Current projects", read: true }] },
  { id: 6, title: "Current persons", value: 3, totalValue: 30, path: "/employees", menu: "dashboard", component: IconData.CurrentPersonsIcon, requiredPermissions: [{ permission: "Current persons", read: true }] },
  { id: 7, title: "Starters", value: 10, totalValue: 42, path: "/dashboard", component: IconData.StartersIcon, requiredPermissions: [{ permission: "Starters", read: true }] },
  { id: 8, title: "Stoppers", value: 1, totalValue: 10, path: "/dashboard", menu: "users", component: IconData.StoppersIcon, requiredPermissions: [{ permission: "Stoppers", read: true }] },
  { id: 9, title: "Total", value: 2, totalValue: 15, path: "/dashboard", component: IconData.TotalIcon, requiredPermissions: [{ permission: "total", read: true }] },
  { id: 10, title: "Leave", value: 0, totalValue: 40, path: "/dashboard", component: IconData.LeaveIcon, requiredPermissions: [{ permission: "Leave", read: true }] },
  { id: 11, title: "ECW", value: 2, totalValue: 20, path: "/dashboard", component: IconData.ECWIcon, requiredPermissions: [{ permission: "ECW", read: true }] },
  { id: 12, title: "Illness", value: 0, totalValue: 20, path: "/dashboard", component: IconData.IllnessIcon, requiredPermissions: [{ permission: "Illness", read: true }] },
  { id: 13, title: "Other", value: 2, totalValue: 60, path: "/dashboard", component: IconData.OthersIcon, requiredPermissions: [{ permission: "Other", read: true }] },
];
