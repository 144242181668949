import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Button from "components/atoms/Button";
import ModalPopup from "components/atoms/ModalPopup";
import { ApiCall } from "services/ApiServices";
import CustomNotify from "components/atoms/CustomNotify";
import { t } from "pages/microservices/masterData/translation/Translation";
import { PATH_CREATE_SUPPLIER, PATH_EDIT_SUPPLIER } from "constants/Paths";
import TableActions from "components/organism/Actions/TableAction";
import { useNavigate } from "react-router-dom";
import {
  VACANCY_DELETE,
  COMPANY_LIST,
  SUPPLIER_DELETE,
} from "routes/ApiEndpoints";
import { ERROR, M_MASTER_DATA } from "constants/Constants";
import Pagination from "components/atoms/Pagination";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import FilterElementsRender from "../FilterElement";
import { fetchOptions } from "../utils/FetchOptions";
import { IconData } from "services/IconData";
import Title from "components/atoms/Title";
import LabelField from "components/atoms/LabelField";
import ResetBtn from "components/atoms/ResetBtn";
import SearchBtn from "components/atoms/SearchBtn";
import LinkTo from "components/atoms/LinkTo";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

interface ManageSupplierProps {
  data?: any[];
  handleData: any;
  currentPage: number;
  totalPages: number;
  onPageChange: (pageNumber: number) => void;
  itemsPerPage: number;
  searchValue: (name: string, value: string | number) => void;
  handleSubmitAction: (clear: boolean) => void;
  searchData: any;
  handleGetSupplier: any;
}

const ManageSupplier: React.FC<ManageSupplierProps> = ({
  data,
  handleData,
  currentPage,
  totalPages,
  onPageChange,
  itemsPerPage,
  searchValue,
  handleSubmitAction,
  searchData,
  handleGetSupplier,
}) => {
  const [id, setId] = useState<number | undefined>(undefined);
  const [showModal, setShowModal] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    supplierType: [],
  });

  const navigate = useNavigate();

  const handleSearchChange = (event: any, name: string, type: string) => {
    switch (type) {
      case "text":
      case "date":
        return searchValue(name, event.target.value);

      case "select":
        return searchValue(name, event.value);
    }
  };

  const handleSaveChanges = async () => {
    setShowModal(false);
    let postData = {
      id: id,
    };

    if (id) {
      const response = await ApiCall.service(
        SUPPLIER_DELETE,
        "POST",
        postData,
        false,
        M_MASTER_DATA
      );

      if (response?.status === 200) {
        CustomNotify({ type: "success", message: response.message });
        handleGetSupplier({
          page: 1,
          nOR: 10,
          type: "supplier",
          search: {
            name: "",
          },
        });
      } else {
        CustomNotify({ type: "error", message: response.message });
      }
    }
  };

  useEffect(() => {
    getFilterData();
  }, []);

  const getFilterData = async () => {
    try {
      const filterData = await fetchOptions();
      if (filterData?.status == 200) {
        setFilterOptions((prevState: any) => ({
          ...prevState,
          supplierType: filterData.data.suppliers,
        }));
      } else {
        CustomNotify({ type: "error", message: filterData.message });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleModalClose = () => setShowModal(!showModal);

  const handleSubmit = (e: any, clear: boolean = false) => {
    e.preventDefault();
    handleSubmitAction(clear);
  };

  const handleEdit = (id: number) => {
    navigate(`${PATH_EDIT_SUPPLIER}/${id}`);
  };

  const handleArchive = (id: number) => {
    setShowModal(true);
    setId(id);
  };
  const userData = useSelector(selectAuth);

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Supplier",
          read: true,
        },
      ]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <>
        <div className="search-bar">
          <Title title={t("Manage supplier")} />
          <div className="row">
            <div className="col-10">
              <div className="row">
                <FilterElementsRender
                  data={filterOptions?.supplierType}
                  type="multi-select"
                  handleChange={(event) =>
                    handleSearchChange(event, "supplierType", "select")
                  }
                  value={searchData?.supplierType}
                  placeHolder={"Supplier type"}
                  name="supplierType"
                  searchclass="col-lg-3"
                />

                <FilterElementsRender
                  data={searchData?.name}
                  type="text"
                  handleChange={(event) =>
                    handleSearchChange(event, "name", "text")
                  }
                  value={searchData?.name}
                  placeHolder={"Name"}
                  name="name"
                  searchclass="col-lg-3"
                />

                <FilterElementsRender
                  data={searchData?.vatNumber}
                  type="text"
                  handleChange={(event) =>
                    handleSearchChange(event, "vatNumber", "text")
                  }
                  value={searchData?.vatNumber}
                  placeHolder={"VAT number"}
                  name="vatNumber"
                  searchclass="col-lg-3"
                />
                <FilterElementsRender
                  data={searchData?.email}
                  type="text"
                  handleChange={(event) =>
                    handleSearchChange(event, "email", "text")
                  }
                  value={searchData?.email}
                  placeHolder={"Email"}
                  name="email"
                  searchclass="col-lg-3"
                />
              </div>
            </div>
            <div className="col-md-4 col-lg-2">
              <div className="d-flex align-items-center justify-content-end">
                <ResetBtn handleResetClick={(e) => handleSubmit(e, true)} />
                <SearchBtn handleSearchClick={handleSubmit} />
              </div>
            </div>
          </div>
        </div>

        <div className="position-relative tableMainWrapper">
          <div>
            <AccessControl
              requiredPermissions={[
                {
                  permission: "Supplier",
                  create: true,
                },
              ]}
              override={userData.isSuperAdmin}
            >
              <div className="row">
                <div className="ManageCreateBtn">
                  <LinkTo
                    pagelink={PATH_CREATE_SUPPLIER}
                    title={t("Create supplier")}
                    icon={faPlus}
                  />
                </div>
              </div>
            </AccessControl>
          </div>
          <div className="table-responsive manage-projects tableSection">
            <div className="manageSupplierHeight">
              <table className="table table-hover">
                <thead>
                  <tr className="TableHeader">
                    <th style={{ width: "10%" }}>{t("Supplier type")}</th>
                    <th style={{ width: "20%" }}>{t("Name")}</th>
                    <th style={{ width: "20%" }}>{t("VAT number")}</th>
                    <th style={{ width: "20%" }}>{t("Email")}</th>
                    <th style={{ width: "20%" }}>
                      <AccessControl
                        requiredPermissions={[
                          {
                            permission: "Supplier",
                            update: true,
                            delete: true,
                          },
                        ]}
                        actions={true}
                        strict={false}
                        override={userData.isSuperAdmin}
                      >
                        {t("Action")}
                      </AccessControl>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data && data.length > 0 ? (
                    data.map((record, key) => {
                      return (
                        <tr key={key}>
                          <td className="align-middle">{record.type.name}</td>
                          <td className="align-middle">{record.name}</td>
                          <td className="align-middle">{record.vatNumber}</td>
                          <td className="align-middle">{record.email}</td>
                          <td className="align-middle">
                            <TableActions
                              isAction={true}
                              handleEdit={handleEdit}
                              handleDelete={handleArchive}
                              value={record.id}
                              permission={"Supplier"}
                              signature={record.project_pdf_path}
                            />
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={5} className="text-center">
                        {t("No records found")}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div
              className="pagination justify-content-center align-items-center"
              style={{ paddingTop: "0.25vw" }}
            >
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={onPageChange}
              />
            </div>
          </div>
        </div>
        <ModalPopup
          show={showModal}
          onHide={handleModalClose}
          title={t("Delete confirmation")}
          body={t("Are you sure want to delete?")}
          onCloseButtonClick={handleModalClose}
          onConfirmButtonClick={handleSaveChanges}
          closeTitle="No"
          confirmTitle="Yes"
        />
      </>
    </AccessControl>
  );
};
export default ManageSupplier;
