import React, { useState } from "react";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import PhoneInput from "react-phone-number-input";
import LabelField from "components/atoms/LabelField";
import InputTextfield from "components/atoms/InputTextField";
import {
  GoogleReCaptchaProvider,
  // GoogleReCaptcha,
} from "react-google-recaptcha-v3";
import Button from "components/atoms/Button";
import { ApiCall } from "services/ApiServices";
import { useNavigate, Link } from "react-router-dom";
import LoadingIcon from "utils/LoadingIcon";
import {
  validateForm,
  validateEmail,
  validateRequired,
  validateCheckbox,
  validateTextFieldAlpha,
  validatePhoneNumber,
} from "services/validation/ValidationService";
import "static/css/login.css";
import ImageField from "components/atoms/ImageField";
import CustomNotify, { NotifyType } from "components/atoms/CustomNotify";
import { reCaptchaSiteKey } from "constants/Constants";
import * as ENDPOINTS from "routes/ApiEndpoints";
import { t } from "pages/microservices/masterData/translation/Translation";
import { E164Number } from "libphonenumber-js";

interface Registration {
  firstName?: string;
  lastName?: string;
  email?: string;
  mobNumber?: string | E164Number;
  terms?: boolean;
  isCaptcha?: boolean;
  formStatus?: number;
  role?: string;
}

interface ValidationRules {
  [key: string]: Function[];
}

const RegistrationForm: React.FC = () => {
  const navigate = useNavigate();
  const [errors, setErrors] = useState<Registration>({});
  const [formData, setFormData] = useState<Registration>({
    firstName: "",
    lastName: "",
    email: "",
    mobNumber: "" as E164Number,
    terms: false,
    isCaptcha: false,
    formStatus: 1,
    role: "Candidate",
  });

  const [loading, setLoading] = useState(false);

  const validation = (
    name: string,
    value: string | boolean,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      firstName: [validateRequired, validateTextFieldAlpha],
      lastName: [validateRequired, validateTextFieldAlpha],
      email: [validateRequired, validateEmail],
      mobNumber: [validatePhoneNumber],
      terms: [validateCheckbox],
      // isCaptcha: [validateCheckbox],
    };

    const validationErrors = validateForm(
      { ...formData, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation && Object.keys(errors).length > 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }

    return true;
  };

  /** Update the form data fields statue onChange event*/
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;
    let updatedValue: string | boolean;
    if (type === "checkbox") {
      updatedValue = checked;
    } else {
      updatedValue = value;
    }
    setFormData((formData) => ({ ...formData, [name]: updatedValue }));
    validation(name, updatedValue, true);
  };

  /** Read the phone number filed state onChange event*/
  const handlePhoneNumberChange = (e: string | E164Number | undefined) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      mobNumber: e,
    }));
  };

  /** Update ReCAPTCHA value onChange event*/
  // const handleCaptchaVerify = (response: string | null) => {
  //   if (response) {
  //     setFormData((prevFormData) => ({
  //       ...prevFormData,
  //       isCaptcha: true,
  //     }));
  //   }
  // };

  const handleSubmit = async (e: React.FormEvent) => {
    setLoading(true);
    e.preventDefault();
    const { name, value } = e.target as HTMLInputElement;
    if (validation(name, value)) {
      const response = await ApiCall.service(
        ENDPOINTS.REGISTER,
        "POST",
        formData
      );
      if (response.status === 200) {
        let notificationType: NotifyType = "success";
        setTimeout(() => {
          navigate("/login");
        }, 2000); // Navigate after 5 seconds
        CustomNotify({
          type: "success",
          message: response.message,
        });
        // if (response.userId) {
        //   const updatedFormData = {
        //     general: formData,
        //     userId: response.userId,
        //     candidateStatus: 0,
        //     formStatus: 0,
        //   };
        //   await ApiCall.service(
        //     ENDPOINTS.RESGISTER_CANDIDATE,
        //     "POST",
        //     updatedFormData,
        //     false,
        //     "candidate-creation"
        //   );
        // }
      } else {
        if (response.errors.email && response.errors.mobNumber) {
          setErrors({
            email: response.errors.email,
            mobNumber: response.errors.phone_number,
          });
        } else {
          response.errors.email
            ? setErrors({ email: response.errors.email })
            : setErrors({ mobNumber: response.errors.mobNumber });
        }
      }
    }
    setLoading(false);
  };

  return (
    <>
      <div className="container-fluid login-bg">
        <div className="row">
          <div className="col-6 px-0 vh-100">
            <ImageField
              altText="Banner"
              className="w-100 h-100 img-fluid object-fit-cover"
              src="/static/images/loginImage.jpg"
            />
          </div>
          <div className="col-6 align-self-center vh-100 overflow-auto">
            <div style={{ width: "60%", margin: "auto" }}>
              <div
                className="text-center"
                style={{ marginBottom: "3vw", paddingTop: "2vw" }}
              >
                <ImageField
                  altText="Logo"
                  className="text-center logo-width"
                  src="static/images/logo.svg"
                />
              </div>
              <div
                className="text-center login-title"
                style={{ marginBottom: "2vw" }}
              >
                {t("Candidate registration")}
              </div>
              <GoogleReCaptchaProvider reCaptchaKey={reCaptchaSiteKey}>
                <form onSubmit={handleSubmit} className="register-form">
                  <LabelWithInputField
                    isMandatory={true}
                    name="firstName"
                    handleChange={changeHandler}
                    value={formData.firstName}
                    id="fname"
                    label={t("First name")}
                    placeholder={t("First name")}
                    error={errors.firstName}
                  />
                  <LabelWithInputField
                    isMandatory={true}
                    name="lastName"
                    handleChange={changeHandler}
                    value={formData.lastName}
                    id="lname"
                    label={t("Last name")}
                    placeholder={t("Last name")}
                    error={errors.lastName}
                  />
                  <LabelWithInputField
                    isMandatory={true}
                    name="email"
                    handleChange={changeHandler}
                    value={formData.email}
                    id="email"
                    label={t("Email")}
                    placeholder={t("Email")}
                    type="email"
                    error={errors.email}
                  />
                  <LabelField
                    title={t("Phone number")}
                    isMandatory={true}
                    key="PhoneInput"
                  />
                  <div className="form-control field-shadow rounded-3 d-flex">
                    <PhoneInput
                      initialValueFormat="national"
                      placeholder={t("Enter phone number")}
                      defaultCountry="BE" //Belgium country code
                      value={formData.mobNumber as E164Number}
                      onChange={handlePhoneNumberChange}
                      name="mobNumber"
                      className="w-100"
                    />
                  </div>
                  <div className="height-20" style={{marginBottom:"0.5vw", marginTop:"0.5vw"}}>
                    {errors.mobNumber && (
                      <span className="text-danger">{errors.mobNumber}</span>
                    )}
                  </div>
                  <div className="d-flex align-items-center">
                    <InputTextfield
                      type="checkbox"
                      handleChange={changeHandler}
                      checked={formData.terms}
                      name="terms"
                      className="form-check-input mt-0 shadow-none rounded-0 border"
                      id="termsAndConditions"
                    />
                    <Link
                      to="/terms-conditions"
                      about="terms and conditions"
                      target="_blank"
                      className="ms-2 link-color"
                    >
                      {t("Accept terms and conditions")}
                    </Link>
                  </div>
                  <div className="height-20" style={{marginBottom:"0.5vw", marginTop:"0.5vw"}}>
                    {errors.terms && (
                      <span className="text-danger">
                        {t("Please accept terms and conditions.")}
                      </span>
                    )}
                  </div>
                  {/* <div className="d-flex justify-content-center">
                    <GoogleReCaptcha onVerify={handleCaptchaVerify} />
                  </div> */}
                  <div
                    className="text-center"
                    style={{ marginBottom: "0.5vw" }}
                  >
                    {errors.isCaptcha && (
                      <span className="text-danger text-center">
                        {t("Please verify that you are not a robot.")}
                      </span>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-md-10 mx-auto">
                      {!loading ? (
                        <Button
                          type="submit"
                          className="form-button w-100"
                          title={t("Register")}
                        />
                      ) : (
                        <LoadingIcon
                          iconType="bars"
                          color="#00a5ce"
                          height={"3vw"}
                          width={"3vw"}
                        />
                      )}
                    </div>
                  </div>
                </form>
              </GoogleReCaptchaProvider>
              <div
                className="text-center"
                style={{ marginTop: "1.4vw", marginBottom: "1vw" }}
              >
                {t("Already registered?")}
                <a href="/login" className="ms-2 link-color">
                  {t("Login here")}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegistrationForm;
