import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import WindowHeightComponent from "components/atoms/WindowHeightComponent";
import Button from "components/atoms/Button";
import CustomNotify from "components/atoms/CustomNotify";
import { ApiCall } from "services/ApiServices";
import { M_MASTER_DATA } from "constants/Constants";
import {
  APPROVE_WAGE_PROPOSAL,
  GENERATE_WAGE_PROPOSAL,
  SEND_WAGE_PROPOSAL_MAIL,
} from "routes/ApiEndpoints";
import {
  PATH_ACCESS_DENIED,
  PATH_EMPLOYEE_MANAGE,
  PATH_HOME,
} from "constants/Paths";
import LoadingIcon from "utils/LoadingIcon";
import { t } from "../translation/Translation";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import CheckBoxField from "components/atoms/CheckBoxField";

const WageProposal: React.FC = () => {
  const url = new URL(window.location.href);
  const { id } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const approve = queryParams.get("approve") === "true";
  const userAuth = useSelector(selectAuth);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [htmlPath, setHtmlPath] = useState("");
  const [checked, setChecked] = useState(false);
  const mailSend = url.searchParams.get("wage_mail_send");
  const buttonTitle = mailSend=="true" ? t("Resend") : t("Send");

  let employeeId;
  if (isNaN(Number(id))) {
    const decryptedProjectId = id ? atob(id) : "";
    const splitArray = decryptedProjectId.split("id-");
    employeeId = splitArray[1];
    const requestIdAsNumber = parseInt(employeeId || "", 10);
    if (requestIdAsNumber !== userAuth.employeeId) {
      navigate(PATH_ACCESS_DENIED);
    }
  } else {
    employeeId = id;
  }
  const requestData = { id: employeeId || id };
  useEffect(() => {
    generateWageProposal();
  }, []);

  const generateWageProposal = async () => {
    try {
      const response = await ApiCall.service(
        GENERATE_WAGE_PROPOSAL,
        "POST",
        requestData,
        true,
        M_MASTER_DATA
      );
      if (response?.status === 200) {
        setHtmlPath(response.data.html_file_path);
      } else {
        CustomNotify({ type: "error", message: `Error: ${response.message}` });
      }
    } catch (error) {
      console.error(error);
      CustomNotify({ type: "error", message: "An error occurred." });
    }
  };

  const handleSend = async () => {
    setLoading(true);
    try {
      const response = await ApiCall.service(
        SEND_WAGE_PROPOSAL_MAIL,
        "POST",
        requestData,
        false,
        M_MASTER_DATA
      );
      if (response?.status === 200) {
        CustomNotify({ type: "success", message: response.message });
        navigate(PATH_EMPLOYEE_MANAGE);
      } else {
        CustomNotify({ type: "error", message: `Error: ${response.message}` });
      }
    } catch (error) {
      console.error(error);
      CustomNotify({ type: "error", message: "An error occurred." });
    }
    setLoading(false);
  };

  const handleApprove = async () => {
    try {
      const response = await ApiCall.service(
        APPROVE_WAGE_PROPOSAL,
        "POST",
        requestData,
        false,
        M_MASTER_DATA
      );
      if (response?.status === 200) {
        CustomNotify({ type: "success", message: response.message });
        navigate(PATH_HOME);
      } else {
        CustomNotify({ type: "error", message: `Error: ${response.message}` });
      }
    } catch (error) {
      console.error(error);
      CustomNotify({ type: "error", message: "An error occurred." });
    }
  };

  const handleBack = () => {
    navigate(PATH_EMPLOYEE_MANAGE);
  };

  const handleCheckbox = (checked: boolean) => {
    setChecked(checked);
  };

  return (
    <WindowHeightComponent>
      <div className="d-flex h-100 overflow-auto flex-column">
        <div className="flex-1 overflow-auto">
          <iframe
            src={`${process.env.REACT_APP_CONTRACT_URL}/${htmlPath}`}
            height="99%"
            width="100%"
          />
        </div>
        {approve && (
          <div className="d-flex justify-content-between align-items-center">
            <CheckBoxField
              name="checkbox"
              onChangeHandler={(e) => handleCheckbox(e.target.checked)}
              isChecked={checked}
              label={t(
                "I hereby declare that I have reviewed the document and confirming that I agree with all details"
              )}
            />
          </div>
        )}
        <div className="d-flex justify-content-between align-items-center">
          {approve && (
            <Button
              type="button"
              title={t("Back")}
              handleClick={handleBack}
              className="btn form-button px-3 shadow-none text-uppercase"
            />
          )}

          {approve && checked ? (
            <>
              <Button
                type="button"
                title={t("Approve")}
                handleClick={handleApprove}
                className="btn form-button px-3 shadow-none text-uppercase"
              />
              {!loading ? (
                <Button
                  type="button"
                  title={t("Send now")}
                  handleClick={handleSend}
                  className="btn form-button px-3 ms-3 shadow-none text-uppercase"
                />
              ) : (
                <LoadingIcon
                  className="ms-3 h-100"
                  iconType="bars"
                  color="#00a5ce"
                />
              )}
            </>
          ) : (
            <div className="w-100">
              {isNaN(Number(id)) ? (
                <>
                  <Button
                    type="button"
                    title={t("Approve")}
                    handleClick={handleApprove}
                    className="btn form-button px-3 shadow-none text-uppercase"
                  />
                </>
              ) : (
                !approve && (
                  <div className="d-flex justify-content-between align-items-center w-100">
                    <Button
                      type="button"
                      title={t("Back")}
                      handleClick={handleBack}
                      className="btn form-button px-3  shadow-none text-uppercase"
                    />

                    {!loading ? (
                      <Button
                        type="button"
                        title={buttonTitle}
                        handleClick={handleSend}
                        className="btn form-button px-3 ms-3 shadow-none text-uppercase"
                      />
                    ) : (
                      <LoadingIcon
                        className="ms-3 h-100"
                        iconType="bars"
                        color="#00a5ce"
                      />
                    )}
                  </div>
                )
              )}
            </div>
          )}
        </div>
      </div>
    </WindowHeightComponent>
  );
};

export default WageProposal;
