import { getOptions } from "../utils/FetchOptions";

export const rentalFunction = (payload: any, dependentFields: any) => {
  let { fieldData, state } = payload;
  const fieldValue = fieldData.value;
  let newState = state.map((item: any) => {
    if (item.name === fieldData.field) {
      item.value = fieldValue;
      item.error = fieldData.error;
    }
    if (dependentFields.includes(item.name)) {
      item.display = fieldValue ? true : false;
    }
    return item
  })
  return newState

}

export const usersSetFunction = async (payload: any, dataObj: any, entityType: any) => {
  let { fieldData, state } = payload;
  const fieldValue = fieldData?.value?.value == undefined ? fieldData?.value : fieldData?.value?.value;
  const response = await getOptions(fieldValue, dataObj, entityType);
  let newState = (state.map((item: any) => {
    if (item.name == "clientOrEmployee") {
      item.options = response?.data;
      if ((dataObj != null || dataObj != undefined)) {
        item.value = dataObj.clientOrEmployee;
      }
    }
    if (item.dependencyAction == "users") {
      item.value = fieldData.value;
    }
    return item;
  }));
  return newState;
}


