import React, { useState } from "react";
import TitleFieldMolecule from "components/molecules/TitleField";
import { SalaryBenefitHeader } from "TableHeader";
import { Link, useNavigate } from "react-router-dom";
import { t } from "pages/microservices/masterData/translation/Translation";
import { ARCHIVE_SALARY_BENEFITS } from "routes/ApiEndpoints";
import { ApiCall } from "services/ApiServices";
import CustomNotify from "components/atoms/CustomNotify";
import Search from "components/atoms/Search";
import Pagination from "components/atoms/Pagination";
import ModalPopup from "components/atoms/ModalPopup";
import DynamicTableStructure from "components/atoms/DynamicTableStructure";
import { M_MASTER_DATA } from "constants/Constants";
import { PATH_MASTER_DATA, PATH_SALARY_BENEFITS_CREATE } from "constants/Paths";
import Button from "components/atoms/Button";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import { SalaryBenefits } from "./ManagePage";
import { IconData } from "services/IconData";
import Title from "components/atoms/Title";
import LabelField from "components/atoms/LabelField";
import ResetBtn from "components/atoms/ResetBtn";
import SearchBtn from "components/atoms/SearchBtn";
import LinkTo from "components/atoms/LinkTo";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

interface SalaryBenefitPageProps {
  salaryBenefitObject: SalaryBenefits[];
  currentPage: number;
  totalPages: number;
  onPageChange: (pageNumber: number) => void;
  searchValue: (name: string, value: string | number) => void;
  deleteFilter: (deleteId: number) => void;
  handleSubmitAction: (clear: boolean) => void;
  searchData: any;
}

const ManageSalaryBenefitPage: React.FC<SalaryBenefitPageProps> = ({
  salaryBenefitObject,
  currentPage,
  totalPages,
  onPageChange,
  searchValue,
  deleteFilter,
  handleSubmitAction,
  searchData,
}) => {
  const [deleteID, setDeleteId] = useState<number | undefined>();
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  const handleSearchChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    const debounceTime = 10;
    clearTimeout((handleSearchChange as any).timeoutId);
    (handleSearchChange as any).timeoutId = setTimeout(() => {
      searchValue(name, value);
    }, debounceTime);
  };

  const handleModalClose = () => setShowModal(!showModal);
  const handleSaveChanges = async () => {
    setShowModal(false);
    if (deleteID) {
      const url = `${ARCHIVE_SALARY_BENEFITS}/${deleteID}`;
      const response = await ApiCall.getService(url, "GET", M_MASTER_DATA);
      if (response?.status === 200) {
        deleteFilter(deleteID);
        CustomNotify({ type: "success", message: response.message });
      }
    }
  };

  const handleSubmit = (e: any, clear: boolean = false) => {
    e.preventDefault();
    handleSubmitAction(clear);
  };

  const handleDelete = (SalaryBenefits: SalaryBenefits | any) => {
    setShowModal(true);
    setDeleteId(SalaryBenefits.id);
  };

  const handleEdit = (SalaryBenefits: SalaryBenefits | any) => {
    if (SalaryBenefits.id) {
      navigate(`${PATH_SALARY_BENEFITS_CREATE}/${SalaryBenefits.id}`);
    }
  };

  const userData = useSelector(selectAuth);
  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Salary benefits",
          read: true,
        },
      ]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <div className="search-bar">
        <Title title={t("Manage salary benefits")} />
        <div className="row align-items-center">
          <div className="col-8">
            <Search
              className="form-control"
              handleChange={handleSearchChange}
              placeholder={t("Salary benefit name")}
              name="name"
              value={searchData.name}
            />
          </div>
          <div className="col-3">
            <div className="d-flex align-items-center mt-1">
              <ResetBtn handleResetClick={(e) => handleSubmit(e, true)} />
              <SearchBtn handleSearchClick={handleSubmit} />
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="position-relative tableMainWrapper">
          <div className="">
            <AccessControl
              requiredPermissions={[
                {
                  permission: "Salary benefits",
                  create: true,
                },
              ]}
              override={userData.isSuperAdmin}
            >
              <div className="row">
                <div className="ManageCreateBtn">
                  <LinkTo
                    pagelink={PATH_SALARY_BENEFITS_CREATE}
                    title={t("Create salary benefits")}
                    icon={faPlus}
                  />
                </div>
              </div>
            </AccessControl>
          </div>
          <div className="table-responsive tableSection">
            <div className="TableHeight">
              <DynamicTableStructure
                isAction
                headers={SalaryBenefitHeader}
                data={salaryBenefitObject}
                handleEdit={handleEdit}
                handleArchive={handleDelete}
                permission={"Salary benefits"}
              />
            </div>
            <div
              className="pagination justify-content-center align-items-center"
              style={{ paddingTop: "0.25vw" }}
            >
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={onPageChange}
              />
            </div>
          </div>
        </div>
        <div style={{ padding: "1vw 0" }}>
          <Link
            to={PATH_MASTER_DATA}
            className="text-uppercase back-btn text-decoration-underline"
          >
            {t("Back")}
          </Link>
        </div>
        <ModalPopup
          show={showModal}
          onHide={handleModalClose}
          title={t("Archive confirmation")}
          body={t("Are you sure want to archive?")}
          onCloseButtonClick={handleModalClose}
          onConfirmButtonClick={handleSaveChanges}
          closeTitle={t("No")}
          confirmTitle={t("Yes")}
        />
      </div>
    </AccessControl>
  );
};
export default ManageSalaryBenefitPage;
