import React, { Suspense, useState, useEffect } from "react";
import ManageVacancies from "./ManageVacancies";
import { M_MASTER_DATA } from "constants/Constants";
import { VACANCIES } from "routes/ApiEndpoints";
import { ApiCall } from "services/ApiServices";
import { useNavigate } from "react-router-dom";
import { getPCAndItsParameters } from "./utils/Utils";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";

const Vacancies: React.FC<any> = () => {
  const navigate = useNavigate();
  const userData = useSelector(selectAuth);
  const [state, setState] = useState({
    data: [],
    selected: {
      company: null,
      project: null,
      function: [],
      business_unit: userData?.businessUnit?.map((item: any) => item?.value),
      vacancy_status: null,
      hotlist: 0,
    },
    options: {
      Company: [],
      Project: [],
      PcFunction: [],
      BusinessUnit: [],
      VacancyStatus: [
        {value: "open", label: "Open", name: "Open"},
        {value: "closed", label: "Closed", name: "Closed"},
      ],
    },
    initialCall: true,
    currentPage: 1,
    totalPages: 1,
    itemsPerPage: 10,
    popup: {
      delete: {
        show: false,
        data: {}
      }
    },
    refresh: true
  })

  const handlePageChange = (pageNumber: number) => {
    setState((prev: any) => ({ ...prev, currentPage: pageNumber }));
  };

  useEffect(() => {
    const postData = {
      page: state?.currentPage,
      nOR: state?.itemsPerPage,
      initialCall: state?.initialCall,
      search: state?.selected,
      optionsPayload: ["Company", "Project", "BusinessUnit", "ParitairComitee"],
    };
    getVacanciesData(postData);
  }, [state?.currentPage, state?.refresh]);

  const getVacanciesData = async (postData: any) => {
    const response = await ApiCall.service(VACANCIES, "POST", {...postData, user_id: userData.userId}, true, M_MASTER_DATA);
    if (response?.status === 200) {      
      let data: any = { ...state, data: response?.data ?? [], initialCall: false, totalPages: response?.totalPages };
      if (response?.options) {
        data.options = {
          ...state.options,
          Company: response?.options?.Company,
          Project: response?.options?.Project,
          BusinessUnit: response?.options?.BusinessUnit,
          PcFunction: getPCAndItsParameters(response?.options)?.PcFunctions ?? []
        }
      }
      setState(data);
    }
    return response ?? [];
  };

  const handleSearchSubmit = (clear: boolean) => {
    if (clear) {
      setState((prev: any) => ({
        ...prev, 
        selected: {
          company: null,
          project: null,
          function: [],
          business_unit: [],
          vacancy_status: null,
          hotlist: 0,
        },
        refresh: !state?.refresh
      }));
    } else {
      setState((prev: any) => ({...prev, refresh: !state?.refresh}));
    }
  };

  return (
    <>
      <Suspense fallback={<div className="text-center text-danger"></div>}>
        <ManageVacancies
          state={state}
          onPageChange={handlePageChange}
          handleSubmitAction={handleSearchSubmit}
          handleGetVacany={getVacanciesData}
          setState={setState}
        />
      </Suspense>
    </>
  );
};

export default Vacancies;
