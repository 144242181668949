import React from "react";
import { FormElementRender } from "../formElementRender/Index";
import Button from "components/atoms/Button";
import CloseFile from "static/images/CloseFile";
import { useFormContext } from "utils/multiTabForm/context/Context";
import EditIcon from "static/images/EditIcon";
import DeleteIcon from "static/images/DeleteIcon";
import { t } from "pages/microservices/masterData/translation/Translation";
import CustomNotify from "components/atoms/CustomNotify";

interface FormElement {
  dynamic?: boolean;
}

interface DynamicForm {
  form: Record<string, FormElement>;
  dynamic?: boolean;
}
interface DynamicTableForm {
  form: Record<string, FormElement>;
  dynamicTable?: boolean;
}
export const renderFields = (
  key: any,
  fieldIndex: any,
  value: any,
  changeHandler: any,
  dynamic?: boolean,
  formIndex?: number | string
) => {
  return (
    <React.Fragment key={fieldIndex}>
      {FormElementRender(
        value,
        (
          e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
          editor: any
        ) => {
          changeHandler(e, editor, value, dynamic, key, formIndex);
        }
      )}
    </React.Fragment>
  );
};

export const IterateFields = (
  dynamicForm: DynamicForm[],
  key: string,
  changeHandler: any,
  removeDynamicForm: any,
  dynamic?: boolean,
  formIndex?: string | number,
  table?: boolean
) => {
  return (
    <React.Fragment key={`${key}-${formIndex}`}>
      <div key={key}>
        <form
          className={`row`}
          style={{
            borderRadius: !table && formIndex !== "0" ? "1vw" : "",
            padding: !table && formIndex !== "0" ? "1vw" : "",
            marginBottom: !table && formIndex !== "0" ? "1vw" : "",
          }}
        >
          {!table && formIndex !== "0" && (
            <div className="table-action-icons">
              <span
                title="Delete"
                onClick={() => removeDynamicForm(key, formIndex)}
                className="table-action-btn float-end"
              >
                <CloseFile />
              </span>
            </div>
          )}
          {Object.entries(dynamicForm).map(([fieldIndex, value]) => {
            // if((value?.dynamic)){
            //   // return IterateFields(
            //   //   value,
            //   //   key,
            //   //   changeHandler,
            //   //   removeDynamicForm,
            //   //   dynamic,
            //   //   formIndex
            //   // );
            // }else
            //   {
            return renderFields(
              key,
              fieldIndex,
              value,
              changeHandler,
              dynamic,
              formIndex
            );
            // }
          })}
        </form>
      </div>
    </React.Fragment>
  );
};

interface FormElement {
  dynamic?: boolean;
}

interface DynamicForm {
  form: Record<string, FormElement>;
  dynamic?: boolean;
}

export const RenderDynamicComponent = (
  dynamicForm: DynamicForm,
  key: string,
  changeHandler: any,
  addDynamicForm: any,
  removeDynamicForm: any,
  addTreeData?: any,
  dynamic?: boolean,
  formKey?: string | number
) => {
  const { state, dispatch } = useFormContext();
  
  return (
    <React.Fragment key={key}>
      {Object.entries(dynamicForm).map(([subKey, value]) => {
        if (value?.dynamic) {
          return RenderDynamicComponent(
            value as DynamicForm,
            key,
            changeHandler,
            addDynamicForm,
            removeDynamicForm,
            addTreeData,
            true,
            subKey
          );
        } else {
          if (Array.isArray(value)) {
            return IterateFields(
              value,
              key,
              changeHandler,
              removeDynamicForm,
              dynamic,
              formKey ?? subKey
            );
          } else {
            return renderFields(key, subKey, value, changeHandler, dynamic);
          }
        }
      })}

      <div className="col-12">
        <div className="row" style={{ padding: "1vw 0" }}>
          <div className="col-md-12">
            {state.tabs[1].id === "categoryAndFunction" &&
            state.currentTab === 1 ? (
              <Button
                title="Save"
                handleClick={(e) => addTreeData(state?.form, e, key)}
                className="form-button px-2 float-end"
              />
            ) : (
              <Button
                title="+ Add another"
                handleClick={(e) => addDynamicForm(e, key)}
                className="form-button px-2 float-end"
              />
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export const RenderTableComponent = (
  DynamicTableForm: DynamicTableForm,
  key: string,
  changeHandler: any,
  addDynamicTable: any,
  removeDynamicForm: any,
  manageIndex?: any,
  dynamic?: boolean,
  formKey?: string | number
) => {
  const { state, dispatch } = useFormContext();
  let currentTab = state?.currentTab;
  const data: any = state?.form[currentTab];
  const tableHeader = data[key]?.header;
  let dynamicTable = data[key]?.dynamicTable;
  const body = data[key]?.form;
  const isEdit = data[key]?.isEdit;
  const manageTable = (e?: any, key?: any, dynamicIndex?: any) => {
    const res = body[data[key]?.currentFormIndex].map((eachItem: any) => {
      if (eachItem?.required === true || eachItem?.validate == true) {
        return eachItem.value !== "";
      }
      return true;
    });
    if (!res.includes(false)) {
      addDynamicTable(e, key);
      manageIndex(body?.length?.toString(), "", key);
    } else {
      CustomNotify({ type: "warning", message: "All Fields are required" });
    }
    // if (data[dynamicIndex]?.currentFormIndex)
    //   manageIndex(body?.length?.toString(), "", key);

    // addDynamicTable(e, key);
  };

  return (
    <React.Fragment key={key}>
      {Object.entries(DynamicTableForm).map(([subKey, value]) => {
        if (Array.isArray(value)) {
          let data: any = state?.form[currentTab];

          /**
           * Render the latest index of dynamic form state
           */
          if (subKey == data[key]?.currentFormIndex) {
            return IterateFields(
              value,
              key,
              changeHandler,
              removeDynamicForm,
              dynamic,
              formKey ?? subKey,
              true
            );
          }
        } else {
          return renderFields(key, subKey, value, changeHandler, dynamic);
        }
      })}

      <div className="col-12">
        <div className="row">
          <div
            className="col-md-12"
            style={{ paddingTop: "1vw", paddingBottom: "1vw" }}
          >
            {dynamicTable && (
              <Button
                title={`${isEdit ? t("Save") : t("+ Add another")}`}
                handleClick={(e) => manageTable(e, key, key)}
                className="form-button px-2 float-end"
              />
            )}
          </div>
          <div className="col-12">
            <div className="table-responsive tableSection p-0 border-0 rounded-0">
              {tableHeader?.length > 0 && body?.length > 1 && (
                <table className="table table-hover">
                  <thead className="TableHeader">
                    <tr>
                      {tableHeader?.map((column: any, index: any) => (
                        <th style={{ width: "20%" }} key={index}>
                          {column.name}
                        </th>
                      ))}
                      <th className="col-md-3">{t("Actions")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {body
                      ?.slice(0, body?.length - 1)
                      ?.map((row: any, index: any) => (
                        <tr key={index}>
                          {
                            <>
                              {row.map((cell: any, cellIndex: any) => {
                                return (
                                  <td
                                    key={cellIndex}
                                    className={`${cell?.className} align-middle`}
                                  >
                                    {/* {cell.value?.label?():(cell.value)} */}
                                    {cell.value?.label ?? cell.value}
                                  </td>
                                );
                              })}
                              <td className="table-action-icons">
                                <span
                                  className="cursor-pointer table-action-btn me-2"
                                  onClick={() =>
                                    manageIndex(index, "edit", key)
                                  }
                                >
                                  <EditIcon />
                                </span>
                                <span
                                  className="cursor-pointer table-action-btn"
                                  onClick={() =>
                                    manageIndex(index, "delete", key)
                                  }
                                >
                                  <DeleteIcon />
                                </span>
                              </td>
                            </>
                          }
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
