import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Button from "components/atoms/Button";
import ModalPopup from "components/atoms/ModalPopup";
import { ApiCall } from "services/ApiServices";
import CustomNotify from "components/atoms/CustomNotify";
import { t } from "pages/microservices/masterData/translation/Translation";
import { PATH_CREATE_CAR, PATH_EDIT_CAR } from "constants/Paths";
import TableActions from "components/organism/Actions/TableAction";
import { useNavigate } from "react-router-dom";
import {
  CAR_DELETE,
  FLEET_EMP_DELETE,
  FLEET_EMP_CREATE,
} from "routes/ApiEndpoints";
import { ERROR, M_MASTER_DATA } from "constants/Constants";
import Pagination from "components/atoms/Pagination";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import FilterElementsRender from "../FilterElement";
import { fetchOptions } from "../utils/FetchOptions";
import { getStatusColor, getTooltipMessage } from "services/util/UtilService";
import FleetEmployeeModal from "../FleetEmployeeModal";
import {
  validateForm,
  validateRequired,
} from "services/validation/ValidationService";
import { IconData } from "services/IconData";
import Title from "components/atoms/Title";
import LabelField from "components/atoms/LabelField";
import ResetBtn from "components/atoms/ResetBtn";
import SearchBtn from "components/atoms/SearchBtn";
import LinkTo from "components/atoms/LinkTo";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

interface ManageCarProps {
  data?: any;
  fleetProps?: any;
  handleData: any;
  currentPage: number;
  totalPages: number;
  onPageChange: (pageNumber: number) => void;
  itemsPerPage: number;
  searchValue: (name: string, value: string | number) => void;
  handleSubmitAction: (clear: boolean) => void;
  searchData: any;
  handleGetVacany: any;
}

interface CompanyItem {
  value: number;
  label: string;
}

const ManageCar: React.FC<ManageCarProps> = ({
  data,
  fleetProps,
  handleData,
  currentPage,
  totalPages,
  onPageChange,
  itemsPerPage,
  searchValue,
  handleSubmitAction,
  searchData,
  handleGetVacany,
}) => {
  const [id, setId] = useState<number | undefined>(undefined);
  const [showModal, setShowModal] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    businessUnit: [],
    status: [],
    carType: [],
    location: [],
  });
  const { id: routeId, userType: userType } = useParams();
  const [showFleetEmployeeModal, setShowFleetEmployeeModal] = useState(false);
  const [fleetData, setFleetData] = useState({
    fromDate: "",
    toDate: "",
    type: "car",
    userId: routeId,
    fleetId: "",
    status: false,
    purchaseDate: "",
    userType,
  });

  const navigate = useNavigate();

  const handleSearchChange = (event: any, name: string, type: string) => {
    switch (type) {
      case "text":
      case "date":
        return searchValue(name, event.target.value);

      case "select":
        return searchValue(name, event.value);
    }
  };

  /** Adding fleet material to employee */
  const handleAddFleetEmp = async () => {
    if (validationFunction()) {
      try {
        const response = await ApiCall.service(
          FLEET_EMP_CREATE,
          "POST",
          fleetData,
          false,
          M_MASTER_DATA
        );
        if (response.status == 200) {
          CustomNotify({ type: "success", message: response.message });
          setShowFleetEmployeeModal(false);
          handleGetVacany({
            page: 1,
            nOR: 10,
            type: "car",
            userId: routeId ?? null,
            userType: userType ?? null,
            search: {
              company: "",
              businessUnit: "",
              brand: "",
              model: "",
              carType: "",
              licencePlate: "",
              driver: "",
              chassisNumber: "",
              contractStartDate: "",
              contractEndDate: "",
              fromDate: "",
              toDate: "",
              status: 1,
              contractNumber: "",
              location: "",
            },
          });
        } else {
          CustomNotify({ type: "warning", message: response.message });
        }
      } catch (error) {
        console.log("Error");
      }
    }
  };

  const handleFleetEmpModalClose = () => {
    setFleetData({
      fromDate: "",
      toDate: "",
      type: "car",
      userId: routeId,
      fleetId: "",
      status: false,
      purchaseDate: "",
      userType,
    });
    setShowFleetEmployeeModal(false);
  };

  const handleRemoveCar = async () => {
    try {
      const response = await ApiCall.service(
        FLEET_EMP_DELETE,
        "POST",
        { fleetId: fleetData.fleetId, userId: routeId, type: "car" },
        false,
        M_MASTER_DATA
      );
      if (response.status == 200) {
        CustomNotify({ type: "success", message: response?.message });
        setShowFleetEmployeeModal(false);
        handleGetVacany({
          page: 1,
          nOR: 10,
          type: "car",
          userId: routeId ?? null,
          userType: userType ?? null,
          search: {
            company: "",
            businessUnit: "",
            brand: "",
            model: "",
            carType: "",
            licencePlate: "",
            driver: "",
            chassisNumber: "",
            contractStartDate: "",
            contractEndDate: "",
            fromDate: "",
            toDate: "",
            status: 1,
            contractNumber: "",
            location: "",
          },
        });
      } else {
        CustomNotify({ type: "error", message: response?.message });
      }
    } catch (error) {
      console.log("Error");
    }
  };

  const handleLink = (id: number) => {
    setShowFleetEmployeeModal(true);
    setFleetData((prevState: any) => ({ ...prevState, fleetId: id }));
  };

  const handleRemove = (id: any) => {
    const filteredData = data.filter(
      (eachItem: any) => eachItem.status == 3 && eachItem.id == id
    );
    if (filteredData.length > 0) {
      const { fromDate, toDate, purchaseDate } = filteredData[0];
      setFleetData({
        fromDate: fromDate,
        toDate: toDate,
        type: "car",
        userId: routeId,
        fleetId: id,
        status: true,
        purchaseDate,
        userType,
      });
    } else {
      setFleetData((prevState: any) => ({ ...prevState, fleetId: id }));
    }
    setShowFleetEmployeeModal(true);
  };

  const handleSaveChanges = async () => {
    setShowModal(false);
    let postData = {
      id: id,
    };

    if (id) {
      const response = await ApiCall.service(
        CAR_DELETE,
        "POST",
        postData,
        false,
        M_MASTER_DATA
      );

      if (response?.status === 200) {
        CustomNotify({ type: "success", message: response.message });
        handleGetVacany({
          page: 1,
          nOR: 10,
          type: "car",
          userId: routeId ?? null,
          userType: userType ?? null,
          search: {
            company: "",
            businessUnit: "",
            brand: "",
            model: "",
            carType: "",
            licencePlate: "",
            driver: "",
            chassisNumber: "",
            contractStartDate: "",
            contractEndDate: "",
            fromDate: "",
            toDate: "",
            status: "",
            contractNumber: "",
            location: "",
          },
        });
      } else {
        CustomNotify({ type: "error", message: response.message });
      }
    }
  };

  const [error, setErrors] = useState({
    fromDate: "",
    toDate: "",
  });

  const validationFunction = (
    name: any = null,
    value: any = null,
    isSingleFieldValidation = false
  ) => {
    const validationRules: any = {
      fromDate: [validateRequired],
      toDate: [validateRequired],
    };
    const validationErrors: any = validateForm(
      { ...fleetData, [name]: value },
      isSingleFieldValidation ? validationRules[name] : validationRules
    );
    if (isSingleFieldValidation) {
      setErrors((prevErrors: any) => {
        if (validationErrors.hasOwnProperty(name)) {
          return { ...prevErrors, [name]: validationErrors[name] };
        }
        const { [name]: ty, ...remains } = prevErrors;
        return { ...remains };
      });
    } else {
      setErrors(validationErrors);
      if (Object.keys(validationErrors).length > 0) {
        return false;
      } else {
        const fromDate = new Date(fleetData.fromDate);
        const lastDate = new Date(fleetData.toDate);
        const purchaseDate = new Date(fleetData.purchaseDate);
        if (fromDate > lastDate) {
          setErrors((prevState: any) => ({
            ...prevState,
            fromDate: "From date should be on or before to date",
          }));
          return false;
        }
        if (fromDate < purchaseDate) {
          setErrors((prevState: any) => ({
            ...prevState,
            fromDate: "From date should be on or after purchase date",
          }));
          return false;
        }
        return true;
      }
    }
  };

  useEffect(() => {
    getFilterData();
  }, []);

  const getFilterData = async () => {
    try {
      const filterData = await fetchOptions();
      if (filterData?.status == 200) {
        setFilterOptions((prevState: any) => ({
          ...prevState,
          businessUnit: filterData.data.businessUnit,
          status: filterData.data.status,
          carType: filterData.data.carType,
          location: filterData.data.location,
        }));
      } else {
        CustomNotify({ type: "error", message: filterData.message });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleModalClose = () => setShowModal(!showModal);

  const handleSubmit = (e: any, clear: boolean = false) => {
    e.preventDefault();
    handleSubmitAction(clear);
  };

  const handleEdit = (id: number) => {
    navigate(`${PATH_EDIT_CAR}/${id}`);
  };

  const handleDelete = (id: number) => {
    setShowModal(true);
    setId(id);
  };
  const userData = useSelector(selectAuth);

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Car",
          read: true,
        },
      ]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <>
        <div className="search-bar">
          <Title title={t("Manage car")} />
          {fleetProps !== "fleet" ? (
            <div className="row">
              <FilterElementsRender
                data={searchData?.brand}
                searchclass="searchSpacing"
                type="text"
                handleChange={(event) =>
                  handleSearchChange(event, "company", "text")
                }
                value={searchData?.company}
                placeHolder={"Company"}
                name="company"
              />

              <FilterElementsRender
                data={filterOptions.businessUnit}
                type="multi-select"
                handleChange={(event) =>
                  handleSearchChange(event, "businessUnit", "select")
                }
                value={searchData?.businessUnit}
                placeHolder={"Business unit"}
                name="businessUnit"
              />
              <FilterElementsRender
                data={filterOptions.carType}
                type="multi-select"
                handleChange={(event) =>
                  handleSearchChange(event, "carType", "select")
                }
                value={searchData?.carType}
                placeHolder={"Car type"}
                name="carType"
              />

              <FilterElementsRender
                data={searchData?.brand}
                type="text"
                handleChange={(event) =>
                  handleSearchChange(event, "brand", "text")
                }
                value={searchData?.brand}
                placeHolder={"Brand"}
                name="brand"
              />
              <FilterElementsRender
                data={searchData?.model}
                type="text"
                handleChange={(event) =>
                  handleSearchChange(event, "model", "text")
                }
                value={searchData?.model}
                placeHolder={"Model"}
                name="model"
              />

              <FilterElementsRender
                data={searchData?.licencePlate}
                type="text"
                handleChange={(event) =>
                  handleSearchChange(event, "licencePlate", "text")
                }
                value={searchData?.licencePlate}
                placeHolder={"Licence plate"}
                name="licencePlate"
              />
              <FilterElementsRender
                data={searchData?.driver}
                type="text"
                handleChange={(event) =>
                  handleSearchChange(event, "driver", "text")
                }
                value={searchData?.driver}
                placeHolder={"Driver"}
                name="driver"
              />
              <FilterElementsRender
                data={searchData?.chassisNumber}
                type="text"
                handleChange={(event) =>
                  handleSearchChange(event, "chassisNumber", "text")
                }
                value={searchData?.chassisNumber}
                placeHolder={"Chassis number"}
                name="chassisNumber"
              />
              <FilterElementsRender
                searchclass="searchSpacing"
                data={searchData?.contractNumber}
                type="text"
                handleChange={(event) =>
                  handleSearchChange(event, "contractNumber", "text")
                }
                value={searchData?.contractNumber}
                placeHolder={"Contract number"}
                name="contractNumber"
              />

              <FilterElementsRender
                data={searchData?.contractStartDate}
                type="date"
                handleChange={(event) =>
                  handleSearchChange(event, "contractStartDate", "date")
                }
                value={searchData?.contractStartDate}
                placeHolder={"Contract start date"}
                name="contractStartDate"
              />
              <FilterElementsRender
                data={searchData?.contractEndDate}
                type="date"
                handleChange={(event) =>
                  handleSearchChange(event, "contractEndDate", "date")
                }
                value={searchData?.contractEndDate}
                placeHolder={"Contract end date"}
                name="contractEndDate"
              />
              <FilterElementsRender
                data={searchData?.fromDate}
                type="date"
                handleChange={(event) =>
                  handleSearchChange(event, "fromDate", "date")
                }
                value={searchData?.fromDate}
                placeHolder={"From date"}
                name="fromDate"
              />
              <FilterElementsRender
                data={searchData?.toDate}
                type="date"
                handleChange={(event) =>
                  handleSearchChange(event, "toDate", "date")
                }
                value={searchData?.toDate}
                placeHolder={"To date"}
                name="toDate"
              />
              <FilterElementsRender
                data={filterOptions.location}
                type="multi-select"
                handleChange={(event) =>
                  handleSearchChange(event, "location", "select")
                }
                value={searchData?.location}
                placeHolder={"Location"}
                name="location"
              />
              <FilterElementsRender
                data={filterOptions.status}
                type="multi-select"
                handleChange={(event) =>
                  handleSearchChange(event, "status", "select")
                }
                value={searchData?.status}
                placeHolder={"Status"}
                name="status"
              />
              <div className="col-3 ms-auto">
                <div className="d-flex align-items-center justify-content-end">
                  <ResetBtn handleResetClick={(e) => handleSubmit(e, true)} />
                  <SearchBtn handleSearchClick={handleSubmit} />
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>

        <div className="position-relative tableMainWrapper">
          {fleetProps !== "fleet" && (
            <div className="">
              <AccessControl
                requiredPermissions={[
                  {
                    permission: "Car",
                    create: true,
                  },
                ]}
                override={userData.isSuperAdmin}
              >
                <div className="row">
                  <div className="ManageCreateBtn">
                    <LinkTo
                      pagelink={PATH_CREATE_CAR}
                      title={t("Create car")}
                      icon={faPlus}
                    />
                  </div>
                </div>
              </AccessControl>
            </div>
          )}
          <div className="table-responsive manage-projects tableSection">
            <div className="manageCarHeight">
              <table className="table table-hover">
                <thead>
                  <tr className="TableHeader">
                    <th style={{ width: "10%" }}>{t("Company")}</th>
                    <th style={{ width: "8%" }}>{t("Business unit")}</th>
                    <th style={{ width: "8%" }}>{t("Car type")}</th>
                    <th style={{ width: "8%" }}>
                      {t("Brand")}
                      <br />
                      {t("Model ")}
                    </th>
                    <th style={{ width: "9%" }}>{t("Licence plate")}</th>
                    <th style={{ width: "10%" }}>{t("Driver")}</th>
                    <th style={{ width: "10%" }}>{t("Chassis number")}</th>
                    <th style={{ width: "10%" }}>{t("Contract number")}</th>
                    <th style={{ width: "13%" }}>
                      {t("Contract start date ")}
                      <br />
                      {t("Contract end date ")}
                    </th>
                    <th style={{ width: "10%" }}>{t("From")}</th>
                    <th style={{ width: "10%" }}>{t("To ")}</th>
                    <th style={{ width: "10%" }}>{t("Location")}</th>
                    <th style={{ width: "10%" }}>{t("Status")}</th>
                    <th style={{ width: "10%" }}>
                      <AccessControl
                        requiredPermissions={[
                          {
                            permission: "Car",
                            update: true,
                            delete: true,
                          },
                        ]}
                        actions={true}
                        strict={false}
                        override={userData.isSuperAdmin}
                      >
                        {t("Action")}
                      </AccessControl>
                    </th>
                  </tr>
                </thead>
                {/* if isStatus=false then enable all the options else give option for only status is 3 for other things disable that */}
                <tbody>
                  {data && data.length > 0 ? (
                    data.map((record: any, key: any) => {
                      return (
                        <tr key={key}>
                          <td className="align-middle">
                            {record.ownerCompany}
                          </td>
                          {record.businessUnit &&
                          record.businessUnit.length > 0 ? (
                            <td className="align-middle">
                              {record.businessUnit
                                .map((fun: any) => fun.label)
                                .join(", ")}
                            </td>
                          ) : (
                            <td></td>
                          )}
                          <td className="align-middle">{record.type?.label}</td>
                          <td className="align-middle">
                            {record.brand}
                            <br />
                            {record.model}
                          </td>
                          <td className="align-middle">
                            {record.licencePlate}
                          </td>
                          <td className="align-middle">{record.driver}</td>
                          <td className="align-middle">
                            {record.chassisNumber}
                          </td>
                          <td className="align-middle">
                            {record.contracNumber}
                          </td>
                          <td className="align-middle">
                            {record.startDate}
                            <br />
                            {record.endDate}
                          </td>
                          <td className="align-middle">
                            {record.guaranteeStarts}
                          </td>
                          <td className="align-middle">
                            {record.guaranteeEnds}
                          </td>
                          <td className="align-middle">
                            {record.location?.label}
                          </td>
                          <td className="align-middle">
                            <div
                              className={getStatusColor(record.status)}
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title={getTooltipMessage(record.status)}
                            ></div>
                          </td>
                          <td className="align-middle">
                            <TableActions
                              isAction={true}
                              {...(fleetProps !== "fleet"
                                ? {
                                    handleEdit: handleEdit,
                                    handleArchive: handleDelete,
                                  }
                                : record.status == 1
                                ? { handleAddFleet: handleLink }
                                : { handleRemove: handleRemove })}
                              value={record.id}
                              permission={"Car"}
                              isDisable={
                                record.isStatus == false
                                  ? false
                                  : record.status == 1
                                  ? true
                                  : false
                              }
                              signature={record.project_pdf_path}
                            />
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={15} className="text-center">
                        {t("No records found")}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div
              className="pagination justify-content-center align-items-center"
              style={{ paddingTop: "0.25vw" }}
            >
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={onPageChange}
              />
            </div>
          </div>
        </div>
        <ModalPopup
          show={showModal}
          onHide={handleModalClose}
          title={t("Archive confirmation")}
          body={t("Are you sure want to archive?")}
          onCloseButtonClick={handleModalClose}
          onConfirmButtonClick={handleSaveChanges}
          closeTitle="No"
          confirmTitle="Yes"
        />
        {showFleetEmployeeModal && (
          <FleetEmployeeModal
            handleClose={handleFleetEmpModalClose}
            data={fleetData}
            errors={error}
            setFleetData={setFleetData}
            handleSave={handleAddFleetEmp}
            handleRemove={handleRemoveCar}
            validationFn={validationFunction}
          />
        )}
      </>
    </AccessControl>
  );
};
export default ManageCar;
