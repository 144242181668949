import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import Button from "components/atoms/Button";
import React, { useState, ChangeEvent, useEffect } from "react";
import { t } from "../translation/Translation";
import {
  validateForm,
  validateRequired,
} from "services/validation/ValidationService";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";

import { ApiCall } from "services/ApiServices";
import {
  COMPETENCE_CREATE,
  COMPETNCE_EDIT,
  GET_CATOGRY_BY_ID,
  GET_COMPETENCE_OPTIONS,
  GET_FUNCTION_BY_CATEGORY_ID,
} from "routes/ApiEndpoints";
import { M_MASTER_DATA } from "constants/Constants";
import CustomNotify from "components/atoms/CustomNotify";
import { Link, useNavigate, useParams } from "react-router-dom";
import { PATH_COMPETENCE_MANAGE } from "constants/Paths";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { Option } from "components/common/CommonInterfaces";
import CloseFile from "static/images/CloseFile";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import TitleAtom from "components/atoms/Title";
import AddPlusIcon from "static/images/AddPlusIcon";

interface ValidationRules {
  [key: string]: Function[];
}
interface Payload {
  id: string | number | any;
  pcId?: string | number;
}

function CreateCompetence() {
  const [formData, setFormData] = useState<any>({
    competenceName: "",
    competenceType: [""],
    paritairComitee: null,
    category: null,
    function: [""],
  });
  const [pcOption, setPcOptions] = useState<Option[]>();
  const [categoryOption, setCategoryOption] = useState<Option[]>();
  const [functionOption, setFunctionOption] = useState<Option[]>();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [errors, setErrors] = useState<any>({
    competenceName: "",
    competenceType: [""],
    paritairComitee: "",
    function: "",
  });

  useEffect(() => {
    fetchOptions();
  }, []);

  const fetchOptions = async () => {
    try {
      const response = await ApiCall.getService(
        GET_COMPETENCE_OPTIONS,
        "GET",
        M_MASTER_DATA
      );
      if (response?.status === 200) {
        setPcOptions(response?.data?.pc);
        setCategoryOption(response?.data?.category);
        setFunctionOption(response?.data.function);
        if (id) {
          fetchData(response?.data);
        }
      }
    } catch (error) {}
  };

  const fetchData = async (optiondata: any) => {
    if (id) {
      try {
        const editData = { id: id };
        const response = await ApiCall.service(
          COMPETNCE_EDIT,
          "POST",
          editData,
          false,
          M_MASTER_DATA
        );
        if (response?.status === 200) {
          const {
            competenceName,
            competenceType,
            paritairComitee,
            category,
            function: functions, // Renamed to 'functions' to avoid reserved keyword
          } = response.data;

          // const paritairComiteeOption = optiondata.pc.find(
          //   (item: any) => item.value === paritairComitee
          // );
          // const categoryOption = optiondata.category.find(
          //   (item: any) => item.value === category
          // );
          // const functionOption = functions.map((func: any) => ({
          //   label: func.label,
          //   value: func.value,
          // }));

          setFormData({
            id,
            competenceName,
            competenceType: competenceType.map(
              (type: any) => type.competence_type
            ),
            paritairComitee: paritairComitee || null,
            category: category || null,
            function: functions || null,
          });
        }
      } catch (error) {
        console.error(error);
      }
    }
  };
  const changeHandler = (e: ChangeEvent<HTMLInputElement>, index?: number) => {
    const { name, value } = e.target;
    const updatedFormData = { ...formData };
    if (index !== undefined && index !== null) {
      updatedFormData.competenceType[index] = value;
    } else {
      updatedFormData[name] = value;
    }
    setFormData(updatedFormData);
    validateInput(index === 0 || index ? "competenceType" : name, value, index);
  };

  const handleSelectChange = async (selectedOption: Option, name: string) => {
    const id = selectedOption.value;
    // Initialize payload with selected option id
    const payload: Payload = {
      id: id,
    };
    if (name === "paritairComitee") {
      setFormData((prevData: any) => ({ ...prevData, ["category"]: null }));
      setFormData((prevData: any) => ({ ...prevData, ["function"]: null }));
      const response = await ApiCall.service(
        GET_CATOGRY_BY_ID,
        "POST",
        payload,
        false,
        M_MASTER_DATA
      );
      if (response?.status === 200) {
        setCategoryOption(response?.data?.categories);
        setFunctionOption(response?.data?.functions);
      }
    }

    if (name === "category") {
      setFormData((prevData: any) => ({ ...prevData, ["function"]: null }));
      const paritairComiteeId = formData.paritairComitee?.value;
      if (paritairComiteeId) {
        payload.pcId = paritairComiteeId;
      }

      const response = await ApiCall.service(
        GET_FUNCTION_BY_CATEGORY_ID,
        "POST",
        payload,
        false,
        M_MASTER_DATA
      );
      if (response?.status === 200) {
        setFunctionOption(response?.data);
      }
    }
    setFormData((prevData: any) => ({ ...prevData, [name]: selectedOption }));
    validateInput(name, selectedOption);
  };

  const validateInput = (name: string, value: any, index?: number) => {
    if (name === "competenceType" && (index === 0 || index)) {
      const errorsArray: any = [...errors.competenceType];
      errorsArray[index] = value.trim() !== "" ? "" : "This field is required";
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        competenceType: errorsArray,
      }));
      return value.trim() !== "";
    }
    const validationRules: ValidationRules = { [name]: [validateRequired] };
    const validationErrors = validateForm(
      { ...formData, [name]: value },
      validationRules
    );
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [name]: validationErrors[name],
    }));
    return Object.keys(validationErrors).length === 0;
  };

  const handleAddType = (indexToAdd: number) => {
    const lastFieldIndex = formData.competenceType.length - 1;
    const lastFieldValue = formData.competenceType[lastFieldIndex];
    const isValid = validateInput(
      "competenceType",
      lastFieldValue,
      lastFieldIndex
    );
    if (isValid) {
      setFormData((prevState: any) => ({
        ...prevState,
        competenceType: [...prevState.competenceType, ""],
      }));
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        competenceType: [...prevErrors.competenceType, ""],
      }));
    }
  };

  const handleRemoveType = (indexToRemove: number) => {
    setFormData((prevState: any) => ({
      ...prevState,
      competenceType: prevState.competenceType.filter(
        (item: any, index: number) => index !== indexToRemove
      ),
    }));
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      competenceType: prevErrors.competenceType.filter(
        (item: any, index: number) => index !== indexToRemove
      ),
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    let isValid = true;
    Object.keys(formData).map((key) => {
      if (key === "category") {
        return; // Skip validation for "category"
      }

      if (key !== "competenceType") {
        isValid = validateInput(key, formData[key], 0);
      } else {
        isValid = validateInput(key, formData[key][0], 0);
      }
    });

    if (isValid) {
      try {
        const response = await ApiCall.service(
          COMPETENCE_CREATE,
          "POST",
          formData,
          false,
          M_MASTER_DATA
        );
        if (response?.status === 200) {
          CustomNotify({
            type: "success",
            message: response.message,
          });
          navigate(PATH_COMPETENCE_MANAGE);
        } else {
          // Handle error scenario
          console.error("Error occurred while submitting the form");
        }
      } catch (error) {
        console.error("Error occurred while submitting the form:", error);
      }
    }
  };

  const permissionType = id ? "update" : "create";
  const permissionObject: any = {
    permission: "Competence",
  };

  permissionObject[permissionType] = true;
  const userData = useSelector(selectAuth);
  return (
    <AccessControl
      requiredPermissions={[permissionObject]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <>
        <div className="search-bar">
          <TitleAtom
            title={id ? t("Edit competence") : t("Create competence")}
          />
        </div>
        <form onSubmit={handleSubmit}>
          <div className="form-border">
            <div className="row">
              <div className="form-group col-md-6">
                <SelectWithSearch
                  title={t("Paritair comitee")}
                  name="paritairComitee"
                  id="paritairComitee"
                  isMandatory={true}
                  search={true}
                  options={pcOption}
                  placeHolder="Select"
                  value={formData.paritairComitee}
                  onChange={(e) => handleSelectChange(e, "paritairComitee")}
                  isMulti={false}
                  className="select-field"
                  error={errors.paritairComitee}
                />
              </div>

              <div className="form-group col-md-6">
                <SelectWithSearch
                  title={t("Category")}
                  name="category"
                  id="category"
                  isMandatory={false}
                  search={true}
                  options={categoryOption}
                  placeHolder="Select"
                  value={formData.category}
                  onChange={(e) => handleSelectChange(e, "category")}
                  isMulti={false}
                  className="select-field"
                  error={errors.category}
                />
              </div>
              <div className="form-group col-md-6">
                <SelectWithSearch
                  title={t("Function")}
                  name="function"
                  id="function"
                  isMandatory={true}
                  search={true}
                  options={functionOption}
                  placeHolder="Select"
                  value={formData.function}
                  onChange={(e) => handleSelectChange(e, "function")}
                  isMulti={true}
                  className="select-field"
                  error={errors.function}
                />
              </div>

              <div className="form-group col-md-6">
                <LabelWithInputField
                  isMandatory={true}
                  name="competenceName"
                  handleChange={(e) => changeHandler(e)}
                  value={formData.competenceName}
                  id="competenceName"
                  label={t("Name of competences")}
                  placeholder={t("Name of competences")}
                  type="text"
                  error={errors.competenceName}
                />
              </div>
            </div>

            <div className="row d-flex align-items-center">
              {formData.competenceType.map(
                (competenceType: any, index: number) => (
                  <>
                    <div
                      className="form-group col-md-6"
                      key={`competenceType-${index}`}
                    >
                      <LabelWithInputField
                        isMandatory={true}
                        name={`competenceType-${index}`}
                        handleChange={(e) => changeHandler(e, index)}
                        value={formData.competenceType[index]}
                        id={`competenceType-${index}`}
                        label={t("Type of competences")}
                        placeholder={t("Type of competences")}
                        type="text"
                        error={errors.competenceType[index]}
                      />
                    </div>
                    <div className="col-3 table-action-icons">
                      {formData.competenceType.length !== 1 && (
                        <span
                          title={t("Delete")}
                          onClick={() => handleRemoveType(index)}
                          className="table-action-btn cursor-pointer me-2"
                        >
                          <CloseFile />
                        </span>
                      )}
                      {index === formData.competenceType.length - 1 && (
                        <span
                          onClick={() => handleAddType(index)}
                          className="table-action-btn cursor-pointer"
                          title={t("Add another competences")}
                        >
                          <AddPlusIcon />
                        </span>
                      )}
                    </div>
                  </>
                )
              )}
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between my-3">
            <div className="">
              <div className="">
                <Link
                  to={PATH_COMPETENCE_MANAGE}
                  className="text-uppercase back-btn text-decoration-underline"
                >
                  {t("Back")}
                </Link>
              </div>
            </div>
            <div className="">
              <Button
                title={t("Save")}
                handleClick={handleSubmit}
                className="form-button float-end px-3 shadow-none"
              />
            </div>
          </div>
        </form>
      </>
    </AccessControl>
  );
}
export default CreateCompetence;
