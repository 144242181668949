import React from "react";
import { Link, useNavigate } from "react-router-dom";
// import "../CandidateCreation/create-candidate/css/Candidate.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { FILE_URL } from "../../routes/ApiEndpoints";
import { faArrowUpFromBracket } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import * as ENDPOINTS from "../../routes/ApiEndpoints";
import RightArrow from "static/images/RightArrow";
import AccessControl from "services/AccessControl";
import CardCircle from "components/atoms/CardCircle";
import { t } from "pages/microservices/masterData/translation/Translation";
import DownloadIcon from "static/images/downloadIcon";
import Button from "components/atoms/Button";
// import { PHONE_CALL_URL } from "routes/ApiEndpoints";

export type MatchingCandidateProfileCardType = {
  candidateId?: number | null;
  firstName: string;
  lastName: string;
  functions: any;
  mobNumber?: string;
  email?: string;
  street?: string;
  number?: string;
  box?: string;
  zipCode?: string;
  city?: string;
  picFileName?: string;
  picFilePath?: string;
  country?: string;
  cvData?: any;
  profilePicData?: any;
  isScreening?: boolean;
  source?: string;
};

export interface MatchingProfileCardProps {
  profileCardProps: MatchingCandidateProfileCardType;
}

const MatchingProfileCard: React.FC<MatchingProfileCardProps> = (props) => {
  const navigate = useNavigate();

  const functionList =
    props.profileCardProps.functions === undefined
      ? []
      : props.profileCardProps.functions;

  let address = [];

  if (props.profileCardProps.street) {
    address.push(props.profileCardProps.street);
  }
  if (props.profileCardProps.number) {
    address.push(props.profileCardProps.number);
  }
  if (props.profileCardProps.zipCode) {
    address.push(props.profileCardProps.zipCode);
  }

  if (props.profileCardProps.city) {
    address.push(props.profileCardProps.city);
  }

  if (props.profileCardProps.country) {
    address.push(props.profileCardProps.country);
  }

  let fullAddress = address ? address.join(", ") : [];

  const downloadFile = async (
    id: number | null,
    name: string | null,
    path: string | null,
    uploadPath: string | null
  ) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_SERVICE}/${ENDPOINTS.FILE_DOWNLOAD}/${id}/${uploadPath}`
      );
      if (response.status === 200) {
        const fileUrl = response.data.url;
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = fileUrl;
        link.target = "_blank";
        link.download = response.data.filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error("Error fetching download URL:", error);
    }
  };

  const handleCvDownload = () => {
    try {
      // Parse the cvData JSON string
      const cvData = JSON.parse(props?.profileCardProps.cvData);
      const cvUrl = cvData.accessUrl; // Assuming this is equivalent to download_url
      const fileName = cvData.fileName;

      // Check if the URL is available
      if (cvUrl) {
        const link = document.createElement("a");
        link.href = cvUrl;
        link.target = "_blank"; // Open the link in a new tab
        link.setAttribute("download", fileName); // Set the file name for download
        document.body.appendChild(link);
        link.click();
        setTimeout(() => {
          document.body.removeChild(link); // Clean up the DOM
        }, 1000);
      } else {
        console.error("Invalid CV data or missing download URL");
      }
    } catch (error) {
      console.error("Failed to parse CV data or download CV:", error);
    }
  };

  const stringUrl = props.profileCardProps?.profilePicData;

  let url: any = "";
  if (stringUrl) {
    url = JSON.parse(stringUrl);
  }

  return (
    <div className="row">
      <div className="col-md-7">
        <div
          style={{
            marginLeft: "4rem",
          }}
        >
          <div
            className="card position-relative border-0"
            style={{
              backgroundColor: "var(--sideBarBgColor)",
              padding: "2.5vw",
            }}
          >
            <div className="cv-uplod">
              {props.profileCardProps.picFileName === ""
                ? ""
                : // <img
                  //   src="../../static/images/ProfileBadge.svg"
                  //   alt="mobile"
                  //   className="img-fluid ProfileBadge"
                  //   width={60}
                  //   style={{
                  //     position: "absolute",
                  //     bottom: "-115px",
                  //     left: "6px",
                  //   }}
                  // />
                  // <img
                  //   src="../../static/images/crown.svg"
                  //   alt="mobile"
                  //   className="img-fluid"
                  //   width={40}
                  //   style={{
                  //     position: "absolute",
                  //     left: "-136px",
                  //     top: "-50px",
                  //     transform: "rotate(-20deg)",
                  //   }}
                  // />
                  ""}
              <CardCircle
                position="absolute"
                width="7.813vw"
                height="7.813vw"
                backgroundColor="var(--primaryBrandColor)"
                left="0vw"
                top={"50%"}
                transform={`translate(-32%, -50%)`}
                className="me-5 flex-column cursor-pointer justify-content-center"
                color="var(--color-white)"
                inputClass={"mb-2"}
              >
                {url &&
                url["fileName"] !== "" &&
                url["fileName"] !== undefined ? (
                  <img
                    src={
                      url["accessUrl"].startsWith("https")
                        ? url["accessUrl"]
                        : ""
                      //  JSON.parse( props.profileCardProps?.profilePicData?.uploadPhoto)
                      // `${ENDPOINTS.FILE_URL}/${props.profileCardProps?.profilePicData?.filePath}`
                    }
                    alt={t("Uploaded")}
                    style={{
                      backgroundColor: "var(--color-white)",
                      borderRadius: "50%",
                      boxShadow: "rgb(248 216 235) 1px 0px 10px 3px",
                    }}
                    width="100%"
                    height="100%"
                  />
                ) : (
                  <img
                    src="/static/images/Person.svg"
                    alt="Profile"
                    width="100%"
                    height="100%"
                  />
                )}
              </CardCircle>
            </div>
            <div className="row">
              <div className="col-md-10 ms-auto">
                <div className="d-flex align-items-start justify-content-between text-break">
                  {(props.profileCardProps.firstName ||
                    props.profileCardProps.lastName) && (
                    <div className={`color-dark-skyblue name-text text-start`}>
                      {props.profileCardProps.firstName}{" "}
                      {props.profileCardProps.lastName}
                    </div>
                  )}
                  <div className="job-title mb-4 text-start">
                    {/* <span>{t("looking for a job as")}</span> */}
                    <span>
                      {functionList.length > 0 && (
                        <strong> {functionList.join(", ")}</strong>
                      )}
                    </span>
                  </div>
                  <div className="candidate-person-details">
                    {props.profileCardProps.mobNumber && (
                      <div
                        className="d-flex align-items-center"
                        style={{ marginBottom: "1vw" }}
                      >
                        {/* <a
                              href={`${PHONE_CALL_URL}${props.profileCardProps.mobNumber.replace(
                                "+",
                                "%2B"
                              )}`}
                              target="_blank"
                              className="link-color"
                            > */}
                        <img
                          src="../../static/images/mobile.png"
                          alt="mobile"
                          className="profileCardImage"
                        />
                        <span className={`ms-3`}>
                          {props.profileCardProps.mobNumber}
                        </span>
                        {/* </a> */}
                      </div>
                    )}
                    {props.profileCardProps.email && (
                      <div
                        className="d-flex align-items-center"
                        style={{ marginBottom: "1vw" }}
                      >
                        <a
                          href={`mailto:${props.profileCardProps.email}`}
                          target="_blank"
                          className="link-color"
                        >
                          <img
                            src="../../static/images/mail.png"
                            alt="mobile"
                            className="profileCardImage"
                          />
                          <span className={`ms-3`}>
                            {props.profileCardProps.email}
                          </span>
                        </a>
                      </div>
                    )}
                    {fullAddress && (
                      <div className="d-flex align-items-start mb-2">
                        <img
                          src="../../static/images/home.png"
                          alt="mobile"
                          className="profileCardImage"
                        />
                        <span className={`ms-3`}>
                          <div className="mb-2">{fullAddress}</div>
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-3 pt-1 ps-4 align-self-center candidate-cv-download ">
        <div className="shout-icon d-flex align-items-center">
          <div className="d-flex">
            <img
              src="../../static/images/megaphone.svg"
              alt="shout"
              className="img-fluid"
              width="90vw"
            />
            <div className="available-text mb-2">
              <div>{t("Available")}</div>
              <div>{t("for work!")}</div>
            </div>
          </div>
        </div>
        {props.profileCardProps?.cvData !== "" ? (
          <div className="d-flex matchingCandidateBtn">
            {/* <span
                    onClick={(event) =>
                      downloadFile(
                        props.profileCardProps.cvData.fileName
                          ? props.profileCardProps.cvData.fileName
                          : "",
                        props.profileCardProps.cvData.filePath
                          ? props.profileCardProps.cvData.filePath
                          : "",
                        "candidate-cv"
                      )
                    }
                    className="table-action-icons cursor-pointer matching-btns"
                  >
                    <div
                      className="candidate-download-btn px-3"
                      title={t("Download")}
                    >
                      <span className="me-4">{t("Download CV")}</span>
                      <DownloadIcon />
                    </div>
                  </span> */}

            <Button
              title="Cv download"
              type="button"
              handleClick={() => handleCvDownload()}
              className="form-button"
            />
          </div>
        ) : (
          <></>
        )}
        {props.profileCardProps.isScreening && (
          <div className="d-flex mt-2 matchingCandidateBtn">
            <Link
              className="form-button matchingBtn"
              title=""
              to={`/candidate/screening/${props.profileCardProps.candidateId}`}
              state={{ origin: props.profileCardProps.source }}
            >
              <div className="d-flex align-items-center justify-content-between">
                {t("Screening")} &nbsp;
                <span className="me-3">
                  <RightArrow />
                </span>
              </div>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default MatchingProfileCard;
