import Button from "components/atoms/Button";
import { t } from "pages/microservices/masterData/translation/Translation";
import React, { MouseEventHandler } from "react";
import { Link } from "react-router-dom";

export interface IFormNavigationOrganismProps {
  isFirstTab: boolean;
  isSubmitTab: boolean;
  draftButtonIsVisible?: boolean;

  formExitBackTo: string;

  handlePreviousStep: MouseEventHandler<HTMLButtonElement>;
  handleNextStep: MouseEventHandler<HTMLButtonElement>;
  handleSubmit: MouseEventHandler<HTMLButtonElement>;
  handleDraft?: MouseEventHandler<HTMLButtonElement>;
}

/**
 * @returns
 * Form navigation with Back, Next, Submit actions
 * 
 * @example
 <FormNavigationOrganism
  isFirstTab={state.currentTab === state.tabs[0].id}
  isSubmitTab={state.currentTab === state.tabs[state.tabs.length - 1].id}
  draftButtonIsVisible={state.mode === FormMode.CREATE}
  formExitBackTo={PATH_PROJECTS_MANAGE}

  handlePreviousStep={handlePreviousStep}
  handleNextStep={handleNextStep}
  handleSubmit={handleSubmit}
  handleDraft={handleDraft}
></FormNavigationOrganism>;
 */
const FormNavigationOrganism: React.FC<IFormNavigationOrganismProps> = ({
  isFirstTab,
  isSubmitTab,
  draftButtonIsVisible,
  formExitBackTo,
  handlePreviousStep,
  handleNextStep,
  handleSubmit,
  handleDraft,
}) => {
  const renderBackButton = () => {
    return isFirstTab ? (
      <Link
        to={formExitBackTo}
        className="text-uppercase back-btn text-decoration-underline"
      >
        {t("Back")}
      </Link>
    ) : (
      <Button
        type="button"
        title={t("Back")}
        handleClick={handlePreviousStep}
        className="back-btn btn p-0 text-decoration-underline text-uppercase border-0"
      />
    );
  };

  const renderDraftButton = () => {
    return (
      handleDraft && (
        <Button
          type="submit"
          title={t("Save as draft")}
          handleClick={handleDraft}
          className="form-button float-end me-3"
        />
      )
    );
  };

  return (
    <>
      <div className="row" style={{ padding: "1vw 0" }}>
        <div className="col-6 align-self-center">
          <div className="col-6 align-self-center">{renderBackButton()}</div>
        </div>
        <div className="col-6">
          <Button
            type="button"
            title={isSubmitTab ? t("Submit") : t("Next")}
            handleClick={isSubmitTab ? handleSubmit : handleNextStep}
            className="form-button float-end px-2"
          />
          {renderDraftButton()}
        </div>
      </div>
    </>
  );
};

export default FormNavigationOrganism;
