import { createBrowserRouter } from "react-router-dom";
import LoginForm from "pages/microservices/identityManager/authentication/LoginForm";
import { NewRootLayout } from "components/layout/RootLayout";
import RegistrationForm from "pages/microservices/identityManager/candidate-registration/RegistrationForm";
import ResetPasswordForm from "pages/microservices/identityManager/authentication/ResetPasswordForm";
import HomePage from "pages/dashboards/HomePage";
import { ForgotPasswordForm } from "pages/microservices/identityManager/authentication/ForgotPasswordForm";
import CreatePermission from "pages/microservices/identityManager/permissions/CreatePermission";
import ManagePermissions from "pages/microservices/identityManager/permissions/ManagePermissions";
// import CreateUserPage from "pages/microservices/identityManager/users/CreateUserPage";
import CreateUser from "pages/microservices/identityManager/users/CreateUser";
import RolePage from "pages/microservices/identityManager/roles/CreateRole";
// import EditUserPage from "pages/microservices/identityManager/users/EditUserPage";
import ManageRoles, {
  loader as rolesLoader,
} from "pages/microservices/identityManager/roles/ManageRoles";
import TermsAndConditions from "pages/microservices/identityManager/terms-conditions/TermsAndConditions";
import ManageUsers from "pages/microservices/identityManager/users/ManageUsers";
import CreateEmailTemplate from "pages/microservices/masterData/email-templates/CreateEmailTemplate";
import EditEmailTemplate from "pages/microservices/masterData/email-templates/EditEmailTemplate";
import ManageEmailTemplatesPage from "pages/microservices/masterData/email-templates/ManageEmailTemplates";
import { MultiFormProvider } from "pages/microservices/companyCreation/context/MultiFormProvider";
import ManageCityProfile from "pages/microservices/cooperation/configurationAndSettings/cities/ManageCityProfile";
import ManageCompanies from "pages/microservices/companyCreation/create-company/formContent/ManageCompanies";

import Location from "pages/microservices/companyCreation/Location/Location";
import ManageLocation from "pages/microservices/companyCreation/Location/ManageLocation";

import ManageFunctions from "pages/microservices/cooperation/configurationAndSettings/functionsProfile/ManageFunctions";
import AddFunctions from "pages/microservices/cooperation/configurationAndSettings/functionsProfile/AddFunctions";
import Checklist from "pages/microservices/cooperation/configurationAndSettings/checklist/Checklist";
import ManageChecklist from "pages/microservices/cooperation/configurationAndSettings/checklist/ManageChecklist";
import AddCityProfile from "pages/microservices/cooperation/configurationAndSettings/cities/AddCityProfile";
import LowCoefficientTableForm from "pages/microservices/cooperation/configurationAndSettings/coefficients/defaultLowCoefficents/LowCoefficientTableForm";
import WorkTypeExtra from "pages/microservices/cooperation/configurationAndSettings/workTypeExtra/WorkTypeExtra";
import ConfigAndSettingsPage from "pages/dashboards/ConfigAndSettingsPage";
import ManageExtras from "pages/microservices/cooperation/configurationAndSettings/extras/ManageExtras";
import { MultiFormProviderAgreement } from "pages/microservices/cooperation/agreement/context/MultiFormProvider";
import InflationCoefficientsForm from "pages/microservices/cooperation/configurationAndSettings/coefficients/inflations/InflationCoefficientsForm";
import ManageInflations from "pages/microservices/cooperation/configurationAndSettings/coefficients/inflations/ManageInflations";
import ManageLowCoefficients from "pages/microservices/cooperation/configurationAndSettings/coefficients/defaultLowCoefficents/ManageLowCofficients";
import CreateBusinessUnit from "pages/microservices/masterData/business-unit/CreateBusinessUnit";
import ManageBusinessUnit from "pages/microservices/masterData/business-unit/ManageBusinessUnit";
import ManageContactPerson from "pages/microservices/companyCreation/create-company/ContactPerson/ManageContactPerson";
import Contact from "pages/microservices/companyCreation/create-company/ContactPerson";
import CreateEmployeeType from "pages/microservices/masterData/employee-type/Create";
import ManageEmployeeType from "pages/microservices/masterData/employee-type/Manage";
import CreateTags from "pages/microservices/masterData/tags/CreateTags";
import ManageTagPage from "pages/microservices/masterData/tags/ManageTagsPage";
import CreateShift from "pages/microservices/masterData/shifts/Create";
import { ProjectFormProvider } from "pages/microservices/project/ProjectFormProvider";
import Projects from "pages/microservices/project/Projects";
import Planning from "pages/microservices/planning";
import {
  PATH_MANAGE_BUSINESS_UNIT,
  PATH_CREATE_BUSINESS_UNIT,
  PATH_EDIT_BUSINESS_UNIT,
  PATH_EMAIL_TEMPLATE_CREATE,
  PATH_EMAIL_TEMPLATE_MANAGE,
  PATH_EMAIL_TEMPLATE_EDIT,
  PATH_EMAIL_TEMPLATE_VIEW,
  PATH_MANAGE_TAGS,
  PATH_MANAGE_SHIFTS,
  PATH_CREATE_SHIFT,
  PATH_EDIT_SHIFT,
  PATH_PROJECTS_MANAGE,
  PATH_PROJECTS_CREATE,
  PATH_MANAGE_TEMPLATE,
  PATH_TEMPLATE_EDIT,
  PATH_TEMPLATE_VIEW,
  PATH_PROJECTS_EDIT,
  PATH_MANAGE_TIMETABLE,
  PATH_TIMETABLE_CREATE,
  PATH_PROPOSAL_AGREEMENT,
  PATH_EMPLOYEE_CREATE,
  PATH_EMPLOYEE_MANAGE,
  PATH_PC_MANAGE,
  PATH_PC_CREATE,
  PATH_PROPOSAL_AGREEMENT_MANAGER,
  PATH_CONFIG_ELEMENTS_MANAGE,
  PATH_CONFIG_ELEMENTS_CREATE,
  PATH_PROFILE,
  PATH_COMPETENCE_MANAGE,
  PATH_COMPETENCE_CREATE,
  PATH_COMPETENCE_EDIT,
  PATH_COMPETENCE_VIEW,
  PATH_TIMETABLE_EDIT,
  PATH_MATCHING,
  PATH_EMPLOYEE_EDIT,
  PATH_APPLICATION_CONFIGURATION,
  PATH_EDIT_APPLICATION_CONFIG,
  PATH_PC_EDIT,
  PATH_MATCHING_EMPLOYEE,
  PATH_HOTLIST,
  PATH_DOCUMENT,
  MANAGE_QUESIONS,
  ADD_QUESTION,
  PATH_HR_PLANNING,
  PATH_SALES_PLANNING,
  VIEW_QUESTION,
  CLONE_QUESTION,
  EDIT_QUESTION,
  PATH_VACANCY_CREATE,
  PATH_VACANCY_MANAGE,
  PATH_VACANCY_EDIT,
  PATH_CANDIDATE_SCREENING,
  PATH_WAGE_PROPOSAL,
  PATH_CREATE_CONTRACT,
  PATH_MANAGE_CANDIDATES,
  PATH_SALARY_BENEFITS_CREATE,
  PATH_SALARY_BENEFITS_MANAGE,
  PATH_CANDIDATE_CREATE,
  EDIT_HOLIDAY_CODE,
  CREATE_HOLIDAY_CODES,
  HOLIDAY_CONFIGS,
  MANAGE_PUBLIC_HOLIDAYS,
  MANAGE_HOLIDAY_CODES,
  CREATE_PUBLIC_HOLIDAY,
  EDIT_PUBLIC_HOLIDAY,
  MANAGE_HOLIDAY_PER_PC,
  LINK_HOLIDAY_PER_PC,
  EDIT_LINK_HOLIDAY_PER_PC,
  PATH_TODO_CREATE,
  PATH_TODO_MANAGE,
  PATH_TODO_VIEW,
  PATH_ADD_CONTRACT_TEMPLATE,
  PATH_MANAGE_CONTRACT_TEMPLATE,
  PATH_VIEW_CONTRACT_TEMPLATE,
  PATH_CLONE_CONTRACT_TEMPLATE,
  APPLY_LEAVE_OF_EMPLOYEE,
  VIEW_LEAVE_OF_EMPLOYEE,
  EDIT_LEAVE_OF_EMPLOYEE,
  MANAGE_EMPLOYEE_LEAVES,
  EMPLOYEE_LEAVE_COUNT,
  MANAGE_EMPLOYER_LEAVES,
  EMPLOYER_LEAVE_COUNT,
  ADD_LEAVE,
  VIEW_LEAVE_THROUGH_EMPLOYER,
  EDIT_LEAVE_THROUGH_EMPLOYER,
  PATH_MANAGE_SCREENING,
  PATH_SCREENING_ASSESSMENT,
  PATH_CREATE_MATERIAL,
  PATH_MANAGE_MATERIAL,
  PATH_FLEET_AND_MATERIAL,
  PATH_MANAGE_CAR,
  PATH_CREATE_CAR,
  PATH_MANAGE_CLOTHES,
  PATH_CREATE_CLOTHES,
  PATH_MANAGE_SUPPLIER,
  PATH_CREATE_SUPPLIER,
  PATH_MANAGE_CLIENT,
  PATH_CREATE_CLIENT,
  PATH_MANAGE_PURCHASE_ORDER,
  PATH_CREATE_PURCHASE_ORDER,
  PATH_EDIT_MATERIAL,
  PATH_EDIT_CLOTHES,
  PATH_EDIT_CAR,
  PATH_EDIT_PURCHASE_ORDER,
  PATH_CONTRACT_OVERVIEW,
  PATH_EDIT_SUPPLIER,
  PATH_EDIT_CLIENT,
  PATH_ACCESS_DENIED,
  PATH_MANAGE_TIMEHSHEETS,
  PATH_PROJECT_PROPOSAL,
  PATH_EMPLOYEE_FLEET_OVERVIEW,
  PATH_WORK_PAY_BILL_ROLL,
  PATH_MANAGE_TEMP_AGENCY,
  PATH_CREATE_TEMP_AGENCY,
  PATH_EDIT_TEMP_AGENCY,
  PATH_MANAGECOEFF_DEVIATION,
  PATH_MANAGE_WAGE_ELEMENT,
  PATH_EDIT_COEEFICIENT,
  PATH_MANAGE_TRANSLATIONS,
  PATH_VIEW_PURCHASE_ORDER,
  PATH_MARGIN_CALCULATOR,
  PATH_MANAGE_PARTENA,
  PATH_ADD_PARTENA,
  PATH_PARTENA_CALCULATION,
  PATH_TEMP_AGENCY_PROPOSAL,
  PATH_MANAGE_FLEET_LOCATION,
  PATH_CREATE_FLEET_LOCATION,
  PATH_EDIT_FLEET_LOCATION,
} from "constants/Paths";
import ManageShift from "pages/microservices/masterData/shifts/Manage";
import ManageTemplatesPage from "pages/microservices/masterData/templates/Manage";
import EditTemplate from "pages/microservices/masterData/templates/Edit";
import ManageTimeTable from "pages/microservices/masterData/timetable/Manage";
import CreateTimeTable from "pages/microservices/masterData/timetable/create";
import { Proposal } from "pages/microservices/project/Proposals/proposals";
import { Employees } from "pages/microservices/employees/Index";
import { CreateEmployees } from "pages/microservices/employees/create/Index";
import CreateParitairComitee from "pages/microservices/masterData/paritair-comitee/create";
import ManageMasterDataElements from "pages/microservices/masterData/configElements/Index";
import ConfigForm from "pages/microservices/masterData/configElements/create/Index";
import Matching from "pages/microservices/masterData/matching";
import UsersProfile from "pages/microservices/identityManager/my-profile";
import CreateCompetence from "pages/microservices/masterData/competence/Create";
import ManageCompetence from "pages/microservices/masterData/competence/Manage";
import EditAppConfig from "pages/microservices/masterData/application-configuration/Create";
import AppConfiguration from "pages/microservices/masterData/application-configuration/Index";
import ManagePC from "pages/microservices/masterData/paritair-comitee/Manage";
import ManagePCPage from "pages/microservices/masterData/paritair-comitee/ManagePage";
import FileUploader from "utils/S3Bucket/FileUploader";
import MatchingEmployee from "pages/microservices/masterData/matching/MatchingEmployee";
import Vacancies from "pages/microservices/vacancies/Vacancies";
import ManageHotlist from "pages/microservices/masterData/hotlist";
import ManageDocumentPage from "pages/microservices/masterData/documents/Index";
import ManageQuestions from "pages/microservices/masterData/questions";
import AddQuestions from "pages/microservices/masterData/questions/AddQuestions";
import Hrmanage from "pages/HrManage";
import CreateHolidayCodes from "pages/microservices/masterData/holidays/createHolidayCodes";
import CreateHoliday from "pages/microservices/masterData/holidays/createHoliday";
import ManageHolidays from "pages/microservices/masterData/holidays/createHoliday/ManageHoliday";
import ManageHolidayCodes from "pages/microservices/masterData/holidays/createHolidayCodes/ManageHolidayCodes";
import ManageHolidayPerPC from "pages/microservices/masterData/holidays/holidayPerPC/ManageHolidayPerPC";
import LinkHolidayToPc from "pages/microservices/masterData/holidays/holidayPerPC";
import HolidayConfig from "pages/dashboards/HolidayConfig";
import WageProposal from "pages/microservices/masterData/wageProposal/wageProposalOverview";
import CreateContract from "pages/microservices/masterData/wageProposal/createEmployeeContract";
import WelcomeDashboard from "dashboard/WelcomeDashboard";
import CreateSalaryBenefits from "pages/microservices/masterData/salary-benefits/Create";
import ManageSalaryBenefits from "pages/microservices/masterData/salary-benefits/ManagePage";
import ManageTodo from "pages/microservices/masterData/follow-up-todo/Manage";
// import CreateTodo from "pages/microservices/masterData/follow-up-todo/Create";
import ManageCandidates from "pages/microservices/identityManager/Candidates/ManageCandidates";
import CreateCandidate from "pages/microservices/identityManager/Candidates/CreateCandidate";
import AddContractsTemplate from "pages/microservices/masterData/contracts/AddContractTemplate";
import ManageContractTemplate from "pages/microservices/masterData/contracts/ManageContractTemplates";
import ManageLeavesOfEmployee from "pages/microservices/masterData/leaves/ManageLeavesForEmployee";
import LeaveCountOfEmployee from "pages/microservices/masterData/leaves/LeaveCountOfEmployee";
import ApplyForLeave from "pages/microservices/masterData/leaves/ApplyForLeave";
import AddLeave from "pages/microservices/masterData/leaves/AddLeave";
import LeaveCountOfEmployer from "pages/microservices/masterData/leaves/LeaveCountOfEmployer";
import ManageLeavesForEmployer from "pages/microservices/masterData/leaves/ManageLeavesForEmployers";
import CandidateCorner from "pages/microservices/identityManager/Candidates/CandidateCorner";
import ManageScreening from "pages/microservices/masterData/Screening/ManageScreening";
import ScreeningAssessment from "pages/microservices/masterData/Screening/ScreeningAssessment";
import CandidateScreening from "pages/microservices/masterData/Screening/EmployeeScreening";
import Material from "pages/microservices/fleetAndMaterial/material/Material";
import AddMaterial from "pages/microservices/fleetAndMaterial/material/AddMaterial";
import FleetAndMaterial from "pages/microservices/fleetAndMaterial";
import Car from "pages/microservices/fleetAndMaterial/car/Car";
import AddCar from "pages/microservices/fleetAndMaterial/car/AddCar";
import Clothes from "pages/microservices/fleetAndMaterial/clothes/Clothes";
import AddClothes from "pages/microservices/fleetAndMaterial/clothes/AddClothes";
import Supplier from "pages/microservices/fleetAndMaterial/supplier/Supplier";
import AddSupplier from "pages/microservices/fleetAndMaterial/supplier/AddSupplier";
import Client from "pages/microservices/fleetAndMaterial/client/Client";
import AddClient from "pages/microservices/fleetAndMaterial/client/AddClient";
import PurchaseOrder from "pages/microservices/fleetAndMaterial/purchaseOrder/PurchaseOrder";
import AddPurchaseOrder from "pages/microservices/fleetAndMaterial/purchaseOrder/AddPurchaseOrder";
import ContractOverview from "pages/microservices/masterData/wageProposal/contractOverview";
import AccessDenied from "pages/AccessDenied/AccessDenied";
import Timesheets from "pages/microservices/timesheets";
import FleetOverview from "pages/microservices/fleetAndMaterial/FleetOverview";
import ProjectOverview from "pages/microservices/project/components/ProjectOverview";
import FleetEmployeeOverview from "pages/microservices/fleetAndMaterial/FleetEmployeeOverview";
import WorkPayBillRoll from "pages/microservices/work-pay-bill-roll";

import ManageTempAgency from "pages/microservices/tempAgancy/manage/ManageTempAgency";
import { TempAgencyFormProvider } from "pages/microservices/tempAgancy/TempAgencyFormProvider";
import ManageCoeffDeviation from "pages/microservices/masterData/WageCodes/ManageCoeffDeviation";
import ManageWageElements from "pages/microservices/masterData/WageElement/ManageWageElements";
import CoeffDeviation from "pages/microservices/masterData/WageCodes/CoeffDeviation";
import CreateTodo from "pages/microservices/masterData/follow-up-todo/Create";
import ManageTranslations from "pages/microservices/masterData/translation/ManageTranslation";
import MarginCalculator from "pages/microservices/marginCalculation";
import { MarginProviderWrapper } from "pages/microservices/marginCalculation/MarginWrapper";
import ManagePartena from "pages/microservices/masterData/partena/PartenaExport";
import AddPartenaCalculation from "pages/microservices/masterData/partena/AddPartenaCalculation";
import PartenaCalculation from "pages/microservices/masterData/partena/PartenaCalculation";
import TempAgencyOverview from "pages/microservices/tempAgancy/componests/TempAgencyOverview";
import VacancyForm from "pages/microservices/vacancies/create-vacancy/form-content/VacancyForm";
import MultiFormProviderVacancy from "pages/microservices/vacancies/context/MultiFormProvider";
import PageNotFound from "pages/PageNotFound/PageNotFound";
import CreateFleetLocation from "pages/microservices/fleetAndMaterial/locations/CreateLocations";
import ManageFleetLocation from "pages/microservices/fleetAndMaterial/locations/ManageLocations";

export const Routes = createBrowserRouter([
  { path: "/login", element: <LoginForm /> },
  { path: "/register", element: <RegistrationForm /> },
  { path: "/reset-password", element: <ResetPasswordForm /> },
  { path: "/forgot-password", element: <ForgotPasswordForm /> },
  { path: "/terms-conditions", element: <TermsAndConditions /> },
  {
    path: "/",
    element: <NewRootLayout />,
    children: [
      { path: "*", element: <PageNotFound /> },
      { path: "/", element: <WelcomeDashboard /> },
      // { path: "/", element: <WelcomeDashboard /> },
      { path: "/dashboard", element: <WelcomeDashboard /> },
      //// routes for permissions (Manage, Create, Edit and Delete)
      { path: "/permission/create", element: <CreatePermission /> },
      { path: "/permission/edit/:id", element: <CreatePermission /> },
      { path: "/permission/update/:id", element: <CreatePermission /> },
      { path: "/permission/delete/:id", element: <ManagePermissions /> },
      { path: "/manage-permissions", element: <ManagePermissions /> },
      // ///// routes for roles (Create, Edit)
      { path: "/manage-roles", element: <ManageRoles />, loader: rolesLoader },
      { path: "/role/create", element: <RolePage /> },
      { path: "/role/edit/:id", element: <RolePage /> },
      { path: "/role/clone/:id", element: <RolePage /> },
      // ///// routes for creating the AJ-Users (Create, Edit, Update)
      { path: "/user/create", element: <CreateUser /> },
      { path: "/user/edit/:id", element: <CreateUser /> },
      { path: "/user/view/:id", element: <CreateUser /> },
      // { path: "/create/user", element: <CreateUserPage /> },
      // { path: "/edit/user/:id", element: <EditUserPage /> },
      // { path: "/user/update/:id", element: <EditUserPage /> },
      { path: "/manage-users", element: <ManageUsers /> },

      // Email templates
      {
        path: `${PATH_EMAIL_TEMPLATE_CREATE}`,
        element: <CreateEmailTemplate />,
      },
      {
        path: `${PATH_EMAIL_TEMPLATE_EDIT}/:id`,
        element: <EditEmailTemplate />,
      },
      {
        path: `${PATH_EMAIL_TEMPLATE_VIEW}/:id`,
        element: <EditEmailTemplate />,
      },
      {
        path: `${PATH_EMAIL_TEMPLATE_MANAGE}`,
        element: <ManageEmailTemplatesPage />,
      },

      ///for company-creation
      { path: "/company/:companyId?", element: <MultiFormProvider /> },
      { path: "/manage-companies", element: <ManageCompanies /> },
      { path: "/location/:id?", element: <Location /> },
      { path: "manage-locations", element: <ManageLocation /> },
      { path: "/manage-contact/person", element: <ManageContactPerson /> },
      { path: "/create/contact-person/:id?", element: <Contact /> },

      //Candidate creation
      // {
      //   path: "/candidate/:candidateId?",
      //   element: <MultiFormProviderCandidate />,
      // },
      // { path: "/manage-candidates", element: <ManageCandidates /> },
      { path: "/checklist/edit/:id?", element: <Checklist /> },
      { path: "/checklist/view/:id?", element: <Checklist /> },
      { path: "/manage-checklist/:id?", element: <ManageChecklist /> },

      //Cooperation agreement config and settings
      { path: "/config/settings", element: <ConfigAndSettingsPage /> },
      { path: "/manage/function-profile", element: <ManageFunctions /> },
      { path: "/function/add", element: <AddFunctions /> },
      { path: "/function/edit/:id", element: <AddFunctions /> },
      { path: "/function/view/:id", element: <AddFunctions /> },
      {
        path: "/edit/default/low-coefficients/:id?",
        element: <LowCoefficientTableForm />,
      },
      {
        path: "/add/default/low-coefficients",
        element: <LowCoefficientTableForm />,
      },
      {
        path: "/manage/default/low-coefficients",
        element: <ManageLowCoefficients />,
      },

      { path: "/add/city-profile", element: <AddCityProfile /> },
      { path: "/edit/city-profile/:id", element: <AddCityProfile /> },
      { path: "/view/city-profile/:id", element: <AddCityProfile /> },
      { path: "/manage-city-profile", element: <ManageCityProfile /> },
      { path: "/work-type/extra", element: <WorkTypeExtra /> },

      { path: "/manage-extras", element: <ManageExtras /> },

      {
        path: "/inflation/coefficients",
        element: <InflationCoefficientsForm />,
      },
      {
        path: "/create/cooperation-agreement/:companyId/:modalId?",
        element: <MultiFormProviderAgreement />,
      },
      { path: "/add/inflation", element: <InflationCoefficientsForm /> },

      { path: "/edit/:id/inflation", element: <InflationCoefficientsForm /> },
      { path: "/view/:id/inflation", element: <InflationCoefficientsForm /> },

      { path: "/manage/inflations", element: <ManageInflations /> },

      //for employee type
      { path: "/manage/employee-type", element: <ManageEmployeeType /> },
      { path: "/create/employee-type/:id?", element: <CreateEmployeeType /> },

      //For Business unit
      { path: `${PATH_MANAGE_BUSINESS_UNIT}`, element: <ManageBusinessUnit /> },
      { path: `${PATH_CREATE_BUSINESS_UNIT}`, element: <CreateBusinessUnit /> },
      {
        path: `${PATH_EDIT_BUSINESS_UNIT}/:id`,
        element: <CreateBusinessUnit />,
      },

      //For Tags
      { path: `${PATH_MANAGE_TAGS}`, element: <ManageTagPage /> },
      { path: "/create/tags/:id?", element: <CreateTags /> },

      //For shifts
      { path: `${PATH_MANAGE_SHIFTS}`, element: <ManageShift /> },
      { path: `${PATH_CREATE_SHIFT}`, element: <CreateShift /> },
      { path: `${PATH_EDIT_SHIFT}/:id`, element: <CreateShift /> },

      // Projects
      { path: `${PATH_PROJECTS_MANAGE}`, element: <Projects /> },
      { path: `${PATH_PROJECT_PROPOSAL}`, element: <ProjectOverview /> },

      { path: `${PATH_PROJECTS_CREATE}`, element: <ProjectFormProvider /> },
      { path: `${PATH_PROJECTS_EDIT}/:id`, element: <ProjectFormProvider /> },

      //Template
      { path: `${PATH_MANAGE_TEMPLATE}`, element: <ManageTemplatesPage /> },
      { path: `${PATH_TEMPLATE_EDIT}/:id`, element: <EditTemplate /> },
      { path: `${PATH_TEMPLATE_VIEW}/:id`, element: <EditTemplate /> },

      // Proposal Agreement
      { path: `${PATH_PROPOSAL_AGREEMENT}`, element: <Proposal /> },
      { path: `${PATH_PROPOSAL_AGREEMENT_MANAGER}`, element: <Proposal /> },

      { path: `${PATH_EMPLOYEE_MANAGE}`, element: <Employees /> },
      { path: `${PATH_EMPLOYEE_CREATE}`, element: <CreateEmployees /> },

      //PC
      { path: `${PATH_PC_MANAGE}`, element: <ManagePCPage /> },
      { path: `${PATH_PC_CREATE}`, element: <CreateParitairComitee /> },
      { path: `${PATH_PC_EDIT}/:id`, element: <CreateParitairComitee /> },

      //Salary Benefits
      {
        path: `${PATH_SALARY_BENEFITS_MANAGE}`,
        element: <ManageSalaryBenefits />,
      },
      {
        path: `${PATH_SALARY_BENEFITS_CREATE}/:id?`,
        element: <CreateSalaryBenefits />,
      },
      // config data
      {
        path: `${PATH_CONFIG_ELEMENTS_MANAGE}`,
        element: <ManageMasterDataElements />,
      },
      { path: `${PATH_CONFIG_ELEMENTS_CREATE}`, element: <ConfigForm /> },

      //My Profile
      { path: `${PATH_PROFILE}`, element: <UsersProfile /> },

      //competence
      { path: `${PATH_COMPETENCE_CREATE}`, element: <CreateCompetence /> },
      { path: `${PATH_COMPETENCE_MANAGE}`, element: <ManageCompetence /> },
      { path: `${PATH_COMPETENCE_EDIT}/:id`, element: <CreateCompetence /> },
      { path: `${PATH_COMPETENCE_VIEW}/:id`, element: <CreateCompetence /> },
      // Proposal Agreement
      { path: `${PATH_PROPOSAL_AGREEMENT}`, element: <Proposal /> },

      { path: `${PATH_EMPLOYEE_MANAGE}`, element: <Employees /> },
      { path: `${PATH_EMPLOYEE_CREATE}`, element: <CreateEmployees /> },
      { path: `${PATH_EMPLOYEE_EDIT}/:id`, element: <CreateEmployees /> },

      { path: `${PATH_VACANCY_MANAGE}`, element: <Vacancies /> },
      { path: `${PATH_VACANCY_CREATE}/:id?`, element: <MultiFormProviderVacancy /> },

      // Testing purpose only you can remove it before going to production
      { path: `${"/s3service"}`, element: <FileUploader /> },

      //Timetable
      { path: `${PATH_MANAGE_TIMETABLE}`, element: <ManageTimeTable /> },
      { path: `${PATH_TIMETABLE_CREATE}`, element: <CreateTimeTable /> },
      { path: `${PATH_TIMETABLE_EDIT}/:id`, element: <CreateTimeTable /> },

      //Application configuration
      {
        path: `${PATH_APPLICATION_CONFIGURATION}`,
        element: <AppConfiguration />,
      },
      {
        path: `${PATH_EDIT_APPLICATION_CONFIG}/:element/:id`,
        element: <EditAppConfig />,
      },
      //Matching

      { path: `${PATH_MATCHING}/:element/:id`, element: <Matching /> },
      { path: `${PATH_MATCHING_EMPLOYEE}/:id`, element: <MatchingEmployee /> },

      //Hotlist
      { path: `${PATH_HOTLIST}`, element: <ManageHotlist /> },
      {
        path: `${PATH_EMPLOYEE_MANAGE}/:projectId/:employeeId`,
        element: <Employees />,
      },
      //Documents
      { path: `${PATH_DOCUMENT}`, element: <ManageDocumentPage /> },

      //Questions
      { path: `${MANAGE_QUESIONS}`, element: <ManageQuestions /> },
      { path: `${ADD_QUESTION}`, element: <AddQuestions /> },
      { path: `${EDIT_QUESTION}`, element: <AddQuestions /> },
      { path: `${CLONE_QUESTION}`, element: <AddQuestions /> },
      { path: `${VIEW_QUESTION}`, element: <AddQuestions /> },

      {
        path: `${PATH_EMPLOYEE_MANAGE}/:projectId/:employeeId`,
        element: <Employees />,
      },
      //Questions
      { path: `${MANAGE_QUESIONS}`, element: <ManageQuestions /> },
      { path: `${ADD_QUESTION}`, element: <AddQuestions /> },
      { path: `${EDIT_QUESTION}`, element: <AddQuestions /> },
      { path: `${CLONE_QUESTION}`, element: <AddQuestions /> },
      { path: `${VIEW_QUESTION}`, element: <AddQuestions /> },
      { path: `${PATH_PROJECTS_MANAGE}/:employeeId`, element: <Projects /> },

      //Screening
      { path: `${PATH_CANDIDATE_SCREENING}`, element: <CandidateScreening /> },
      { path: `${PATH_MANAGE_CANDIDATES}`, element: <ManageCandidates /> },
      { path: `${PATH_MANAGE_SCREENING}`, element: <ManageScreening /> },
      {
        path: `${PATH_SCREENING_ASSESSMENT}`,
        element: <ScreeningAssessment />,
      },

      //Planning
      { path: `${PATH_HR_PLANNING}`, element: <Planning type={"hr"} /> },
      { path: `${PATH_SALES_PLANNING}`, element: <Planning type={"sales"} /> },

      { path: "/hrmanage", element: <Hrmanage /> },

      // PATH_WAGE_PROPOSAL
      { path: `${PATH_WAGE_PROPOSAL}/:id?`, element: <WageProposal /> },
      { path: `${PATH_CREATE_CONTRACT}/:id?`, element: <CreateContract /> },
      { path: `${PATH_CONTRACT_OVERVIEW}`, element: <ContractOverview /> },
      { path: `${PATH_ACCESS_DENIED}`, element: <AccessDenied /> },

      //candidates
      { path: `${PATH_CANDIDATE_CREATE}`, element: <CreateCandidate /> },
      { path: "/candidate/view/:id", element: <CreateCandidate /> },
      { path: "/candidate/details/:candidateId", element: <CandidateCorner /> },
      { path: "/employee/details/:candidateId", element: <CandidateCorner /> },

      //Holiday codes
      { path: `${CREATE_HOLIDAY_CODES}`, element: <CreateHolidayCodes /> },
      { path: `${EDIT_HOLIDAY_CODE}/:id`, element: <CreateHolidayCodes /> },

      //Holiday config

      { path: `${HOLIDAY_CONFIGS}`, element: <HolidayConfig /> },

      //Holiday
      { path: `${CREATE_PUBLIC_HOLIDAY}`, element: <CreateHoliday /> },
      { path: `${EDIT_PUBLIC_HOLIDAY}/:id`, element: <CreateHoliday /> },
      { path: `${MANAGE_PUBLIC_HOLIDAYS}`, element: <ManageHolidays /> },
      { path: `${MANAGE_HOLIDAY_CODES}`, element: <ManageHolidayCodes /> },

      //Holidays per PC
      { path: `${MANAGE_HOLIDAY_PER_PC}`, element: <ManageHolidayPerPC /> },
      { path: `${LINK_HOLIDAY_PER_PC}`, element: <LinkHolidayToPc /> },
      { path: `${EDIT_LINK_HOLIDAY_PER_PC}/:id`, element: <LinkHolidayToPc /> },
      //Todo
      { path: `${PATH_TODO_CREATE}/:id?`, element: <CreateTodo /> },
      { path: `${PATH_TODO_VIEW}/:id`, element: <CreateTodo /> },
      { path: `${PATH_TODO_MANAGE}`, element: <ManageTodo /> },

      //Contract
      {
        path: `${PATH_ADD_CONTRACT_TEMPLATE}/:id?`,
        element: <AddContractsTemplate />,
      },
      {
        path: `${PATH_MANAGE_CONTRACT_TEMPLATE}`,
        element: <ManageContractTemplate />,
      },
      {
        path: `${PATH_VIEW_CONTRACT_TEMPLATE}/:id?`,
        element: <AddContractsTemplate />,
      },
      {
        path: `${PATH_CLONE_CONTRACT_TEMPLATE}/:id?`,
        element: <AddContractsTemplate />,
      },

      //Apply for leaves
      { path: `${APPLY_LEAVE_OF_EMPLOYEE}`, element: <ApplyForLeave /> },
      { path: `${VIEW_LEAVE_OF_EMPLOYEE}/:id`, element: <ApplyForLeave /> },
      { path: `${EDIT_LEAVE_OF_EMPLOYEE}/:id`, element: <ApplyForLeave /> },

      //Leave flow
      {
        path: `${MANAGE_EMPLOYEE_LEAVES}`,
        element: <ManageLeavesOfEmployee />,
      },
      { path: `${EMPLOYEE_LEAVE_COUNT}`, element: <LeaveCountOfEmployee /> },
      {
        path: `${MANAGE_EMPLOYER_LEAVES}`,
        element: <ManageLeavesForEmployer />,
      },
      { path: `${EMPLOYER_LEAVE_COUNT}`, element: <LeaveCountOfEmployer /> },
      { path: `${ADD_LEAVE}`, element: <AddLeave /> },
      { path: `${VIEW_LEAVE_THROUGH_EMPLOYER}/:id`, element: <AddLeave /> },
      { path: `${EDIT_LEAVE_THROUGH_EMPLOYER}/:id`, element: <AddLeave /> },

      // timesheets
      { path: `${PATH_MANAGE_TIMEHSHEETS}`, element: <Timesheets /> },

      // fleet and material
      { path: PATH_FLEET_AND_MATERIAL, element: <FleetAndMaterial /> },
      { path: PATH_CREATE_MATERIAL, element: <AddMaterial /> },
      { path: `${PATH_EDIT_MATERIAL}/:id`, element: <AddMaterial /> },
      { path: `${PATH_MANAGE_MATERIAL}`, element: <Material /> },
      { path: `${PATH_MANAGE_CAR}`, element: <Car /> },
      { path: PATH_CREATE_CAR, element: <AddCar /> },
      { path: `${PATH_EDIT_CAR}/:id`, element: <AddCar /> },
      { path: PATH_MANAGE_CLOTHES, element: <Clothes /> },
      { path: PATH_CREATE_CLOTHES, element: <AddClothes /> },
      { path: `${PATH_EDIT_CLOTHES}/:id`, element: <AddClothes /> },
      { path: PATH_MANAGE_SUPPLIER, element: <Supplier /> },
      { path: PATH_CREATE_SUPPLIER, element: <AddSupplier /> },
      { path: `${PATH_EDIT_SUPPLIER}/:id`, element: <AddSupplier /> },
      { path: PATH_MANAGE_CLIENT, element: <Client /> },
      { path: PATH_CREATE_CLIENT, element: <AddClient /> },
      { path: `${PATH_EDIT_CLIENT}/:id`, element: <AddClient /> },
      { path: PATH_MANAGE_PURCHASE_ORDER, element: <PurchaseOrder /> },
      { path: PATH_CREATE_PURCHASE_ORDER, element: <AddPurchaseOrder /> },
      {
        path: `${PATH_VIEW_PURCHASE_ORDER}/:id`,
        element: <AddPurchaseOrder />,
      },

      {
        path: `${PATH_EDIT_PURCHASE_ORDER}/:id`,
        element: <AddPurchaseOrder />,
      },
      { path: `/fleetOverview/:type?`, element: <FleetOverview /> },
      {
        path: `${PATH_EMPLOYEE_FLEET_OVERVIEW}/:id/:userType`,
        element: <FleetEmployeeOverview />,
      },
      {
        path: PATH_MANAGE_FLEET_LOCATION, element: <ManageFleetLocation />
      },
      {
        path: PATH_CREATE_FLEET_LOCATION, element: <CreateFleetLocation />
      },
      {
        path: `${PATH_EDIT_FLEET_LOCATION}/:id`, element: <CreateFleetLocation />
      },

      //work-pay-bill-roll
      { path: `${PATH_WORK_PAY_BILL_ROLL}`, element: <WorkPayBillRoll /> },
      //temp agency
      { path: PATH_MANAGE_TEMP_AGENCY, element: <ManageTempAgency /> },
      { path: PATH_CREATE_TEMP_AGENCY, element: <TempAgencyFormProvider /> },

      { path: `${PATH_TEMP_AGENCY_PROPOSAL}`, element: <TempAgencyOverview /> },
      {
        path: `${PATH_EDIT_TEMP_AGENCY}/:id`,
        element: <TempAgencyFormProvider />,
      },
      { path: PATH_MANAGECOEFF_DEVIATION, element: <ManageCoeffDeviation /> },
      { path: `${PATH_EDIT_COEEFICIENT}/:id`, element: <CoeffDeviation /> },
      { path: PATH_MANAGE_WAGE_ELEMENT, element: <ManageWageElements /> },

      // ManageCoeffDeviation

      //Translations
      { path: PATH_MANAGE_TRANSLATIONS, element: <ManageTranslations /> },

      //Margin calculation
      { path: PATH_MARGIN_CALCULATOR, element: <MarginCalculator /> },
      { path: "/margin", element: <MarginProviderWrapper /> },
      //Partena
      { path: PATH_MANAGE_PARTENA, element: <ManagePartena /> },
      { path: PATH_ADD_PARTENA, element: <AddPartenaCalculation /> },
      { path: `${PATH_PARTENA_CALCULATION}/:id`, element: <PartenaCalculation /> },
    ],
  },
]);
