import React from 'react'
import { getRandomInt } from "../utils/utils";
import { dateUtils } from '../utils/dateUtils';
import { useFilterOptions } from '../planning-redux/context/FiltersContext/FiltersContext';
import { PROJECT_VIEW_CHARACTERS } from '../constants/planningConstants';
import { YEAR_KEYWORD } from '../constants/planningConstants';
import { SALES_VIEW_LIST } from '../constants/planningConstants';

function PlannedEventBox({ date, employee_id, bg_color, color, handleWeeklyClickEventOnPlannedUnit, plan = [], frequency, view, name }) {

    const { filterOptionsState } = useFilterOptions();

    const getMainTitle = (eplan, type, truncate = null) => {
        let title = '';
        if (view == 'all') {
            title = (SALES_VIEW_LIST.includes(name)) ? getSuffix(name, eplan['salesvalue'])
                : getTitle(eplan.project_id, type, truncate);
        } else {
            title = getSuffix(name, eplan[view])
        }
        if (eplan.leave) {
            title = getTitle(eplan.code_id, 'holidaycode')
        }
        return title;
    }

    const getSuffix = (key, value) => {
        switch (key) {
            case 'Margin %':
                value = value + "%"
                break;
            case 'Total employee':
                value = value;
                break;

            default:
                value = "€" + value
                break;
        }
        return value;
    }

    const getTitle = (id, type, truncate = null) => {
        let titleArray = filterOptionsState.state[type]
            ?.filter((item) => item['value'] == id)
            ?.map((item) => item['label']) || [];

        // Join the array into a string or return a default string
        let title = titleArray.length ? titleArray.join(', ') : titleArray;
        if (truncate != null && title.length > truncate) {
            return (title.substring(0, truncate)).toUpperCase();
        }
        return title;
    }

    const getBGColor = (bgcolor, eachplan) => {
        let col = 'green';
        if (bgcolor == '' || bgcolor == undefined) {
            if (SALES_VIEW_LIST.includes(name)) {
                col = '#ebebeb';
            } else {
                switch (eachplan.plantype) {
                    case "leave":
                        console.log(filterOptionsState.state.holidaycode);
                        col = 'yellow';
                        break;
                    case "timesheet":
                        col = 'pink';
                        break;
                    case "invoiced":
                        col = 'blue';
                        break;
                    default:
                        break;
                }
            }
        }
        return col;
    }

    const getColor = (colour, eachplan) => {
        let list = ['leave', 'timesheet']
        if (colour == '' || colour == undefined) {
            colour = (SALES_VIEW_LIST.includes(name) || list.includes(eachplan.plantype)) ? 'black' : 'white';
        }
        return colour
    }

    return (
        plan.map((eachplan) => {
            return (
                <div key={getRandomInt(1, 100)}
                    className="e-appointment e-lib e-draggable e-appointment-border d-flex align-items-center justify-content-center"
                    dataid="Appointment_10"
                    role="button"
                    tabIndex="0"
                    aria-disabled="false"
                    aria-label="Planned"
                    data-guid="6a05ce81-bc5b-87cc-087b-faa1bb4ab242"
                    data-group-index="1"
                    aria-grabbed="false"
                    onClick={(e) => handleWeeklyClickEventOnPlannedUnit(e, employee_id, date, eachplan.planid)}
                    style={{
                        color: getColor(color, eachplan),
                        backgroundColor: getBGColor(bg_color, eachplan),
                        flex: 1,
                        height: "100%",
                        borderRadius: "10px",
                        border: "1px solid white"
                    }}>
                    <div className="e-event-resize e-left-handler">
                        <div className="e-left-right-resize"></div>
                    </div>
                    <div className="e-planned_details">
                        <div className="e-inner-wrap">
                        <div
                          data-bs-toggle="tooltip"
                          title={getMainTitle(
                            eachplan,
                            "projects",
                            frequency == YEAR_KEYWORD ? 1 : PROJECT_VIEW_CHARACTERS
                          )}
                        >
                          {getMainTitle(
                            eachplan,
                            "projects",
                            frequency == YEAR_KEYWORD ? 1 : PROJECT_VIEW_CHARACTERS
                          )}
                        </div>
                        </div>
                    </div>
                    {!SALES_VIEW_LIST.includes(name) &&
                        <>
                            <div className='e-planned-details'>
                                {eachplan.leave &&
                                    <>
                                        <div>Leave: True</div>
                                        <div>Holiday code: {getTitle(eachplan.code_id, 'holidaycode')}</div>
                                    </>
                                }
                                {eachplan.plan &&
                                    <>
                                        <div>Company: {getTitle(eachplan.company_id, 'companies')}</div>
                                        <div>Project: {getTitle(eachplan.project_id, 'projects')}</div>
                                        <div>Shift hours: {eachplan.start_time + "-" + eachplan.end_time}</div>
                                        <div>Break hours: {eachplan.break_hours}</div>
                                    </>
                                }
                            </div>
                            <div className="e-event-resize e-right-handler">
                                <div className="e-left-right-resize"></div>
                            </div>
                        </>
                    }
                </div>
            )
        })
    )
}

export default PlannedEventBox
