import React, { useState, useEffect } from "react";
import FormBuilder from "services/form/FormBuilder";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  ADD_CONTRACT_TEMPLATE,
  GET_CONTRACT_TEMPLATES_DATA,
  GET_DROPDOWN_DATA,
  GET_TEMPLATE_BY_ID,
  MODULES,
  STORE_TEMPLATE,
} from "routes/ApiEndpoints";
import { ContractValidation } from "services/validation/ValidationRules";
import {
  PATH_ADD_CONTRACT_TEMPLATE,
  PATH_MANAGE_CONTRACT_TEMPLATE,
} from "constants/Paths";
import { M_MASTER_DATA } from "constants/Constants";
import { ApiCall } from "services/ApiServices";
import { t } from "../translation/Translation";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { Option } from "components/common/CommonInterfaces";
import LabelWithSelectField from "components/molecules/LabelwithSelectField";
import LabelWithCKEditorField from "components/molecules/LabelWithCKEditorField";
import AccessControl from "services/AccessControl";
import {
  validateForm,
  validateMultiSelectField,
  validateRequired,
  validateSelectField,
} from "services/validation/ValidationService";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import Button from "components/atoms/Button";
import CustomNotify from "components/atoms/CustomNotify";
import TitleAtom from "components/atoms/Title";

interface TemplateProps {
  id: string;
  category?: string;
  contractType?: Option;
  documentCategory?: Option;
  businessUnit?: Option | string | number | null | undefined;
  projectTemplate?: Option;
  body?: string;
}
interface TemplateErrors {
  category?: string;
  contractType?: string;
  documentCategory?: string;
  businessUnit?: string;
  projectTemplate?: string;
  body?: string;
}

interface ValidationRules {
  [key: string]: Function[];
}
interface ContractDetails {
  [key: string]: string;
}

export default function AddContractsTemplate() {
  const params = useParams();
  const location = useLocation();
  const [isDataReady, setIsDataReady] = useState(false);
  const path = location.pathname;
  const viewMode = location.pathname.includes("view");

  const isCloneMode = location.pathname.includes("clone");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [displayedTokens, setDisplayedTokens] = useState<{
    [key: string]: string;
  }>({});
  const [formData, setFormData] = useState<TemplateProps>({
    id: "",
    category: "",
    contractType: [],
    documentCategory: [],
    businessUnit: [],
    projectTemplate: [],
    body: "",
  });
  const [optionList, selectedOptionList] = useState<any>({
    contractTypes: [],
    documentTypes: [],
    businessUnits: [],
    projectTemplate: [],
    tokens: [],
  });
  const [id, setId] = useState<number | string | null>(null);
  const navigate = useNavigate();
  const [errors, setErrors] = useState<TemplateErrors>({});

  const extractIdFromUrl = (url: string): number | null => {
    const segments = url.split("/");
    const idSegment = segments.pop();
    const parsedId = idSegment ? parseInt(idSegment, 10) : NaN;
    return isNaN(parsedId) ? null : parsedId;
  };
  useEffect(() => {
    fetchModules().then(() => setIsDataReady(true));
    const idFromUrl = extractIdFromUrl(window.location.href);
    if (idFromUrl !== null) setId(idFromUrl);
  }, []);

  useEffect(() => {
    if (id !== null && isDataReady) {
      fetchTemplateById(id);
    }
  }, [id, isDataReady]);

  const fetchTemplateById = async (id: any) => {
    const url = `${GET_TEMPLATE_BY_ID}/${id}`;
    const response = await ApiCall.getService(url, "GET", M_MASTER_DATA);
    if (response?.status === 200) {
      if (response?.category === 1) {
        setSelectedCategory(response?.category?.toString() || "");
        setFormData((prevData) => ({
          ...prevData,
          category: response.category?.toString() || "",
          contractType: [],
          documentCategory: [],
          businessUnit: [],
          projectTemplate: response?.type,
          body: response?.template,
        }));
        updateDisplayedTokens(
          response.type,
          response.category?.toString() || ""
        );
      } else if (response?.category === 2) {
        setSelectedCategory(response.category?.toString() || "");
        setFormData((prevData) => ({
          ...prevData,
          category: response?.category?.toString() || "",
          contractType: response?.type,
          documentCategory: response?.documentType,
          businessUnit: response?.businessUnit,
          projectTemplate: [],
          body: response?.template,
        }));
        updateDisplayedTokens(
          response.documentType,
          response.category?.toString() || "",
          response.type.label
        );
      } else if (response?.category === 3) {
        setFormData((prevData) => ({
          ...prevData,
          category: response.category?.toString() || "",
          contractType: [],
          documentCategory: [],
          businessUnit: [],
          projectTemplate: [],
          body: response.template,
        }));
      }
    }
  };

  const fetchModules = async () => {
    const response = await ApiCall.getService(
      GET_DROPDOWN_DATA,
      "GET",
      M_MASTER_DATA
    );
    if (response.status === 200) {
      const data = response.data;

      selectedOptionList(response.data);
    }
  };

  const updateDisplayedTokens = (
    selectedOption: Option,
    category?: string,
    contractName?: string
  ) => {
    const effectiveCategory = category || formData.category;

    if (effectiveCategory === "1" && selectedOption && selectedOption.label) {
      setDisplayedTokens(optionList.tokens[selectedOption.label] || {});
    } else if (
      effectiveCategory === "2" &&
      selectedOption &&
      selectedOption.label
    ) {
      const contractTypeName =
        contractName || formData.contractType?.name || "";
      const selectedOptionName = selectedOption?.label;
      const key = `${contractTypeName}_${selectedOptionName}`;
      setDisplayedTokens(optionList.tokens[key] || {});
    } else {
      setDisplayedTokens({});
    }
  };
  const validateSelectFields = (selectedObject: any) => {
    if (
      !selectedObject ||
      selectedObject.value === "" ||
      selectedObject.value == undefined
    ) {
      return t("This field is required");
    }
    return null;
  };
  const validation = (
    name: string,
    value: Option | string | number | null | undefined,
    isSingleFieldValidation: boolean = false
  ): boolean => {
    // Define validation rules
    const validationRules: ValidationRules = {
      category: [validateSelectField],
      body: [validateCkeditorData],
    };

    if (selectedCategory === "2") {
      validationRules.contractType = [validateSelectFields];
      validationRules.documentCategory = [validateSelectFields];
      validationRules.businessUnit = [validateMultiSelectField];
    } else if (selectedCategory === "1") {
      validationRules.projectTemplate = [validateSelectFields];
    }

    const validationErrors = validateForm(
      { ...formData, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );
    if (isSingleFieldValidation) {
      setErrors((prevErrors: TemplateErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
      return !validationErrors[name];
    } else {
      setErrors(validationErrors);
      return Object.keys(validationErrors).length === 0;
    }
  };

  const validateCkeditorData = (value: string): string | null | undefined => {
    const spaceValueRegex = /^<p>&nbsp;<\/p>$/;
    if (spaceValueRegex.test(value) || !value) {
      return t("This field is required");
    }
    return null;
  };

  const handleCKEditorChange = (event: any, editor: any, fieldName: string) => {
    const content = editor.getData();
    const name = fieldName;
    setFormData((prevData) => ({ ...prevData, [name]: content }));
    validation(name, content, true);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const relevantFields: TemplateProps = {
      category: formData.category,
      body: formData.body,
      id: "",
    };
    // Only set id if not in clone mode
    if (!isCloneMode) {
      if (id !== null) {
        relevantFields.id = id.toString();
      }
    }
    if (selectedCategory === "2") {
      relevantFields.contractType = formData.contractType;
      relevantFields.documentCategory = formData.documentCategory;
      relevantFields.businessUnit = formData.businessUnit;
    } else if (selectedCategory === "1") {
      relevantFields.projectTemplate = formData.projectTemplate;
    }
    const validationResults = Object.entries(relevantFields).map(
      ([key, value]) => {
        return validation(key as keyof TemplateProps, value, true);
      }
    );
    const isValid = !validationResults.includes(false);
    if (!isValid) {
      return false;
    }
    if (isValid) {
      const response = await ApiCall.service(
        ADD_CONTRACT_TEMPLATE,
        "POST",
        relevantFields,
        false,
        M_MASTER_DATA
      ); //api call to store formdata
      if (response.status === 200) {
        CustomNotify({ type: "success", message: response.message });
        navigate(`${PATH_MANAGE_CONTRACT_TEMPLATE}`);
      } else {
        CustomNotify({ type: "error", message: response.message });
      }
    }
  };

  const handleSelectChange = (selectedOption: Option, name: string) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: selectedOption,
    }));
    if (name === "projectTemplate") {
      updateDisplayedTokens(selectedOption);
    }
    if (name === "documentCategory") {
      updateDisplayedTokens(selectedOption);
    }
    if (name === "contractType") {
      setFormData((prevData) => ({
        ...prevData,
        documentCategory: [],
      }));
    }

    validation(name, selectedOption, true);
    if (name === "category") {
      setSelectedCategory(selectedOption.value?.toString() || "");
      setFormData((prevData) => ({
        ...prevData,
        category: selectedOption.value?.toString() || "",
        contractType: [],
        documentCategory: [],
        businessUnit: [],
        projectTemplate: [],
        body: "",
      }));
      setDisplayedTokens({});
      setErrors({});
    }
    //validation(name, selectedOption, true);
  };
  const handleBack = () => {
    navigate(PATH_MANAGE_CONTRACT_TEMPLATE);
  };

  const userData = useSelector(selectAuth);
  const isCategoryDisabled =
    (viewMode && !isCloneMode) || (!viewMode && isCloneMode);

  return (
    <>
      <AccessControl
        requiredPermissions={[
          {
            permission: "Contract template",
            create: true,
          },
        ]}
        renderNoAccess={true}
        override={userData.isSuperAdmin}
      >
        <>
          <div className="search-bar">
            <TitleAtom title={t("Add template")} />
          </div>
          <form>
            <div className="form-border" style={{ paddingBottom: "1vw" }}>
              <div className="row">
                <div className="col-3">
                  <SelectWithSearch
                    title={t("Category")}
                    name="category"
                    id="category"
                    isMandatory={true}
                    search={true}
                    options={[
                      { value: 1, label: "Project Proposal" },
                      { value: 2, label: "Employee" },
                      { value: 3, label: "Temp Agency" },
                    ]}
                    placeHolder="Select"
                    value={formData.category}
                    onChange={(e) => handleSelectChange(e, "category")}
                    isMulti={false}
                    className="select-field"
                    error={errors.category}
                    isDisabled={isCategoryDisabled}
                  />
                </div>

                {selectedCategory === "2" && (
                  <>
                    <div className="col-3">
                      <SelectWithSearch
                        title={t("Contract type")}
                        name="contractType"
                        id="contractType"
                        isMandatory={true}
                        search={true}
                        options={optionList.contractTypes}
                        placeHolder="Select"
                        value={formData.contractType}
                        onChange={(e) => handleSelectChange(e, "contractType")}
                        isMulti={false}
                        className="select-field"
                        error={errors.contractType}
                        isDisabled={viewMode}
                      />
                    </div>
                    <div className="col-3">
                      <SelectWithSearch
                        title={t("Document category")}
                        name="documentCategory"
                        id="documentCategory"
                        isMandatory={true}
                        search={true}
                        options={optionList.documentTypes}
                        placeHolder="Select"
                        value={formData.documentCategory}
                        onChange={(e) =>
                          handleSelectChange(e, "documentCategory")
                        }
                        isMulti={false}
                        className="select-field"
                        error={errors?.documentCategory}
                        isDisabled={viewMode}
                      />
                    </div>
                    <div className="col-3">
                      <SelectWithSearch
                        title={t("Business unit")}
                        name="businessUnit"
                        id="businessUnit"
                        isMandatory={true}
                        search={true}
                        options={optionList.businessUnits}
                        placeHolder="Select"
                        value={formData.businessUnit}
                        onChange={(e) => handleSelectChange(e, "businessUnit")}
                        isMulti={true}
                        className="select-field"
                        error={errors?.businessUnit || ""}
                        isDisabled={viewMode}
                      />
                    </div>
                  </>
                )}
                {selectedCategory === "1" && (
                  <div className="col-3">
                    <SelectWithSearch
                      title={t("Project template")}
                      name="projectTemplate"
                      id="projectTemplate"
                      isMandatory={true}
                      search={true}
                      options={optionList.projectTemplate}
                      placeHolder="Select"
                      value={formData.projectTemplate}
                      onChange={(e) => handleSelectChange(e, "projectTemplate")}
                      isMulti={false}
                      className="select-field"
                      error={errors?.projectTemplate}
                      isDisabled={viewMode}
                    />
                  </div>
                )}

                <div className="col-md-12">
                  <LabelWithCKEditorField
                    label={t("Body")}
                    name="body"
                    value={formData.body}
                    placeholder={t("Type here...")}
                    handleChange={(event, editor) =>
                      handleCKEditorChange(event, editor, "body")
                    }
                    isMandatory={true}
                    error={errors?.body}
                    isDisabled={viewMode}
                  />
                </div>
              </div>
            </div>
            <div className="row" style={{ padding: "1vw 0" }}>
              <div className="col-6 ps-0 align-self-center">
                <Button
                  title={t("Back")}
                  handleClick={handleBack}
                  className="btn back-btn shadow-none text-decoration-underline text-uppercase border-0"
                />
              </div>
              {!viewMode && (
                <div className="col-6 text-end">
                  <Button
                    title={t("Save")}
                    handleClick={handleSubmit}
                    className="form-button float-end px-2"
                  />
                </div>
              )}
            </div>
          </form>
          <div className="table-responsive tableSection p-0 border-0 rounded-0">
            <div style={{ maxHeight: "calc(100vh - 30vw)", overflow: "auto" }}>
              <table className="table table-hover">
                <thead>
                  <tr className="TableHeader">
                    <th style={{ width: "50%" }}>{t("Name")}</th>
                    <th>{t("Value")}</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(displayedTokens).length > 0 ? (
                    Object.entries(displayedTokens).map(
                      ([token, description]) => (
                        <tr key={token}>
                          <td>{token}</td>
                          <td>{description}</td>
                        </tr>
                      )
                    )
                  ) : (
                    <tr>
                      <td className="text-center py-3" colSpan={2}>
                        <span className="text-danger">{t("No tokens")}</span>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </>
      </AccessControl>
    </>
  );
}
