import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ModalPopup from "components/atoms/ModalPopup";
import { ApiCall } from "services/ApiServices";
import CustomNotify from "components/atoms/CustomNotify";
import { t } from "pages/microservices/masterData/translation/Translation";
import {
  PATH_CREATE_PURCHASE_ORDER,
  PATH_EDIT_PURCHASE_ORDER,
  PATH_VIEW_PURCHASE_ORDER,
} from "constants/Paths";
import TableActions from "components/organism/Actions/TableAction";
import { useNavigate } from "react-router-dom";
import { PURCHASE_ORDER_DELETE } from "routes/ApiEndpoints";
import { ERROR, M_MASTER_DATA } from "constants/Constants";
import Pagination from "components/atoms/Pagination";
import { getStatusColor, getTooltipMessage } from "services/util/UtilService";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import FilterElementsRender from "../FilterElement";
import { fetchOptions } from "../utils/FetchOptions";
import Button from "components/atoms/Button";
import { IconData } from "services/IconData";
import Title from "components/atoms/Title";
import LabelField from "components/atoms/LabelField";
import ResetBtn from "components/atoms/ResetBtn";
import SearchBtn from "components/atoms/SearchBtn";
import LinkTo from "components/atoms/LinkTo";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

interface ManagePurchaseOrderProps {
  data?: any[];
  handleData: any;
  currentPage: number;
  totalPages: number;
  onPageChange: (pageNumber: number) => void;
  itemsPerPage: number;
  searchValue: (name: string, value: string | number) => void;
  handleSubmitAction: (clear: boolean) => void;
  searchData: any;
  handleGetVacany: any;
}

interface CompanyItem {
  value: number;
  label: string;
}

const ManagePurchaseOrder: React.FC<ManagePurchaseOrderProps> = ({
  data,
  handleData,
  currentPage,
  totalPages,
  onPageChange,
  itemsPerPage,
  searchValue,
  handleSubmitAction,
  searchData,
  handleGetVacany,
}) => {
  const [id, setId] = useState<number | undefined>(undefined);
  const [showModal, setShowModal] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    businessUnits: [],
    status: [],
  });

  const navigate = useNavigate();

  const handleSearchChange = (event: any, name: string, type: string) => {
    switch (type) {
      case "text":
      case "date":
        return searchValue(name, event.target.value);

      case "select":
        return searchValue(name, event.value);
    }
  };

  const handleSaveChanges = async () => {
    setShowModal(false);
    let postData = {
      id: id,
    };

    if (id) {
      const response = await ApiCall.service(
        PURCHASE_ORDER_DELETE,
        "POST",
        postData,
        false,
        M_MASTER_DATA
      );

      if (response?.status === 200) {
        CustomNotify({ type: "success", message: response.message });
        handleGetVacany({
          page: 1,
          nOR: 10,
          search: {
            name: "",
          },
        });
      } else {
        CustomNotify({ type: "error", message: response.message });
      }
    }
  };

  useEffect(() => {
    getFilterData();
  }, []);

  const getFilterData = async () => {
    try {
      const filterData = await fetchOptions();
      if (filterData?.status == 200) {
        setFilterOptions((prevState: any) => ({
          ...prevState,
          businessUnits: filterData.data.businessUnit,
          status: filterData.data.status,
        }));
      } else {
        CustomNotify({ type: "error", message: filterData.message });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleModalClose = () => setShowModal(!showModal);

  const handleSubmit = (e: any, clear: boolean = false) => {
    e.preventDefault();
    handleSubmitAction(clear);
  };

  const handleView = (id: number) => {
    navigate(`${PATH_VIEW_PURCHASE_ORDER}/${id}`);
  };

  const handleEdit = (id: number) => {
    navigate(`${PATH_EDIT_PURCHASE_ORDER}/${id}`);
  };

  const handleDelete = (id: number) => {
    setShowModal(true);
    setId(id);
  };
  const userData = useSelector(selectAuth);

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "PurchaseOrder",
          read: true,
        },
      ]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <>
        <div className="form-height-dashboard">
          <div className="search-bar">
            <Title title={t("Manage purchase order")} />
            <div className="row">
              <FilterElementsRender
                data={searchData?.company}
                type="text"
                handleChange={(event) =>
                  handleSearchChange(event, "company", "text")
                }
                value={searchData?.company}
                placeHolder={"Company"}
                name="company"
              />

              <FilterElementsRender
                data={filterOptions.businessUnits}
                type="multi-select"
                handleChange={(event) =>
                  handleSearchChange(event, "businessUnit", "select")
                }
                value={searchData?.businessUnit}
                placeHolder={"Business unit"}
                name="businessUnit"
              />

              <FilterElementsRender
                data={searchData?.brand}
                type="text"
                handleChange={(event) =>
                  handleSearchChange(event, "brand", "text")
                }
                value={searchData?.brand}
                placeHolder={"Brand"}
                name="brand"
              />
              <FilterElementsRender
                data={searchData?.model}
                type="text"
                handleChange={(event) =>
                  handleSearchChange(event, "model", "text")
                }
                value={searchData?.model}
                placeHolder={"Model"}
                name="model"
              />

              <FilterElementsRender
                data={filterOptions.status}
                type="multi-select"
                handleChange={(event) =>
                  handleSearchChange(event, "status", "select")
                }
                value={searchData?.status}
                placeHolder={"Status"}
                name="status"
              />
              <div className="col-md-4 col-lg-2">
                <div className="d-flex align-items-center justify-content-end">
                  <ResetBtn handleResetClick={(e) => handleSubmit(e, true)} />
                  <SearchBtn handleSearchClick={handleSubmit} />
                </div>
              </div>
            </div>
          </div>
          <div className="position-relative tableMainWrapper">
            <div>
              <AccessControl
                requiredPermissions={[
                  {
                    permission: "PurchaseOrder",
                    create: true,
                  },
                ]}
                override={userData.isSuperAdmin}
              >
                <div className="row">
                  <div className="ManageCreateBtn">
                    <LinkTo
                      pagelink={PATH_CREATE_PURCHASE_ORDER}
                      title={t("Create purchase order")}
                      icon={faPlus}
                    />
                  </div>
                </div>
              </AccessControl>
            </div>
            <div className="table-responsive manage-projects tableSection">
              <div className="managePurchaseOrderHeight">
                <table className="table table-hover">
                  <thead>
                    <tr className="TableHeader">
                      <th style={{ width: "20%" }}>{t("Company")}</th>
                      <th style={{ width: "15%" }}>{t("Business unit")}</th>
                      <th style={{ width: "15%" }}>{t("Brand")}</th>
                      <th style={{ width: "15%" }}>{t("Model ")}</th>
                      <th style={{ width: "8%" }}>{t("Status")}</th>
                      <th style={{ width: "15%" }}>
                        <AccessControl
                          requiredPermissions={[
                            {
                              permission: "PurchaseOrder",
                              update: true,
                              delete: true,
                            },
                          ]}
                          actions={true}
                          strict={false}
                          override={userData.isSuperAdmin}
                        >
                          {t("Action")}
                        </AccessControl>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data && data.length > 0 ? (
                      data.map((record, key) => {
                        return (
                          <tr key={key}>
                            <td className="align-middle">
                              {record.ownerCompany}
                            </td>
                            {record.businessUnit &&
                            record.businessUnit.length > 0 ? (
                              <td className="align-middle">
                                {record.businessUnit
                                  .map((fun: any) => fun.label)
                                  .join(", ")}
                              </td>
                            ) : (
                              <td></td>
                            )}
                            <td className="align-middle">{record.brand}</td>
                            <td className="align-middle">{record.model}</td>
                            <td className="align-middle">
                              <div
                                className={getStatusColor(record.status)}
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title={getTooltipMessage(record.status)}
                              ></div>
                            </td>
                            <td className="align-middle">
                              <TableActions
                                isAction={true}
                                {...(record.status == 1
                                  ? {
                                      handleEdit: handleEdit,
                                      handleArchive: handleDelete,
                                    }
                                  : { handleView: handleView })}
                                value={record.id}
                                permission={"PurchaseOrder"}
                                signature={record.project_pdf_path}
                              />
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={6} className="text-center">
                          {t("No records found")}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div
                className="pagination justify-content-center align-items-center"
                style={{ paddingTop: "0.25vw" }}
              >
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={onPageChange}
                />
              </div>
            </div>
          </div>
        </div>
        <ModalPopup
          show={showModal}
          onHide={handleModalClose}
          title={t("Archive confirmation")}
          body={t("Are you sure want to archive?")}
          onCloseButtonClick={handleModalClose}
          onConfirmButtonClick={handleSaveChanges}
          closeTitle="No"
          confirmTitle="Yes"
        />
      </>
    </AccessControl>
  );
};
export default ManagePurchaseOrder;
