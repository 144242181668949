import InputTextfield from "components/atoms/InputTextField";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import React from "react";

// Define the types for the props
interface FilterElementsRenderProps {
  data: any;
  type: string;
  handleChange: (
    event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => void;
  value: any;
  placeHolder: string;
  name: string;
  searchclass?:string;
}

const FilterElementsRender: React.FC<FilterElementsRenderProps> = ({
  data,
  type,
  handleChange,
  value,
  placeHolder,
  name,
  searchclass
}) => {
  switch (type) {
    case "multi-select":
      return (
        <div className={`col-md-4 col-lg-2 ${searchclass}`}>
          <SelectWithSearch
            name={name}
            id={name}
            isMandatory={false}
            search={true}
            placeHolder={placeHolder}
            options={data}
            value={value}
            onChange={handleChange}
            isMulti={false}
            className="select-field"
            // title={placeHolder}
            containerClassName="mb-0"
          />
        </div>
      );
    case "text":
    case "date":
      return (
        <div className={`col-md-4 col-lg-2 ${searchclass}`}>
          <LabelWithInputField
            errorclass="d-none"
            type={type}
            value={value}
            handleChange={handleChange}
            name={name}
            placeholder={placeHolder}
            // label={placeHolder}
          />
        </div>
      );

    default:
      return null; // Return null if no case matches
  }
};

export default FilterElementsRender;
