import { useFormContext } from "pages/microservices/companyCreation/context/Context";
import Button from "components/atoms/Button";
import { Navigation } from "../formNavigation/Navigation";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import LabelField from "components/atoms/LabelField";
import PhoneInput from "react-phone-number-input";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { useEffect, useState } from "react";
import { ValidationRules } from "utils/TypeAnnotations";
import {
  validateForm,
  validateRequired,
  validateTextFieldAlpha,
  validateEmail,
  validatePhoneNumber,
  validateSelectField,
  scrollToTop,
} from "services/validation/ValidationService";
import { ApiCall } from "services/ApiServices";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as END_POINTS from "routes/ApiEndpoints";
import CustomNotify from "components/atoms/CustomNotify";
import LabelWithTextAreaField from "components/molecules/LabelWithTextAreaField";
import { M_COMPANY_CREATION } from "constants/Constants";
import { E164Number } from "libphonenumber-js";
import {
  companyLocationProps,
  ContactProps,
} from "../../annotations/CompanyAnnotations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { t } from "pages/microservices/masterData/translation/Translation";
import DeleteIcon from "static/images/DeleteIcon";
import EditIcon from "static/images/EditIcon";
import ViewIcon from "static/images/ViewIcon";
const Contacts = () => {
  const { state, dispatch } = useFormContext();

  const navigate = useNavigate();
  const [validationStatus, setValidationStatus] = useState({
    isValid: false,
    type: "",
  });
  const [loading, setLoading] = useState(false);

  const { companyId } = useParams<{ companyId: string }>();
  const location = useLocation();
  // Check if 'mode=view' is present in the query string
  const isViewMode = location.search.includes("mode=view");

  const validation = (
    name: string,
    value: any,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      fName: [validateRequired, validateTextFieldAlpha],
      lName: [validateRequired, validateTextFieldAlpha],
      email: [validateRequired, validateEmail],
      mobileNumber: [validatePhoneNumber],
    };
    const validationErrors = validateForm(
      { ...state.contacts, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    const isFieldValid = Object.keys(validationErrors).length === 0;

    if (isFieldValid) {
      state.fieldError[name] = "";
    } else {
      state.fieldError[name] = validationErrors[name];
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }

    return true;
  };

  const handleFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value, type } = event.target;
    const processedValue = type === "number" ? String(value) : value;
    if (name === "phoneNumber" && /\D/.test(value)) {
      return;
    }
    dispatch({
      type: "UPDATE_CONTACTS_FIELD",
      field: name,
      value: processedValue,
    });
    validation(name, processedValue, true);
  };

  const handlePhoneNumberChange = (
    mobileNumber: string | E164Number | undefined
  ) => {
    const processedValue = mobileNumber ? String(mobileNumber) : "";
    dispatch({
      type: "UPDATE_CONTACTS_FIELD",
      field: "mobileNumber",
      value: processedValue,
    });
    validation("mobileNumber", processedValue, true);
  };
  const validateAllFields = (): boolean => {
    // Track overall validity
    let allFieldsValid = true;

    Object.keys(state.contacts).forEach((key) => {
      const value = state.contacts[key as keyof ContactProps];
      const isFieldValid = validation(key, value, true);
      allFieldsValid = allFieldsValid && isFieldValid; // Combine results
    });

    return allFieldsValid; // Return overall validity
  };
  const handleAddContact = () => {
    const isValid = validateAllFields();
    if (isValid) {
      dispatch({
        type: "UPDATE_CONTACTPERSON_TABLE_FIELD",
        value: state.contacts,
      });
    }
    const comapanyContactPersonFields = [
      { field: "userId", value: "" },
      { field: "fName", value: "" },
      { field: "fName", value: "" },
      { field: "lName", value: "" },
      { field: "email", value: "" },
      { field: "location", value: "" },
      { field: "mobileNumber", value: "" },
      { field: "language", value: "" },
      { field: "info", value: "" },
      { field: "roles", value: "" },
      { field: "businessUnit", value: "" },
    ];
    comapanyContactPersonFields.forEach((field) => {
      dispatch({
        type: "UPDATE_CONTACTS_FIELD",
        field: field.field,
        value: field.value,
      });
    });
  };
  const currentDate = new Date();
  const below15 = new Date();
  below15.setFullYear(currentDate.getFullYear() - 15);

  const handleSelectChange = (selectedOption: any, name: string) => {
    dispatch({
      type: "UPDATE_CONTACTS_FIELD",
      field: name,
      value: selectedOption,
    });
    validation(name, selectedOption, true);
  };

  const validStatus = (validation: any) => {
    setValidationStatus({
      isValid: validation.isValid,
      type: validation.type,
    });
  };

  const checkForDuplicates = () => {
    const emailSet = new Set();
    const phoneSet = new Set();
    let hasDuplicate = false;

    // state.contacts.forEach((contact, index) => {
    //   if (contact.email.trim() !== "") {
    //     if (emailSet.has(contact.email)) {
    //       state.dynamicErrors[index].email = "Duplicate email";
    //       hasDuplicate = true;
    //     } else {
    //       emailSet.add(contact.email);
    //     }
    //   }

    //   if (contact.mobileNumber.trim() !== "") {
    //     if (phoneSet.has(contact.mobileNumber)) {
    //       state.dynamicErrors[index].mobileNumber = "Duplicate phone number";
    //       hasDuplicate = true;
    //     } else {
    //       phoneSet.add(contact.mobileNumber);
    //     }
    //   }
    // });

    return hasDuplicate;
  };
  // This handle final submit of the form with all the details
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const duplicates = checkForDuplicates();
    let finalCompanyLocation = null;
    let finalCompanyContact = null;
    if (state.companyLocationTable.length === 0) {
      finalCompanyLocation = [state.companyLocation];
    } else {
      const specificLocationFields: Array<keyof companyLocationProps> = [
        "locationName",
        "locationPhoneNumber",
        "locationStreet",
        "locationNumber",
        "locationZipCode",
        "locationCountry",
        "locationCity",
      ];

      // Check if all specific fields are filled
      const areSpecificFieldsFilled = specificLocationFields.every((field) => {
        const value = state.companyLocation[field];

        // Check if value is a string and apply trim if needed
        if (typeof value === "string") {
          return value.trim() !== ""; // Check for non-empty strings after trimming
        }

        // Otherwise, check for non-null and non-undefined values (for other types)
        return value !== null && value !== undefined;
      });

      // Initialize finalCompanyContact with existing entries
      finalCompanyLocation = [...state.companyLocationTable];

      // Add to finalCompanyContact only if all of the specific fields are filled
      if (areSpecificFieldsFilled) {
        finalCompanyLocation.push(state.companyLocation);
      }
    }

    if (state.companyContactPersonTable.length === 0) {
      finalCompanyContact = [state.contacts];
    } else {
      const specificFields: Array<keyof ContactProps> = [
        "fName",
        "lName",
        "email",
        "mobileNumber",
      ];

      const areSpecificFieldsFilled = specificFields.every((field) => {
        const value = state.contacts[field];

        // Check if value is a string and apply trim if needed
        if (typeof value === "string") {
          return value.trim() !== ""; // Check for non-empty strings after trimming
        }

        // Otherwise, check for non-null and non-undefined values (for other types)
        return value !== null && value !== undefined;
      });

      // Initialize finalCompanyContact with existing entries
      finalCompanyContact = [...state.companyContactPersonTable];

      // Add to finalCompanyContact only if all of the specific fields are filled
      if (areSpecificFieldsFilled) {
        finalCompanyContact.push(state.contacts);
      }
    }
    const formData = {
      basic: state.basic,
      address: state.address,
      companyLocation: finalCompanyLocation,
      contacts: finalCompanyContact,
    };
    formData.basic.companyId = companyId;
    if (!duplicates && validationStatus.isValid) {
      setLoading(true);
      const url = !companyId
        ? END_POINTS.REGISTER_COMPANY
        : `${END_POINTS.REGISTER_COMPANY}/${companyId}`;
      const response = await ApiCall.service(
        url,
        "POST",
        formData,
        true,
        M_COMPANY_CREATION
      );

      if (response?.status === 422) {
        CustomNotify({
          type: "warning",
          message: response?.msg,
        });
      }
      if (response?.status === 200) {
        const msg =
          state.basic.companyStatus === 1
            ? "Company updated successfully!"
            : "Company created successfully!";
        CustomNotify({
          type: "success",
          message: msg,
        });
        navigate("/manage-companies");
      } else if (response?.status === 400) {
        CustomNotify({ type: "error", message: response?.msg });
        dispatch({ type: "UPDATE_TAB_ERROR", tabIndex: 0, error: true });
        scrollToTop();
        state.fieldError.vat = response?.msg;
      }

      // }
      setLoading(false);
    }
  };
  const handleEdit = (entryId: number) => {
    dispatch({
      type: "EDIT_COMPANYCONTACT_PERSON_FIELD",
      index: entryId,
    });
    const comapanyContactPersonFields = [
      { field: "userId", value: "" },
      { field: "id", value: "" },
      { field: "fName", value: "" },
      { field: "lName", value: "" },
      { field: "email", value: "" },
      { field: "location", value: "" },
      { field: "mobileNumber", value: "" },
      { field: "language", value: "" },
      { field: "info", value: "" },
      { field: "roles", value: "" },
      { field: "businessUnit", value: "" },
    ];
    comapanyContactPersonFields.forEach((field) => {
      dispatch({
        type: "UPDATE_FIELD_ERROR",
        fieldName: field.field,
        error: field.value,
      });
    });
  };

  const handleArchive = (deleteId: number) => {
    dispatch({
      type: "DELETE_COMPANYCONTACT_PERSON_INDEX",
      index: deleteId,
    });
  };
  const handleSaveParticularData = () => {
    dispatch({
      type: "UPDATE_COMPANYCONTACT_PERSON_EDITED_FIELD",
      value: state.contacts,
    });
    const comapanyContactPersonFields = [
      { field: "userId", value: "" },
      { field: "id", value: "" },
      { field: "fName", value: "" },
      { field: "lName", value: "" },
      { field: "email", value: "" },
      { field: "location", value: "" },
      { field: "mobileNumber", value: "" },
      { field: "language", value: "" },
      { field: "info", value: "" },
      { field: "roles", value: "" },
      { field: "businessUnit", value: "" },
    ];
    comapanyContactPersonFields.forEach((field) => {
      dispatch({
        type: "UPDATE_CONTACTS_FIELD",
        field: field.field,
        value: field.value,
      });
    });
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="form-border" style={{ paddingTop: "3vw" }}>
        {state.companyContactPersonTable.length > 0 && (
          <div className="bg-white locationBg">
            <table className="table table-hover">
              <thead className="TableHeader">
                <tr>
                  <th>{t("Name")}</th>
                  <th>{t("Email")}</th>
                  <th>{t("Mobile number")}</th>
                  <th>{t("Action")}</th>
                </tr>
              </thead>
              <tbody>
                {state.companyContactPersonTable.map(
                  (eachContactPersonData: any, index: number) => (
                    <tr key={index}>
                      <td className="align-middle">{`${eachContactPersonData.fName} ${eachContactPersonData.lName}`}</td>
                      <td className="align-middle">
                        {eachContactPersonData.email}
                      </td>
                      <td className="align-middle">
                        {eachContactPersonData.mobileNumber}
                      </td>
                      <td className="table-action-icons">
                        <span
                          onClick={() => handleEdit(index)}
                          className="table-action-btn me-2"
                          title={isViewMode ? t("View") : t("Edit")}
                        >
                          {isViewMode ? <ViewIcon /> : <EditIcon />}
                        </span>
                        {!isViewMode && (
                          <span
                            onClick={() => handleArchive(index)}
                            className="table-action-btn me-2"
                            title={t("Delete")}
                          >
                            <DeleteIcon />
                          </span>
                        )}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        )}
        <div className="row">
          <div className="col-sm-12 col-md-4">
            <LabelWithInputField
              isMandatory={true}
              name="fName"
              handleChange={(event) => handleFieldChange(event)}
              value={state.contacts.fName ?? ""}
              label="First name"
              placeholder="First name"
              error={state.fieldError.fName}
              isDisabled={isViewMode}
            />
          </div>
          <div className="col-sm-12 col-md-4">
            <LabelWithInputField
              isMandatory={true}
              name="lName"
              handleChange={(event) => handleFieldChange(event)}
              value={state.contacts.lName ?? ""}
              label="Last name"
              placeholder="Last name"
              error={state.fieldError.lName}
              isDisabled={isViewMode}
            />
          </div>

          <div className="col-sm-12 col-md-4">
            <LabelWithInputField
              isMandatory={true}
              name="email"
              handleChange={(event) => handleFieldChange(event)}
              value={state.contacts.email ?? ""}
              label="Email"
              placeholder="Email"
              type="email"
              error={state.fieldError.email}
              isDisabled={isViewMode}
            />
          </div>
          <div className="col-sm-12 col-md-4">
            <LabelField
              title="Mobile number"
              isMandatory={true}
              key="PhoneInput"
            />
            <div className="form-control field-shadow">
              <PhoneInput
                defaultCountry="BE"
                international
                placeholder=""
                onChange={(mobileNumber: E164Number) =>
                  handlePhoneNumberChange(mobileNumber)
                }
                value={
                  state.contacts.mobileNumber
                    ? String(state.contacts.mobileNumber)
                    : ""
                }
                disabled={isViewMode}
              />
            </div>
            <div
              className="height-20 text-danger"
              style={{ marginBottom: "0.5vw", marginTop: "0.5vw" }}
            >
              {state.fieldError?.mobileNumber && (
                <span className="text-danger">
                  {state.fieldError?.mobileNumber}
                </span>
              )}
            </div>
          </div>

          <div className="col-sm-12 col-md-4">
            <SelectWithSearch
              title="Language"
              name="language"
              isMandatory={false}
              search={true}
              options={state.languageList}
              placeHolder="Select"
              value={state.contacts.language}
              onChange={(e) => handleSelectChange(e, "language")}
              isMulti={false}
              className="select-field"
              error={""}
              isDisabled={isViewMode}
            />
          </div>
          <div className="col-sm-12 col-md-4">
            <SelectWithSearch
              title="Roles"
              name="role"
              isMandatory={false}
              search={true}
              options={state.rolesList}
              placeHolder="Select"
              value={state.contacts.roles ?? ""}
              onChange={(e) => handleSelectChange(e, "roles")}
              isMulti={true}
              className="select-field"
              isDisabled={isViewMode}
              error={""}
            />
          </div>
          <div className="col-sm-12 col-md-4">
            <SelectWithSearch
              title="Location"
              name="location"
              isMandatory={false}
              search={true}
              options={state.locationList}
              placeHolder="Select"
              value={state.contacts.location ?? ""}
              onChange={(e) => handleSelectChange(e, "location")}
              isMulti={true}
              className="select-field"
              isDisabled={isViewMode}
              error={""}
            />
          </div>
          <div className="col-sm-12 col-md-4">
            <SelectWithSearch
              title="Business unit"
              name="businessUnit"
              isMandatory={false}
              search={true}
              options={state.bussinessUnitList}
              placeHolder="Select"
              value={state.contacts.businessUnit ?? ""}
              onChange={(e) => handleSelectChange(e, "businessUnit")}
              isMulti={true}
              className="select-field"
              isDisabled={isViewMode}
              error={""}
            />
          </div>
          <div className="col-lg-12">
            <LabelWithTextAreaField
              name="info"
              handleChange={(event) => handleFieldChange(event)}
              value={state.contacts.info ?? ""}
              label="Info"
              isDisabled={isViewMode}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            {!isViewMode && (
              <Button
                title={
                  state.contacts.id !== "" && state.contacts.id !== null
                    ? t("Save")
                    : t("+ Add another")
                }
                handleClick={
                  state.contacts.id !== "" && state.contacts.id !== null
                    ? handleSaveParticularData
                    : handleAddContact
                }
                className="form-button float-end"
              />
            )}
          </div>
        </div>
      </div>

      <div style={{ padding: "1vw 0" }}>
        <Navigation
          validStatus={validStatus}
          // isLoading={loading}
          isLoading={false}
        />
      </div>
    </form>
  );
};

export default Contacts;
