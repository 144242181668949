import React, { useState } from "react";
import TitleFieldMolecule from "components/molecules/TitleField";
import { CompetenceHeader } from "TableHeader";
import { Link, useNavigate } from "react-router-dom";
import { t } from "pages/microservices/masterData/translation/Translation";
import { COMPETENCE_ARCHIVE } from "routes/ApiEndpoints";
import { ApiCall } from "services/ApiServices";
import CustomNotify from "components/atoms/CustomNotify";
import Search from "components/atoms/Search";
import Pagination from "components/atoms/Pagination";
import ModalPopup from "components/atoms/ModalPopup";
import DynamicTableStructure from "components/atoms/DynamicTableStructure";
import { M_MASTER_DATA } from "constants/Constants";
import {
  PATH_COMPETENCE_CREATE,
  PATH_COMPETENCE_EDIT,
  PATH_COMPETENCE_VIEW,
  PATH_MASTER_DATA,
} from "constants/Paths";
import { Competence } from "./Manage";
import Button from "components/atoms/Button";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import { IconData } from "services/IconData";
import Title from "components/atoms/Title";
import LinkTo from "components/atoms/LinkTo";
import LabelField from "components/atoms/LabelField";
import ResetBtn from "components/atoms/ResetBtn";
import SearchBtn from "components/atoms/SearchBtn";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

interface ManageCompetencePageProps {
  competenceObject: Competence[];
  currentPage: number;
  totalPages: number;
  onPageChange: (pageNumber: number) => void;
  searchValue: (name: string, value: string | number) => void;
  deleteFilter: (deleteId: number) => void;
  handleSubmitAction: (clear: boolean) => void;
  searchData: any;
}

const ManageCompetencePage: React.FC<ManageCompetencePageProps> = ({
  competenceObject,
  currentPage,
  totalPages,
  onPageChange,
  searchValue,
  deleteFilter,
  handleSubmitAction,
  searchData,
}) => {
  const [deleteID, setDeleteId] = useState<number | undefined>();
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  const handleSearchChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    const debounceTime = 10;
    clearTimeout((handleSearchChange as any).timeoutId);
    (handleSearchChange as any).timeoutId = setTimeout(() => {
      searchValue(name, value);
    }, debounceTime);
  };

  const handleModalClose = () => setShowModal(!showModal);
  const handleSaveChanges = async () => {
    setShowModal(false);
    if (deleteID) {
      const url = `${COMPETENCE_ARCHIVE}/${deleteID}`;
      const response = await ApiCall.getService(url, "GET", M_MASTER_DATA);
      if (response?.status === 200) {
        deleteFilter(deleteID);
        CustomNotify({ type: "success", message: response.message });
      }
    }
  };

  const handleSubmit = (e: any, clear: boolean = false) => {
    e.preventDefault();
    handleSubmitAction(clear);
  };

  const handleDelete = (Competence: Competence | any) => {
    setShowModal(true);
    setDeleteId(Competence.id);
  };

  const handleEdit = (competence: Competence | any) => {
    if (competence.id) {
      navigate(`${PATH_COMPETENCE_EDIT}/${competence.id}`);
    }
  };

  const handleView = (competence: Competence | any) => {
    if (competence.id) {
      navigate(`${PATH_COMPETENCE_VIEW}/${competence.id}`);
    }
  };

  const userData = useSelector(selectAuth);

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Competence",
          read: true,
        },
      ]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <div className="search-bar">
        <Title title={t("Manage competence")} />
        <div className="row">
          <div className="col-8">
            <Search
              className="form-control"
              handleChange={handleSearchChange}
              placeholder={t("Competence name")}
              name="competenceName"
              value={searchData.competenceName}
            />
          </div>
          <div className="col-3">
            <div className="d-flex align-items-center">
              <ResetBtn handleResetClick={(e) => handleSubmit(e, true)} />
              <SearchBtn handleSearchClick={handleSubmit} />
            </div>
          </div>
        </div>
      </div>

      <div className="position-relative tableMainWrapper">
        <div>
          <AccessControl
            requiredPermissions={[
              {
                permission: "Competence",
                create: true,
              },
            ]}
            override={userData.isSuperAdmin}
          >
            <div className="row">
              <div className="ManageCreateBtn">
                <LinkTo
                  pagelink={PATH_COMPETENCE_CREATE}
                  title={t("Create competence")}
                  icon={faPlus}
                />
              </div>
            </div>
          </AccessControl>
        </div>
        <div className="table-responsive tableSection">
          <div className="TableHeight">
            <DynamicTableStructure
              isAction
              headers={CompetenceHeader}
              data={competenceObject}
              handleEdit={handleEdit}
              handleArchive={handleDelete}
              //   handleView={handleView}
              permission={"Competence"}
            />
          </div>
          <div
            className="pagination justify-content-center align-items-center"
            style={{ paddingTop: "0.25vw" }}
          >
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={onPageChange}
            />
          </div>
        </div>
      </div>
      <div style={{ padding: "1vw 0" }}>
        <Link
          to={PATH_MASTER_DATA}
          className="text-uppercase back-btn text-decoration-underline"
        >
          {t("Back")}
        </Link>
      </div>
      <ModalPopup
        show={showModal}
        onHide={handleModalClose}
        title={t("Archive confirmation")}
        body={t("Are you sure want to archive?")}
        onCloseButtonClick={handleModalClose}
        onConfirmButtonClick={handleSaveChanges}
        closeTitle={t("No")}
        confirmTitle={t("Yes")}
      />
    </AccessControl>
  );
};
export default ManageCompetencePage;

{
  /* <div className="table-responsive">
<DynamicTableStructure
  isAction
  headers={CompetenceHeader}
  data={competenceObject}
  handleEdit={handleEdit}
  handleArchive={handleDelete}
  handleView={handleView}
  permission={"Competence"}
/>
</div>
<div className="pagination justify-content-center align-items-center my-3">
<Pagination
  currentPage={currentPage}
  totalPages={totalPages}
  onPageChange={onPageChange}
/>
</div>
<div className="">
<Link
  to={PATH_MASTER_DATA}
  className="text-uppercase back-btn text-decoration-underline"
>
  {t("Back")}
</Link>
</div> */
}
